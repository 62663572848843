import React from "react";

// Styles
import "./svg.css";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 100 120"
    >
      <image
        style={{ overflow: " visible", opacity: "0.5" }}
        width="92"
        height="116"
        xlinkHref="/img/logoDropShadow.png"
        transform="matrix(1 0 0 1 7 5)"
      />
      <path
        d="M37.4,66.7V47.6l4,2.3v19C40,68.3,38.7,67.6,37.4,66.7 M65,47.6l4,2.3v10.9c-1.1,1.6-2.5,3.1-4,4.4V47.6z M51.5,38.7
				l5.4,3.1v27.9c-1.7,0.5-3.5,0.9-5.4,1V38.7z M27.6,48.1c0-12.5,10.1-22.6,22.6-22.6s22.6,10.1,22.6,22.6c0,4-1,7.8-2.9,11v-9.8
				l-5.1-3.4l-6.5,3.6v-8.5l-7-4.6l-9.1,5v7.9l-5-3.3l-6.7,3.7v9.9C28.7,56,27.6,52.2,27.6,48.1 M13.8,48.1
				c0,26.9,35.9,60.7,36.4,60.7c0.5,0,36.4-33.8,36.4-60.7c0-20.1-16.3-36.4-36.4-36.4S13.8,28,13.8,48.1"
      />
    </svg>
  );
};
