import React from "react";
import { withRouter } from "react-router-dom";

// Components
import VerticalScroll from "../../../components/vertical-scroll";

// Utilities
import { getAdStatus } from "../../../../utilites/status";
import { formatListingTime, toTitleCase } from "../../../../utilites/format";

// Assets
import Skyline from "../../../../assets/svg/skyline";

// Styles
import "./other-ads.css";

const RelatedAds = ({ isAd = true, currentId = "", ads, name, history }) => {
  const filterAds = ads.filter(({ _id }) => currentId !== _id);

  return (
    !!filterAds.length && (
      <div className="listing-content-container">
        <div className="other-ads-specs-container">
          <p>{`Related Posts: `}</p>
        </div>

        <VerticalScroll>
          {filterAds.map((ad) => {
            const { _id, title, categories, isFind, isGrowth } = ad;
            const { cat1Img } = categories;
            const formattedAd = formatListingTime({ ad });
            const { status } = getAdStatus(formattedAd);
            const url = isFind ? `/find/${_id}` : `/listing/${_id}`;

            return (
              <a
                className={`pointer other-ads-container result-card-${status}`}
                href={url}
                target="_blank"
                key={_id}
              >
                {cat1Img ? (
                  <img className="other-ads-image-" alt={name} src={cat1Img} />
                ) : (
                  <div className="other-ads-image-">
                    <Skyline />
                  </div>
                )}
                <div
                  className={`result-card-overlay result-card-overlay-gradient`}
                />
                <div
                  className={`result-card-overlay result-card-status-overlay result-card-overlay-${status}`}
                />
                <div className="other-ads-title-conatiner">
                  <p className="other-ads-title">
                    {toTitleCase({ input: title, lowerCaseRest: false })}
                  </p>
                </div>
              </a>
            );
          })}
        </VerticalScroll>
      </div>
    )
  );
};

export default withRouter(RelatedAds);
