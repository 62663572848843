import React from "react";

// Styles
import "./svg.css";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 19"
    >
      <path
        className="svg-clip-rule"
        d="M1.1,0.5h27.8c0.2,0,0.5,0.2,0.5,0.5v4.5c-2.1,0.1-3.7,1.9-3.7,4c0,2,1.6,3.8,3.7,4v4.5c0,0.3-0.3,0.6-0.5,0.6
  H1.1c-0.3,0-0.5-0.3-0.5-0.6v-4.5c2.1-0.2,3.7-1.9,3.7-4c0-2.1-1.7-3.9-3.7-4V1C0.6,0.7,0.8,0.5,1.1,0.5z M15,4.7l1.2,3.7H20
  l-3.2,2.2l1.2,3.6L15,12l-3.2,2.2l1.2-3.6L10,8.4h3.8L15,4.7z"
      />
    </svg>
  );
};
