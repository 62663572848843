import React from "react";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 18"
    >
      <path
        d="M27.8,1.7l-6,3.2v-1c0-1.8-1.6-3.3-3.6-3.3H3.7c-2,0-3.6,1.5-3.6,3.3v10.3c0,1.8,1.6,3.3,3.6,3.3h14.5c2,0,3.6-1.5,3.6-3.3
	v-1l6,3.2c1.1,0.6,2.1,0.1,2.1-1.1V2.8C29.8,1.6,28.9,1.1,27.8,1.7z"
      />
    </svg>
  );
};
