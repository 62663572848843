import { callAPI } from ".";
import { baseURL } from "./url";
import { formatQueryParams } from "../utilites/format";

const dataEntryURL = baseURL("/data-entry");

export function getSignedUpBusinesses(options) {
  const url = `${dataEntryURL}?${formatQueryParams(options)}`;
  return callAPI(url, "GET");
}

export function dataEntryClaim(data) {
  return callAPI(`${dataEntryURL}/claim`, "POST", data);
}

export function dataEntryVerifyAvailability(phone) {
  const url = `${dataEntryURL}/verify?phone=${phone}`;
  return callAPI(url, "GET");
}

export function dataEntryCreate(data) {
  return callAPI(dataEntryURL, "PUT", data);
}
