import React, { useState } from "react";
import { connect } from "react-redux";
import * as stateActions from "../../../redux/actions/state-actions";
import * as userActions from "../../../redux/actions/user-actions";

// Components
import Spacer from "../../components/common/spacer";
import Button from "../../components/common/button";
import Modal from "../../components/modal";
import PasswordInput from "../../components/common/password-input";

// Utilities
import { validatePassword } from "../../../utilites/validate";
import { setPasswordToken, getPaswordToken } from "../../../api/password";
import { formatPhoneNumber } from "../../../utilites/format";

// Styles
import "./recovery.css";

const Recovery = ({ match, setMessage, setLoader, setUser, history }) => {
  const [passwordInput, setPasswordInput] = useState("");
  const [verifyPasswordInput, setVerifyPasswordInput] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [userData, setUserData] = useState({});
  const { params } = match || {};
  const { token } = params || {};

  async function handleSubmit(evt) {
    evt.preventDefault();
    if (
      passwordInput === verifyPasswordInput &&
      validatePassword(passwordInput)
    ) {
      setLoader(true);
      const userData = {
        token,
        password: passwordInput,
      };
      const { user, expired } = await setPasswordToken(userData);
      if (expired) {
        setUserData(expired);
        const { email, phone } = expired;
        let account = email;
        if (phone) account = formatPhoneNumber({ phone });
        setModalMessage(
          `Would you like to request a new recovery email sent to the account associated with ${account}`
        );
      }
      if (user) {
        const isUser = "saved" in user;
        setUser({ isUser, user });
        history.push(`/${isUser ? "user" : "business"}/profile`);
        setMessage({ message: "Password Updated" });
      }
      setLoader(false);
    } else {
      setMessage({ message: "Invalid Password", messageType: "error" });
      setPasswordInput("");
      setVerifyPasswordInput("");
    }
  }

  async function handleModalAccept() {
    setModalMessage("");
    setLoader(true);
    const { recoverEmail } = await getPaswordToken(userData);
    if (recoverEmail) {
      setMessage({
        message: "Reset link has been emailed",
      });
    }
    setLoader(false);
    history.push("/");
  }

  return (
    <>
      <Spacer height={40} />
      <form
        id="form-business-sign-up"
        className="card-wrapper signup-wrapper-BusinessSignUp"
      >
        <div className="card card-form">
          <div className="card-info-form">
            <div className="recover-title-container">
              <h2>Account Recovery</h2>
              <p>Enter your new password</p>
            </div>
            <label id="passwordInput" className="label-form">
              Password:
            </label>
            <PasswordInput
              value={passwordInput}
              handleChange={(evt) => setPasswordInput(evt.target.value)}
            />
            <label id="verifyPasswordInput" className="label-form">
              Verify Password:
            </label>
            <PasswordInput
              value={verifyPasswordInput}
              handleChange={(evt) => setVerifyPasswordInput(evt.target.value)}
            />
            <legend className="legend-form">
              Requires: Min length 8, letter(lower & uppercase), number, and a
              special character.
            </legend>
            <div className="submit-wapper-UserSignUp">
              <div className="submit-button-UserSignUp">
                <Button text="Submit" handleClick={handleSubmit} />
              </div>
            </div>
          </div>
        </div>
        <Modal
          directModalMessage={modalMessage}
          modalCancel="no"
          modalAccept="Resend Email"
          handleCancelClick={() => history.push("/")}
          handleAcceptClick={handleModalAccept}
        />
      </form>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setUser: (userObj) => dispatch(userActions.setUser(userObj)),
  setLoader: (value) => dispatch(stateActions.setLoader(value)),
  setMessage: (messageObj) => dispatch(stateActions.setMessage(messageObj)),
});

export default connect(null, mapDispatchToProps)(Recovery);
