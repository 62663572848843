import React from "react";

export default props => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 16 16"
    >
      <path
        d="M14.8,9.8c-0.9-2.8-2.9-4.7-5.4-5.3c0,0,0,0,0,0c0-0.8-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4c0,0,0,0,0,0
		C4.1,5.1,2.1,7.1,1.2,9.8l-0.1,0.4h13.9L14.8,9.8z M8,3.7c0.4,0,0.8,0.3,0.8,0.8C8.6,4.4,8.3,4.4,8,4.4c-0.3,0-0.6,0-0.8,0.1
		C7.2,4,7.6,3.7,8,3.7z M1.9,9.6C2.9,6.8,5.3,5,8,5c2.7,0,5.1,1.9,6.1,4.7H1.9z"
      />
      <path
        d="M15.9,10.8H0.1v0.6h1c0,0,0,0,0,0c0,0.6,0.5,1.2,1.2,1.5l0,0h11.1l0,0c0.7-0.3,1.2-0.8,1.2-1.5c0,0,0,0,0,0h1V10.8z
		 M14.2,11.4c0,0.4-0.4,0.8-0.8,0.9H2.5c-0.4-0.2-0.8-0.5-0.8-0.9c0,0,0,0,0,0L14.2,11.4C14.2,11.4,14.2,11.4,14.2,11.4z"
      />
      <path d="M8.7,5.7l0,0.6c1.4,0.4,2.7,1.3,3.6,2.5l0.7,0C11.9,7.3,10.4,6.1,8.7,5.7z" />
    </svg>
  );
};
