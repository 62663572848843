import moment from "moment";
import { sortResults } from "../../utilites/sort";
import { getCurrentDate } from "../../utilites/date";
import { getCurrentTime, verifyCurrentTime } from "../../utilites/time";

const defaultFilters = {
  type: "",
  subCat: "",
  feature: "",
  searchInput: "",
  search: "",
  date: "",
  time: "",
  distance: "50 Miles",
  sortBy: "Start Time",
  limit: 30,
};

const initialState = {
  results: [],
  autoComplete: [],
  filters: { ...defaultFilters },
  isDefaultFilters: true,
};

const dataReducer = (state = initialState, action) => {
  const { type, payload } = action;
  const { time, sortBy } = state;
  let filters = {};
  let isDefaultFilters = true;
  let results = [];
  let override = {};

  const currentDate = moment().format("L");
  const isDateBefore = moment(state.filters.date).isBefore(currentDate);

  if (isDateBefore) override.date = getCurrentDate();

  switch (type) {
    case "setResults":
      results = payload.results;
      if (
        (sortBy === "Start Time" && payload.pathname !== "") ||
        payload.pathname === "/"
      )
        results = sortResults(results, time);
      return { ...state, results, ...override };

    case "addResults":
      results = payload.results;
      if (
        (sortBy === "Start Time" && payload.pathname !== "") ||
        payload.pathname === "/"
      )
        results = sortResults(results, time);
      return { ...state, results: [...state.results, ...results] };

    case "updateResultInResults":
      const { _id: updatedId } = payload;
      const updatedResults = state.results.map((result) => {
        const { _id } = result;
        if (_id === updatedId) return payload;
        return result;
      });
      return { ...state, results: updatedResults };

    case "setAutoComplete":
      return { ...state, autoComplete: payload, ...override };

    case "setFilter":
      filters = {
        ...state.filters,
        ...override,
        ...payload,
      };
      for (let key in filters) {
        if (
          key !== "type" &&
          key !== "searchInput" &&
          key !== "search" &&
          filters[key] !== defaultFilters[key]
        ) {
          isDefaultFilters = false;
        }
        if (key === "time" && filters[key] === getCurrentTime())
          filters[key] = "";
      }
      return { ...state, filters, isDefaultFilters, results };

    case "resetFilters":
      return {
        ...state,
        results,
        filters: { ...defaultFilters, type: state.filters.type },
        isDefaultFilters,
      };

    default:
      return state;
  }
};

export default dataReducer;
