/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:83813da8-576d-411c-83cf-04b046a4d44c",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_dVJQWJSFd",
    "aws_user_pools_web_client_id": "1f8qs403m3b893km0r4085aifv",
    "oauth": {},
    "aws_user_files_s3_bucket": "eventhoundassets163325-prod",
    "aws_user_files_s3_bucket_region": "us-west-2",
    "aws_mobile_analytics_app_id": "a70bae194f0e40e1bd549eeae025ff0f",
    "aws_mobile_analytics_app_region": "us-west-2"
};


export default awsmobile;
