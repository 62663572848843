import { callAPI } from ".";
import { baseURL } from "./url";

const passwordURL = baseURL("/password");

export function getPaswordToken({ phone, email }) {
  let params = "";
  if (phone) params = `phone=${phone}`;
  if (email) params = `email=${email}`;
  let url = `${passwordURL}?${params}`;
  return callAPI(url, "GET");
}

export function updatePassword(passwordData) {
  return callAPI(passwordURL, "POST", passwordData);
}

export function setPasswordToken(userData) {
  return callAPI(passwordURL, "PUT", userData);
}
