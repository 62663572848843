import React from "react";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 26 30"
    >
      <path
        d="M12.4,29.3c0.5,0,1,0,1.6-0.1c0.1,0,0.2,0,0.2,0c0,0,0,0,0,0c1.1-0.1,2.1-0.4,3.1-0.7c0,0-3.8-2.3-3.9-8.9c0,0,0,0,0,0
      c-4.7-0.8-3.1-4.7-3.1-4.7c0,0,0,0,0,0.1c-0.1,0.5-0.7,4.2,4.2,3.9c0,0,3.2,1,4.9,2.2c0,0,0,1.7,2.2,2.2c0,0,0.8-0.5,1.2-0.7
      l0.3,0.1c0,0,1.6-0.4,2-1.8l-0.2-1.2c0,0,0.2-0.6,0.7-1.3c0,0-0.4-0.7-1.2-1.1c0,0-1.3-2.1-2.8-3.7s-1.6-2.3-1.6-3.6
      c0,0-0.7-1-1.8-1.4c0,0-0.4-1-1.3-2c0.4-0.7,0.9-2.3,0.4-5.1c0,0-0.2,1-0.7,1.7c-0.4,0.5-1,1-1.4,1.6c0.4,0.3,0.7,0.9,0.7,0.9
      c-0.5-0.4-1.1-0.7-1.5-0.8c-0.1,0-0.2-0.1-0.3-0.1c0.2-0.9,0.3-2.2,0-4.1c0,0-0.2,1-0.7,1.7c-0.4,0.5-1,1-1.3,1.6
      c-0.1,0.1-0.1,0.2-0.2,0.4c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.2c0,0,0,0,0,0.1c0,0.1,0,0.1,0,0.2
      c0,0.1,0,0.3,0.1,0.4c0,0-0.4-0.3-0.4-1c-0.8,0-1.8,0.1-2.9,0.5C6.7,5.6,6.1,6.6,5.9,6.8C5.6,7.4,5.3,8.3,5.3,9.4
      c0,2.4,1.8,4.7,1.6,7.3c0,0-0.1-1.9-1-3.3c-0.6-0.9-1.7-2.7-1.6-4.5c-0.3,0.6-0.7,1.5-0.7,2.6c0,2.4,1.8,4.7,1.6,7.3
      c0,0-0.1-1.9-1-3.3c-0.5-0.8-1.5-2.4-1.6-4.1C2.3,12.1,2,12.9,2,14c0,2.4,1.8,4.7,1.6,7.3c0,0-0.1-1.9-1-3.3
      c-0.3-0.5-0.8-1.3-1.2-2.3c-0.1,0.4-0.2,0.8-0.2,1.3c0,2,1.6,4,1.3,6.2c0,0-0.1-1.6-0.9-2.8c-0.1-0.2-0.3-0.5-0.5-0.9
      c0,0,0.1,2.7-0.6,5.1c2.7,3.2,7,4.2,10,4.5C11.2,29.3,11.8,29.3,12.4,29.3z M22.6,18.6c0.1,0,0.2,0,0.3,0c0,0-0.1,0-0.1,0
      c-0.5,0.2-0.7,0.9-0.4,1.5c0.1,0.1,0.1,0.2,0.2,0.3c-0.5,0-0.9-0.4-0.9-0.9C21.6,19,22,18.6,22.6,18.6z M16,11c0,0,0.9,0.4,0.6,1.5
      c0,0-0.6,0.4-1.3-0.2c-0.6-0.4-1.4-0.5-1.4-0.5S15.2,11,16,11z"
      />
    </svg>
  );
};
