import React from "react";

export default props => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 16 16"
    >
      <path
        d="M15,14.4l-3.9-3.9c0.9-1,1.4-2.4,1.4-3.8c0-3.2-2.6-5.9-5.9-5.9c-3.2,0-5.9,2.6-5.9,5.9s2.6,5.9,5.9,5.9
	c1.5,0,2.8-0.5,3.8-1.4l3.9,3.9c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1C15.2,14.9,15.2,14.6,15,14.4z M1.7,6.7
	c0-2.8,2.3-5,5-5c2.8,0,5,2.3,5,5s-2.3,5-5,5C3.9,11.8,1.7,9.5,1.7,6.7z"
      />
    </svg>
  );
};
