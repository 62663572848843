import React from "react";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 28"
    >
      <path
        d="M29.1,7.6c0-2.5-2-4.5-4.5-4.5c-0.1,0-0.2,0-0.4,0c-0.7-1.4-2.2-2.3-3.9-2.3c-1.5,0-2.9,0.8-3.7,2c-0.2,0-0.5-0.1-0.7-0.1
		c-1.5,0-2.8,0.7-3.6,1.8l0.5,0.7c0.6,0.8,0.7,1.8,0.2,2.7c-0.1,0.1-0.1,0.2-0.2,0.4l1.5,2.1c0.6,0.8,0.7,1.8,0.2,2.7
		c-0.1,0.1-0.1,0.2-0.2,0.4l1.5,2.1c0.4,0.6,0.6,1.3,0.4,1.9c0.3,0.1,0.6,0.1,1,0.1c0.6,0,1.1-0.1,1.6-0.3l-0.3,5.5
		c-1.5-0.2-3.1-0.3-4.7-0.3c-1.7,0-3.4,0.1-4.9,0.4v-5H14c0.7,0,1-0.7,0.7-1.3c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0
		l-2.8-3.8h0.5c0.7,0,1.1-0.8,0.7-1.4l-2.8-3.8h0.5c0.7,0,1.1-0.8,0.7-1.4l-3-4.1c-0.3-0.5-1-0.5-1.4,0l-3,4.1
		c-0.4,0.6,0,1.4,0.7,1.4h0.5l-2.8,3.8c-0.4,0.6,0,1.4,0.7,1.4h0.5L1,16.6c-0.4,0.6,0,1.4,0.7,1.4h4.8v5.4c-1.8,0.4-3.5,1-4.9,1.7
		c-1.1,0.6-0.6,2.2,0.7,2.1c3.6-0.4,7.6-0.7,11.8-0.7s8.2,0.2,11.8,0.7c1.2,0.1,1.8-1.5,0.7-2.1c-1.4-0.7-3-1.3-4.8-1.7l-0.3-6.1
		c0.5,0.2,1.1,0.3,1.6,0.3c2.5,0,4.5-2,4.5-4.5c0-0.7-0.1-1.3-0.4-1.9C28.4,10.5,29.1,9.1,29.1,7.6z"
      />
    </svg>
  );
};
