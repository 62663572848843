import React from "react";

// Utilities
import { buttonStyles } from "../../../../constants/format";

// Styles
import "./button.css";

const Button = ({
  size = "medium",
  isPrimary = true,
  children,
  text = "submit",
  isDisabled = false,
  handleClick,
  styles = {},
}) => (
  <button
    className={`button-wrapper-Button ${
      isDisabled
        ? "button-disabled"
        : isPrimary
        ? "button-primary"
        : "button-secondary"
    }`}
    style={{ ...buttonStyles.button[size], ...styles }}
    onClick={handleClick}
  >
    {children && (
      <div
        className="button-svg-container-Button"
        style={buttonStyles.svg[size]}
      >
        {children}
      </div>
    )}
    <p className="button-text-Button">{text.toUpperCase()}</p>
  </button>
);

Button.defaultProps = {
  size: "medium",
  isPrimary: true,
  text: "",
};

export default Button;
