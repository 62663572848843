import React, { useState } from "react";
import { withRouter, useParams } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import * as stateActions from "../../../../redux/actions/state-actions";
import * as userActions from "../../../../redux/actions/user-actions";

// Components
import ViewLayout from "../../../components/view-layout";
import BusinessCommon from "../../../components/business-common";
import Button from "../../../components/common/button";
import InputLabeled from "../../../components/input-labeled";
import Checkbox from "../../../components/common/checkbox";
import ImageUpload from "../../../components/image-upload";
import Modal from "../../../components/modal";

// Utilities
import { verifyForm, shakeElement } from "../../../../utilites/animation";
import { validateEmail, validatePassword } from "../../../../utilites/validate";
import { yelpVerifyBusiness, createBusiness } from "../../../../api/business";
import { storeImage, deleteImage } from "../../../../api/image";
import { convertDateToDbDate } from "../../../../utilites/date";

// Assets
import SkinnyArrow from "../../../../assets/svg/directionArrow";

// Styles
import "../sign-up.css";
import "./business.css";

const BusinessSignUp = ({ setLoader, setUser, history }) => {
  const { promoCode } = useParams();
  const [modalMessage, setModalMessage] = useState("");
  const [imageFile1, setImageFile1] = useState("");
  const [imageFile2, setImageFile2] = useState("");
  const [imageFile3, setImageFile3] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [verifyPasswordInput, setVerifyPasswordInput] = useState("");
  const [promoInput, setPromoInput] = useState(promoCode || "");
  const [consentInput, setConsentInput] = useState(false);
  const [commonData, setCommonData] = useState({});
  const images = [imageFile1, imageFile2, imageFile3];

  const handleCommonData = (businessData) => {
    setCommonData(businessData);
  };

  const handleChange = (evt, type, newValue) => {
    const { value } = evt.target;
    switch (type) {
      case "imageInput1":
        return setImageFile1(newValue);
      case "imageInput2":
        return setImageFile2(newValue);
      case "imageInput3":
        return setImageFile3(newValue);
      case "businessPasswordInput":
        return setPasswordInput(value);
      case "businessVerifyPasswordInput":
        return setVerifyPasswordInput(value);
      case "consentInput":
        return setConsentInput(newValue);
      case "promoInput":
        return setPromoInput(value.slice(0, 6));
    }
  };

  function createBusinessData() {
    const {
      nameInput,
      managerNameInput,
      emailInput,
      phoneInput,
      showPhone,
      websiteInput,
      addressInput,
      cityInput,
      stateInput,
      zipInput,
      hours,
      categories,
      featuresInput,
      deliveryLinkInput,
      descriptionInput,
    } = commonData;
    const businessData = {
      name: nameInput.trim().toLowerCase(),
      managerName: managerNameInput.trim().toLowerCase(),
      email: emailInput.trim().toLowerCase(),
      phone: phoneInput,
      showPhone,
      website: websiteInput.trim(),
      address: addressInput.trim().toLowerCase(),
      city: cityInput.trim().toLowerCase(),
      state: stateInput.trim().toLowerCase(),
      zip: zipInput,
      password: passwordInput,
      features: featuresInput,
      deliveryLink: deliveryLinkInput,
      hours,
      image: "",
      categories,
      verified: false,
      lastUpdated: convertDateToDbDate(),
      createdDate: convertDateToDbDate(),
    };
    if (descriptionInput) businessData.description = descriptionInput.trim();
    if (promoInput) businessData.promoCode = promoInput;
    return businessData;
  }

  const handleBusiness = async (businessData) => {
    if (imageFile1 || imageFile2 || imageFile3) {
      const storedImages = await Promise.all(
        images.reduce((acc, image) => {
          if (image) return [...acc, storeImage(image, "business")];
          return acc;
        }, [])
      );
      if (storedImages.length) businessData.images = storedImages;
    }
    const { user } = await createBusiness(businessData);
    if (user) {
      return setTimeout(() => {
        setUser({ isUser: false, user });
        setLoader(false);
        history.push("/business/profile");
      }, 1000);
    } else {
      if (businessData.images)
        await Promise.all(
          businessData.images.map((image) => deleteImage(image))
        );
      setLoader(false);
    }
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    const businessData = createBusinessData();
    const {
      promoCode,
      name,
      managerName,
      email,
      phone,
      address,
      city,
      state,
      zip,
      categories,
      description,
      features,
      deliveryLink,
    } = businessData;
    const { cat1, cat1Sub, cat2, cat2Sub } = categories || {};
    const isBetaPromo = promoCode === "beta2y";
    const passwordVerified = isBetaPromo
      ? true
      : passwordInput === verifyPasswordInput &&
        validatePassword(passwordInput);
    if (!passwordVerified) {
      setPasswordInput("");
      setVerifyPasswordInput("");
    }
    const validateIds = {
      nameInput: name,
      managerNameInput: managerName,
      emailInput: validateEmail(email),
      phoneInput: phone.length > 9,
      addressInput: address,
      cityInput: city,
      stateInput: state,
      zipInput: zip,
      businessPasswordInput: passwordVerified,
      businessVerifyPasswordInput: passwordVerified,
      consentInput,
      cat1: cat1 && cat1Sub,
      descriptionInput: (description || "").trim().length > 40,
      imageInput: imageFile1 || imageFile2 || imageFile3,
    };
    if (cat2) validateIds.cat2 = cat2 && cat2Sub;
    if (features["Delivery"] && !deliveryLink) validateIds.deliveryLink = false;
    const hasRequiredInfo = verifyForm(validateIds);
    if (!passwordVerified || !hasRequiredInfo)
      return shakeElement("form-business-sign-up");

    setLoader(true);
    const { yelp, option, error } = await yelpVerifyBusiness(businessData);
    if (option) {
      setLoader(false);
      return setModalMessage(option);
    }
    if (!error) {
      if (yelp) {
        yelp.fetchedDate = moment().format();
        businessData.yelp = yelp;
      }
      return await handleBusiness(businessData);
    }
    setLoader(false);
  };

  const handleAcceptClick = async (evt) => {
    evt.preventDefault();
    setModalMessage("");
    setLoader(true);
    const businessData = createBusinessData();
    return await handleBusiness(businessData);
  };

  const passwordInputs = [
    {
      name: "businessPasswordInput",
      value: passwordInput,
      label: "Password",
      type: "password",
    },
    {
      name: "businessVerifyPasswordInput",
      value: verifyPasswordInput,
      label: "Verify Password",
      type: "password",
    },
  ];

  return (
    <ViewLayout>
      <form
        id="form-business-sign-up"
        className="card-wrapper signup-wrapper-BusinessSignUp"
      >
        <div className="card card-form">
          <div className="card-info-form">
            <h2 className="card-title-SignUp">BUSINESS SIGN-UP</h2>
          </div>
        </div>

        <BusinessCommon handleCommonData={handleCommonData} page="sign-up" />

        <div className="card card-form">
          <div className="card-info-form">
            <label id="imageInput" className="label-form">
              Image:
            </label>
            <div className="time-selector-wrapper-CreateListing">
              <ImageUpload
                type="imageInput1"
                imageInput={imageFile1}
                handleChange={handleChange}
              />
            </div>
            {(imageFile1 || imageFile2) && (
              <>
                <label className="label-form">Image:</label>
                <div className="time-selector-wrapper-CreateListing">
                  <ImageUpload
                    type="imageInput2"
                    imageInput={imageFile2}
                    handleChange={handleChange}
                  />
                </div>
              </>
            )}
            {((imageFile2 && imageFile1) || imageFile3) && (
              <>
                <label className="label-form">Image:</label>
                <div className="time-selector-wrapper-CreateListing">
                  <ImageUpload
                    type="imageInput3"
                    imageInput={imageFile3}
                    handleChange={handleChange}
                  />
                </div>
              </>
            )}
            <legend className="legend-form">
              Required: Prefer pictures in landscape format
            </legend>
          </div>
        </div>

        <div className="card card-form">
          <div className="card-info-form">
            {passwordInputs.map((input, idx) => {
              const { name, value, label, type } = input;
              return (
                <InputLabeled
                  name={name}
                  value={value}
                  label={label}
                  type={type}
                  handleChange={(evt) => handleChange(evt, name)}
                  key={idx}
                />
              );
            })}
            <legend className="legend-form">
              Requires: Min length 8, letter(lower & uppercase), number, and a
              special character.
            </legend>
          </div>
        </div>

        <div className="card card-form">
          <div className="card-info-form">
            <InputLabeled
              name="promoInput"
              value={promoInput}
              label="Promo Code"
              handleChange={(evt) => handleChange(evt, "promoInput")}
            />
            <legend className="legend-form" />
          </div>
        </div>

        <div id="consentInput" className="card card-form">
          <div className="card-info-form">
            <label className="label-form">Terms Of Use:</label>
            <div className="submit-outer-wrapper-CreateListing">
              <div className="submit-inner-wrapper-CreateListing">
                <div className="consent-terms-container">
                  <a
                    className="consent-terms-link"
                    href="/terms-of-service"
                    target="_blank"
                  >
                    View Terms
                  </a>
                  <div className="consent-terms-arrow">
                    <SkinnyArrow />
                  </div>
                </div>
                <div id="consetInput" className="consent-wrapper-CreateListing">
                  <Checkbox
                    state={consentInput}
                    handleClick={(evt) =>
                      handleChange(evt, "consentInput", !consentInput)
                    }
                    params={[{ target: { value: "" } }, "consentInput"]}
                  />
                  <p className="consent-text-CreateListing">
                    I have read and accept the terms
                  </p>
                </div>
              </div>
              <div className="submit-button-container">
                <Button
                  className="submit-button-CreateListing"
                  text="Submit"
                  handleClick={handleSubmit}
                />
              </div>
            </div>
          </div>
        </div>
        <Modal
          directModalMessage={modalMessage}
          modalAccept="Continue"
          modalCancel="Review"
          handleCancelClick={(evt) => {
            evt.preventDefault();
            setModalMessage("");
          }}
          handleAcceptClick={handleAcceptClick}
        />
      </form>
    </ViewLayout>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setLoader: (loaderState) => dispatch(stateActions.setLoader(loaderState)),
  setUser: (userObj) => dispatch(userActions.setUser(userObj)),
});

export default connect(null, mapDispatchToProps)(withRouter(BusinessSignUp));
