import React from "react";

import "./svg.css";

export default () => (
  <svg
    width="100%"
    height="100%"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
  >
    <path
      className="svg-clip-rule"
      d="M58.9,62.6c-2.7-2-4.5-4.8-6.7-7.3c-1.1-1.2-2-2.7-2.8-4.1c-1.7-3.1-3.5-7.3-0.5-10.3c1.9-1.9,3.9-1.9,6.2,0
	c1.6,1.3,2.8,3.1,4.1,4.6c0.6,0.6,2.6,2.5,2.2,0.8c-0.2-1-0.9-3.4-0.8-5c0.1-2,0-2.6,0.8-4.6c0.7-1.8,2.1-3.3,3.5-4.5
	c1.8-1.6,3.5-3.1,5.3-4.7c3.2-2.9,6-6,8.4-9.6c1.1-1.6,2.3-3.4,2.5-5.4c0.1-1.4-0.7-3.7-1.9-4.5c-1.9-1.2-4-0.2-5.6,1
	c-0.7,0.5-1.2,1.1-1.8,1.7c-1.5,1.5-3,3.2-4.5,4.7c-3.8,3.6-8.2,6.3-13,8.2C45,27.2,35,28,25.4,29.9c-3.9,0.8-14,1.8-18.7,2
	c-4.2,0.2-6,7.4,1.8,7.9c9.3,0.6,17.2,0.7,23.2,0.8c1.4,0,2.4,0.6,2.7,2.1c0.7,3.6,1,4,1,7.6c0,2.4-0.5,4.9-0.3,7.3
	c0.1,1,0.8,0.7,1.3,0.6c3.1-0.7,7.8,1,9.8,3.5c2,2.6,2.6,6.4,1.4,9.5c-0.1,0.3-0.9,1.3-0.6,1.5c0.6,0.5,2.3-1,2.7-1.3
	c1.5-0.9,2.9-1.8,4.3-2.9c2.2-1.8,4.5-3.1,7.2-0.2c-0.3-1.6,0.1-2.8-0.3-4C60.2,63.8,59.6,63.2,58.9,62.6z M50.6,72.7
	C49,74,47,75,45,75.7c-7.5,2.4-15.4-0.5-22.1-3.6c-0.8-0.4-1.7-0.6-2.4-0.2c-0.8,0.4-0.5,1.4-0.4,2.1c0.1,1.1,0.6,2.1,1.4,2.9
	c2.8,2.8,6.1,5.1,9.8,6.7c1.7,0.7,1.2,3.8,1.2,5.2c0,3.2,0.1,6.5,0.2,9.7c1.8,0.2,10.1,0.9,16.8-0.8c0-4.2,0.1-8.4,0-12.7
	c0-2.1,0.9-3.2,2.3-4.2c6.1-4.5,7.9-7.8,7.7-10.8C59.1,65.4,52,71.7,50.6,72.7z M96,37.6c-2.2-1.2-2.8-1.1-4,1
	c-1.3,2.2-2.4,4.5-3.6,6.7c-3,5.2-6.8,9.4-13.1,10.5c-1.1,0.2-2.3,0.2-3.3,0.1c-1.4-0.1-2.9-0.4-4.2-1c-5-2-8.2-6.8-11.6-10.7
	c-0.5-0.6-1.1-1.3-1.7-1.8c-1-1-2.1-1.6-3.4-1c-1.3,0.5-2,1.6-2.2,3c-0.1,1.2,0.4,2.4,0.8,3.5c0.9,2.3,2.4,4.5,3.9,6.4
	c0.6,0.8,1.2,1.5,1.9,2.3c1.2,1.3,2.4,2.6,3.7,3.9c1.2,1.2,2.3,2.5,3.7,3.5c0.7,0.5,1.5,0.7,1.6,1.6c0.2,3.9,0.4,7.9,0.6,11.8
	c0.2,4.9,0.5,9.8,0.9,14.7c3.5-1.7,6.8-3.8,9.9-6.2c0-0.1,0-0.2,0.1-0.4c0,0.1,0,0.2,0.1,0.3c3.5-2.6,6.7-5.6,9.6-8.9
	c-0.1-3.7-0.2-7.3-0.3-11c-0.1-1.5,0.3-2.6,1.4-3.7c3.8-3.8,7.1-8,8.9-13.1c0.9-2.8,1.4-5.7,1.6-8.7C97,39.5,96.6,38.5,96,37.6z
	 M72.4,38.3c-3.7-0.1-8.1,3.5-7.4,7.8c0.6,4.2,3.3,7.8,7.4,7.8c4.1,0,7.4-3.5,7.4-7.8C79.9,41.7,77.2,38.4,72.4,38.3z M31.9,13.8
	c0.6,5.4,4.4,9.8,9.8,9.8c5.4,0,9.8-4.4,9.8-9.8S46.4,3.4,41,3.4S31.3,8.2,31.9,13.8z M39,74.1c3.7-0.3,6.7-3.1,6.7-6.9
	c0-3.8-3-6.9-6.7-6.9c-3.7,0-6.9,1.6-7.2,6.2C31.5,70.4,34.3,74.5,39,74.1z M55.3,9.9c2.4-0.2,4.3-2,4.3-4.4c0-2.4-1.9-4.4-4.3-4.4
	s-4.4,1-4.6,3.9C50.5,7.6,52.3,10.2,55.3,9.9z M52.6,11.2c0.2,0.9,0.4,1.7,0.4,2.6c0,2.3-0.7,4.4-1.8,6.1c0,0.8,0,1.9,0,3.3
	c0.8-0.3,1.7-0.6,2.5-0.9c2.9-1.1,5.7-2.6,8.3-4.5c0-0.4,0-0.7,0-0.9c0-1.3,0.5-2.3,1.5-2.7c1.2-0.4,1.7-1.4,2.5-2.1
	c1.3-1.2,2.6-2.9,2.4-4.7c-0.2-3-4.7,1.1-5.6,1.7c-1.1,0.8-2.3,1.5-3.6,1.9C57,11.6,54.8,11.6,52.6,11.2z"
    />
  </svg>
);
