import { callAPI } from ".";
import { baseURL } from "./url";
import { formatQueryParams } from "../utilites/format";

const reviewURL = baseURL("/review");

export function searchReview(options) {
  const url = `${reviewURL}?${formatQueryParams(options)}`;
  return callAPI(url, "GET");
}

export function businessReview(options) {
  const url = `${reviewURL}/business?${formatQueryParams(options)}`;
  return callAPI(url, "GET");
}

export function businessWithNoListingsReview(options) {
  const url = `${reviewURL}/business/listings?${formatQueryParams(options)}`;
  return callAPI(url, "GET");
}

export function updateReviewStatus(data) {
  return callAPI(reviewURL, "POST", data);
}

export function findReviewUpdate(data) {
  return callAPI(`${reviewURL}/find`, "PUT", data);
}

export function findEditReviewUpdate(data) {
  return callAPI(`${reviewURL}/find`, "POST", data);
}
