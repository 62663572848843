import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as stateActions from "../../../redux/actions/state-actions";
import * as userActions from "../../../redux/actions/user-actions";

// Components
import SideMenu from "../slider-menu";
import MenuItem from "../slider-menu/menu-item";
import PasswordModal from "../password-modal";
import Button from "../common/button";
import BottomDrawer from "../bottom-drawer";

// Utilities
import { charLimitTo } from "../../../utilites/format";
import { verifyForm } from "../../../utilites/animation";
import { validatePassword } from "../../../utilites/validate";
import { updatePassword } from "../../../api/password";

// Assets
import Back from "../../../assets/svg/back";
import EventHound from "../../../assets/svg/eventHound-name";
import User from "../../../assets/svg/user";
import Profile from "../../../assets/svg/profile";

// Styles
import "./header.css";

const Title = ({
  isMobile,
  headerTitle,
  isFullHeader,
  user,
  isUser,
  history,
  location,
  setLoader,
  setUser,
  setMessage,
  setPaymentModal,
  setCovidModal,
}) => {
  const [logoutDrawer, setlogoutDrawer] = useState("");
  const { pathname } = location;
  const rootPath = pathname.split("/")[1];
  const pathOptions = ["login", "business-sign-up", "user-sign-up"];
  const foundInOptions = pathOptions.indexOf(rootPath);
  const { _id } = user || {};
  const { businessId, businessName } = headerTitle || {};
  const isProfilePage = window.location.href.indexOf("profile") > -1;
  let { image, locationType } = user || {};
  const isCorporate =
    !isUser && user ? locationType === "Multiple Locations" : false;
  if (!isUser)
    image = user && user.images && user.images.length ? user.images[0] : "";

  function handleLogOut() {
    if (logoutDrawer) {
      setUser();
      isUser
        ? localStorage.removeItem("user")
        : localStorage.removeItem("business");
      history.push("/");
      setlogoutDrawer("");
    } else {
      setlogoutDrawer("Confirm Logout");
    }
  }

  const LegalMenuItems = [
    {
      title: "terms of service",
      handleClick: () => history.push("/terms-of-service"),
    },
    {
      title: "privacy policy",
      handleClick: () => history.push("/privacy-policy"),
    },
  ];

  const StandardMenuItems = [
    {
      title: "contact",
    },
    {
      title: "legal",
      dropDownItems: LegalMenuItems,
    },
  ];

  const StanardUserItems = [
    ...StandardMenuItems,
    {
      title: "log out",
      handleClick: () => handleLogOut(),
    },
  ];

  const UserMenuItems = [
    {
      title: "edit profile",
      handleClick: () => history.push("/user/edit"),
    },
    ...StanardUserItems,
  ];

  const BusinessMenuItems = [
    {
      title: "edit profile",
      handleClick: () => history.push(`/business/edit/${_id}`),
    },
    {
      title: "post ad",
      handleClick: () => history.push("/create-listing"),
    },
    {
      title: "account",
      handleClick: () => setPaymentModal(true),
    },
    {
      title: "covid compliance",
      handleClick: () => setCovidModal(true),
    },
    ...StanardUserItems,
  ];
  if (isCorporate)
    BusinessMenuItems.splice(2, 0, {
      title: "Manage locations",
      handleClick: () => history.push("/business/corporate"),
    });

  function renderUserHeaderProfile() {
    const isProfileLocation = pathname.indexOf("profile") >= 0;
    const userType = isUser ? "user" : "business";
    const menuItems = isUser ? UserMenuItems : BusinessMenuItems;
    return isProfileLocation ? (
      <SideMenu>
        {menuItems.map((item, idx) => {
          const { title, dropDownItems, handleClick } = item || {};
          return (
            <MenuItem
              title={title}
              dropDownItems={dropDownItems}
              handleClick={handleClick}
              key={idx}
            />
          );
        })}
      </SideMenu>
    ) : (
      <>
        {image ? (
          <div
            className="header-profile-image-container-MobileHeader"
            onClick={() => history.push(`/${userType}/profile`)}
          >
            <img
              className="profile-image"
              src={image}
              alt=""
              height="100%"
              width="100%"
            />
          </div>
        ) : (
          <div
            className="header-profile-svg-container-MobileHeader"
            onClick={() => history.push(`/${userType}/profile`)}
          >
            <Profile />
          </div>
        )}
      </>
    );
  }

  return (
    <div
      className={`mobile-header-title header-el-wrapper ${
        isFullHeader ? "" : "header-el-shadow"
      }`}
    >
      <div
        className="header-title-outer-wrapper-MobileHeader"
        style={{
          width: isMobile ? "calc(100% - 20px)" : "calc(100% - 50px)",
          marginLeft: isMobile ? "10px" : "25px",
        }}
      >
        <div className="header-title-inner-wrapper-MobileHeader">
          {isMobile ? (
            <div className="header-back-container-MobileHeader">
              {!!rootPath && (
                <div
                  className="header-svg-container-MobileHeader header-back-MobileHeader"
                  onClick={() => history.goBack()}
                >
                  <Back />
                </div>
              )}
            </div>
          ) : (
            <img
              className="pointer title-EventHound-logo"
              src="/img/icons/event-hound-icon.png"
              alt="EventHound - Event Finder"
              onClick={() => history.push("/")}
            />
          )}
          {headerTitle && (
            <h2
              className="pointer header-title-business"
              onClick={() => history.push(`/business/${businessId}`)}
            >
              {isMobile
                ? charLimitTo(businessName, 20).toUpperCase()
                : businessName.toUpperCase()}
            </h2>
          )}
          {!headerTitle && (
            <div
              className="pointer header-logo-MobileHeader"
              onClick={() => history.push("/")}
            >
              <EventHound />
            </div>
          )}

          {isProfilePage ? (
            <div className="header-profile-container-MobileHeader">
              {user ? (
                renderUserHeaderProfile()
              ) : (
                <SideMenu>
                  {StandardMenuItems.map((item, idx) => {
                    const { title, dropDownItems, handleClick } = item || {};
                    return (
                      <MenuItem
                        title={title}
                        dropDownItems={dropDownItems}
                        handleClick={handleClick}
                        key={idx}
                      />
                    );
                  })}
                </SideMenu>
              )}
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (store) => ({
  headerTitle: store.state.headerTitle,
  user: store.user.user,
  isUser: store.user.isUser,
});

const mapDispatchToProps = (dispatch) => ({
  setLoader: (loaderState) => dispatch(stateActions.setLoader(loaderState)),
  setUser: (userObj) => dispatch(userActions.setUser(userObj)),
  setMessage: (messageObj) => dispatch(stateActions.setMessage(messageObj)),
  setPaymentModal: (boolean) => dispatch(stateActions.setPaymentModal(boolean)),
  setCovidModal: (boolean) => dispatch(stateActions.setCovidModal(boolean)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Title));
