import React from "react";

// Components
import VerticalScroll from "../../../components/vertical-scroll";

// Assets
import RatingStarsClip from "../../../../assets/svg/rating-stars-clip";

// Styles
import "./yelp.css";

const Yelp = ({ yelp, name, setImage }) => {
  const { url, photos, rating = 0, review_count } = yelp || {};
  const ratingPercent = (rating / 5) * 100;
  const isYelp = yelp && "fetchedDate" in yelp;
  return isYelp ? (
    <div style={{ position: "relative" }}>
      <a href={url} target="_blank">
        <img className="yelp-logo" alt="yelp" src="/img/yelp.png" />
      </a>
      <div className="listing-content-container">
        <div className="yelp-specs-container">
          <a className="yelp-review-wrapper" href={url} target="_blank">
            <div className="yelp-review-result-conatiner">
              <div
                className="yelp-review-fill-clip"
                style={{ width: `${ratingPercent}%` }}
              >
                <RatingStarsClip />
              </div>
            </div>
            <p className="yelp-review-count-text">
              (<span className="yelp-review-count">{review_count}</span>)
              Reviews
            </p>
          </a>
        </div>
        <VerticalScroll>
          {photos.map((url, idx) => (
            <img
              className="pointer yelp-image-preview"
              alt={name}
              src={url}
              onClick={() => setImage(url)}
              key={idx}
            />
          ))}
        </VerticalScroll>
      </div>
    </div>
  ) : null;
};

export default Yelp;
