import React from "react";

// Styles
import "./svg.css";

export default () => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 900 85"
    >
      <path d="M7.1,3.3h57.5V19H22.8v17.8h31.3v15.7H22.8V66h41.8v15.7H7.1V3.3z" />
      <path d="M128.8,81.7h-4.7L81.2,3.3h17.9l27.4,50l27.3-50h17.9L128.8,81.7z" />
      <path d="M189.3,3.3h57.5V19h-41.8v17.8h31.3v15.7h-31.3V66h41.8v15.7h-57.5V3.3z" />
      <path d="M277.8,3.3h4.2L330,49.1V3.3h15.7v78.3h-4.3l-48-45.8v45.8h-15.7V3.3z" />
      <path d="M370.4,3.3h67.9V19h-26.1v62.7h-15.7V19h-26.1V3.3z" />
      <path d="M528.4,5.9v73.1h-10.4V55.6h-41.8v23.5h-10.4V5.9h10.4v39.2h41.8V5.9H528.4z" />
      <path
        d="M589.5,79.1c-20.2,0-36.6-16.4-36.6-36.6s16.4-36.6,36.6-36.6s36.6,16.4,36.6,36.6S609.7,79.1,589.5,79.1z M563.4,42.5
	c0,14.4,11.7,26.1,26.1,26.1s26.1-11.7,26.1-26.1c0-14.4-11.7-26.1-26.1-26.1S563.4,28.1,563.4,42.5z"
      />
      <path
        d="M679.8,79.1c-17.3,0-31.3-14-31.3-31.3V5.9h10.4v41.8c0,11.6,9.3,20.9,20.9,20.9c11.5,0,20.9-9.3,20.9-20.9V5.9h10.4v41.8
	C711.2,65.1,697.2,79.1,679.8,79.1z"
      />
      <path d="M802.1,5.9v73.1h-0.6l-51.6-49.3v49.3h-10.4V5.9h0.5l51.7,49.3V5.9H802.1z" />
      <path
        d="M830.3,3.3l-0.1,0.1V3.3H830.3z M830.2,79.1V5.9h26.1c20.2,0,36.6,16.4,36.6,36.6s-16.4,36.6-36.6,36.6H830.2z M856.3,68.6
	c14.4,0,26.1-11.7,26.1-26.1c0-14.4-11.7-26.1-26.1-26.1h-15.7v52.2H856.3z"
      />
    </svg>
  );
};
