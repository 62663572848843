import React from "react";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 26"
    >
      <path d="M19,1.4h-6.8c-0.9,0-1.8,0.5-2.2,1.3L8.8,4.8h13.5l-1.1-2.1C20.8,1.9,19.9,1.4,19,1.4z" />
      <path d="M6.7,2.7H4c-0.3,0-0.6,0.3-0.6,0.6v1.5h3.8V3.3C7.3,2.9,7,2.7,6.7,2.7z" />
      <path d="M8,15.3c0-1.3,0.3-2.4,0.9-3.5H0.7v6.9h8.2C8.3,17.7,8,16.5,8,15.3z" />
      <path
        d="M15,21.2c3.3,0,5.9-2.6,5.9-5.9S18.3,9.4,15,9.4S9.1,12,9.1,15.3S11.7,21.2,15,21.2z M13.6,12.1c0.5,0,1,0.2,1.4,0.4
	c0.4-0.3,0.9-0.4,1.4-0.4c1.4,0,2.5,1.1,2.5,2.5c0,2.6-3.5,4.8-3.7,4.9c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3-0.1
	c-0.1-0.1-3.7-2.3-3.7-4.9C11,13.3,12.2,12.1,13.6,12.1z"
      />
      <path
        d="M15,18.4c1-0.7,2.8-2.3,2.8-3.7c0-0.8-0.6-1.4-1.4-1.4c-0.4,0-0.8,0.2-1,0.4c-0.2,0.2-0.6,0.2-0.8,0c-0.3-0.3-0.6-0.4-1-0.4
	c-0.8,0-1.4,0.6-1.4,1.4C12.1,16.1,14,17.7,15,18.4z"
      />
      <path d="M22,15.3c0,1.3-0.3,2.4-0.9,3.5h8.2v-6.9h-8.2C21.7,12.8,22,14,22,15.3z" />
      <path
        d="M15,22.3c-2.1,0-4-0.9-5.3-2.4h-9v2.2c0,1.4,1.1,2.5,2.5,2.5h23.5c1.4,0,2.5-1.1,2.5-2.5v-2.2h-9C19,21.4,17.1,22.3,15,22.3
	z"
      />
      <path d="M26.7,5.9H3.3c-1.4,0-2.5,1.1-2.5,2.5v2.2h9C11,9.2,12.9,8.2,15,8.2s4,0.9,5.3,2.4h9V8.5C29.3,7.1,28.1,5.9,26.7,5.9z" />
    </svg>
  );
};
