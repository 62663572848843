import React from "react";

export default () => (
  <svg
    width="100%"
    height="100%"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 100 65"
  >
    <path
      d="M97.5,34.5h0.3L88,16c-1.7-2.9-4.4-5.2-7.6-6.4c-3-7.2-11.3-10.5-18.5-7.5c-3.4,1.5-6.2,4.2-7.6,7.7c-2.7-1.4-6-1.4-8.7,0
	C42.8,2.6,34.5-0.9,27.3,2c-3.5,1.4-6.3,4.2-7.8,7.8c-3,1.2-5.5,3.4-7.1,6.2L4.8,29.9c-6.8,10-4.1,23.6,5.9,30.3
	c10,6.8,23.6,4.1,30.3-5.9c2-3,3.3-6.5,3.6-10.2c0.1-0.7,0.1-1.4,0.1-2.1c0-1.5-0.1-2.9-0.4-4.4l0,0c-0.2-0.9-0.4-1.8-0.8-2.7
	c1.7-3.5,6-5,9.5-3.3c1.4,0.7,2.6,1.9,3.3,3.3c-0.3,0.9-0.6,1.8-0.8,2.7c-0.3,1.4-0.4,2.9-0.4,4.4c0,0.7,0,1.3,0.1,2
	c1.1,12,11.7,20.9,23.7,19.8c12-1.1,20.9-11.7,19.8-23.7C98.7,38.2,98.2,36.3,97.5,34.5z M39.7,43.8c-0.9,9.3-9.1,16.1-18.4,15.2
	c-9.3-0.9-16.1-9.1-15.2-18.4c0.8-8.7,8.1-15.3,16.8-15.3h0.9c6.6,0.4,12.3,4.5,14.8,10.6l0,0c0.4,0.9,0.7,1.9,0.8,2.8
	c0.2,1.1,0.3,2.2,0.3,3.4C39.8,42.7,39.7,43.2,39.7,43.8z M77.1,59c-8.7,0-16-6.6-16.8-15.3c-0.1-0.5-0.1-1-0.1-1.6
	c0-1.1,0.1-2.3,0.3-3.4c0.2-1,0.5-1.9,0.9-2.8c2.4-6.1,8.2-10.2,14.7-10.6h1c6,0,11.6,3.2,14.6,8.4l0,0c4.6,8.1,1.9,18.4-6.2,23
	C82.9,58.2,80,59,77.1,59L77.1,59z"
    />
    <path
      d="M87.8,32.1L87.8,32.1c-2.8-2.9-6.7-4.6-10.7-4.6c-6.5,0-12.2,4.3-14.1,10.5c-0.4,1.4-0.6,2.8-0.6,4.2
	c0,8.1,6.5,14.7,14.7,14.7s14.7-6.5,14.7-14.7c0-3.4-1.1-6.6-3.2-9.2L87.8,32.1z M77.1,51.8c-5.3,0-9.7-4.3-9.7-9.7
	c0-0.9,0.1-1.9,0.4-2.8c1.2-4.1,5-6.9,9.3-6.9c2.9,0,5.7,1.3,7.6,3.6l0.2,0.3c1.2,1.7,1.9,3.7,1.9,5.7
	C86.8,47.5,82.4,51.8,77.1,51.8C77.1,51.8,77.1,51.8,77.1,51.8z"
    />
    <path
      d="M22.9,27.5c-8.1,0-14.6,6.6-14.6,14.7c0,8.1,6.6,14.6,14.7,14.6c8.1,0,14.6-6.6,14.6-14.6c0-1.4-0.2-2.8-0.6-4.2
	C35.1,31.8,29.4,27.5,22.9,27.5z M22.9,51.8c-5.3,0-9.7-4.3-9.7-9.7c0-2.2,0.8-4.3,2.1-6c1.8-2.3,4.6-3.7,7.6-3.7
	c4.3,0,8.1,2.8,9.3,6.9c0.3,0.9,0.4,1.8,0.4,2.7C32.6,47.5,28.3,51.8,22.9,51.8L22.9,51.8z"
    />
  </svg>
);
