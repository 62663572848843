import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as stateActions from "../../../../redux/actions/state-actions";
import * as userActions from "../../../../redux/actions/user-actions";

// Components
import ViewLayout from "../../../components/view-layout";
import CorporateCommon from "../corporate-common";
import Button from "../../../components/common/button";

// Utilities
import { verifyForm, shakeElement } from "../../../../utilites/animation";
import { convertDateToDbDate } from "../../../../utilites/date";
import { editCorporate } from "../../../../api/corporate";
import { storeImage, deleteImage } from "../../../../api/image";
import { formatAfter12Hours } from "../../../../utilites/format";

// Styles
import "../corporate-business-styles.css";

export default ({ match }) => {
  const history = useHistory();
  const {
    _id: mainBusinessId,
    locations,
    images: userImages = [],
  } = useSelector((store) => store.user.user);
  const { id: locationId } = match.params || {};
  const location = locations.find(({ _id }) => _id === locationId);
  const dispatch = useDispatch();
  const [commonData, setCommonData] = useState({});
  const { images: currImages = [] } = location;
  const userImage = userImages[0] || "";
  const currentImage = currImages[0] || "";
  const isCurrentUserImage = userImage === currentImage;
  const {
    phoneInput,
    showPhone,
    addressInput,
    cityInput,
    stateInput,
    zipInput,
    hours,
    featuresInput,
    websiteInput,
    facebookInput,
    instagramInput,
    deliveryLinkInput,
    locationImageInput,
  } = commonData || {};
  const locationData = {
    mainBusinessId,
    businessId: locationId,
    phone: phoneInput,
    showPhone,
    address: addressInput,
    city: cityInput,
    state: stateInput,
    zip: zipInput,
    hours,
    features: featuresInput,
    website: websiteInput,
    facebook: facebookInput,
    instagram: instagramInput,
    deliveryLink: deliveryLinkInput,
    lastUpdated: convertDateToDbDate(),
  };

  const handleCommonData = (commonData) => {
    setCommonData(commonData);
  };

  const handleSubmit = async () => {
    const ids = {
      phoneInput,
      addressInput,
      cityInput,
      stateInput,
      zipInput,
    };
    const hasRequiredInfo = verifyForm(ids);

    if (hasRequiredInfo) {
      dispatch(stateActions.setLoader(true));
      let image = "";

      if (
        !locationImageInput ||
        !locationImageInput.length ||
        currentImage !== locationImageInput[0]
      ) {
        if (locationImageInput) locationData.images = locationImageInput;

        if (!isCurrentUserImage) {
          await deleteImage(currentImage);
          locationData.images = [userImage];
        }

        if (
          locationImageInput &&
          locationImageInput.length &&
          locationImageInput[0] !== currentImage &&
          locationImageInput[0] !== userImage
        ) {
          const newImage = await storeImage(locationImageInput[0], "business");
          image = newImage;
          locationData.images = [newImage];
        }
      }

      const { locations } =
        (await editCorporate({
          ...locationData,
          hours: formatAfter12Hours(locationData.hours),
        })) || {};
      if (locations) {
        setTimeout(() => {
          dispatch(userActions.updateUser({ locations }));
          history.push("/business/corporate");
          dispatch(stateActions.setLoader(false));
        }, 1500);
      } else {
        if (image) await deleteImage(image);
        dispatch(stateActions.setLoader(false));
      }
    } else {
      shakeElement("create-corporate-profile");
    }
  };

  return (
    <ViewLayout id="create-corporate-profile">
      <div className="card card-form">
        <div className="card-info-form">
          <div className="corporate-title-container">
            <h2>EDIT LOCATION</h2>
          </div>
        </div>
      </div>

      <CorporateCommon data={location} handleCommonData={handleCommonData} />

      <div id="consentInput" className="card card-form">
        <div className="card-info-form">
          <div className="listing-edit-submit-container">
            <Button
              className="submit-button-CreateListing"
              text="Cancel"
              isPrimary={false}
              handleClick={() => history.goBack()}
            />
            <Button
              className="submit-button-CreateListing"
              text="Submit"
              handleClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </ViewLayout>
  );
};
