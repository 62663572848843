import React from "react";

export default props => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 29"
    >
      <path
        d="M14.2,19.5c0-0.3-0.2-0.5-0.4-0.7l-2.3-2.2l0.9-6.6c0.4-0.2,0.1,0.1,6.8-7.2c0.5-0.5,0.4-1.3-0.1-1.7
		c-0.5-0.5-1.3-0.4-1.7,0.1l-6.1,6.7L9,7.5c-0.3,0-0.7,0.1-0.9,0.2L4.5,8.6C4.1,8.6,3.7,8.9,3.6,9.3L2,13.7
		c-0.2,0.6,0.1,1.3,0.7,1.6c0.6,0.2,1.3-0.1,1.6-0.7l1.3-3.7l1.7-0.4l-0.6,4.8c0,0.2,0,0.5,0.1,0.7L0.9,26.5
		c-0.3,0.6-0.1,1.3,0.5,1.6C2,28.4,2.7,28.2,3,27.6l5.7-10.3l3.1,2.9l1.1,6.4c0.1,0.7,0.7,1.1,1.4,1c0.7-0.1,1.1-0.7,1-1.4
		L14.2,19.5z"
      />
      <path
        d="M28.3,8.3l-2.7-0.5c-0.2-0.3-0.6-0.6-1-0.6l-1.8-0.1c-0.6,0-1.2,0.4-1.3,1.4l-1.5,0.9l-1.8-3c-0.3-0.6-1.1-0.8-1.7-0.4
		c-0.6,0.3-0.8,1.1-0.4,1.7l2.4,4c0.3,0.6,1.1,0.8,1.7,0.4l1.2-0.7l-0.1,3.1l-2.9,4.4c0,0,0,0,0,0c-0.4,0.6-0.8,0.9-0.6,1.6l1.2,5
		c0.2,0.6,0.8,1,1.5,0.9c0.6-0.2,1-0.8,0.9-1.5l-1.1-4.5l0.1-0.1c0.8,0.4,1.6,0.5,2.4,0.6l0.8,6.3c0.1,0.6,0.6,1.1,1.2,1.1
		c0.7,0,1.3-0.6,1.2-1.4l-0.8-6.2c1.4-0.3,2.3-0.9,2.3-0.9l-1.9-4.4l0.2-5.2l1,0.2L26,13.5c-0.3,1.6,2.1,2,2.4,0.4l0.8-4.2
		C29.3,9.1,28.9,8.4,28.3,8.3z"
      />
      <circle cx="23.8" cy="4" r="2.4" />
      <circle cx="9.8" cy="4.4" r="2.4" />
    </svg>
  );
};
