import React from "react";

import "./toggle.css";

const Toggle = ({
  value,
  handleToggle = () => {},
  handleDebouceToggle = () => {},
  params,
}) => {
  return (
    <label
      className="toggle-container"
      onClick={(evt) => {
        evt.stopPropagation();
        handleToggle(evt, !value, params);
      }}
    >
      <input
        type="checkbox"
        checked={value}
        onChange={(evt) => handleDebouceToggle(evt, !value, params)}
      />
      <span className="toggle-slider" />
    </label>
  );
};

Toggle.defaultProps = {
  value: false,
};

export default Toggle;
