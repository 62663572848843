import { callAPI } from ".";
import { baseURL } from "./url";

const paymentURL = baseURL("/payment");

export function getPricing(id) {
  let url = paymentURL;
  if (id) url += `?id=${id}`;
  return callAPI(url, "GET");
}

export function processPayment(paymentData) {
  return callAPI(paymentURL, "POST", paymentData);
}

export function processUpgradePayment(paymentData) {
  const url = `${paymentURL}/upgrade`;
  return callAPI(url, "POST", paymentData);
}

export function cancelAutoPay(businessId) {
  const url = `${paymentURL}/autopay`;
  return callAPI(url, "POST", { businessId });
}
