import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";

// Utilities
import UseGetTheme from "../../../utilites/hooks/useGetTheme";
import { mapOptions } from "../../../constants/data-models";

// Assets
import PersonDay from "../../../assets/svg/personDay.svg";
import PersonNight from "../../../assets/svg/personNight.svg";
import Pin from "../../../assets/svg/pin.svg";
import PinPrimary from "../../../assets/svg/pin-primary.svg";
import PinCurrent from "../../../assets/svg/pin-current.svg";
import PinSecondary from "../../../assets/svg/pin-secondary.svg";
import PinSelected from "../../../assets/svg/pin-selected.svg";

const Map = withGoogleMap(
  ({
    isGeoLocation,
    mapCenter,
    coordinates = [],
    userMarkerPostion,
    results = [],
    defaultZoom = 14,
    options = {},
    handleMarkerClick,
    handleClick,
    handleCenterMovedCoords,
    selectedId,
    status,
    draggable = true,
  }) => {
    const map = useRef(null);
    const [hasMounted, setHasMounted] = useState(false);
    const { isNight } = UseGetTheme();
    const { standard, day, night } = mapOptions;
    const dynamicStyles = isNight ? night : day;
    const styles = [...standard, ...dynamicStyles];

    useEffect(() => {
      setHasMounted(true);
    }, []);

    useEffect(() => {
      if (hasMounted && mapCenter)
        map.current.panTo({ lat: mapCenter[1], lng: mapCenter[0] });
    }, [mapCenter]);

    const handleCenterChange = () => {
      if (handleCenterMovedCoords) {
        const ne = map.current.getBounds().getNorthEast();
        const sw = map.current.getBounds().getSouthWest();
        const northLat = ne.lat();
        const eastLng = ne.lng();
        const southLat = sw.lat();
        const westLng = sw.lng();
        const mapCenter = map.current.getCenter();
        const centerLng = mapCenter.lng();
        const centerLat = mapCenter.lat();
        handleCenterMovedCoords(
          [
            [westLng, northLat],
            [eastLng, northLat],
            [eastLng, southLat],
            [westLng, southLat],
            [westLng, northLat],
          ],
          [centerLng, centerLat]
        );
      }
    };

    const getPin = (status) => {
      switch (status) {
        case "starting":
          return PinPrimary;
        case "active":
          return PinCurrent;
        case "ending":
          return PinSecondary;
        case "ended":
          return Pin;
        default:
          return PinPrimary;
      }
    };

    const getPinStatus = (status) => {
      const statusList = Object.values(status);
      if (statusList.includes("active")) return "active";
      if (statusList.includes("ending")) return "ending";
      if (statusList.includes("starting")) return "starting";
      if (statusList.includes("ended")) return "ended";
      return "";
    };

    const renderListingMarkers = () =>
      results.map((result, idx) => {
        const { id, coordinates, status: statusObj } = result;
        const status = getPinStatus(statusObj);
        const isSelected = id === selectedId;
        return (
          <Marker
            icon={{
              url: isSelected ? PinSelected : getPin(status),
              scaledSize: new window.google.maps.Size(20, 30),
            }}
            animation={4}
            onClick={(evt) => handleMarkerClick(id)}
            position={{ lng: coordinates[0], lat: coordinates[1] }}
            key={idx}
          />
        );
      });

    return (
      <GoogleMap
        ref={map}
        defaultZoom={defaultZoom}
        defaultCenter={
          mapCenter
            ? { lng: mapCenter[0], lat: mapCenter[1] }
            : { lng: -118.2436849, lat: 34.0522342 }
        }
        onClick={handleClick}
        onCenterChanged={handleCenterChange}
        onTilesLoaded={handleCenterChange}
        defaultOptions={{
          styles,
          zoomControl: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          clickableIcons: false,
          draggable: draggable,
          ...options,
        }}
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCIK_sRlm_ldHug3oomA08HkdToZugOMpc&v=3.exp&libraries=geometry"
      >
        {isGeoLocation && (
          <Marker
            icon={{
              url: isNight ? PersonNight : PersonDay,
              scaledSize: new window.google.maps.Size(14, 36),
            }}
            position={{ lng: userMarkerPostion[0], lat: userMarkerPostion[1] }}
          />
        )}
        {!!coordinates && !!coordinates.length && (
          <Marker
            position={{ lng: coordinates[0], lat: coordinates[1] }}
            icon={{
              url: getPin(status),
              scaledSize: new window.google.maps.Size(26, 52),
            }}
          />
        )}
        {renderListingMarkers()}
      </GoogleMap>
    );
  }
);

const mapStateToProps = (store) => ({
  // filterDate: store.data.filters.date,
  // type: store.data.filters.type,
});

export default connect(mapStateToProps)(Map);
