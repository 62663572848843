import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as stateActions from "../../../redux/actions/state-actions";

// Components
import ViewLayout from "../../components/view-layout";
import Button from "../../components/common/button";
import Select from "../../components/common/select";

// Utilities
import { getEmployees, sendEmployeeInvite } from "../../../api/employee";
import { toTitleCase, formatPhoneNumber } from "../../../utilites/format";
import { formatDbDateToReadable } from "../../../utilites/date";

// Styles
import "./employees-styles.css";

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [employees, setEmployees] = useState("");
  const [nameInput, setNameInput] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [payInput, setPayInput] = useState("");
  const [accountTypeInput, setAccountTypeInput] = useState("");

  useEffect(() => {
    (async () => {
      dispatch(stateActions.setLoader(true));
      const { employees: foundEmployees } = await getEmployees();
      if (foundEmployees) setEmployees(foundEmployees);
      dispatch(stateActions.setLoader(false));
    })();
  }, []);

  const handleChange = (evt, type, newValue) => {
    const { value } = evt.target;
    switch (type) {
      case "nameInput":
        return setNameInput(value);
      case "emailInput":
        return setEmailInput(value);
      case "payInput":
        return setPayInput(value);
      case "accountTypeInput":
        return setAccountTypeInput(value);
    }
  };

  const handleNewEmployee = async (evt) => {
    evt.preventDefault();
    if (!nameInput || !emailInput) return;
    dispatch(stateActions.setLoader(true));
    const employeeData = {
      name: nameInput,
      email: emailInput,
      pay: payInput,
      accountType: accountTypeInput,
    };
    const { success } = await sendEmployeeInvite(employeeData);
    if (success) {
      setNameInput("");
      setEmailInput("");
      dispatch(stateActions.setMessage({ message: "Request Sent" }));
    }
    dispatch(stateActions.setLoader(false));
  };

  const handleClick = (employeeId) => {
    history.push(`/employee?employeeId=${employeeId}`);
  };

  return (
    <ViewLayout>
      <form
        id="form-business-sign-up"
        className="card-wrapper signup-wrapper-BusinessSignUp"
      >
        <div className="card card-form">
          <div className="card-info-form">
            <h2>New Employee Input</h2>
            <label id="emailInput" className="label-form">
              Name:
            </label>
            <input
              className="input-style input-form text-form"
              value={nameInput}
              onChange={(evt) => handleChange(evt, "nameInput")}
            />

            <label id="emailInput" className="label-form">
              Email:
            </label>
            <input
              className="input-style input-form text-form"
              type="email"
              value={emailInput}
              onChange={(evt) => handleChange(evt, "emailInput")}
            />

            <div className="new-employee-flex-row">
              <label id="payInput" className="employees-label-form">
                Pay:
              </label>
              <input
                className="input-style input-form text-form"
                style={{ marginRight: "40px" }}
                type="number"
                value={payInput}
                onChange={(evt) => handleChange(evt, "payInput")}
              />

              <label id="accountTypeInput" className="employees-label-form">
                Job Title:
              </label>
              <Select
                classNames="filter-select-container"
                options={[
                  "telemarketing",
                  "data entry",
                  "flex",
                  "review",
                  "admin",
                  "developer",
                ]}
                value={accountTypeInput}
                handleChange={(evt) => handleChange(evt, "accountTypeInput")}
              />
            </div>

            <div className="submit-wapper-UserSignUp">
              <div className="submit-button-UserSignUp">
                <Button text="Submit" handleClick={handleNewEmployee} />
              </div>
            </div>
          </div>
        </div>

        {employees &&
          employees.length &&
          employees.map(
            ({
              _id,
              accountType,
              email,
              fName,
              lName,
              phone,
              lastActive,
              createdDate,
            }) => (
              <div
                className="pointer card card-form"
                onClick={() => handleClick(_id)}
                key={_id}
              >
                <div className="employee-card-wrapper">
                  <div className="employee-left-container">
                    <h3>{toTitleCase({ input: `${fName} ${lName}` })}</h3>
                    <p>{toTitleCase({ input: accountType || "Employee" })}</p>
                    <p>{formatPhoneNumber({ phone })}</p>
                    <p>{email}</p>
                  </div>

                  <div className="employee-right-container">
                    <label id="emailInput" className="label-form">
                      Last Active:
                    </label>
                    <p>{lastActive}</p>
                    <label
                      id="emailInput"
                      className="label-form"
                      style={{ marginTop: "15px" }}
                    >
                      Created Date:
                    </label>
                    <p>
                      {formatDbDateToReadable({
                        date: createdDate,
                        isForUser: true,
                      })}
                    </p>
                  </div>
                </div>
              </div>
            )
          )}
      </form>
    </ViewLayout>
  );
};
