import React from "react";

export default props => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 25 30"
    >
      <path
        d="M11,6.2V0.3L3.7,7.7L11,15V9.1h1.5c4.9,0,8.8,4,8.8,8.8s-4,8.8-8.8,8.8s-8.8-4-8.8-8.8v-1.5H0.7v1.5
		c0,6.5,5.3,11.8,11.8,11.8s11.8-5.3,11.8-11.8S19,6.2,12.5,6.2H11z"
      />
    </svg>
  );
};
