import React from "react";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 19"
    >
      <path
        transform="translate(0,-952.36218)"
        d="M28.9,961.3l-6.6-8c-0.3-0.4-0.9-0.4-1.3-0.1c-0.4,0.3-0.4,0.9-0.1,1.3l5.3,6.4H1.9c-0.5,0-0.9,0.4-0.9,0.9
		s0.4,0.9,0.9,0.9h24.3l-5.3,6.4c-0.3,0.4-0.3,1,0.1,1.3c0.4,0.3,1,0.3,1.3-0.1l6.6-8C29.2,962,29.1,961.7,28.9,961.3z"
      />
    </svg>
  );
};
