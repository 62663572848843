import React from "react";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 24 30"
    >
      <path
        d="M22,10.1l-0.6-0.4c-0.7-0.5-1-1.5-0.6-2.3l0.3-0.6c0.4-0.8,0-1.5-0.9-1.5l-0.7,0c-0.9-0.1-1.6-0.8-1.7-1.7l0-0.7
	c-0.1-0.9-0.8-1.3-1.5-0.9l-0.6,0.3c-0.8,0.4-1.8,0.1-2.3-0.6l-0.4-0.6c-0.5-0.7-1.3-0.7-1.8,0l-0.4,0.6c-0.5,0.7-1.5,1-2.3,0.6
	L7.8,1.8C7,1.5,6.3,1.9,6.2,2.7l0,0.7C6.1,4.3,5.3,5.1,4.5,5.1l-0.7,0C2.9,5.2,2.5,5.9,2.9,6.7l0.3,0.6c0.4,0.8,0.1,1.8-0.6,2.3
	L2,10.1c-0.7,0.5-0.7,1.3,0,1.8l0.6,0.4c0.7,0.5,1,1.5,0.6,2.3l-0.3,0.6c-0.4,0.8,0,1.5,0.9,1.5l0.7,0c0.9,0.1,1.6,0.8,1.7,1.7
	l0,0.7c0.1,0.9,0.8,1.3,1.5,0.9l0.6-0.3c0.8-0.4,1.8-0.1,2.3,0.6l0.4,0.6c0.5,0.7,1.3,0.7,1.8,0l0.4-0.6c0.5-0.7,1.5-1,2.3-0.6
	l0.6,0.3c0.8,0.4,1.5,0,1.5-0.9l0-0.7c0.1-0.9,0.8-1.6,1.7-1.7l0.7,0c0.9-0.1,1.3-0.8,0.9-1.5l-0.3-0.6c-0.4-0.8-0.1-1.8,0.6-2.3
	l0.6-0.4C22.8,11.4,22.8,10.6,22,10.1z M12,17.4c-3.5,0-6.4-2.9-6.4-6.4c0-3.5,2.9-6.4,6.4-6.4c3.5,0,6.4,2.9,6.4,6.4
	C18.4,14.5,15.5,17.4,12,17.4z"
      />
      <circle cx="12" cy="11" r="4.7" />
      <path
        d="M10.1,21.7l-0.4-0.6c-0.1-0.1-0.3-0.2-0.5-0.2c-0.1,0-0.1,0-0.2,0l-0.6,0.3c-0.4,0.2-0.8,0.3-1.1,0.3c-1,0-1.9-0.7-2.1-1.7
	l-2.8,7.7l3.7-1.2l2,3.3l2.7-7.3C10.4,22.1,10.2,21.9,10.1,21.7z"
      />
      <path
        d="M16.8,21.5c-0.4,0-0.8-0.1-1.1-0.3L15,20.9c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4,0.1-0.5,0.2L14,21.7c-0.1,0.2-0.3,0.4-0.5,0.6
	l2.7,7.3l2-3.3l3.7,1.2L19,19.8C18.7,20.8,17.9,21.5,16.8,21.5z"
      />
    </svg>
  );
};
