import React from "react";

export default props => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 30"
    >
      <path
        d="M23.3,6.5L13.2,23L12,18.7l-0.3-1.2l-1.1-0.6L6,14.3L23.3,6.5 M28.7,0.8c-0.1,0-0.2,0-0.4,0.1L0.9,13.4
		c-0.6,0.3-0.7,1.2-0.1,1.5l8.3,4.5l2.5,9.1c0.1,0.4,0.5,0.6,0.8,0.6c0.3,0,0.6-0.1,0.7-0.4L29.5,2.1C29.8,1.5,29.4,0.8,28.7,0.8
		L28.7,0.8z"
      />
    </svg>
  );
};
