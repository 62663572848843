import React from "react";

// Components
import Spacer from "../common/spacer";

// Utilities
import { isBottomSpacing } from "../../../utilites";

// Styles
import "./view-layout.css";

const ViewLayout = ({ id = "", children }) => {
  return (
    <div id={id} className="view-layout">
      <Spacer height={40} />
      {children}
      <Spacer height={isBottomSpacing(6)} />
    </div>
  );
};

export default ViewLayout;
