import React from "react";

export default props => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 28 30"
    >
      <path
        d="M14,0.4C8.2,0.4,0.6,1.7,0.6,4.2l1.5,21.7c0,2.5,7.7,3.7,11.9,3.7s11.9-1.2,11.9-3.7l1.5-21.7C27.4,1.7,19.8,0.4,14,0.4z
		 M13.9,1.6c4.4,0,11.4,0.4,12.2,2.6c-1.3,0.9-7.7,2-12.1,2S3.1,4.8,1.8,3.9C2.4,2.3,9.5,1.6,13.9,1.6z M25,23.4
		c-0.4,0.7-7.1,2.3-11,2.3S3.5,24.1,3,23.4L1.6,5.2C4.9,7,12.4,7.4,13.7,7.4s9.4-0.1,12.7-1.9L25,23.4z"
      />
      <path
        d="M20.9,11.5l-0.4-1.1c-0.4-1.2-1.5-1.9-2.8-1.9c-0.4,0-0.7,0.1-1,0.1l-5.9,2.1c-0.6,0.2-1,0.6-1.4,1.1
		c-2.4-0.3-4.6-0.8-6.1-1.2l1.1,11.9c1.5,0.6,4.7,1.8,9.5,1.8s8-1.1,9.5-1.8l0.9-11.9C23.4,10.9,22.2,11.2,20.9,11.5z M20.4,18.6
		l-5.9,2.1c-0.7,0.3-1.6-0.1-1.9-0.9l-2.1-5.9c-0.1-0.4-0.1-0.7,0.1-1.1c0.1-0.4,0.4-0.7,0.8-0.7l5.9-2.1c0.1-0.1,0.3-0.1,0.5-0.1
		c0.6,0,1.2,0.4,1.4,0.9l2.1,5.9C21.6,17.5,21.2,18.3,20.4,18.6z"
      />
    </svg>
  );
};
