import React from "react";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 29"
    >
      <path
        d="M13.9,1C7.8,1.3,2.6,5,0.7,10.2c0,0.1,0,0.1,0.1,0.1c0.8-0.7,2.5-1.2,4.4-1.2c1.9,0,3.5,0.4,4.3,1.1c0,0,0.1,0,0.1,0
			c0-0.7,0.2-2.1,0.7-3.7c0.4-1.3,0.9-2.4,1.5-3.3C12.4,2.2,13.1,1.5,13.9,1z"
      />
      <path
        d="M29.3,10.2C27.4,5,22.2,1.3,16.1,1c0.8,0.5,1.5,1.2,2.1,2.1c0.6,0.9,1.1,2,1.5,3.3c0.4,1.6,0.6,3,0.7,3.7c0,0,0,0.1,0.1,0
			C21.3,9.5,22.9,9,24.7,9c1.9,0,3.6,0.5,4.4,1.2C29.3,10.3,29.3,10.3,29.3,10.2z"
      />
      <path
        d="M15,1c-3.3,1.7-4.3,6.7-4.5,8.7c0,0.1,0,0.2,0,0.4c0,0,0,0.1,0.1,0c0.9-0.6,2.6-1,4.5-1.1c1.9,0,3.6,0.4,4.5,1.1
			c0,0,0.1,0,0.1,0c0-0.1,0-0.2,0-0.4C19.3,7.7,18.3,2.7,15,1z"
      />
      <path
        d="M17,9.8l-0.7,1.5c-0.1,0.2-0.3,0.3-0.5,0.4v5.9h-1.4v-5.9c-0.2,0-0.4-0.1-0.5-0.4L13,9.8c0.6-0.1,1.3-0.2,2-0.2
			C15.7,9.6,16.4,9.7,17,9.8z"
      />
      <path
        d="M21.4,18.1H8.6c-0.2,0-0.5,0.2-0.5,0.5v0.6c0,0.2,0.2,0.5,0.5,0.5h4.6v1.9c0,0.3,0.2,0.5,0.5,0.5h0v5h-2.3
			c-0.2,0-0.4,0.2-0.4,0.4s0.2,0.4,0.4,0.4h6.9c0.2,0,0.4-0.2,0.4-0.4s-0.2-0.4-0.4-0.4h-2.3v-5h0c0.3,0,0.5-0.2,0.5-0.5v-1.9h4.6
			c0.2,0,0.5-0.2,0.5-0.5v-0.6C21.9,18.3,21.7,18.1,21.4,18.1z"
      />
      <path
        d="M4.1,23.4c0.4-0.9,0.8-1.8,1.2-2.6c0.3-0.7,0.7-1.6,0.3-2.5c0,0-1.1-2.9-2.3-3.1c0,0-0.8-0.2-0.6,0.5c0,0,0,0.5,0.8,1.6
					c0.5,0.6,0.9,1.6,1,2.4c0,0.4,0,0.8-0.1,1.1c0,0.2-0.1,0.3-0.2,0.5c0,0.1-0.2,0.2-0.2,0.4c0,0-0.3,2,0.2,2L4.1,23.4z"
      />
      <path
        d="M10,22c-0.3-0.4-0.9-0.5-1.3-0.5c-0.7,0-1.4,0-2,0c-0.5,0-1.4,0.1-1.6,0.6c-0.1,0.3-0.3,0.6-0.4,1
					c0,0.2-0.1,0.4,0.1,0.4c0.2,0,0.5,0,0.7,0v4.1c0,0.1,0.1,0.2,0.2,0.2H6c0.1,0,0.2-0.1,0.2-0.2v-1.6h2.3v1.6
					c0,0.1,0.1,0.2,0.2,0.2h0.3c0.1,0,0.2-0.1,0.2-0.2v-4c0.2,0,0.4,0,0.6,0C10.7,23.1,10,22,10,22z M8.5,25.4H6.2v-1.7
					c0.8,0,1.5,0,2.3,0V25.4z"
      />
      <path
        d="M25.9,23.4c-0.4-0.9-0.8-1.8-1.2-2.6c-0.3-0.7-0.7-1.6-0.3-2.5c0,0,1.1-2.9,2.3-3.1c0,0,0.8-0.2,0.6,0.5
					c0,0,0,0.5-0.8,1.6c-0.5,0.6-0.9,1.6-1,2.4c0,0.4,0,0.8,0.1,1.1c0,0.2,0.1,0.3,0.2,0.5c0,0.1,0.2,0.2,0.2,0.4c0,0,0.3,2-0.2,2
					L25.9,23.4z"
      />
      <path
        d="M25.5,23.1c-0.1-0.3-0.2-0.7-0.4-1c-0.3-0.5-1.1-0.6-1.6-0.6c-0.7-0.1-1.4-0.1-2,0c-0.5,0-1.1,0.1-1.3,0.5
					c0,0-0.7,1.1,0,1.7c0.1,0.1,0.4,0.1,0.6,0v4c0,0.1,0.1,0.2,0.2,0.2h0.3c0.1,0,0.2-0.1,0.2-0.2v-1.6h2.3v1.6
					c0,0.1,0.1,0.2,0.2,0.2h0.3c0.1,0,0.2-0.1,0.2-0.2v-4.1c0.2,0,0.5,0,0.7,0C25.6,23.5,25.6,23.3,25.5,23.1z M23.8,25.4h-2.3v-1.7
					c0.8,0,1.5,0,2.3,0V25.4z"
      />
    </svg>
  );
};
