import { callAPI } from "./";
import { baseURL } from "./url";

const listingURL = baseURL("/listing");
const listingsURL = baseURL("/listings");

export function listingGet(id) {
  let url = `${listingURL}?id=${id}`;
  return callAPI(url, "GET");
}

export function listingsGet(ids) {
  let url = `${listingsURL}?ids=${ids}`;
  return callAPI(url, "GET");
}

export function createListing(data) {
  return callAPI(listingURL, "PUT", data);
}

export function updateListing(data) {
  return callAPI(listingURL, "POST", data);
}

export function viewedListing(data) {
  let url = `${listingURL}/viewed?`;
  Object.keys(data).forEach((key, idx) => {
    url += `${idx ? "&" : ""}${key}=${data[key]}`;
  });
  return callAPI(url, "GET");
}

export function deleteListing(id, businessId) {
  let url = `${listingURL}?id=${id}&businessId=${businessId}`;
  return callAPI(url, "DELETE");
}
