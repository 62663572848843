import React from "react";
import { withRouter } from "react-router-dom";
import { Analytics } from "aws-amplify";
import { connect } from "react-redux";
import * as dataActions from "../../../redux/actions/data-actions";

// Utilities
import getAnalytics from "../../../utilites/hooks/analytics";
import GetCategoryIcon from "../../components/category-icons";

// Styles
import "./footer-nav.css";

const MobileNav = ({ location, setFilter, history }) => {
  const { pathname } = location || {};
  const userAnalytics = getAnalytics();
  const navItems = ["Food", "Drinks", "Activities", "Events"];

  const handleClick = (item) => {
    const windowUrl = window.location.href;
    const isMap = pathname.indexOf("map") > -1;
    let url = `/${item.toLowerCase()}`;
    if (isMap) url = `/${item.toLowerCase()}/map`;
    if (windowUrl.indexOf(url) > -1) return;
    setFilter({ subCat: "" });
    history.push(url);
    if (userAnalytics)
      Analytics.record({
        name: "navigation",
        attributes: { path: item, location: userAnalytics },
      });
  };

  return (
    <div id="footer-nav" className="card nav-outer-wrapper-MobileNav">
      <ul className="nav-inner-wrapper-MobileNav">
        {navItems.map((item, idx) => {
          const isActive = pathname.indexOf(item.toLowerCase()) > -1;
          return (
            <div
              onClick={() => handleClick(item)}
              className={`nav-item-container-MobileNav ${
                isActive ? "nav-item-isActive-MobileNav" : ""
              }`}
              key={idx}
            >
              <div className="nav-svg-container-MobileNav">
                {GetCategoryIcon(item)}
              </div>
              <h3 className="nav-text-MobileNav">{item}</h3>
            </div>
          );
        })}
      </ul>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setFilter: (filterObj) => dispatch(dataActions.setFilter(filterObj)),
});

export default connect(null, mapDispatchToProps)(withRouter(MobileNav));
