import React from "react";

export default props => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 26 30"
    >
      <path d="M13,16.2c-2.4,0-4.5,1.5-5.2,3.8C9.2,21.2,11,22,13,22s3.8-0.8,5.2-2.1C17.5,17.7,15.4,16.2,13,16.2z" />
      <path
        d="M13,6.8c-4.2,0-7.6,3.4-7.6,7.6c0,1.8,0.6,3.4,1.7,4.8c1-2.3,3.3-3.9,5.9-3.9c2.6,0,4.9,1.6,5.9,3.9c1.1-1.3,1.7-3,1.7-4.8
		C20.6,10.2,17.2,6.8,13,6.8z M13,14.6c-1.5,0-2.7-1.2-2.7-2.7c0-1.5,1.2-2.7,2.7-2.7c1.5,0,2.7,1.2,2.7,2.7
		C15.7,13.4,14.5,14.6,13,14.6z"
      />
      <path d="M13,10.1c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8c1,0,1.8-0.8,1.8-1.8S14,10.1,13,10.1z" />
      <path
        d="M25.2,0.5c-0.1-0.1-0.3-0.1-0.4,0c-3.9,2.3-7.7,2.3-11.6,0c-0.1-0.1-0.3-0.1-0.4,0c-3.9,2.3-7.7,2.3-11.6,0
		c-0.1-0.1-0.3-0.1-0.4,0C0.6,0.6,0.5,0.7,0.5,0.9v14.9c0,7.1,11.8,13.5,12.2,13.8c0,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0
		c0.5-0.3,12.2-6.7,12.2-13.8V0.9C25.5,0.7,25.4,0.6,25.2,0.5z M13,22.9c-4.7,0-8.4-3.8-8.4-8.5S8.4,5.9,13,5.9s8.4,3.8,8.4,8.4
		C21.5,19.1,17.7,22.9,13,22.9z"
      />
    </svg>
  );
};
