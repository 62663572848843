import React from "react";

import "./svg.css";

export default (props) => (
  <svg
    width="100%"
    height="100%"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
  >
    <rect x="3.1" y="3.1" width="7.8" height="93.8" />
    <path
      className="lgbtq0"
      d="M56.2,64.1c11.2,0,20.3-9.1,20.3-20.3s-9.1-20.3-20.3-20.3s-20.3,9.1-20.3,20.3S45.1,64.1,56.2,64.1z
	 M44.1,34.9c2.5-2.7,6.6-2.7,9.1,0l3,3.3l3-3.3c2.5-2.7,6.6-2.7,9.1,0c2.5,2.7,2.5,7.1,0,9.8L56.2,57.8L44.1,44.7
	C41.6,42,41.6,37.6,44.1,34.9z"
    />
    <path
      className="lgbtq1"
      d="M14.1,60.4c4.3-1.8,11.4-3.9,18.8-3.8c1.4,0,2.7,0.1,4,0.3c-1.6-2.4-2.8-5.2-3.5-8.1c-0.2,0-0.4,0-0.6,0
	c-7.7-0.1-15,2.3-18.7,4.1C14.1,52.9,14.1,60.4,14.1,60.4z"
    />
    <path
      className="lgbtq2"
      d="M34.6,34.8c1.1-2.7,2.7-5.1,4.6-7.1c-2.1-0.5-4.2-0.8-6.4-0.8c-7.7-0.1-15,2.3-18.7,4.1v7.5
	c4.3-1.8,11.4-3.9,18.8-3.8C33.4,34.7,34,34.8,34.6,34.8z"
    />
    <path
      className="lgbtq1"
      d="M61.6,66.6c1.3,0.4,2.7,0.8,4.3,1c5.5,0.8,16.9,0.7,31-8.7v-7.2c-10.2,6.3-19.2,7.9-23.7,8.3
	C70.1,63.2,66.1,65.5,61.6,66.6z"
    />
    <path
      className="lgbtq3"
      d="M14.1,20.1v7.5c4.3-1.8,11.4-3.9,18.8-3.8c3.2,0.1,6.1,0.5,9.1,1.4c2.4-1.8,5.1-3.2,8.1-4.1
	C44.9,18.5,40,16.1,32.8,16C25.1,15.8,17.8,18.3,14.1,20.1z"
    />
    <path
      className="lgbtq4"
      d="M14.1,49.5c4.3-1.8,11.4-3.9,18.8-3.8c0,0,0,0,0.1,0c-0.1-0.6-0.1-1.3-0.1-1.9c0-2,0.3-4,0.8-5.9
	c-0.3,0-0.5,0-0.8-0.1c-7.7-0.1-15,2.3-18.7,4.1V49.5z"
    />
    <path
      className="lgbtq4"
      d="M75.9,56.4c5-0.8,12.6-3,20.9-8.5v-7.2c-6.8,4.1-13.5,6.2-17.6,7.2C78.7,51,77.6,53.9,75.9,56.4z"
    />
    <path
      className="lgbtq5"
      d="M65.4,70.6c-4.5-0.7-8.1-2.1-11-3.5c-5.5-0.4-10.4-2.8-14.2-6.3c-2.4-0.6-4.8-1-7.4-1c-7.7-0.1-15,2.3-18.7,4.1
	v10c4-2.1,10.6-4.8,18.8-4.7C47,69.3,53,78.2,65.6,80c6.7,1,17.1,0.3,31.2-9.4v-8.1C86,69.4,76.7,71,70.4,71
	C68.4,71,66.8,70.8,65.4,70.6z"
    />
    <path
      className="lgbtq6"
      d="M65.6,14.1C53,12.2,47,3.4,32.8,3.1C24.7,3,18.1,5.7,14.1,7.8v8.8c4.3-1.8,11.4-3.9,18.8-3.8
	c8.2,0.1,13.8,3,19.2,5.8c1.1,0.6,2.2,1.1,3.4,1.7l0,0c0.3,0,0.6,0,0.9,0c4.7,0,9,1.4,12.7,3.8c4.2,0.2,15.1-0.4,27.9-9V4.7
	C82.8,14.3,72.4,15.1,65.6,14.1z"
    />
    <path
      className="lgbtq2"
      d="M79.7,43.8c0,0.3,0,0.6,0,0.9c4.3-1.1,10.9-3.4,17.2-7.6v-7.2c-7,4.3-13.8,6.4-18.2,7.4
	C79.3,39.2,79.7,41.5,79.7,43.8z"
    />
    <path
      className="lgbtq3"
      d="M77.6,34.2c4.4-1,11.8-3.2,19.2-8.1v-7.2c-9.8,6.1-18.3,7.9-24.1,8.3C74.8,29.2,76.4,31.5,77.6,34.2z"
    />
  </svg>
);
