import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as stateActions from "../../../redux/actions/state-actions";
import * as userActions from "../../../redux/actions/user-actions";

// Components
import Button from "../common/button";
import PasswordInput from "../common/password-input";

// Utilities
import { validatePassword } from "../../../utilites/validate";
import { convertDateToDbDate } from "../../../utilites/date";
import { createNewEmployee } from "../../../api/employee";

// Styles
import "./new-employee.css";

export default ({ token, setErrorMessage }) => {
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(false);
  const [fNameInput, setFNameInput] = useState("");
  const [lNameInput, setLNameInput] = useState("");
  const [phoneInput, setPhoneInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [verifyPasswordInput, setVerifyPasswordInput] = useState("");

  const handleChange = (evt, type, newValue) => {
    const { value } = evt.target;
    switch (type) {
      case "fNameInput":
        return setFNameInput(value);
      case "lNameInput":
        return setLNameInput(value);
      case "phoneInput":
        const newPhoneValue = value
          .replace("-", "")
          .replace(".", "")
          .match(/^[0-9]*$/gi);
        if (newPhoneValue === null) return setPhoneInput(phoneInput);
        return setPhoneInput(newPhoneValue[0].slice(0, 10));
    }
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    if (!fNameInput || !lNameInput || !phoneInput) return;
    if (
      passwordInput === verifyPasswordInput &&
      validatePassword(passwordInput)
    ) {
      setIsFetching(true);
      const userData = {
        token,
        fName: fNameInput,
        lName: lNameInput,
        phone: phoneInput,
        password: passwordInput,
        createdDate: convertDateToDbDate(),
      };
      const { employee, expired } = await createNewEmployee(userData);
      if (expired) {
        setErrorMessage("Token has expired");
      }
      if (employee) {
        dispatch(userActions.setEmployee(employee));
        dispatch(
          stateActions.setMessage({
            message: "Welcome to the EventHound team!",
          })
        );
      }
      setIsFetching(false);
    } else {
      setPasswordInput("");
      setVerifyPasswordInput("");
      setErrorMessage("Invalid Password");
    }
  };

  return (
    <div className="new-employee-info-form" style={{ minheight: "1200px" }}>
      <div className="recover-title-container">
        <h2>New Employee Info</h2>
      </div>
      <label id="emailInput" className="label-form">
        First Name:
      </label>
      <input
        className="input-style input-form text-form"
        value={fNameInput}
        onChange={(evt) => handleChange(evt, "fNameInput")}
      />

      <label id="emailInput" className="label-form">
        Last Name:
      </label>
      <input
        className="input-style input-form text-form"
        z
        value={lNameInput}
        onChange={(evt) => handleChange(evt, "lNameInput")}
      />

      <label id="emailInput" className="label-form">
        Phone:
      </label>
      <input
        className="input-style input-form text-form"
        value={phoneInput}
        onChange={(evt) => handleChange(evt, "phoneInput")}
      />

      <label id="passwordInput" className="label-form">
        Password:
      </label>
      <PasswordInput
        value={passwordInput}
        handleChange={(evt) => setPasswordInput(evt.target.value)}
      />

      <label id="verifyPasswordInput" className="label-form">
        Verify Password:
      </label>
      <PasswordInput
        value={verifyPasswordInput}
        handleChange={(evt) => setVerifyPasswordInput(evt.target.value)}
      />
      <legend className="legend-form">
        Requires: Min length 8, letter(lower & uppercase), number, and a special
        character.
      </legend>
      <div className="submit-wapper-UserSignUp">
        <div className="submit-button-UserSignUp">
          <Button
            text="Submit"
            isDisabled={isFetching}
            handleClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};
