import React from "react";
import { useDispatch } from "react-redux";
import * as stateActions from "../../../redux/actions/state-actions";

// Components
import ImageUpload from "../../components/image-upload";

// Utilities
import { subCatOptions, categoryList } from "../../../constants/data-models";

// Styles
import "../listing-common/listing-common.css";
import "./categories-styles.css";

export default ({ isListing = false, categories, setCategories }) => {
  const dispatch = useDispatch();
  const { cat1, cat1Sub, cat1Img, cat2, cat2Sub, cat2Img } = categories || {};

  const cat1SubCatOptions = (() => {
    if (!cat1) return [];
    const options = [...subCatOptions[cat1]];
    const isSameCat = cat1 === cat2;
    if (isSameCat) return options.filter((option) => option !== cat2Sub);
    return options;
  })();

  const cat2SubCatOptions = (() => {
    if (!cat2) return [];
    const options = [...subCatOptions[cat2]];
    const isSameCat = cat1 === cat2;
    if (isSameCat) return options.filter((option) => option !== cat1Sub);
    return options;
  })();

  const handleCategoryChange = (evt, type, newValue) => {
    const { value } = evt.target;
    const isComparableImage =
      newValue && typeof newValue !== "string" && "name" in newValue;

    switch (type) {
      case "category1Select":
        if (value === "")
          return setCategories({
            cat1: "",
            cat1Sub: "",
            cat1Img: "",
            cat2: "",
            cat2Sub: "",
            cat2Img: "",
          });
        return setCategories({ ...categories, cat1: value });
      case "category1SubSelect":
        return setCategories({ ...categories, cat1Sub: value });
      case "imageInputCat1":
        const isComparableCat2Image =
          cat2Img && typeof cat2Img !== "string" && "name" in cat2Img;
        if (
          isComparableImage &&
          isComparableCat2Image &&
          newValue.name === cat2Img.name
        ) {
          return dispatch(
            stateActions.setMessage({
              messageType: "error",
              message: "Cannot Use The Same Photo",
            })
          );
        }
        return setCategories({ ...categories, cat1Img: newValue });
      case "category2Select":
        if (value === "")
          return setCategories({
            cat1,
            cat1Sub,
            cat1Img,
            cat2: "",
            cat2Sub: "",
            cat2Img: "",
          });
        return setCategories({ ...categories, cat2: value });
      case "category2SubSelect":
        return setCategories({ ...categories, cat2Sub: value });
      case "imageInputCat2":
        const isComparableCat1Image =
          cat1Img && typeof cat1Img !== "string" && "name" in cat1Img;
        if (
          isComparableImage &&
          isComparableCat1Image &&
          newValue.name === cat1Img.name
        ) {
          return dispatch(
            stateActions.setMessage({
              messageType: "error",
              message: "Cannot Use The Same Photo",
            })
          );
        }
        return setCategories({ ...categories, cat2Img: newValue });
    }
  };

  const createSelectOptions = (type) =>
    categoryList.reduce((acc, curr, i) => {
      if (curr === "" && type === cat1) {
        return [
          ...acc,
          <option value="" key={i}>
            - Select -
          </option>,
        ];
      } else if (curr === "" && type === cat2) {
        return [
          ...acc,
          <option value="" key={i}>
            - Optional -
          </option>,
        ];
      } else {
        return [
          ...acc,
          <option value={curr} key={i}>
            {curr}
          </option>,
        ];
      }
    }, []);

  return (
    <>
      <div id="cat1" className="card card-form">
        <div className="card-info-form">
          <label className="label-form">Category:</label>
          <select
            className="input-style input-form select-form"
            value={cat1}
            onChange={(evt) => handleCategoryChange(evt, "category1Select")}
          >
            {createSelectOptions(cat1)}
          </select>
          {cat1 && (
            <select
              className="input-style input-form select-form"
              value={cat1Sub}
              onChange={(evt) =>
                handleCategoryChange(evt, "category1SubSelect")
              }
            >
              {[
                <option value="" key={-1}>
                  - Select -
                </option>,
                ...cat1SubCatOptions.map((subCat, idx) => (
                  <option value={subCat} key={idx}>
                    {subCat}
                  </option>
                )),
              ]}
            </select>
          )}
          {isListing && cat1 ? (
            <>
              <label id="cat1Img" className="label-form">
                Category 1 Image:
              </label>
              <div className="time-selector-wrapper-CreateListing">
                <ImageUpload
                  type="imageInputCat1"
                  imageInput={cat1Img}
                  handleChange={handleCategoryChange}
                />
              </div>
              <legend className="legend-form legend-form-secondary">
                {`Image should directly relate to the ${cat1} category`}
              </legend>
            </>
          ) : (
            <legend className="legend-form" />
          )}
        </div>
      </div>

      {cat1 && (
        <div id="cat2" className="card card-form">
          <div className="card-info-form">
            <label className="label-form">Second Category:</label>
            <select
              className="input-style input-form select-form"
              value={cat2}
              onChange={(evt) => handleCategoryChange(evt, "category2Select")}
            >
              {createSelectOptions(cat2)}
            </select>

            {cat2 && (
              <select
                className="input-style input-form select-form"
                value={cat2Sub}
                onChange={(evt) =>
                  handleCategoryChange(evt, "category2SubSelect")
                }
              >
                {[
                  <option value="" key={-1}>
                    - Select -
                  </option>,
                  ...cat2SubCatOptions.map((subCat, idx) => (
                    <option value={subCat} key={idx}>
                      {subCat}
                    </option>
                  )),
                ]}
              </select>
            )}
            {isListing && cat2 ? (
              <>
                <label className="label-form"> Category 2 Image:</label>
                <div className="time-selector-wrapper-CreateListing">
                  <ImageUpload
                    type="imageInputCat2"
                    imageInput={cat2Img}
                    handleChange={handleCategoryChange}
                  />
                </div>
                <legend className="legend-form legend-form-secondary">
                  {`Image should directly relate to the ${cat2} category`}
                </legend>
              </>
            ) : (
              <legend className="legend-form" />
            )}
          </div>
        </div>
      )}
    </>
  );
};
