import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import * as stateActions from "../../../redux/actions/state-actions";
import * as userActions from "../../../redux/actions/user-actions";
import * as dataActions from "../../../redux/actions/data-actions";

// Components
import ViewLayout from "../../components/view-layout";
import ProfileEditDragDrop from "../../components/profile-edit-drag-drop";
import Button from "../../components/common/button";
import ListingCommon from "../../components/listing-common";
import Modal from "../../components/modal";
import ConfirmListing from "../../components/confirm-listing";
import TextInput from "../../components/common/text-input-underline";
import InputLabeled from "../../components/input-labeled/index.js";

// Utilities
import { verifyForm, shakeElement } from "../../../utilites/animation";
import { deleteImage, storeImage } from "../../../api/image";
import { deleteListingData } from "../../../utilites/listing";
import { updateListing } from "../../../api/admin";
import { convertDateToDbDate } from "../../../utilites/date";
import { validateUrl } from "../../../utilites/validate";
import { formatAfter12Hours } from "../../../utilites/format";

// Styles
import "./edit-listing.css";

const EditListing = ({
  user,
  results,
  match,
  setLoader,
  setResults,
  setMessage,
  setPaymentModal,
  updateResultInResults,
  history,
  isLiveSearch,
}) => {
  const { ads = [] } = user || {};
  const { id } = match.params || {};
  const ad = [...ads, ...results].find(({ _id }) => _id === id);
  const {
    images: adImages,
    title,
    promoCode,
    businessId,
    businessName,
    businessPhone,
    address,
    city,
    state,
    zip,
    website,
    categories: adCategories,
  } = ad || {};
  const [isConfirm, setIsConfirm] = useState(false);
  const [commonData, setCommonData] = useState(null);
  const [commonImageData, setCommonImageData] = useState({});
  const [modalMessage, setModalMessage] = useState("");
  const [upgradeModalMessage, setUpgradeModalMessage] = useState("");
  const [nameInput, setNameInput] = useState("");
  const [phoneInput, setPhoneInput] = useState("");
  const [addressInput, setAddressInput] = useState("");
  const [cityInput, setCityInput] = useState("");
  const [stateInput, setStateInput] = useState("");
  const [zipInput, setZipInput] = useState("");
  const [websiteInput, setWebsiteInput] = useState("");
  const {
    typeSelect,
    categories,
    titleInput,
    descriptionInput,
    featuresInput,
    days,
    checkInInput,
    checkInOnceInput,
    location,
    ticketInput,
    promoCodeInput,
    virtiualInput,
  } = commonData || {};
  const { images } = commonImageData || {};
  const newData = {
    ...ad,
    type: typeSelect,
    location,
    categories,
    title: titleInput,
    description: descriptionInput,
    features: featuresInput,
    images,
    days,
    checkInRequired: checkInInput,
    checkInOnce: checkInOnceInput,
    lastUpdated: convertDateToDbDate(),
    ticketLink: ticketInput,
    virtualLink: virtiualInput,
    businessName: (nameInput || "").trim(),
    businessPhone: phoneInput,
    phone: phoneInput,
    showPhone: !!phoneInput,
    address: addressInput,
    city: cityInput,
    state: stateInput,
    zip: zipInput,
    website: websiteInput,
  };
  const adData = {
    ...newData,
    promoCode: promoCodeInput,
  };
  const isCorrectUser = user && user._id === businessId;

  useEffect(() => {
    if (!isLiveSearch) {
      setNameInput(businessName);
      setPhoneInput(businessPhone);
      setAddressInput(address);
      setCityInput(city);
      setStateInput(state);
      setZipInput(zip);
      setWebsiteInput(website);
    }
  }, []);

  const handleChange = (evt, type, newValue) => {
    const { value } = evt.target;
    switch (type) {
      case "nameInput":
        return setNameInput(value);
      case "phoneInput":
        const newPhoneValue = value
          .replace("-", "")
          .replace(".", "")
          .match(/^[0-9]*$/gi);
        if (newPhoneValue === null) return setPhoneInput(phoneInput);
        return setPhoneInput(newPhoneValue[0].slice(0, 10));
      case "addressInput":
        return setAddressInput(value);
      case "cityInput":
        return setCityInput(value);
      case "stateInput":
        let newStateInput = "";
        if (value) newStateInput = value.match(/\w/gi).join("").slice(0, 2);
        return setStateInput(newStateInput);
      case "zipInput":
        let newZipInput = "";
        if (value) newZipInput = value.match(/\d/gi).join("").slice(0, 5);
        return setZipInput(newZipInput);
      case "websiteInput":
        return setWebsiteInput(value);
    }
  };

  const handleAdDelete = async () => {
    setLoader(true);
    setModalMessage("");
    const [{ message }] = await deleteListingData(ad);
    if (message) {
      history.push("/");
      setMessage({ message: "Successful" });
      setResults({ results: [], pathname: "/" });
    }
    setLoader(false);
  };

  const handleSubmit = () => {
    const { cat1, cat1Sub, cat1Img, cat2, cat2Sub, cat2Img } = categories || {};
    const ids = {
      typeSelect,
      cat1: cat1 && cat1Sub && cat1Img,
      titleInput,
      descriptionInput: descriptionInput.trim().length > 40,
    };
    if (cat2) ids.cat2 = cat2 && cat2Sub && cat2Img;
    if (ticketInput) ids.ticketInput = validateUrl(ticketInput);
    if (featuresInput["Virtual"]) ids.virtualLink = validateUrl(virtiualInput);

    const hasRequiredInfo = verifyForm(ids);
    if (hasRequiredInfo) {
      setIsConfirm(true);
    } else {
      shakeElement("edit-listing");
    }
  };

  const handleSave = async () => {
    setLoader(true);
    const { cat1Img: adCat1Img, cat2Img: adCat2Img } = adCategories;
    const { cat1Img, cat2Img } = categories;

    if (adCat1Img !== cat1Img) {
      await deleteImage(adCat1Img);
      newData.categories.cat1Img = "";
    }
    if (!newData.categories.cat1Img && cat1Img) {
      const storedCat1Img = await storeImage(cat1Img, "listing");
      if (storedCat1Img) newData.categories.cat1Img = storedCat1Img;
    }

    if (adCat2Img !== cat2Img) {
      await deleteImage(adCat2Img);
      newData.categories.cat2Img = "";
    }
    if (!newData.categories.cat2Img && cat2Img) {
      const storedCat2Img = await storeImage(cat2Img, "listing");
      if (storedCat2Img) newData.categories.cat2Img = storedCat2Img;
    }

    if (promoCode !== promoCodeInput) {
      await deleteImage(promoCode);
      newData.promoCode = "";
    }
    if (!newData.promoCode && promoCodeInput) {
      const storedPromo = await storeImage(promoCodeInput, "promo");
      if (storedPromo) newData.promoCode = storedPromo;
    }

    delete newData.views;
    delete newData.checkedIn;
    const { ad: updatedAd } = await updateListing({
      ...newData,
      isGrowth: !isLiveSearch,
      days: {
        ...newData.days,
        hours: formatAfter12Hours(newData.days.hours),
      },
    });
    if (updatedAd) {
      updateResultInResults(updatedAd);
      setTimeout(() => {
        history.push(`/listing/${id}`);
        setMessage({ message: "Successful" });
        setLoader(false);
      }, 1000);
    } else {
      setMessage({ type: "error", message: "Successful" });
      setLoader(false);
    }
  };

  const renderHeaderButtons = () => (
    <div className="profile-button-container">
      <Button
        size="small"
        text="View"
        handleClick={() => history.push(`/listing/${id}`)}
      />
      <Button
        size="small"
        text="Delete"
        isPrimary={false}
        handleClick={() => {
          const message = `Are you sure you want to delete your "${title}" listing?`;
          setModalMessage(message);
        }}
      />
    </div>
  );

  const contactInputs = [
    {
      name: "nameInput",
      value: nameInput,
      label: "Business Name",
    },
    {
      name: "phoneInput",
      value: phoneInput,
      label: "Business Phone",
    },
    {
      name: "websiteInput",
      value: websiteInput,
      label: "Website",
    },
    {
      name: "addressInput",
      value: addressInput,
      label: "Business Address",
    },
    {
      name: "cityInput",
      value: cityInput,
      label: "City",
    },
    {
      name: "stateInput",
      value: stateInput,
      label: "State",
    },
    {
      name: "zipInput",
      value: zipInput,
      label: "Zip",
      type: "number",
    },
  ];

  if (!results.length) return history.push("/");
  return isConfirm ? (
    <ConfirmListing
      data={adData}
      setIsConfirm={setIsConfirm}
      handleSave={handleSave}
      isCreate={false}
    />
  ) : (
    <ViewLayout>
      <ProfileEditDragDrop
        input={ad}
        dbImages={adImages}
        data={newData.title ? adData : ad}
        isBusiness={false}
        HeaderButtons={renderHeaderButtons}
        handleCommonData={(data) => setCommonImageData(data)}
      >
        <>
          <ListingCommon
            user={isCorrectUser ? user : newData.title ? adData : ad}
            ad={newData.title ? adData : ad}
            uniqueListingDate={{}}
            setUpgradeModalMessage={setUpgradeModalMessage}
            handleCommonData={(data) => setCommonData(data)}
          />

          {!isLiveSearch && (
            <div className="card card-form">
              <div className="card-info-form">
                {contactInputs.map((input, idx) => {
                  const { name, value, label, type } = input;
                  return (
                    <InputLabeled
                      name={name}
                      value={value}
                      label={label}
                      type={type}
                      handleChange={(evt) => handleChange(evt, name)}
                      key={idx}
                    />
                  );
                })}
                <legend className="legend-form" />
              </div>
            </div>
          )}

          <div id="consentInput" className="card card-form">
            <div className="listing-edit-submit-container">
              <Button
                className="submit-button-CreateListing"
                text="Cancel"
                isPrimary={false}
                handleClick={() => history.push(`/listing/${id}`)}
              />
              <Button
                className="submit-button-CreateListing"
                text="Submit"
                handleClick={handleSubmit}
              />
            </div>
          </div>

          <Modal
            directModalMessage={modalMessage}
            modalAccept="Delete"
            modalCancel="Cancel"
            handleCancelClick={() => setModalMessage("")}
            handleAcceptClick={handleAdDelete}
          />
          <Modal
            directModalMessage={upgradeModalMessage}
            modalAccept="Upgrade"
            modalCancel="Cancel"
            handleCancelClick={() => setUpgradeModalMessage()}
            handleAcceptClick={() => {
              setUpgradeModalMessage();
              setPaymentModal(true);
            }}
          />
        </>
      </ProfileEditDragDrop>
    </ViewLayout>
  );
};

const mapStateToProps = (store) => ({
  user: store.user.user,
  isUser: store.user.isUser,
  results: store.data.results,
  isLiveSearch: store.state.isLiveSearch,
});

const mapDispatchToProps = (dispatch) => ({
  setLoader: (loaderState) => dispatch(stateActions.setLoader(loaderState)),
  setMessage: (dataObj) => dispatch(stateActions.setMessage(dataObj)),
  setResults: (adsArray) => dispatch(dataActions.setResults(adsArray)),
  setPaymentModal: (boolean) => dispatch(stateActions.setPaymentModal(boolean)),
  setUser: (userData) => dispatch(userActions.setUser(userData)),
  updateResultInResults: (ad) =>
    dispatch(dataActions.updateResultInResults(ad)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EditListing));
