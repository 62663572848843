import { timeToMilitary } from "./time";

export function sortResults(results, time) {
  let endingListings = [];
  let startingListings = [];
  time = timeToMilitary({ time });
  results.forEach((listing) => {
    const { startTime } = listing || {};
    if (time > startTime) {
      endingListings.push(listing);
    } else {
      startingListings.push(listing);
    }
  });
  endingListings = endingListings.sort(
    ({ endTime: aEndTime }, { endTime: bEndTime }) => aEndTime - bEndTime
  );
  startingListings = startingListings.sort(
    ({ startTime: astartTime }, { startTime: bstartTime }) =>
      astartTime - bstartTime
  );
  return [...endingListings, ...startingListings];
}
