import React, { useEffect, useState } from "react";
import moment from "moment";

// Components
import SkeletonLoader from "../analytics-community/analytics-loader";
import DetailViewLayout from "../../components/detail-view-scroll-layout";
import SwitchButton from "../../components/common/switch-button";
import BarChartDynamic from "../../components/chart/horizontal-bar-chart-dynamic";
import AreaChart from "../../components/chart/area-chart";

// Utilities
import { getEmployees, employeeAnalyticsGet } from "../../../api/employee";
import { toTitleCase } from "../../../utilites/format";
import GetFilterIcon from "../../components/filter-icons";
import { isSafariAgent } from "../../../utilites";

// Styles
import "./employee-styles.css";

export default () => {
  const urlParams = new URLSearchParams(window.location.search);
  const employeeId = urlParams.get("employeeId");
  const [isLoading, setIsLoading] = useState(true);
  const [isCurrent, setIsCurrent] = useState(true);
  const [employee, setEmployee] = useState(null);
  const [analytics, setAnalytics] = useState(null);
  const [metaData, setMetaData] = useState(null);
  const [totals, setTotals] = useState(null);
  const [weekOverView, setWeekOverView] = useState(null);
  const [telemarketingWeekDetails, setTelemarketingWeekDetails] =
    useState(null);
  const [dataEntryWeekDetails, setDataEntryWeekDetails] = useState(null);
  const [reviewWeekDetails, setReviewWeekDetails] = useState(null);
  const [sharedDetails, setSharedDetails] = useState(null);
  const { startDate, endDate, totalHours, costPer } = metaData || {};
  const { accountType, fName, lName, pay } = employee || {};
  const fullName = `${fName} ${lName}`;
  const daysOfTheWeek = [
    "Sunday",
    "Saturday",
    "Friday",
    "Thursday",
    "Wednesday",
    "Tuesday",
    "Monday",
  ];
  const isTelemarketing =
    accountType === "telemarketing" ||
    accountType === "flex" ||
    accountType === "admin" ||
    accountType === "developer";
  const isDataEntry =
    accountType === "data entry" ||
    accountType === "flex" ||
    accountType === "review" ||
    accountType === "admin" ||
    accountType === "developer";
  const isReview =
    accountType === "review" ||
    accountType === "admin" ||
    accountType === "developer";

  const getEmployee = async () => {
    const { employee: foundEmployee } = await getEmployees(employeeId);
    setEmployee(foundEmployee);
  };

  const getMetaData = (data, week) => {
    const date = moment().isoWeek(week).startOf("week").format("L");
    const startOfWeek = moment(date).add(1, "days").format("L");
    const endOfWeek = moment(date).add(7, "days").format("L");
    const { weeks } = data || {};
    const newMetaDate = {
      startDate: startOfWeek,
      endDate: endOfWeek,
    };

    if (!weeks || !(week in weeks)) {
      setMetaData(newMetaDate);
      return setTotals(null);
    }
    const thisWeek = weeks[week] || {};
    const weekTotals = daysOfTheWeek.map((day) => {
      if (!(day in thisWeek.days))
        return {
          hours: 0,
          completed: 0,
        };
      const { hours, signed_up = 0, completed = 0 } = thisWeek.days[day] || {};
      const combinedCompleted = signed_up + completed;
      return {
        hours: Object.keys(hours).length,
        completed: combinedCompleted,
      };
    });
    const totalHours = weekTotals.reduce((a, { hours }) => a + hours, 0);
    const combinedCompleted = weekTotals.reduce(
      (a, { completed }) => a + completed,
      0
    );

    setMetaData({
      ...newMetaDate,
      ...thisWeek,
      totalHours,
      costPer: combinedCompleted
        ? (totalHours * pay) / combinedCompleted
        : totalHours * pay,
    });

    const weekCopy = { ...thisWeek };
    delete weekCopy.days;

    const topTerms = Object.keys(weekCopy).map((term) => ({
      term: toTitleCase({ input: term.replace("_", " ") }),
      value: weekCopy[term],
    }));
    setTotals(topTerms);
  };

  const getWeekOverView = (data, week) => {
    const { weeks } = data || {};
    if (!weeks || !(week in weeks)) return setWeekOverView(null);
    const thisWeek = weeks[week] || {};
    if (!("days" in thisWeek)) return setWeekOverView(null);

    const days = daysOfTheWeek.map((day) => {
      if (!(day in thisWeek.days))
        return {
          day,
          hours: 0,
          interactions: 0,
        };

      const { hours } = thisWeek.days[day] || {};
      return {
        day,
        hours: Object.keys(hours).length,
        interactions: Object.values(hours).reduce((a, b) => a + b, 0),
      };
    });
    setWeekOverView(days);
  };

  const getTelemarketingWeekDetails = (data, week) => {
    const { weeks } = data || {};
    if (!weeks || !(week in weeks)) return setTelemarketingWeekDetails(null);
    const thisWeek = weeks[week] || {};
    if (!("days" in thisWeek)) return setTelemarketingWeekDetails(null);

    const days = daysOfTheWeek.map((day) => {
      if (!(day in thisWeek.days))
        return {
          day,
          "Info Sent": 0,
          "Signed Up": 0,
        };

      const { send_info = 0, signed_up = 0 } = thisWeek.days[day] || {};
      return {
        day,
        "Info Sent": send_info,
        "Signed Up": signed_up,
      };
    });
    setTelemarketingWeekDetails(days);
  };

  const getDataEntryWeekDetails = (data, week) => {
    const { weeks } = data || {};
    if (!weeks || !(week in weeks)) return setDataEntryWeekDetails(null);
    const thisWeek = weeks[week] || {};
    if (!("days" in thisWeek)) return setDataEntryWeekDetails(null);

    const days = daysOfTheWeek.map((day) => {
      if (!(day in thisWeek.days))
        return {
          day,
          create: 0,
          completed: 0,
        };

      const { create = 0, completed = 0 } = thisWeek.days[day] || {};
      return {
        day,
        create,
        completed,
      };
    });
    setDataEntryWeekDetails(days);
  };

  const getReviewWeekDetails = (data, week) => {
    const { weeks } = data || {};
    if (!weeks || !(week in weeks)) return setReviewWeekDetails(null);
    const thisWeek = weeks[week] || {};
    if (!("days" in thisWeek)) return setReviewWeekDetails(null);

    const days = daysOfTheWeek.map((day) => {
      if (!(day in thisWeek.days))
        return {
          day,
          "reviewed listing": 0,
          "reviewed business": 0,
          "create listing": 0,
        };

      const {
        reviewed_listing = 0,
        reviewed_business = 0,
        create_listing = 0,
      } = thisWeek.days[day] || {};
      return {
        day,
        "reviewed listing": reviewed_listing,
        "reviewed business": reviewed_business,
        "create listing": create_listing,
      };
    });
    setReviewWeekDetails(days);
  };

  const getSharedDetails = (data, week) => {
    const { weeks } = data || {};
    if (!weeks || !(week in weeks)) return setTelemarketingWeekDetails(null);
    const thisWeek = weeks[week] || {};
    if (!("days" in thisWeek)) return setTelemarketingWeekDetails(null);

    const days = daysOfTheWeek.map((day) => {
      if (!(day in thisWeek.days))
        return {
          day,
          Claimed: 0,
          "Save Notes": 0,
        };

      const { claimed = 0, saved_notes = 0 } = thisWeek.days[day] || {};
      return {
        day,
        claimed,
        "Saved Notes": saved_notes,
      };
    });
    setSharedDetails(days);
  };

  const getWeekValues = (data = {}, week = moment().isoWeek()) => {
    if (!Object.keys(data).length) return;
    getMetaData(data, week);
    getWeekOverView(data, week);
    if (isTelemarketing) getTelemarketingWeekDetails(data, week);
    if (isDataEntry) getDataEntryWeekDetails(data, week);
    if (isReview) getReviewWeekDetails(data, week);
    getSharedDetails(data, week);
  };

  const getAnalytics = async () => {
    setIsLoading(true);
    const { analytics: newAnalytics } = await employeeAnalyticsGet(employeeId);
    setAnalytics(newAnalytics);
    getWeekValues(newAnalytics);
    setIsLoading(false);
  };

  useEffect(() => {
    if (employee) {
      getAnalytics();
    } else {
      getEmployee();
    }
  }, [employee]);

  const handleClick = (type) => {
    const isSafari = isSafariAgent();
    if (isSafari) setIsLoading(true);
    const isCurrent = type === "Current";
    setIsCurrent(isCurrent);
    getWeekValues(
      analytics,
      isCurrent ? moment().isoWeek() : moment().subtract(7, "days").isoWeek()
    );
    if (isSafari) setTimeout(() => setIsLoading(false));
  };

  const renderGraphs = () => (
    <>
      <div className="listing-content-container">
        <div className="flex-center" style={{ flexDirection: "column" }}>
          <p className="analytics-title">{`${endDate} - ${startDate}`}</p>
          <p
            className="analytics-title"
            style={{ marginTop: "10px" }}
          >{`Total Hours: ${totalHours}`}</p>
          <p
            className="analytics-title"
            style={{ marginTop: "5px" }}
          >{`Cost Per: $${costPer}`}</p>
        </div>
      </div>

      {totals && (
        <div className="listing-content-container">
          <div className="flex-center">
            <BarChartDynamic isValue isTooltip data={totals} />
          </div>
        </div>
      )}

      {weekOverView && (
        <div className="listing-content-container">
          <div className="flex-center">
            <p className="analytics-title">Week Over View</p>
          </div>
          <div className="analytics-spacing">
            <AreaChart
              primaryKey="interactions"
              secomdaryKey="hours"
              data={weekOverView}
            />
          </div>
        </div>
      )}

      {isTelemarketing && telemarketingWeekDetails && (
        <div className="listing-content-container">
          <div className="flex-center">
            <p className="analytics-title">Telemarketing Week Details</p>
          </div>
          <div className="analytics-spacing">
            <AreaChart
              primaryKey="Info Sent"
              secomdaryKey="Signed Up"
              data={telemarketingWeekDetails}
            />
          </div>
        </div>
      )}

      {isDataEntry && dataEntryWeekDetails && (
        <div className="listing-content-container">
          <div className="flex-center">
            <p className="analytics-title">Data Entry Week Details</p>
          </div>
          <div className="analytics-spacing">
            <AreaChart
              primaryKey="create"
              secomdaryKey="completed"
              data={dataEntryWeekDetails}
            />
          </div>
        </div>
      )}

      {isReview && reviewWeekDetails && (
        <div className="listing-content-container">
          <div className="flex-center">
            <p className="analytics-title">Review Week Details</p>
          </div>
          <div className="analytics-spacing">
            <AreaChart
              primaryKey="reviewed listing"
              secomdaryKey="reviewed business"
              promoKey="create listing"
              data={reviewWeekDetails}
            />
          </div>
        </div>
      )}

      <div className="listing-content-container">
        <div className="flex-center">
          <p className="analytics-title">Shared Week Details</p>
        </div>
        <div className="analytics-spacing">
          <AreaChart
            primaryKey="claimed"
            secomdaryKey="Saved Notes"
            data={sharedDetails}
          />
        </div>
      </div>
    </>
  );

  return (
    <DetailViewLayout heightFromTop={100} showImage={false} name={fullName}>
      <div className="analytics-header-container">
        <div className="flex-center">
          <p className="analytics-title">{`${toTitleCase({
            input: fullName,
          })} Analytics`}</p>
        </div>
        <div
          className="analytics-reset-container"
          onClick={() => getAnalytics()}
        >
          {GetFilterIcon("refresh")}
        </div>
        <div className="analytics-selector-container">
          <SwitchButton
            button1Name="Current"
            button2Name="Last Week"
            isFirst={isCurrent}
            handleClick={handleClick}
          />
        </div>
      </div>

      {isLoading ? (
        <SkeletonLoader />
      ) : totals ? (
        renderGraphs()
      ) : (
        <p className="analytics-no-text">No Statistics At This Time</p>
      )}
    </DetailViewLayout>
  );
};
