import React, { useRef } from "react";
import { connect } from "react-redux";
import * as stateActions from "../../../redux/actions/state-actions";
import * as dataActions from "../../../redux/actions/data-actions";

// Components
import ButtonLink from "../business/button-link";
import Date from "./date";
import Features from "./features";
import Contact from "./contact";
import OtherAds from "./related-ads";
import ReviewButton from "./review-buttons";
import Spacer from "../../components/common/spacer";

// Utilities
import ListingLoader from "./listing-loader";
import { isBottomSpacing, setTheme } from "../../../utilites";
import UseGetTheme from "../../../utilites/hooks/useGetTheme";
import { formatListingTime, toTitleCase } from "../../../utilites/format";
import { getAdStatus } from "../../../utilites/status";
import getFeaturesIcon from "../../components/features-input/getFeatureIcon";

// Styles
import "./listing.css";

const Listing = ({
  edit,
  original,
  business,
  related,
  setEdit,
  setPromoCode,
}) => {
  const {
    name: businessName = "",
    images: businessImages = [],
    ads = [],
  } = business || {};
  const edited = { ...original, ...edit };
  const formattedAd = formatListingTime({ ad: edited });
  const adFormattedStatus = useRef(getAdStatus(formattedAd));
  const hasViewedPromo = useRef(false);
  const { isNight } = UseGetTheme();
  const { businessName: originalBusinessName } = original;
  const { businessName: editedBusinessName, requestDelete } = edit;

  const renderPost = (post, postTitle) => {
    const {
      type,
      isGrowth,
      _id,
      categories,
      images: adImages,
      title,
      description,
      virtualLink,
      features,
      promoCode,
      ticketLink,
      businessName: growthBusinessName,
      reviewed,
    } = post || {};
    const {
      isDateCurrent,
      status: adStatus,
      hasStarted,
    } = adFormattedStatus.current;
    const { cat1Img, cat2Img } = categories || {};
    const cardHeaderStyle = isDateCurrent
      ? `listing-header-container-${adStatus}`
      : "listing-header-container-starting";
    const isDarkColor = !isNight && adStatus === "starting";
    const images = [...adImages, ...businessImages];
    if (cat2Img) images.unshift(cat2Img);
    images.unshift(cat1Img);
    const {
      isReviewed,
      agent: reviewedAgent,
      timeStamp: reviewedTimeStamp,
    } = reviewed || {};
    const isEdit = postTitle === "Edited";

    const handlePromoClick = () => {
      hasViewedPromo.current = true;
      setPromoCode(promoCode);
    };

    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          overflowY: "scroll",
        }}
      >
        <div className="listing-content-wrapper">
          <Spacer height={60} />
          {isReviewed && (
            <div className="listing-content-container">
              <div className="check-in-container">
                <div className="listing-marketing-metedata-conatiner">
                  {isReviewed && (
                    <>
                      <p className="listing-marketing-metedata">{`Reviewed By: ${reviewedAgent}`}</p>
                      <p className="listing-marketing-metedata">{`Reviewed Time: ${reviewedTimeStamp}`}</p>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}

          {postTitle === "Original" && (
            <OtherAds currentId={_id} ads={related} name={businessName} />
          )}

          <p
            style={{
              width: "100%",
              margin: "-10px 0px 10px 0px",
              textAlign: "center",
              fontWeight: 600,
              color: requestDelete ? "RED" : "black",
            }}
          >
            {requestDelete ? `REQUEST TO DELETE` : postTitle}
          </p>

          <div className="listing-content-container">
            <div className={`listing-header-container ${cardHeaderStyle}`}>
              <div
                className="lisitng-type-container"
                style={isEdit && type !== original.type ? { fill: "red" } : {}}
              >
                {getFeaturesIcon(type)}
              </div>
              <p
                className={`listing-title-main ${
                  isDarkColor ? "listing-title-main-dark" : ""
                }`}
                style={
                  isEdit && title !== original.title ? { color: "red" } : {}
                }
              >
                {title.toUpperCase()}
              </p>
            </div>
            <div className="listing-business-info-conatiner">
              <p
                className="pointer listing-business-name"
                style={
                  isEdit && originalBusinessName !== editedBusinessName
                    ? { color: "red" }
                    : {}
                }
              >
                {toTitleCase({
                  input: growthBusinessName || businessName,
                  lowerCaseRest: false,
                })}
              </p>
            </div>
            <p
              className="listing-spacing listing-description-main"
              style={
                isEdit && description !== original.description
                  ? { color: "red" }
                  : {}
              }
            >
              {description}
            </p>

            <ButtonLink
              isDateCurrent={isDateCurrent}
              status={adStatus}
              text="GET TICKETS"
              link={ticketLink}
            />

            <ButtonLink
              isDateCurrent={isDateCurrent}
              status={adStatus}
              text="VIRTUAL LINK"
              link={virtualLink}
            />

            {promoCode && (
              <div className="listing-spacing listing-center-content">
                <div
                  className={`redemtion-button-code-button ${cardHeaderStyle}`}
                  onClick={handlePromoClick}
                >
                  <p
                    className={`redemtion-button-code-text ${
                      hasStarted ? "color-white" : ""
                    }`}
                  >
                    REDEMPTION CODE
                  </p>
                </div>
              </div>
            )}

            <Date data={post} status={adStatus} />
          </div>

          <Features
            adTitle={title}
            name={businessName || growthBusinessName}
            categories={categories}
            features={features}
            adStatus={adStatus}
          />

          <OtherAds currentId={_id} ads={ads} name={businessName} />

          <Contact
            ad={post}
            business={isGrowth ? post : business}
            status={adStatus}
          />
          <Spacer height={isBottomSpacing()} />
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
        }}
      >
        {original && renderPost(original, "Original")}
        {!requestDelete && renderPost(edited, "Edited")}
      </div>

      <ReviewButton
        size="large"
        edited={edited}
        original={original}
        related={related}
        setEdit={setEdit}
      />
    </>
  );
};

const mapStateToProps = (store) => ({
  user: store.user.user,
  isUser: store.user.isUser,
  results: store.data.results,
});

const mapDispatchToProps = (dispatch) => ({
  setHeaderTitle: (title) => dispatch(stateActions.setHeaderTitle(title)),
  setImage: (value) => dispatch(stateActions.setImage(value)),
  setSavedPage: (url) => dispatch(stateActions.setSavedPage(url)),
  setLoader: (value) => dispatch(stateActions.setLoader(value)),
  setMessage: (messageObj) => dispatch(stateActions.setMessage(messageObj)),
  setResults: (resultsObj) => dispatch(dataActions.setResults(resultsObj)),
  setPromoCode: (value) => dispatch(stateActions.setPromoCode(value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListingLoader(Listing));
