import React from "react";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 12"
    >
      <path
        className="svg-clip-rule"
        d="M19,0.4L11,6L6.1,2.9l0,0L0.6,6.6v4.5c0,0.3,0.2,0.5,0.5,0.5h27.7c0.3,0,0.5-0.2,0.5-0.5V7.6L19,0.4z"
      />
      <path
        className="svg-clip-rule"
        d="M13.4,3.2c-0.8-0.7-1.7-1.1-2.6-1v0C9.9,2.2,9,2.5,8.4,3.2L11,4.9L13.4,3.2z"
      />
    </svg>
  );
};
