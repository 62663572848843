import { toTitleCase } from "../utilites/format";

export const dayTimes = {
  dayStart: 600,
  dayEnd: 1700,
};

export const defaultDaysOfTheWeek = {
  Monday: false,
  Tuesday: false,
  Wednesday: false,
  Thursday: false,
  Friday: false,
  Saturday: false,
  Sunday: false,
};
export const dayOfTheWeek = Object.keys(defaultDaysOfTheWeek);

export const searchTypes = [
  "Deals",
  "Promotions",
  // "Instaworthy",
  // "Signature Specialities",
  "Business",
];

export const searchTypesDescriptions = {
  Deals:
    "People love a good deal, lets promote yours! Specials and discounts belong here.",
  Promotions:
    "Want to get the word out? We want to help. Use this type to promote to the local community.",
  Instaworthy:
    "Have something that makes people say WOW and take a picture? That’s “Instaworthy” and it belongs to this type.",
  "Signature Specialities":
    "This is to promote what you specialize in. A signature item, product your customers love, or something you simply do better than most.",
};

export const subCatOptions = {
  Food: [
    "American",
    "Asian",
    "Breakfast",
    "Desserts",
    "French",
    "Fusion",
    "Greek",
    "Indian",
    "Italian",
    "Latin",
    "Mediterranean",
    "Seafood",
    "Vegan",
    "Vegetarian",
    "Other",
  ],
  Drinks: ["Alcohol", "Coffee/Tea", "Juices/Smoothies", "Shakes", "Other"],
  Activities: [
    "Arts",
    "Educational",
    "Games",
    "Health",
    "Recreational",
    "Sports",
    "Tobacco/Cannabis",
    "Tours",
    "Treat Yourself",
    "Other",
    // "Music",
    // "Pets",
    // "Seasonal",
    // "Singles",
    // "Kids",
    // "Virtual",
  ],
  Events: [
    "Entertainment",
    "Exhibits",
    "Music",
    "Sports",
    "Other",
    // "Arts",
    // "Educational",
    // "Health",
    // "Kids",
    // "Seasonal",
    // "Singles",
    // "Virtual",
  ],
};
export const categoryList = ["", ...Object.keys(subCatOptions)];

export const features = [
  "Animals",
  "Arcade",
  "Attraction",
  "Bar",
  "Black Owned",
  "Brewery",
  "Curbside Pickup",
  "Dance Floor",
  "Delivery",
  "Dog Friendly",
  "Fire Pit",
  "Food Truck",
  "Instaworthy",
  "Kids",
  "Landmark",
  "LGBTQ",
  "Outdoors",
  "Outdoor Dining",
  "Outdoor Heater",
  "Patio",
  "Pool Table",
  "Rooftop",
  "Seasonal",
  "Speakeasy",
  "Sports Bar",
  "Take Out",
  "View",
  "Vineyard",
  "Water Sport",
];

export const getFilterList = (values, timeOptions, type) => ({
  Distance: {
    options: [
      "1 Mile",
      "5 Miles",
      "10 Miles",
      "25 Miles",
      "50 Miles",
      "100 Miles",
      "500 Miles",
    ],
    value: values[1],
    name: "distance",
    legend: "Distance:",
  },
  "Sort By": {
    options:
      type === "Events"
        ? ["Start Time", "Distance", "Popularity", "Newest"]
        : ["Popularity", "Newest", "Oldest"],
    value: values[2],
    name: "sortBy",
    legend: "Sort By:",
  },
  Limit: {
    options: [30, 50, 75, 100],
    value: values[3],
    name: "limit",
    legend: "Limit:",
  },
});

export const mapOptions = {
  standard: [
    {
      featureType: "poi",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "transit",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
  ],
  day: [
    {
      featureType: "poi.park",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#C2E994",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#F8E1BE",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#B08C55",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#5A7189",
        },
        {
          lightness: -15,
        },
      ],
    },
  ],
  night: [
    { elementType: "geometry", stylers: [{ color: "#4a4d4e" }] },
    {
      elementType: "labels.text.stroke",
      stylers: [{ color: "#242f3e" }],
    },
    { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
    {
      featureType: "administrative.locality",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [{ color: "#263c3f" }],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [{ color: "#6b9a76" }],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [{ color: "#999B9C" }],
    },
    {
      featureType: "road",
      elementType: "geometry.stroke",
      stylers: [{ color: "#212a37" }],
    },
    {
      featureType: "road",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9ca5b3" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [{ color: "#999B9C" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [{ color: "#1f2835" }],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [{ color: "#f3d19c" }],
    },
    {
      featureType: "transit",
      elementType: "geometry",
      stylers: [{ color: "#2f3948" }],
    },
    {
      featureType: "transit.station",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{ color: "#17263c" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [{ color: "#515c6d" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.stroke",
      stylers: [{ color: "#17263c" }],
    },
  ],
};

export const verbiage = {
  acceptFreeMonthlyAd:
    "This will be set as your one free monthly ad. If accepted, you will not be able to edit the title or descritption for one month. If you would like to add more, upgrade to a Premium or Unlimited account. Do you wish to continue?",
  acceptPremiumMonthlyAd:
    "This will be set as one of your monthly ads. If accepted, you will not be able to edit the title or descritption for one month. If you would like to have more then 3 ads, upgrade to an Unlimited account. Do you wish to continue?",
};

export const covidMessage = (businessName) =>
  `This badge indicates that ${toTitleCase({
    input: businessName,
  })} has personally agreed to adhere to the full guidelines provided by the CDC and local county/state public health order for COVID protocol.
  
  Please review link below for full CDC guidance and review your local state/county health department guidelines for more information.`;
