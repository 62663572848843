import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as userActions from "../../redux/actions/user-actions";

// Utilities
import { getCoordinates } from "../../api/search";

export default () => {
  const mapLocation = useSelector((state) => state.user.mapLocation);
  const isMapLocation = !!mapLocation;
  const [isUpdatingLocation, setIsUpdatingLocation] = useState(isMapLocation);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isMapLocation) {
      (async () => {
        const latlng = `${mapLocation[1]}, ${mapLocation[0]}`;
        const { location } = (await getCoordinates({ latlng })) || {};
        dispatch(
          userActions.setLocation({
            ...location,
            coordinates: mapLocation,
          })
        );
        setIsUpdatingLocation(false);
      })();
    } else {
      setIsUpdatingLocation(false);
    }
  }, []);

  return { isUpdatingLocation };
};
