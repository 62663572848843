import React from "react";

// Styles
import "./svg.css";

export default () => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 310 45"
    >
      <>
        <image
          style={{ overflow: " visible", opacity: "0.7" }}
          width="129"
          height="157"
          xlinkHref="/img/F2479AE9DF349A45.png"
          transform="matrix(0.24 0 0 0.24 8.2303 6.7522)"
        />
        <path d="M11.3,9.8H30v5.1H16.4v5.8h10.2v5.1H16.4v4.4H30v5.1H11.3V9.8z" />

        <image
          style={{ overflow: " visible", opacity: "0.7" }}
          width="173"
          height="157"
          xlinkHref="/img/F2479AE9DF349A47.png"
          transform="matrix(0.24 0 0 0.24 32.2303 6.7522)"
        />
        <path d="M50.8,35.2h-1.5L35.4,9.8h5.8L50.1,26l8.8-16.2h5.8L50.8,35.2z" />

        <image
          style={{ overflow: " visible", opacity: "0.7" }}
          width="129"
          height="157"
          xlinkHref="/img/F2479AE9DF349A42.png"
          transform="matrix(0.24 0 0 0.24 67.2703 6.7522)"
        />
        <path d="M70.4,9.8h18.6v5.1H75.5v5.8h10.2v5.1H75.5v4.4h13.6v5.1H70.4V9.8z" />

        <image
          style={{ overflow: " visible", opacity: "0.7" }}
          width="143"
          height="157"
          xlinkHref="/img/F2479AE9DF349A43.png"
          transform="matrix(0.24 0 0 0.24 96.0703 6.7522)"
        />
        <path d="M99.1,9.8h1.4l15.6,14.8V9.8h5.1v25.4h-1.4l-15.6-14.8v14.8h-5.1V9.8z" />

        <image
          style={{ overflow: " visible", opacity: "0.7" }}
          width="143"
          height="157"
          xlinkHref="/img/F2479AE9DF349A4D.png"
          transform="matrix(0.24 0 0 0.24 126.0703 6.7522)"
        />
        <path d="M129.2,9.8h22v5.1h-8.5v20.3h-5.1V14.9h-8.5V9.8z" />

        <image
          style={{ overflow: " visible", opacity: "0.7" }}
          width="135"
          height="150"
          xlinkHref="/img/F2479AE9DF349A4F.png"
          transform="matrix(0.24 0 0 0.24 157.0303 7.4722)"
        />
        <path d="M180.4,10.6v23.7H177v-7.6h-13.6v7.6h-3.4V10.6h3.4v12.7H177V10.6H180.4z" />

        <image
          style={{ overflow: " visible", opacity: "0.7" }}
          width="149"
          height="150"
          xlinkHref="/img/F2479AE9DF349A40.png"
          transform="matrix(0.24 0 0 0.24 185.3503 7.4722)"
        />
        <path
          d="M200.2,34.4c-6.5,0-11.9-5.3-11.9-11.9s5.3-11.9,11.9-11.9c6.5,0,11.9,5.3,11.9,11.9S206.8,34.4,200.2,34.4z M191.8,22.5
			c0,4.7,3.8,8.5,8.5,8.5c4.7,0,8.5-3.8,8.5-8.5c0-4.7-3.8-8.5-8.5-8.5C195.6,14,191.8,17.8,191.8,22.5z"
        />

        <image
          style={{ overflow: " visible", opacity: "0.7" }}
          width="136"
          height="150"
          xlinkHref="/img/F2479AE9DF349A41.png"
          transform="matrix(0.24 0 0 0.24 216.3103 7.4722)"
        />
        <path
          d="M229.6,34.4c-5.6,0-10.2-4.5-10.2-10.2V10.6h3.4v13.6c0,3.8,3,6.8,6.8,6.8c3.7,0,6.8-3,6.8-6.8V10.6h3.4v13.6
			C239.7,29.8,235.2,34.4,229.6,34.4z"
        />

        <image
          style={{ overflow: " visible", opacity: "0.7" }}
          width="135"
          height="150"
          xlinkHref="/img/F2479AE9DF349A46.png"
          transform="matrix(0.24 0 0 0.24 245.8303 7.4722)"
        />
        <path d="M269.2,10.6v23.7H269l-16.7-16v16h-3.4V10.6h0.2l16.8,16v-16H269.2z" />

        <image
          style={{ overflow: " visible", opacity: "0.7" }}
          width="136"
          height="153"
          xlinkHref="/img/45963CD429A4C0E.png"
          transform="matrix(0.24 0 0 0.24 275.1103 6.7522)"
        />
        <path
          d="M278.4,9.8L278.4,9.8L278.4,9.8L278.4,9.8z M278.3,34.4V10.6h8.5c6.5,0,11.9,5.3,11.9,11.9s-5.3,11.9-11.9,11.9H278.3z
			 M286.8,31c4.7,0,8.5-3.8,8.5-8.5c0-4.7-3.8-8.5-8.5-8.5h-5.1V31H286.8z"
        />
      </>
    </svg>
  );
};
