import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as dataActions from "../../../redux/actions/data-actions";
import * as userActions from "../../../redux/actions/user-actions";
import * as stateActions from "../../../redux/actions/state-actions";

// Components
import Recommended from "./recommended";
import AutocompleteItems from "../../components/autocomplete-items.js";
import Select from "../../components/common/select";

// Utilities
import { isMobileAgent } from "../../../utilites";
import { getCityLocation } from "../../../utilites/location";
import { toTitleCase } from "../../../utilites/format";
import { getCoordinates } from "../../../api/search";

// Assets
import Icon from "../../../assets/svg/eventHound-logo-with-shadow";
import Eventhound from "../../../assets/svg/eventHound-name-shadow";
import User from "../../../assets/svg/user";
import DirectionArrow from "../../../assets/svg/directionArrow";
import Back from "../../../assets/svg/back";
import Search from "../../../assets/svg/search";
import Location from "../../../assets/svg/location";
import LocationStroke from "../../../assets/svg/location-stroke";
import Map from "../../../assets/svg/map";
import Close from "../../../assets/svg/close";
import Logo from "../../../assets/svg/event-hound-icon";

// Styles
import "./home.css";

const Home = ({
  user,
  isUser,
  setLocation,
  setFilter,
  history,
  setMessage,
  type,
}) => {
  const [isMounted, setIsMounted] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [locationInput, setLocationInput] = useState("");
  const [searchInputFocued, setSearchInputFocued] = useState(false);
  const [autoCompleteListType, setAutoCompleteListType] = useState("");
  const [autoCompleteList, setAutoCompleteList] = useState([]);
  const cityLocation = getCityLocation(user);
  const isMobile = isMobileAgent();
  const { geoPermission, location } = user;
  const isLocation = "coordinates" in location;
  const geoLocationIsCurrent = geoPermission && !isLocation;
  const { fName } = isUser && user && user.user ? user.user : {};
  const welcomeName = fName
    ? `Hi ${toTitleCase({ input: fName })}, what`
    : "What";
  const suggestedTerms = [
    "happy hour",
    "meal deal",
    "golf",
    "live music",
    "brunch",
    "football",
  ];

  useEffect(() => {
    if (!isMounted) setFilter({ search: "" });
    setIsMounted(true);
  }, []);

  const handleChange = (evt, type, name) => {
    const { value } = evt.target;
    switch (type) {
      case "searchInput":
        return setSearchInput(value);
      case "locationInput":
        return setLocationInput(value);
      case "select":
        setFilter({ [name]: value });
        break;
    }
  };

  const handleUserClick = () => {
    const userType = isUser ? "user" : "business";
    history.push(`/${userType}/profile`);
  };

  const handleBackClick = () => {
    setSearchInputFocued(false);
    setSearchInput("");
    setLocationInput("");
    setAutoCompleteList([]);
  };

  const handleLocationClick = () => {
    if (!geoPermission)
      setMessage({
        messageType: "error",
        message: "Enable Location Permissions",
      });
    if (isLocation) setLocation({});
  };

  const handleClearInput = (type) => {
    setAutoCompleteList([]);
    switch (type) {
      case "location":
        setLocationInput("");
        break;
      case "search":
        setSearchInput("");
        break;
    }
  };

  const handleFocus = () => {
    setSearchInputFocued(true);
    const slidUpInput = document.getElementById("home-search-slid-up-input");
    slidUpInput.focus();
  };

  const handleKeyDown = (evt) => {
    const { which, keyCode } = evt;
    const isEnter = which === 13 || keyCode === 13;
    if (isEnter) handleSearch();
  };

  const handleSearch = async () => {
    if (locationInput) {
      const { location } =
        (await getCoordinates({ address: locationInput })) || {};
      if ("coordinates" in location) {
        setLocation(location);
      } else {
        setMessage({
          messageType: "error",
          message: "Location Not found",
        });
      }
    }
    if (searchInput) setFilter({ search: searchInput });
    history.push("/top");
  };

  const handleAutoCompleteChange = (list, type) => {
    setAutoCompleteListType(type);
    setAutoCompleteList(list);
  };

  const handleAutoCompleteClick = async (item, type) => {
    switch (type) {
      case "search":
        if (item) setFilter({ search: item });
        break;
      case "location":
        const { location } = (await getCoordinates({ address: item })) || {};
        if ("coordinates" in location) {
          setAutoCompleteList([]);
          setLocation(location);
          setLocationInput("");
        }
        break;
    }
    history.push("/top");
  };

  return (
    <>
      <div className="home-top-wrapper">
        <img
          className="home-background-image"
          alt="EventHound Concert"
          src="/img/food.jpg"
        />
        <div className="home-background-image-overlay" />
        <div
          className="home-profile-svg-container pointer"
          onClick={handleUserClick}
        >
          <User />
        </div>
        <div className="home-text-container">
          <div className="home-logo-container">
            <div className="home-icon-container">
              <Icon />
            </div>
            <div className="home-name-container">
              <Eventhound />
            </div>
          </div>
        </div>
        <div className="home-search-wrapper">
          <div
            className={`home-search-container ${
              searchInputFocued ? "home-search-container-focused" : ""
            }`}
          >
            <div className="home-search-svg-container">
              <Search />
            </div>
            <input
              className="home-search-input"
              type="text"
              placeholder={`${welcomeName} are you looking for?`}
              value={searchInput}
              onChange={(evt) => handleChange(evt, "searchInput")}
              onFocus={handleFocus}
              spellCheck="true"
            />
          </div>
        </div>
        {!isMobile && searchInputFocued && (
          <div
            className="home-search-slideup-background"
            onClick={handleBackClick}
          />
        )}
        <div
          className={`home-search-slideup-wrapper ${
            searchInputFocued ? "home-search-slideup-wrapper-focused" : ""
          }`}
        >
          <div className="home-input-slideup-top-container">
            <div className="home-input-slideup-top-left-wrapper">
              <div className="home-input-slideup-top-left-container">
                <div
                  className="home-back-svg-container pointer"
                  onClick={handleBackClick}
                >
                  <Back />
                </div>
                <div className="home-search-slideup-container">
                  <div className="home-search-svg-container">
                    <Search />
                  </div>
                  <input
                    id="home-search-slid-up-input"
                    className="home-search-slideup-input"
                    type="text"
                    placeholder={'Try "Happy Hour"'}
                    value={searchInput}
                    onKeyDown={handleKeyDown}
                    onChange={(evt) => handleChange(evt, "searchInput")}
                    spellCheck="true"
                  />
                  {!!searchInput && (
                    <div
                      className="searchbar-input-clear-container pointer"
                      onClick={() => handleClearInput("search")}
                    >
                      <Close />
                    </div>
                  )}
                </div>
              </div>

              <div className="home-search-slideup-container">
                <div
                  className={`home-search-svg-container pointer ${
                    geoLocationIsCurrent ? "searchbar-svg-current-location" : ""
                  }`}
                  onClick={handleLocationClick}
                >
                  {geoLocationIsCurrent ? <Location /> : <LocationStroke />}
                </div>
                <input
                  className="home-search-slideup-input"
                  type="text"
                  placeholder={cityLocation || "Enter Location"}
                  value={locationInput}
                  onKeyDown={handleKeyDown}
                  onChange={(evt) => handleChange(evt, "locationInput")}
                />
                {!!locationInput && (
                  <div
                    className="searchbar-input-clear-container pointer"
                    onClick={() => handleClearInput("location")}
                  >
                    <Close />
                  </div>
                )}
              </div>
            </div>
            <div
              className="home-map-svg-container pointer"
              onClick={() => history.push("/top/map")}
            >
              <Map />
            </div>
          </div>
          <div className="home-input-slideup-bottom-container">
            <div className="home-input-type-container">
              <Select
                classNames="filter-select-container"
                options={["Events", "Business"]}
                value={type}
                handleChange={(evt) => handleChange(evt, "select", "type")}
                styles={{ paddingLeft: "8px" }}
                name={"type"}
              >
                <Logo />
              </Select>
            </div>
            {AutocompleteItems({
              searchInput,
              locationInput,
              handleChange: handleAutoCompleteChange,
              handleClick: handleAutoCompleteClick,
              list:
                autoCompleteList.length > 0 ? autoCompleteList : suggestedTerms,
              type: autoCompleteListType || "search",
            })}
          </div>
        </div>
      </div>
      <div className="home-bottom-wrapper-outer">
        <div className="home-bottom-wrapper">
          <div className="home-bottom-text-conatiner">
            <p className="home-highlight-text">What's Happening Near You</p>
            <div
              className="home-see-more-container pointer"
              onClick={() => {
                setFilter({ type: "Events" });
                history.push("/top");
              }}
            >
              <p className="home-bottom-see-more">see more</p>
              <div className="home-see-more-svg">
                <DirectionArrow />
              </div>
            </div>
          </div>
          <Recommended history={history} />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (store) => ({
  user: store.user,
  isUser: store.user.isUser,
  type: store.data.filters.type,
});

const mapDispatchToProps = (dispatch) => ({
  setFilter: (filterObj) => dispatch(dataActions.setFilter(filterObj)),
  setLocation: (coordsArray) => dispatch(userActions.setLocation(coordsArray)),
  setMessage: (dataObj) => dispatch(stateActions.setMessage(dataObj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Home));
