import { callAPI } from "./";
import { baseURL } from "./url";

const businessURL = baseURL("/business");
const listingURL = baseURL("/listing/set");

export function businessGet(id) {
  const url = baseURL(`/getbusiness?id=${id}`);
  return callAPI(url, "GET");
}

export function businessLogin(data) {
  const url = baseURL("/getbusiness");
  return callAPI(url, "POST", data);
}

export function createBusiness(businessData) {
  return callAPI(businessURL, "PUT", businessData);
}

export function updateBusiness(businessData) {
  if ("token" in businessData) delete businessData.token;
  if ("ads" in businessData) delete businessData.ads;
  return callAPI(businessURL, "POST", businessData);
}

export function deleteBusiness({ phone, password }) {
  let url = `${businessURL}?phone=${phone}&password=${password}`;
  return callAPI(url, "DELETE");
}

export function yelpVerifyBusiness(businessData) {
  const url = `${businessURL}/verify/yelp`;
  return callAPI(url, "PUT", businessData);
}

export function applyBusinessPromo(businessData) {
  return callAPI(`${businessURL}/promo`, "PUT", businessData);
}

export function reportBusiness(data) {
  return callAPI(`${businessURL}/report`, "POST", data);
}

export function viewedBusiness(analyticsId, businessId, date) {
  const url = `${businessURL}/viewed?analyticsId=${analyticsId}&businessId=${businessId}&date=${date}`;
  return callAPI(url, "GET");
}

export function setBusinessFree(listingData) {
  return callAPI(listingURL, "POST", listingData);
}

export function setBusinessCovid(data) {
  const url = `${businessURL}/covid`;
  return callAPI(url, "POST", data);
}
