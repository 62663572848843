import { callAPI } from "./";
import { baseURL } from "./url";

const verifyURL = baseURL("/verify");

export function verifyUser(id) {
  const url = `${verifyURL}?id=${id}`;
  return callAPI(url, "GET");
}

export function confirmVerifyUser(data) {
  return callAPI(verifyURL, "POST", data);
}
