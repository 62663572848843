import React from "react";
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
} from "recharts";

// Utilities
import UseGetTheme from "../../../utilites/hooks/useGetTheme";

const Chart = ({
  primaryKey,
  secondaryKey,
  promoKey,
  data,
  height = "280px",
  isToolTip = true,
}) => {
  const { isNight } = UseGetTheme();
  return data ? (
    <div style={{ width: "100%", height }}>
      <ResponsiveContainer>
        <BarChart data={data} layout="vertical">
          <defs>
            <linearGradient
              id="horizontalpromoKey"
              x1="0"
              y1="0"
              x2="0"
              y2="1"
              gradientTransform="rotate(90 .5 .5)"
            >
              <stop offset="5%" stopColor="#48C826" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#48C826" stopOpacity={0.2} />
            </linearGradient>
            <linearGradient
              id={`horizontal${primaryKey}`}
              x1="0"
              y1="0"
              x2="0"
              y2="1"
              gradientTransform="rotate(90 .5 .5)"
            >
              <stop
                offset="5%"
                stopColor="hsl(215, 100%, 65%)"
                stopOpacity={0.8}
              />
              <stop
                offset="95%"
                stopColor="hsl(215, 100%, 65%)"
                stopOpacity={0.2}
              />
            </linearGradient>
            <linearGradient
              id={`horizontal${secondaryKey}`}
              x1="0"
              y1="0"
              x2="0"
              y2="1"
              gradientTransform="rotate(90 .5 .5)"
            >
              <stop
                offset="5%"
                stopColor="hsl(339, 100%, 55%)"
                stopOpacity={0.8}
              />
              <stop
                offset="95%"
                stopColor="hsl(339, 100%, 55%)"
                stopOpacity={0.4}
              />
            </linearGradient>
          </defs>
          <XAxis
            type="number"
            stroke={isNight ? "#f1f1f1" : " rgb(80,80,80)"}
          />
          <YAxis
            dataKey="name"
            type="category"
            stroke={isNight ? "#f1f1f1" : " rgb(80,80,80)"}
          />
          {isToolTip && <Tooltip />}
          <Legend />
          {promoKey && (
            <Bar
              dataKey={promoKey}
              stroke="#2DA90C"
              fill="url(#horizontalpromoKey)"
            />
          )}
          <Bar
            dataKey={secondaryKey}
            stroke="hsl(339, 100%, 55%)"
            fill={`url(#horizontal${secondaryKey})`}
          />
          <Bar
            dataKey={primaryKey}
            stroke="hsl(215, 100%, 65%)"
            fill={`url(#horizontal${primaryKey})`}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  ) : null;
};

export default Chart;
