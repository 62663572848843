import React from "react";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 25 30"
    >
      <rect
        x="5.7"
        y="11.1"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -5.3303 12.2655)"
        width="12.8"
        height="3"
      />
      <polygon points="6,17.5 5.8,18.7 7.1,18.7 	" />
      <path
        d="M0.8,2.5v25.1c0,1,0.8,1.7,1.7,1.7h20c1,0,1.7-0.8,1.7-1.7V6.4V2.5c0-1-0.8-1.8-1.8-1.8h-7.5H2.5C1.6,0.7,0.8,1.5,0.8,2.5z
		 M4.6,16.2c0-0.3,0.1-0.5,0.3-0.6l12-12c0.4-0.4,1.1-0.4,1.5,0l2.6,2.6c0.4,0.4,0.4,1.1,0,1.5l-12,12c-0.2,0.2-0.4,0.3-0.7,0.3
		l-2.6,0.1h0c-0.4,0-0.7-0.1-1-0.4s-0.4-0.7-0.4-1.1L4.6,16.2z"
      />
      <rect
        x="17.5"
        y="5"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 0.7486 14.755)"
        width="1.5"
        height="3"
      />
    </svg>
  );
};
