import React from "react";

// Styles
import "../listing/listing-skeleton/listing-skeleton.css";

export default () => {
  return (
    <>
      <div className="listing-skeleton-card-container" />
      <div className="listing-skeleton-card-container" />
    </>
  );
};
