import { callAPI } from "./";
import { baseURL } from "./url";

const AutoCompleteURL = baseURL("/autocomplete");

export function getAutoComplete({ search, location }) {
  let url = `${AutoCompleteURL}?search=${search}`;
  if (location) url = `${AutoCompleteURL}?location=${location}`;
  return callAPI(url, "GET");
}
