import React from "react";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 20"
    >
      <g transform="translate(0,-952.36218)">
        <path
          d="M2.8,953.3c-0.4,0-0.8,0.1-1.1,0.3l12.3,10.4c0.6,0.5,1.3,0.5,1.9,0l12.3-10.4c-0.3-0.2-0.7-0.3-1.1-0.3H2.8z M0.9,954.7
		c-0.1,0.2-0.1,0.4-0.1,0.7v13.9c0,0.4,0.1,0.8,0.3,1.1l8.8-8L0.9,954.7z M29.1,954.7l-9,7.7l8.8,8.1c0.2-0.3,0.3-0.7,0.3-1.1v-13.9
		C29.2,955.2,29.2,954.9,29.1,954.7z M19,963.3l-2.2,1.9c-1.1,0.9-2.6,0.9-3.7,0l-2.2-1.8l-8.8,8c0.2,0.1,0.4,0.1,0.6,0.1h24.3
		c0.2,0,0.4,0,0.6-0.1C27.8,971.3,19,963.3,19,963.3z"
        />
      </g>
    </svg>
  );
};
