import React from "react";

export default props => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 30"
    >
      <path
        d="M3.4,9.3L22.4,4l-0.3-1.4c-0.1-0.4-0.4-0.7-0.7-0.6L3.6,6.9C3.2,7,3,7.4,3.1,7.8C3.2,8.3,3.3,8.8,3.4,9.3
	L3.4,9.3z M3.2,5.6l18.1-5C22.2,0.4,23,1,23.2,2.1l1.9,10.3H24l-0.9-4.6L4,13l1.2,6.4L5.3,20c0,0.2,0.2,0.4,0.4,0.3l0.1,0v1.4l0,0
	c-0.7,0.2-1.4-0.4-1.6-1.2L2,8c-0.1-0.5,0-1,0.2-1.5C2.4,6,2.8,5.7,3.2,5.6L3.2,5.6L3.2,5.6z M7.9,12.7c-0.9,0-1.6,0.9-1.6,2v12.8
	c0,1.1,0.7,2,1.6,2h18.6c0.9,0,1.6-0.9,1.6-2V14.7c0-1.1-0.7-2-1.6-2L7.9,12.7L7.9,12.7L7.9,12.7z M26.1,18.5c0-0.9,0-1.7,0-2.6
	c0-0.2-0.1-0.3-0.2-0.3c-1.6,0-3.2,0-4.7,0c-0.1,0-0.2,0.1-0.2,0.3c0,0.9,0,1.7,0,2.6c0,0.2,0.1,0.3,0.2,0.3c1.6,0,3.2,0,4.7,0
	C26,18.8,26.1,18.6,26.1,18.5L26.1,18.5z M22.6,26.3c-0.1,0-0.2-0.1-0.2-0.3v-0.6c0-0.2,0.1-0.3,0.2-0.3h3.3c0.1,0,0.2,0.1,0.2,0.3
	V26c0,0.2-0.1,0.3-0.2,0.3H22.6L22.6,26.3L22.6,26.3z M18,26.3c-0.1,0-0.2-0.1-0.2-0.3v-0.6c0-0.2,0.1-0.3,0.2-0.3h3.3
	c0.1,0,0.2,0.1,0.2,0.3V26c0,0.2-0.1,0.3-0.2,0.3H18L18,26.3z M13.5,26.3c-0.1,0-0.2-0.1-0.2-0.3v-0.6c0-0.2,0.1-0.3,0.2-0.3h3.3
	c0.1,0,0.2,0.1,0.2,0.3V26c0,0.2-0.1,0.3-0.2,0.3H13.5L13.5,26.3z M9,26.3c-0.1,0-0.2-0.1-0.2-0.3v-0.6c0-0.2,0.1-0.3,0.2-0.3h3.3
	c0.1,0,0.2,0.1,0.2,0.3V26c0,0.2-0.1,0.3-0.2,0.3H9z"
      />
    </svg>
  );
};
