export function updateAdInAds(ads, ad) {
  if (!ad) return;
  const { _id: updateId } = ad;
  return ads.reduce((acc, cur) => {
    const { _id } = cur;
    if (_id === updateId) return [...acc, ad];
    return [...acc, cur];
  }, []);
}

export function updateAdInResults(results, ad) {
  const { _id: updateId } = ad;
  return results.reduce((acc, cur) => {
    const { _id } = cur;
    if (_id === updateId) return [...acc, ad];
    return [...acc, cur];
  }, []);
}
