import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const RedirectRoute = ({ user, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (!user) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location
                }
              }}
            />
          );
        }
      }}
    />
  );
};

const mapStateToProps = store => ({
  user: store.user.user
});

export default connect(mapStateToProps)(RedirectRoute);
