import React from "react";

export default props => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 26 30"
    >
      <polygon points="22.3,0.8 22.5,2.1 22.6,2.5 22.7,2.6 23,2.6 23.5,2.6 23.5,2.5 23.5,2.1 23.5,1.8 23.5,0.7 " />
      <path
        d="M21.5,4.5c0-0.7-0.7-1.3-1.2-1.3c-0.4,0-0.8,0.2-1,0.5c-0.2,0.2-0.3,0.5-0.3,0.8c0.1,0.7,0.4,1.1,1,1.1c0,0,0,0,0.1,0
	c0.1,0,0.2,0,0.3,0c0.4,0,0.7-0.1,0.9-0.4C21.4,5.2,21.5,4.9,21.5,4.5z"
      />
      <path
        d="M22.5,7.4c0,0,0.1-0.1,0.1-0.1c1.7-1.4,2.2-2.8,1.6-4.3c-0.1,0.1-0.2,0.2-0.3,0.2l-1.1,0.1c0,0,0,0.1,0,0.1
	c0.3,0.7,0.5,1.5-1.1,2.9c-0.1,0.1-0.6,0.1-0.9,0.1c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0
	c-0.7,0-1.3,0.1-1.7,0.5C18.2,7,18.1,7.2,18,7.4c0,0,0.1,0.1,0.1,0.1c0.8,0.9,0.7,1.9,0.7,2.6c0,0,0,0.2,0,0.4c0,0-0.1,0.4-0.1,0.6
	h3.8L22.5,7.4L22.5,7.4z"
      />
      <path
        d="M0.8,29.3H8h17.2v-0.1V11.8H0.8V29.3z M17.8,14.9H23v5.2h-5.2V14.9z M17.8,22.3H23v5.2h-5.2V22.3z M10.8,14.9h2.4H16v5.2
	h-2.8h-2.4V14.9z M10.8,22.3h2.4H16v5.2h-2.8h-2.4V22.3z M3.5,14.9H8h0.7v5.2H8H3.5V14.9z M3.5,22.3H8h0.7v5.2H8H3.5V22.3z"
      />
      <path d="M5.8,4.1c0.1-0.8-0.7-1.5-1.4-1.5C3.7,2.6,3,3.3,3,4c0,0.8,0.6,1.3,1.5,1.3C5.3,5.4,5.7,4.8,5.8,4.1z" />
      <path d="M16,5.2c0.1-0.8-0.7-1.5-1.4-1.5c-0.6,0-1.3,0.7-1.3,1.4c0,0.8,0.6,1.3,1.5,1.3C15.6,6.5,16,6,16,5.2z" />
      <path d="M4.6,10l0.2-1.5l-1.1,0l0,0.5c0.3,0.2,0.5,0.6,0.5,1c0,0,0,0.1,0,0.1L4.6,10z" />
      <path
        d="M7.9,9.2c0-0.6-0.1-1.3-0.4-1.8l0,0l0,0c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0,0c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0,0
	c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0C7.1,6.8,7,6.8,7,6.7C6.3,6.3,5.4,6.2,4.6,6.2C3.8,6.2,3.2,6.3,3,6.3c-0.1,0-2.2,0.5-2.2,2.4
	c0,0.5,0.2,1,0.6,1.3c0.3,0.2,0.7,0.3,1.1,0.4v0c0.2,0,0.4,0,0.6,0c0.3,0,0.6-0.3,0.7-0.6l0-0.3C3.7,9.4,3.4,9.1,3.1,9.1
	c-0.2,0-1,0-1-0.4c0-0.4,0.3-1,0.5-1c0,0,0.1,0.8,0.1,0.9c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0
	c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0-0.2,0-0.5,0-0.5c0-0.1,0-0.1,0.1-0.2c0,0,0,0,0.1-0.1c0,0,0,0,0,0c0,0,0,0,0.1,0
	L5.1,8c0.1,0,0.1,0,0.2,0.1c0,0,0.1,0.1,0.1,0.2l-0.3,2c0,0.1-0.1,0.2-0.2,0.2c0,0,0,0,0,0l-0.6-0.1c-0.1,0.3-0.4,0.5-0.6,0.6H6V7.8
	c0.1,0,0.2,0.1,0.2,0.1c0.2,0.2,0.3,1.3,0.3,2.3c0,0.4,0.3,0.7,0.7,0.7c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0
	c0.1,0,0.1,0,0.1-0.1l0,0c0,0,0,0,0,0c0.1-0.1,0.2-0.1,0.2-0.2c0,0,0,0,0,0c0,0,0-0.1,0.1-0.1c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2
	c0-0.1,0-0.2,0-0.3c0,0,0,0,0-0.1C7.9,9.7,7.9,9.5,7.9,9.2C7.9,9.2,7.9,9.2,7.9,9.2z"
      />
      <path
        d="M17.5,8c-0.1-0.1-0.2-0.2-0.3-0.3c-0.4-0.3-1-0.4-1.7-0.5c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2,0-0.3,0c-0.7,0-1.3,0.1-1.7,0.2
	c-0.1,0-0.2,0.1-0.2,0.1c-0.4,0.2-0.7,0.5-1,0.8c-0.2,0.2-0.5,0.5-0.6,0.6c0,0-0.3-0.1-0.7-1.3v1c0,0.2-0.1,0.3-0.3,0.4l-0.5,0
	c0.3,0.6,0.8,1.3,1.6,1.3c0.1,0,0.1,0,0.2,0c0.5-0.1,1-0.5,1.4-1C13,9.2,13.1,9.1,13.2,9l0,2.1h1.7v0l1.5,0l0-2.4
	c0.1,0,0.1,0.1,0.2,0.1c0.2,0.2,0.3,0.7,0.3,1.1c0,0.1-0.1,1-0.1,1.1c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0.2,0,0.7,0,0.9,0
	c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0-0.2,0-0.3c0-0.3,0.1-0.6,0.1-0.7c0-0.1,0-0.1,0-0.2C18.1,9.3,18.1,8.6,17.5,8
	C17.5,8,17.5,8,17.5,8z"
      />
      <polygon points="9,8 9.1,8.3 9.1,8.3 9.5,8.2 9.9,8.2 9.9,6.9 9.9,6.6 9.9,6.4 9.1,6.5 8.7,6.5 8.8,6.9 8.9,7.6 " />
    </svg>
  );
};
