import React from "react";

export default props => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 16 16"
    >
      <path
        d="M12.1,0.5c-0.3-0.3-0.9-0.3-1.2,0c0,0,0,0,0,0L3.9,7.4c-0.3,0.3-0.3,0.9,0,1.2c0,0,0,0,0,0l6.9,6.9c0.3,0.3,0.9,0.3,1.2,0
	c0.3-0.3,0.3-0.9,0-1.2L5.8,8l6.2-6.2C12.4,1.4,12.4,0.9,12.1,0.5C12.1,0.5,12.1,0.5,12.1,0.5z"
      />
    </svg>
  );
};
