import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as stateActions from "../../../redux/actions/state-actions";
import * as userActions from "../../../redux/actions/user-actions";
import moment from "moment";

// Components
import ViewLayout from "../../components/view-layout";
import ImageUpload from "../../components/image-upload";
import ListingCommon from "../../components/listing-common/index.js";
import Checkbox from "../../components/common/checkbox";
import Button from "../../components/common/button";
import ConfirmListing from "../../components/confirm-listing";

// Utilities
import { createListing } from "../../../api/listing";
import { storeImage } from "../../../api/image";
import { convertDateToDbDate } from "../../../utilites/date";
import { validateListing } from "../../../utilites/validate";
import UseGetEmployeeAnalytics from "../../../utilites/hooks/employeeAnalytics";
import { setEmployeeAnalytics } from "../../../api/employee";
import { formatAfter12Hours } from "../../../utilites/format";

// Assets
import SkinnyArrow from "../../../assets/svg/directionArrow";

// Styles
import "./create-listing.css";

const CreateListing = ({ user, setLoader, updateUser, history }) => {
  const employeeAnalytics = UseGetEmployeeAnalytics();
  const [commonData, setCommonData] = useState({});
  const [imageFile1, setImageFile1] = useState("");
  const [imageFile2, setImageFile2] = useState("");
  const [consentInput, setConsentInput] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const { _id, name, analyticsId, locationType } = user || {};
  const images = [imageFile1, imageFile2];
  const {
    typeSelect,
    categories,
    titleInput,
    descriptionInput,
    featuresInput,
    days,
    checkInInput,
    checkInOnceInput,
    location,
    ticketInput,
    promoCodeInput,
    virtiualInput,
  } = commonData;
  const newData = {
    type: typeSelect,
    location,
    categories,
    title: titleInput,
    description: descriptionInput,
    features: featuresInput,
    days,
    checkInRequired: checkInInput,
    checkInOnce: checkInOnceInput,
    businessId: _id,
    businessName: name,
    analyticsId,
    ticketLink: ticketInput,
    virtualLink: virtiualInput,
    lastUpdated: convertDateToDbDate(),
    createdDate: convertDateToDbDate(),
    currentDate: moment().format(),
    timeStamp: moment().format("lll"),
  };

  const handleCommonData = (newCommonData) => {
    setCommonData(newCommonData);
  };

  const handleChange = (evt, type, newValue) => {
    switch (type) {
      case "imageInput1":
        return setImageFile1(newValue);
      case "imageInput2":
        return setImageFile2(newValue);
      case "consentInput":
        return setConsentInput(!consentInput);
    }
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    const isAddressRequired = locationType !== "Multiple Locations";
    const createListingAdditionalIds = {
      consentInput,
    };
    if (isAddressRequired)
      createListingAdditionalIds.locationInput =
        location && location.coordinates && location.coordinates.length;
    const hasRequiredInfo = validateListing(
      newData,
      createListingAdditionalIds,
      "form-CreateListing"
    );
    if (!hasRequiredInfo) return;

    setIsConfirm(true);
  };

  const handleSave = async () => {
    setLoader(true);
    const filteredImages = images.filter((image) => !!image);
    let promiseArray = [];

    if (categories.cat1Img) {
      promiseArray.push(storeImage(categories.cat1Img, "listing"));
    } else {
      promiseArray.push(Promise.resolve(""));
    }

    if (categories.cat2 && categories.cat2Img) {
      promiseArray.push(storeImage(categories.cat2Img, "listing"));
    } else {
      promiseArray.push(Promise.resolve(""));
    }

    if (promoCodeInput) {
      promiseArray.push(storeImage(promoCodeInput, "promo"));
    } else {
      promiseArray.push(Promise.resolve(""));
    }

    if (filteredImages.length) {
      const imagesPromises = images.reduce((acc, image) => {
        if (image) return [...acc, storeImage(image, "listing")];
        return acc;
      }, []);
      promiseArray = [...promiseArray, ...imagesPromises];
    } else {
      promiseArray.push(Promise.resolve(""));
    }

    const [storedCat1Img, storedCat2Img, storedPromo, ...storedImages] =
      await Promise.all(promiseArray);
    newData.categories.cat1Img = storedCat1Img;
    newData.categories.cat2Img = storedCat2Img;
    newData.promoCode = storedPromo;
    newData.images = storedImages.filter((image) => !!image);

    setEmployeeAnalytics({ ...employeeAnalytics, create_listing: true });
    const { user: updatedUser } = await createListing({
      ...newData,
      days: {
        ...newData.days,
        hours: formatAfter12Hours(newData.days.hours),
      },
    });
    if (updatedUser) {
      setTimeout(() => {
        updateUser(updatedUser);
        history.push(`/business/profile/${_id}`);
        setLoader(false);
      }, 1000);
    } else {
      setLoader(false);
    }
  };

  return isConfirm ? (
    <ConfirmListing
      data={{
        ...newData,
        promoCode: promoCodeInput,
        images: images.filter((image) => !!image),
      }}
      setIsConfirm={setIsConfirm}
      handleSave={handleSave}
    />
  ) : (
    <>
      <ViewLayout>
        <form
          id="form-CreateListing"
          className="card-wrapper form-form no-scroll-bars"
        >
          <div className="card card-form">
            <div className="card-info-form">
              <h2 className="card-title-SignUp">CREATE AD</h2>
            </div>
          </div>

          <ListingCommon
            user={user}
            ad={{ ...newData, promoCode: promoCodeInput }}
            handleCommonData={handleCommonData}
            page="create-ad"
          />

          <div className="card card-form">
            <div className="card-info-form">
              <label className="label-form">Additional Images:</label>
              <label id="imageInput" className="label-form">
                Image 1:
              </label>
              <div className="time-selector-wrapper-CreateListing">
                <ImageUpload
                  type="imageInput1"
                  imageInput={imageFile1}
                  handleChange={handleChange}
                />
              </div>
              {(imageFile1 || imageFile2) && (
                <>
                  <label className="label-form">Image 2:</label>
                  <div className="time-selector-wrapper-CreateListing">
                    <ImageUpload
                      type="imageInput2"
                      imageInput={imageFile2}
                      handleChange={handleChange}
                    />
                  </div>
                </>
              )}
              <legend className="legend-form">
                Prefer pictures in landscape format
              </legend>
            </div>
          </div>

          <div id="consentInput" className="card card-form">
            <div className="card-info-form">
              <label className="label-form">Terms Of Use:</label>
              <div className="submit-outer-wrapper-CreateListing">
                <div className="submit-inner-wrapper-CreateListing">
                  <div className="consent-terms-container">
                    <a
                      className="consent-terms-link"
                      href="/terms-of-service"
                      target="_blank"
                    >
                      View Terms
                    </a>
                    <div className="consent-terms-arrow">
                      <SkinnyArrow />
                    </div>
                  </div>
                  <div
                    id="consetInput"
                    className="consent-wrapper-CreateListing"
                  >
                    <Checkbox
                      state={consentInput}
                      handleClick={handleChange}
                      params={[{ target: { value: "" } }, "consentInput"]}
                    />
                    <p className="consent-text-CreateListing">
                      I have read and accept the terms
                    </p>
                  </div>
                </div>
                <div className="listing-edit-submit-container">
                  <Button
                    className="submit-button-CreateListing"
                    text="Cancel"
                    isPrimary={false}
                    handleClick={() => history.push(`/business/profile/${_id}`)}
                  />
                  <Button
                    className="submit-button-CreateListing"
                    text="Submit"
                    handleClick={handleSubmit}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </ViewLayout>
    </>
  );
};

const mapStateToProps = (store) => ({
  user: store.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  setMessage: (dataObj) => dispatch(stateActions.setMessage(dataObj)),
  setLoader: (loaderState) => dispatch(stateActions.setLoader(loaderState)),
  updateUser: (userObj) => dispatch(userActions.updateUser(userObj)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CreateListing));
