import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import * as stateActions from "../../../redux/actions/state-actions";
import * as userActions from "../../../redux/actions/user-actions";

// Components
import ViewLayout from "../../components/view-layout";
import ImageUpload from "../../components/image-upload";
import ListingCommon from "../../components/listing-common/index.js";
import Button from "../../components/common/button";
import InputLabeled from "../../components/input-labeled/index.js";
import ConfirmListing from "../../components/confirm-listing";

// Utilities
import { verifyForm, shakeElement } from "../../../utilites/animation";
import { createGrowth } from "../../../api/growth";
import { storeImage } from "../../../api/image";
import { convertDateToDbDate } from "../../../utilites/date";
import { validateUrl } from "../../../utilites/validate";
import { formatAfter12Hours } from "../../../utilites/format";

// Styles
import "./create-listing.css";

const CreateListing = ({ setLoader, setMessage, history }) => {
  const [isConfirm, setIsConfirm] = useState(false);
  const [nameInput, setNameInput] = useState("");
  const [phoneInput, setPhoneInput] = useState("");
  const [addressInput, setAddressInput] = useState("");
  const [cityInput, setCityInput] = useState("");
  const [stateInput, setStateInput] = useState("");
  const [zipInput, setZipInput] = useState("");
  const [websiteInput, setWebsiteInput] = useState("");
  const [commonData, setCommonData] = useState({});
  const [imageFile1, setImageFile1] = useState("");
  const [imageFile2, setImageFile2] = useState("");
  const [authorName, setAuthorName] = useState("");
  const images = [imageFile1, imageFile2];
  const {
    typeSelect,
    categories,
    days,
    titleInput,
    descriptionInput,
    featuresInput,
    checkInInput,
    checkInOnceInput,
    location = {},
    ticketInput,
    promoCodeInput,
    virtiualInput,
  } = commonData || {};
  const newData = {
    type: typeSelect,
    location,
    categories,
    title: titleInput,
    description: descriptionInput,
    features: featuresInput,
    days,
    checkInRequired: checkInInput,
    checkInOnce: checkInOnceInput,
    businessName: (nameInput || "").trim(),
    businessPhone: phoneInput,
    lastUpdated: convertDateToDbDate(),
    createdDate: convertDateToDbDate(),
    currentDate: moment().format(),
    virtualLink: virtiualInput || "",
    showPhone: !!phoneInput,
    website: websiteInput,
    address: addressInput,
    city: cityInput,
    state: stateInput,
    zip: zipInput,
    authorName,
    timeStamp: moment().format("LLL"),
  };

  const handleCommonData = (commonData) => {
    setCommonData(commonData);
  };

  const handleChange = (evt, type, newValue) => {
    const { value } = evt.target;
    switch (type) {
      case "imageInput1":
        return setImageFile1(newValue);
      case "imageInput2":
        return setImageFile2(newValue);
      case "nameInput":
        return setNameInput(value);
      case "phoneInput":
        const newPhoneValue = value
          .replace("-", "")
          .replace(".", "")
          .match(/^[0-9]*$/gi);
        if (newPhoneValue === null) return setPhoneInput(phoneInput);
        return setPhoneInput(newPhoneValue[0].slice(0, 10));
      case "addressInput":
        return setAddressInput(value);
      case "cityInput":
        return setCityInput(value);
      case "stateInput":
        let newStateInput = "";
        if (value) newStateInput = value.match(/\w/gi).join("").slice(0, 2);
        return setStateInput(newStateInput);
      case "zipInput":
        let newZipInput = "";
        if (value) newZipInput = value.match(/\d/gi).join("").slice(0, 5);
        return setZipInput(newZipInput);
      case "websiteInput":
        return setWebsiteInput(value);
      case "authorName":
        return setAuthorName(value);
    }
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    const { cat1, cat1Sub, cat1Img, cat2, cat2Sub, cat2Img } = categories || {};
    const ids = {
      typeSelect,
      nameInput,
      addressInput,
      cityInput,
      stateInput,
      zipInput,
      cat1: cat1 && cat1Sub && cat1Img,
      titleInput,
      locationInput: location.address,
      descriptionInput: descriptionInput.trim().length > 40,
      authorName,
    };
    if (cat2) ids.cat2 = cat2 && cat2Sub && cat2Img;
    if (ticketInput) ids.ticketInput = validateUrl(ticketInput);
    if (featuresInput["Virtual"]) ids.virtualLink = validateUrl(virtiualInput);
    const hasRequiredInfo = verifyForm(ids);

    if (hasRequiredInfo) {
      setIsConfirm(true);
    } else {
      shakeElement("form-CreateListing");
    }
  };

  const handleSave = async () => {
    setLoader(true);
    const filteredImages = images.filter((image) => !!image);
    let promiseArray = [];

    if (categories.cat1Img) {
      promiseArray.push(storeImage(categories.cat1Img, "listing"));
    } else {
      promiseArray.push(Promise.resolve(""));
    }

    if (categories.cat2 && categories.cat2Img) {
      promiseArray.push(storeImage(categories.cat2Img, "listing"));
    } else {
      promiseArray.push(Promise.resolve(""));
    }

    if (promoCodeInput) {
      promiseArray.push(storeImage(promoCodeInput, "promo"));
    } else {
      promiseArray.push(Promise.resolve(""));
    }

    if (filteredImages.length) {
      const imagesPromises = images.reduce((acc, image) => {
        if (image) return [...acc, storeImage(image, "listing")];
        return acc;
      }, []);
      promiseArray = [...promiseArray, ...imagesPromises];
    } else {
      promiseArray.push(Promise.resolve(""));
    }

    const [storedCat1Img, storedCat2Img, storedPromo, ...storedImages] =
      await Promise.all(promiseArray);
    newData.categories.cat1Img = storedCat1Img;
    newData.categories.cat2Img = storedCat2Img;
    newData.promoCode = storedPromo;
    newData.images = storedImages.filter((image) => !!image);

    const { ad } = await createGrowth({
      ...newData,
      days: {
        ...newData.days,
        hours: formatAfter12Hours(newData.days.hours),
      },
    });
    if (ad) {
      setLoader(false);
      setMessage({ message: "successful" });
      history.push("/");
    } else {
      setLoader(false);
    }
  };

  const contactInputs = [
    {
      name: "nameInput",
      value: nameInput,
      label: "Business Name",
    },
    {
      name: "phoneInput",
      value: phoneInput,
      label: "Business Phone",
    },
    {
      name: "websiteInput",
      value: websiteInput,
      label: "Website",
    },
    {
      name: "addressInput",
      value: addressInput,
      label: "Business Address",
    },
    {
      name: "cityInput",
      value: cityInput,
      label: "City",
    },
    {
      name: "stateInput",
      value: stateInput,
      label: "State",
    },
    {
      name: "zipInput",
      value: zipInput,
      label: "Zip",
      type: "number",
    },
  ];

  return isConfirm ? (
    <ConfirmListing
      data={{
        ...newData,
        promoCode: promoCodeInput,
        images: images.filter((image) => !!image),
      }}
      setIsConfirm={setIsConfirm}
      handleSave={handleSave}
    />
  ) : (
    <>
      <ViewLayout>
        <form
          id="form-CreateListing"
          className="card-wrapper form-form no-scroll-bars"
        >
          <div className="card card-form">
            <div className="card-info-form">
              <h2 className="card-title-SignUp">CREATE AD</h2>
            </div>
          </div>

          <ListingCommon
            isGrowth
            ad={{ ...newData, promoCode: promoCodeInput }}
            handleCommonData={handleCommonData}
            page="create-ad"
          />

          <div className="card card-form">
            <div className="card-info-form">
              <label className="label-form">Additional Images:</label>
              <label id="imageInput" className="label-form">
                Image 1:
              </label>
              <div className="time-selector-wrapper-CreateListing">
                <ImageUpload
                  type="imageInput1"
                  imageInput={imageFile1}
                  handleChange={handleChange}
                />
              </div>
              {(imageFile1 || imageFile2) && (
                <>
                  <label className="label-form">Image 2:</label>
                  <div className="time-selector-wrapper-CreateListing">
                    <ImageUpload
                      type="imageInput2"
                      imageInput={imageFile2}
                      handleChange={handleChange}
                    />
                  </div>
                </>
              )}
              <legend className="legend-form">
                Required: Prefer pictures in landscape format
              </legend>
            </div>
          </div>

          <div className="card card-form">
            <div className="card-info-form">
              {contactInputs.map((input, idx) => {
                const { name, value, label, type } = input;
                return (
                  <InputLabeled
                    name={name}
                    value={value}
                    label={label}
                    type={type}
                    handleChange={(evt) => handleChange(evt, name)}
                    key={idx}
                  />
                );
              })}
              <legend className="legend-form" />
            </div>
          </div>

          <div id="authorName" className="card card-form">
            <div className="card-info-form">
              <label className="label-form">Author - (Your Name) :</label>
              <input
                className="input-style input-form text-form"
                type="text"
                value={authorName}
                onChange={(evt) => handleChange(evt, "authorName")}
                spellCheck="true"
              />
              <legend className="legend-form" />
            </div>
          </div>

          <div id="consentInput" className="card card-form">
            <div className="card-info-form">
              <div className="submit-outer-wrapper-CreateListing">
                <div className="listing-edit-submit-container">
                  <Button
                    className="submit-button-CreateListing"
                    text="Cancel"
                    isPrimary={false}
                    handleClick={() => history.push("/")}
                  />
                  <Button
                    className="submit-button-CreateListing"
                    text="Submit"
                    handleClick={handleSubmit}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </ViewLayout>
    </>
  );
};

const mapStateToProps = (store) => ({
  user: store.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  setMessage: (dataObj) => dispatch(stateActions.setMessage(dataObj)),
  setLoader: (loaderState) => dispatch(stateActions.setLoader(loaderState)),
  setUser: (userObj) => dispatch(userActions.setUser(userObj)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CreateListing));
