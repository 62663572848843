import React, { useRef, useMemo, useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import { connect } from "react-redux";
import * as userActions from "../../../redux/actions/user-actions";
import * as stateActions from "../../../redux/actions/state-actions";
import moment from "moment";

// Components
import Button from "../../components/common/button";

// Utilities
import { getCoordinates } from "../../../api/search";
import { employeeLogin } from "../../../api/employee";
import useDebounce from "../../../utilites/hooks/debounce";
import TextInput from "../../components/common/text-input-underline";
import NewEmployee from "../../components/new-employee";

// Assets
import EventHoundName from "../../../assets/svg/eventHound-name";

// Styles
import "./splash-page.css";

export default (WrappedComponent) => {
  const Component = (props) => {
    const isMounted = useRef(false);
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    const [isFetching, setIsFetching] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [emailInput, setEmailInput] = useState("");
    const [passwordInput, setPasswordInput] = useState("");
    const [latLng, setLlatLng] = useState("");
    const debounceLatLng = useDebounce(latLng, 3 * 60 * 1000);
    const geoWatchId = useMemo(
      () =>
        navigator.geolocation.watchPosition(success, error, {
          enableHighAccuracy: false,
          timeout: 5000,
          maximumAge: 0,
        }),
      []
    );
    const {
      employee: storedEmployee,
      setGeoPermission,
      setGeoLocation,
      setGeoAddress,
      setEmployee,
    } = props || {};

    useEffect(() => {
      if (!isMounted.current && latLng) {
        (async function () {
          const { location } = (await getCoordinates({ latlng: latLng })) || {};
          setGeoAddress(location);
        })();
        isMounted.current = true;
      }
    }, [latLng]);

    useEffect(() => {
      if (debounceLatLng)
        (async function () {
          const { location } =
            (await getCoordinates({ latlng: debounceLatLng })) || {};
          setGeoAddress(location);
        })();
    }, [debounceLatLng]);

    useEffect(() => {
      const { employee: employeeStorage } = window.localStorage || {};
      if (employeeStorage)
        (async () => {
          const { date, token } = JSON.parse(employeeStorage);
          if (moment(date).isSame(moment(), "day")) {
            const loginData = {
              token,
              timeStamp: moment().format("LLL"),
            };
            const { employee } = await employeeLogin(loginData);
            if (employee) {
              const { token } = employee;
              if (token)
                localStorage.setItem(
                  "employee",
                  JSON.stringify({ date: moment().format(), token })
                );
              setEmployee(employee);
            }
          }
        })();

      return () => {
        navigator.geolocation.clearWatch(geoWatchId);
      };
    }, []);

    async function success({ coords }) {
      const { longitude, latitude } = coords;
      setGeoLocation({ coordinates: [longitude, latitude] });
      setGeoPermission(true);
      setLlatLng(`${latitude}, ${longitude}`);
    }

    function error(err) {
      if (error.code === error.PERMISSION_DENIED) setGeoPermission(false);
    }

    const handleSubmit = async (evt) => {
      if (!emailInput || !passwordInput) return;
      setIsFetching(true);
      const loginData = {
        email: emailInput,
        password: passwordInput,
        timeStamp: moment().format("LLL"),
      };
      const { employee, error } = await employeeLogin(loginData);
      if (employee) {
        const { token } = employee;
        if (token)
          localStorage.setItem(
            "employee",
            JSON.stringify({ date: moment().format(), token })
          );
        setEmployee(employee);
      }
      if (error) setErrorMessage(error.description);
      setIsFetching(false);
    };

    return storedEmployee ? (
      <CSSTransition in={storedEmployee} timeout={250} classNames="fade">
        <WrappedComponent {...props} />
      </CSSTransition>
    ) : (
      <CSSTransition in={storedEmployee} timeout={250} classNames="fade">
        <div className="splash-page-wrapper">
          <div className="splash-icon-container" style={{ marginTop: "10vh" }}>
            <img
              className="splash-icon"
              alt="EventHound - Local EventHound"
              src="/img/icons/event-hound-icon.png"
            />
          </div>
          <div className="splash-Name-container">
            <EventHoundName />
          </div>
          {errorMessage && (
            <h2 className="splash-error-text">{errorMessage}</h2>
          )}
          {token ? (
            <NewEmployee token={token} setErrorMessage={setErrorMessage} />
          ) : (
            <div
              style={{
                width: "100vw",
                maxWidth: "400px",
                marginTop: "20px",
              }}
            >
              <TextInput
                placeholder="Email"
                value={emailInput}
                handleChange={(evt) => setEmailInput(evt.target.value)}
                handleEnter={handleSubmit}
              />
              <TextInput
                placeholder="Password"
                value={passwordInput}
                handleChange={(evt) => setPasswordInput(evt.target.value)}
                handleEnter={handleSubmit}
              />
              <div
                className="submit-wapper-UserSignUp"
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  text="Submit"
                  isDisabled={isFetching}
                  handleClick={handleSubmit}
                />
              </div>
            </div>
          )}
        </div>
      </CSSTransition>
    );
  };

  const mapStateToProps = (store) => ({
    employee: store.user.employee,
  });

  const mapDispatchToProps = (dispatch) => ({
    setLoader: (loaderState) => dispatch(stateActions.setLoader(loaderState)),
    setEmployee: (employee) => dispatch(userActions.setEmployee(employee)),
    setMessage: (dataObj) => dispatch(stateActions.setMessage(dataObj)),
    setGeoPermission: (value) => dispatch(userActions.setGeoPermission(value)),
    setGeoLocation: (locationObj) =>
      dispatch(userActions.setGeoLocation(locationObj)),
    setGeoAddress: (address) => dispatch(userActions.setGeoAddress(address)),
  });

  return connect(mapStateToProps, mapDispatchToProps)(Component);
};
