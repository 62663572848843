import moment from "moment";
import { getCurrentDate, convertDateToDbDate } from "./date";
import { getCurrentTime, timeToMilitary } from "./time";
import { isMobileAgent } from "../utilites";

export function charLimitTo(string = "", limitNumber = 120) {
  if (string && string.length > limitNumber) {
    string = string.slice(0, limitNumber - 3);
    string += "...";
  }
  return string;
}

export function toTitleCase({ input = "", lowerCaseRest = true }) {
  return input.replace(
    /\w\S*/g,
    (txt) =>
      txt.charAt(0).toUpperCase() +
      (lowerCaseRest ? txt.substr(1).toLowerCase() : txt.substr(1))
  );
}

export function formatPhoneNumber({ phone, href = false }) {
  const cleaned = ("" + phone).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (!match) return "";
  if (!href) return "(" + match[1] + ") " + match[2] + "-" + match[3];
  return "1-" + match[1] + "-" + match[2] + "-" + match[3];
}

export const formatCreditCardNumber = (cardNumber) => {
  const clearValue = cardNumber.replace(/\D+/g, "");
  return `${clearValue.slice(0, 4)} ${clearValue.slice(
    4,
    8
  )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`.trim();
};

export function formatSearchAnalytics({
  location,
  geoLocation,
  category,
  filters,
}) {
  const { county: enteredCounty } = location || {};
  const { county: geoCounty } = geoLocation || {};
  const { search, subCat, feature } = filters || {};
  const county = enteredCounty || geoCounty || "";
  return {
    term: search,
    category,
    location: county,
    subCat,
    feature,
  };
}

export function formatSearchQuery(
  coords,
  filters,
  category,
  params,
  user,
  geoLocation,
  additional
) {
  const {
    type,
    subCat,
    feature = "",
    search,
    date,
    time,
    distance,
    sortBy,
  } = filters || {};
  const { _id: userId } = user || {};
  const { address, coordinates: geoCoordinates } = geoLocation || {};
  const isMobile = isMobileAgent();
  const { skip = 0, limit = isMobile ? 20 : 30 } = params;
  const [lng, lat] = coords;
  const currentDate = getCurrentDate();
  const searchOptions = {
    lng,
    lat,
    distance: distance.match(/\d/gi).join(""),
    category: toTitleCase({ input: category }),
    subCat,
    feature,
    day: moment(date || currentDate).format("dddd"),
    date: convertDateToDbDate(date || currentDate),
    time: timeToMilitary({ time: time ? time : getCurrentTime() }),
    skip,
    limit,
    sortBy,
    analyticsDate: convertDateToDbDate(currentDate),
    ...additional,
  };
  if (type) searchOptions.type = type;
  if (search) searchOptions.search = search;
  if (userId) searchOptions.userId = userId;
  if (address) {
    searchOptions.address = address;
    searchOptions.addressCoords = geoCoordinates;
  }
  return searchOptions;
}

export function formatMapSearchQuery(
  coordinates,
  filters,
  category,
  skip = 0,
  user,
  geoLocation
) {
  const { type, subCat, feature, search, date, time, limit } = filters || {};
  const { _id: userId } = user || {};
  const { address, coordinates: geoCoordinates } = geoLocation || {};
  const currentDate = getCurrentDate();
  const searchOptions = {
    coordinates: JSON.stringify(coordinates),
    category: toTitleCase({ input: category }),
    subCat,
    feature,
    day: moment(date || currentDate).format("dddd"),
    date: convertDateToDbDate(date || currentDate),
    time: timeToMilitary({ time: time ? time : getCurrentTime() }),
    limit,
    skip,
    analyticsDate: convertDateToDbDate(currentDate),
  };
  if (type) searchOptions.type = type;
  if (search) searchOptions.search = search;
  if (userId) searchOptions.userId = userId;
  if (address) {
    searchOptions.address = address;
    searchOptions.addressCoords = geoCoordinates;
  }
  return searchOptions;
}

export function formatQueryParams(options) {
  let searchOptions = "";
  Object.keys(options).forEach((item, idx) => {
    if (options[item] || typeof options[item] === "number") {
      if (idx && idx !== idx.length - 1) searchOptions += "&";
      searchOptions += `${item}=${options[item]}`;
    }
  });
  return searchOptions;
}

export function formatListingTime({ ad, business }) {
  const localTime = Number(moment().format("HHmm"));
  if (localTime >= 200) return ad || business;

  const currentDay = moment().format("dddd");
  const oneDayEarlier = moment().subtract(1, "days");
  const earlierDate = convertDateToDbDate(moment(oneDayEarlier).format("L"));
  const earlierDay = moment(oneDayEarlier).format("dddd");
  const { days: adDays, date: adDate, endTime } = ad || {};
  const { hours: businessHours } = business || {};

  if (ad && (adDays[earlierDay] || adDate === earlierDate) && endTime > 2400) {
    const formattedAd = {
      ...ad,
      startTime: 0,
      endTime: endTime - 2400,
    };
    if (adDate) {
      formattedAd.date = convertDateToDbDate();
    } else {
      formattedAd.days = { ...adDays, [currentDay]: true };
    }
    return formattedAd;
  }

  if (
    business &&
    businessHours[earlierDay].isOpen &&
    businessHours[earlierDay].close > 2400
  ) {
    const close = businessHours[earlierDay].close;
    return {
      ...business,
      hours: {
        ...businessHours,
        [currentDay]: {
          isOpen: true,
          open: 0,
          close: close - 2400,
        },
      },
    };
  }
  return ad || business;
}

export const createSingleDayHours = (open, close) => ({
  Monday: {
    isOpen: false,
    open,
    close,
  },
  Tuesday: {
    isOpen: false,
    open,
    close,
  },
  Wednesday: {
    isOpen: false,
    open,
    close,
  },
  Thursday: {
    isOpen: false,
    open,
    close,
  },
  Friday: {
    isOpen: false,
    open,
    close,
  },
  Saturday: {
    isOpen: false,
    open,
    close,
  },
  Sunday: {
    isOpen: false,
    open,
    close,
  },
});

export const numberWithCommas = (x) =>
  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const formatAfter12Hours = (hours) => {
  const daysOfTheWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  daysOfTheWeek.forEach((day, idx) => {
    const { isOpen, close } = hours[day];
    const previousDay = daysOfTheWeek[idx !== 6 ? idx + 1 : 0];
    if (close > 2400) {
      hours[previousDay].adjustedTime = {
        isOpen,
        open: 0,
        close: close - 2400,
      };
    } else {
      delete hours[previousDay].adjustedTime;
    }
  });
  return hours;
};
