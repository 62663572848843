import { callAPI } from ".";
import { baseURL } from "./url";
import { formatQueryParams } from "../utilites/format";

const adminURL = baseURL("/admin");
const adminBusinessURL = `${adminURL}/business`;
const adminListingURL = `${adminURL}/listing`;
const adminSearchURL = `${adminURL}/search`;

export function businessGet(id) {
  const url = `${adminBusinessURL}?id=${id}`;
  return callAPI(url, "GET");
}

export function deleteBusiness({ id }) {
  const url = `${adminBusinessURL}?id=${id}`;
  return callAPI(url, "DELETE");
}

export function listingGet(options) {
  const url = `${adminListingURL}?${formatQueryParams(options)}`;
  return callAPI(url, "GET");
}

export function updateListing(data) {
  return callAPI(adminListingURL, "POST", data);
}

export function deleteListing(options) {
  return callAPI(`${adminListingURL}?${formatQueryParams(options)}`, "DELETE");
}

export function listingSearch(options) {
  const url = `${adminSearchURL}?${formatQueryParams(options)}`;
  return callAPI(url, "GET");
}

export function searchBusiness(options) {
  const url = `${adminSearchURL}/business?${formatQueryParams(options)}`;
  return callAPI(url, "GET");
}

export function deleteFind(options) {
  const url = `${adminURL}/find/edit?${formatQueryParams(options)}`;
  return callAPI(url, "DELETE");
}
