import React from "react";

// Components
import Check from "../../../../assets/svg/check";

// Styles
import "./checkbox.css";

const Checkbox = ({ state, handleClick, params, disabled = false }) => (
  <div
    className={`input-style checkbox-Checkbox ${
      disabled ? "checkbox-disabled" : ""
    }`}
    onClick={() => handleClick(...params)}
  >
    {state && <Check />}
  </div>
);

Checkbox.defaultProps = {
  text: "",
  state: false,
  params: [],
};

export default Checkbox;
