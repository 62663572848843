import { callAPI } from "./";
import { baseURL } from "./url";
import { formatQueryParams } from "../utilites/format";

const growthURL = baseURL("/growth");

export function createGrowth(data) {
  return callAPI(growthURL, "PUT", data);
}

export function updateGrowth(data) {
  return callAPI(growthURL, "POST", data);
}

export function deleteGrowth(id) {
  return callAPI(`${growthURL}?id=${id}`, "DELETE");
}

export function growthGet(id) {
  const url = `${growthURL}?id=${id}`;
  return callAPI(url, "GET");
}

export function searchGrowth(options) {
  const url = `${growthURL}/search/dev?${formatQueryParams(options)}`;
  return callAPI(url, "GET");
}

export function searchMapGrowth(options) {
  const url = `${growthURL}/search/dev/map?${formatQueryParams(options)}`;
  return callAPI(url, "GET");
}
