import React from "react";

// Utilities
import { formatDbDateToReadable } from "../../../../utilites/date";

// Styles
import "../listing.css";

export default ({ data, status }) => {
  const { days } = data || {};
  const { hours } = days || {};

  return "dateAvailable" in days && days.dateAvailable ? (
    <div className="listing-spacing listing-center-content">
      <p>{`Only Available: ${formatDbDateToReadable({
        date: days.dateAvailable,
        toFullYear: true,
        isForUser: true,
      })}`}</p>
    </div>
  ) : (
    <div className="listing-spacing">
      <div className="listing-spacing listing-date-text">
        <p>Available Every:</p>
      </div>
      <div className="listing-center-content">
        <div className="listing-business-hours-container">
          {Object.keys(hours).map((day, idx) => {
            const { isOpen } = hours[day];
            const className = isOpen
              ? `listing-day-open listing-day-${status}`
              : "listing-day-closed";
            return (
              <p className={className} key={idx}>
                {day.slice(0, 3)}
              </p>
            );
          })}
        </div>
      </div>
    </div>
  );
};
