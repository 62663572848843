import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";

// Components
import SkeletonLoader from "../analytics-community/analytics-loader";
import DetailViewLayout from "../../components/detail-view-scroll-layout";
import BarChartDynamic from "../../components/chart/horizontal-bar-chart-dynamic";
import AreaChart from "../../components/chart/area-chart";

// Utilities
import {
  setPlatformAnalytics,
  getPlatformAnalytics,
} from "../../../api/platform";
import GetFilterIcon from "../../components/filter-icons";
import { isSafariAgent } from "../../../utilites";
import { numberWithCommas, toTitleCase } from "../../../utilites/format";

// Assets
import Arrow from "../../../assets/svg/back";

// Styles
import "./stats-styles.css";

export default () => {
  const { accountType } = useSelector((store) => store.user.employee);
  const [isLoading, setIsLoading] = useState(true);
  const [searchWeek, setSearchWeek] = useState(0);
  const [analytics, setAnalytics] = useState(null);
  const [metaData, setMetaData] = useState(null);
  const [weekTotals, setWeekTotals] = useState(null);
  const [titleTotals, setTitleTotals] = useState(null);
  const [totals, setTotals] = useState(null);
  const [businessOverView, setBusinessOverView] = useState(null);
  const [listingOverView, setListingOverView] = useState(null);
  const [userOverView, setUserOverView] = useState(null);
  const { startDate, endDate } = metaData || {};
  const { totalBusinesses, totalListings, totalUsers } = titleTotals || {};
  const daysOfTheWeek = [
    "Sunday",
    "Saturday",
    "Friday",
    "Thursday",
    "Wednesday",
    "Tuesday",
    "Monday",
  ];
  const isAdmin = accountType === "admin" || accountType === "developer";

  const getMetaData = (data, week) => {
    const date = moment().isoWeek(week).startOf("week").format("L");
    const startOfWeek = moment(date).add(1, "days").format("L");
    const endOfWeek = moment(date).add(7, "days").format("L");
    const newMetaDate = {
      startDate: startOfWeek,
      endDate: endOfWeek,
    };

    if (!data || !(week in data)) {
      setTitleTotals(null);
      return setMetaData(null);
    }
    const thisWeek = data[week] || {};

    if (week === moment().isoWeek()) {
      const day = moment().format("dddd");
      const { totalBusinesses, totalListings, totalUsers } = thisWeek[day];
      setTitleTotals({ totalBusinesses, totalListings, totalUsers });
    }
    setMetaData(newMetaDate);
  };

  const getWeekTotals = (data, week) => {
    if (!data || !(week in data)) {
      return setWeekTotals(null);
    }
    const thisWeek = data[week] || {};

    const totalsData = {};
    daysOfTheWeek.forEach((weekday) => {
      if (weekday in thisWeek) {
        Object.keys(thisWeek[weekday]).forEach((key) => {
          if (key.indexOf("new") > -1) {
            totalsData[key] = {
              term: key,
              value: totalsData[key]
                ? thisWeek[weekday][key] + totalsData[key].value
                : thisWeek[weekday][key],
            };
          }
        });
      }
    });

    setWeekTotals(Object.values(totalsData));
  };

  const getTotals = (data, week) => {
    if (!data || !(week in data)) {
      return setTotals(null);
    }
    const thisWeek = data[week] || {};
    const weekTotals = daysOfTheWeek.map((day) => {
      if (!(day in thisWeek))
        return {
          day,
          "Total Businesses": 0,
          "Total Listings": 0,
          ...(isAdmin ? { "Total Users": 0 } : {}),
        };
      const {
        totalBusinesses = 0,
        totalListings = 0,
        totalUsers = 0,
      } = thisWeek[day] || {};
      return {
        day,
        "Total Businesses": totalBusinesses,
        "Total Listings": totalListings,
        ...(isAdmin ? { "Total Users": totalUsers } : {}),
      };
    });

    setTotals(weekTotals);
  };

  const getBusinessOverview = (data, week) => {
    if (!data || !(week in data)) {
      return setBusinessOverView(null);
    }
    const thisWeek = data[week] || {};
    const weekTotals = daysOfTheWeek.map((day) => {
      if (!(day in thisWeek))
        return {
          day,
          "Active Businesses": 0,
          "Inactive Businesses": 0,
          "New Businesses": 0,
        };
      const {
        activeBusinesses = 0,
        inactiveBusinesses = 0,
        newBusinesses = 0,
      } = thisWeek[day] || {};
      return {
        day,
        "Active Businesses": activeBusinesses,
        "Inactive Businesses": inactiveBusinesses,
        "New Businesses": newBusinesses,
      };
    });

    setBusinessOverView(weekTotals);
  };

  const getListingsOverview = (data, week) => {
    if (!data || !(week in data)) {
      return setListingOverView(null);
    }
    const thisWeek = data[week] || {};
    const weekTotals = daysOfTheWeek.map((day) => {
      if (!(day in thisWeek))
        return {
          day,
          "Active Listings": 0,
          "Inactive Listings": 0,
          "New Listings": 0,
        };
      const {
        activeListings = 0,
        inactiveListings = 0,
        newListings = 0,
      } = thisWeek[day] || {};
      return {
        day,
        "Active Listings": activeListings,
        "Inactive Listings": inactiveListings,
        "New Listings": newListings,
      };
    });

    setListingOverView(weekTotals);
  };

  const getUsersOverview = (data, week) => {
    if (!data || !(week in data)) {
      return setUserOverView(null);
    }
    const thisWeek = data[week] || {};
    const weekTotals = daysOfTheWeek.map((day) => {
      if (!(day in thisWeek))
        return {
          day,
          "Total Users": 0,
          "New Users": 0,
        };
      const { totalUsers = 0, newUsers = 0 } = thisWeek[day] || {};
      return {
        day,
        "Total Users": totalUsers,
        "New Users": newUsers,
      };
    });

    setUserOverView(weekTotals);
  };

  const getWeekValues = (data = {}, week = moment().isoWeek()) => {
    if (!Object.keys(data).length) return;
    getMetaData(data, week);
    getWeekTotals(data, week);
    getTotals(data, week);
    getBusinessOverview(data, week);
    getListingsOverview(data, week);
    getUsersOverview(data, week);
  };

  const getAnalytics = async () => {
    setIsLoading(true);
    await setPlatformAnalytics();
    const { analytics: newAnalytics } = await getPlatformAnalytics();
    setSearchWeek(0);
    setAnalytics(newAnalytics);
    getWeekValues(newAnalytics);
    setIsLoading(false);
  };

  useEffect(() => {
    getAnalytics();
  }, []);

  const handleClick = (value) => {
    if (value < 0) return;
    const isSafari = isSafariAgent();
    if (isSafari) setIsLoading(true);
    setSearchWeek(value);
    getWeekValues(
      analytics,
      moment()
        .subtract(value * 7, "days")
        .isoWeek()
    );
    if (isSafari)
      setTimeout(() => {
        setIsLoading(false);
      });
  };

  const renderGraphs = () => (
    <>
      <div className="listing-content-container">
        <div className="flex-center" style={{ flexDirection: "column" }}>
          <div className="marketing-date-wrapper">
            {!!searchWeek && (
              <div
                className="marketing-page-arrow"
                onClick={() => handleClick(searchWeek - 1)}
              >
                <Arrow />
              </div>
            )}
            <p className="analytics-title">{`${endDate} - ${startDate}`}</p>
            <div
              className="marketing-page-arrow marketing-page-arrow-right"
              onClick={() => handleClick(searchWeek + 1)}
            >
              <Arrow />
            </div>
          </div>
          {isAdmin && (
            <p
              className="analytics-title"
              style={{ marginTop: "10px" }}
            >{`User Accounts: ${numberWithCommas(totalUsers)}`}</p>
          )}
          <p
            className="analytics-title"
            style={{ marginTop: "5px" }}
          >{`Businesses: ${numberWithCommas(totalBusinesses)}`}</p>
          <p
            className="analytics-title"
            style={{ marginTop: "5px" }}
          >{`Listings: ${numberWithCommas(totalListings)}`}</p>
          <p
            className="analytics-title"
            style={{ marginTop: "5px" }}
          >{`Business to Listing Ratio: ${(
            totalListings / totalBusinesses
          ).toFixed(2)}`}</p>
        </div>
      </div>

      {weekTotals && (
        <div className="listing-content-container">
          <div className="flex-center">
            <BarChartDynamic isValue isTooltip data={weekTotals} />
          </div>
        </div>
      )}

      {totals && (
        <div className="listing-content-container">
          <div className="flex-center">
            <p className="analytics-title">Totals By Day</p>
          </div>
          <div className="analytics-spacing">
            <AreaChart
              primaryKey="Total Listings"
              secomdaryKey="Total Businesses"
              promoKey="Total Users"
              data={totals}
            />
          </div>
        </div>
      )}

      {isAdmin && userOverView && (
        <div className="listing-content-container">
          <div className="flex-center">
            <p className="analytics-title">User Accounts By Day</p>
          </div>
          <div className="analytics-spacing">
            <AreaChart promoKey="New Users" data={userOverView} />
          </div>
        </div>
      )}

      {businessOverView && (
        <div className="listing-content-container">
          <div className="flex-center">
            <p className="analytics-title">Businesses By Day</p>
          </div>
          <div className="analytics-spacing">
            <AreaChart
              secomdaryKey="Inactive Businesses"
              promoKey="New Businesses"
              data={businessOverView}
            />
          </div>
        </div>
      )}

      {listingOverView && (
        <div className="listing-content-container">
          <div className="flex-center">
            <p className="analytics-title">Listings By Day</p>
          </div>
          <div className="analytics-spacing">
            <AreaChart
              secomdaryKey="Inactive Listings"
              promoKey="New Listings"
              data={listingOverView}
            />
          </div>
        </div>
      )}
    </>
  );

  return (
    <DetailViewLayout heightFromTop={100} showImage={false}>
      <div className="business-analytics-header-container">
        <div className="flex-center">
          <p className="analytics-title">Business Statistics</p>
        </div>
        <div
          className="analytics-reset-container"
          onClick={() => getAnalytics()}
        >
          {GetFilterIcon("refresh")}
        </div>
        {/* <div className="analytics-selector-container">
          <SwitchButton
            button1Name="Current"
            button2Name="Last Week"
            isFirst={isCurrent}
            handleClick={handleClick}
          />
        </div> */}
      </div>

      {isLoading ? (
        <SkeletonLoader />
      ) : metaData ? (
        renderGraphs()
      ) : (
        <p className="analytics-no-text">No Statistics At This Time</p>
      )}
    </DetailViewLayout>
  );
};
