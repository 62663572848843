import React from "react";

// Styles
import "./svg.css";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
    >
      <path d="M80,44.3h-7.1V10.7c0-4.5-3.7-8.2-8.2-8.2H35.3c-4.5,0-8.2,3.7-8.2,8.2v33.6H20c-5.3,0-8.4,6.1-5.2,10.3l30,40.3    c2.6,3.5,7.8,3.5,10.4,0l30-40.3C88.4,50.3,85.4,44.3,80,44.3z M53.1,64.2v4.6c0,0.5-0.4,0.9-0.9,0.9h-4.5c-0.5,0-0.9-0.4-0.9-0.9    v-4.5c-4.6-0.3-8.3-3.9-8.7-8.4C38.1,55.4,38.5,55,39,55h4.5c0.4,0,0.8,0.3,0.9,0.7c0.3,1.4,1.6,2.5,3,2.5h4c2.3,0,4.5-1.7,4.7-4    c0.3-2.7-1.8-5-4.5-5h-2.9c-5.6,0-10.6-4.1-11.2-9.7c-0.6-5.9,3.7-11,9.3-11.7v-4.6c0-0.5,0.4-0.9,0.9-0.9h4.5    c0.5,0,0.9,0.4,0.9,0.9v4.5c4.6,0.3,8.3,3.9,8.7,8.4c0.1,0.5-0.4,0.9-0.9,0.9h-4.5c-0.4,0-0.8-0.3-0.9-0.7c-0.3-1.4-1.6-2.5-3-2.5    h-4c-2.3,0-4.5,1.7-4.7,4c-0.3,2.7,1.8,5,4.5,5h3.4c6.3,0,11.3,5.4,10.7,11.8C61.9,59.7,57.9,63.4,53.1,64.2z"></path>
    </svg>
  );
};
