import React from "react";

export default props => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 25 30"
    >
      <path d="M22.7,16.2c0-0.4-0.1-0.7-0.2-1.1l-0.1-0.3c-0.5-1.7-1.5-3.5-2.8-4.9c-1.1-1.2-2.6-2.2-4.1-2.9c-1.1-0.5-2.2-0.8-3.4-0.9
		L11.4,6l0-0.6V3L3.5,7.7l7.9,4.7v-3l0.8,0.2c0.7,0.1,1.3,0.3,1.9,0.6c1.1,0.5,2.1,1.2,3,2.1l0,0c0.7,0.7,1.6,2,2,3.6
		c0,0.1,0.1,0.2,0.1,0.3c0.1,0.2,0.1,0.5,0.2,0.8l0.1,0.5l0,1v9.7h3.4L22.8,17L22.7,16.2z"/>
      <path d="M24.2,18.4l-0.1-1.5L24,16c-0.1-0.5-0.2-0.9-0.3-1.3l-0.1-0.3c-0.6-2-1.6-3.9-3.1-5.5c-1.6-1.7-3.4-2.7-4.5-3.2
		c-1-0.4-2.1-0.7-3.2-0.9V0.6l-12,7.1l12,7.1v-3.7c0.3,0.1,0.5,0.2,0.8,0.3c0.9,0.4,1.8,1,2.5,1.8c0.6,0.6,1.4,1.7,1.7,3
		c0,0.1,0.1,0.2,0.1,0.3c0.1,0.2,0.1,0.4,0.1,0.6l0.1,0.4l0,12h6.2V18.4z M18.8,28.8c0,0,0-1.7,0-4.2c0-1.3,0-2.7,0-4.3
		c0-0.4,0-0.8,0-1.2c0-0.2,0-0.4,0-0.6v-0.2l0-0.1l0-0.2l0-0.5l-0.1-0.5c0-0.3-0.1-0.6-0.2-1c-0.3-1.3-1.1-2.4-1.9-3.3
		c-0.8-0.9-1.8-1.5-2.7-1.9c-0.7-0.3-1.3-0.4-1.8-0.5v3.4L2.1,7.7l9.9-5.9v3.6c0,0,0,0,0,0c0.9,0.1,2.2,0.4,3.6,1
		c1.4,0.6,3,1.6,4.3,3c1.3,1.4,2.4,3.2,3,5.2c0.1,0.5,0.3,1,0.3,1.5l0.1,0.8c0,0.3,0,0.5,0,0.8l0,0.4l0,0.2v0.2c0,0.2,0,0.4,0,0.6
		c0,0.4,0,0.8,0,1.2c0,1.6,0,3.1,0,4.3c0,2.5,0,4.2,0,4.2L18.8,28.8L18.8,28.8z"/>
    </svg>
  );
};
