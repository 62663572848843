import React from "react";

// Styles
import "./switch-button-styles.css";

export default ({ button1Name, button2Name, isFirst, handleClick }) => {
  return (
    <>
      <div className="switch-button-container">
        <div
          className="switch-button-button"
          onClick={() => handleClick(button1Name)}
        >
          <p
            className={`switch-button-selector ${
              isFirst ? "switch-button-isSelected" : ""
            }`}
          >
            {button1Name}
          </p>
        </div>

        <div
          className="switch-button-button"
          onClick={() => handleClick(button2Name)}
        >
          <p
            className={`switch-button-selector ${
              !isFirst ? "switch-button-isSelected" : ""
            }`}
          >
            {button2Name}
          </p>
        </div>
      </div>
      <div
        className={`switch-button-highlight ${
          !isFirst ? "switch-button-highlight-isSelected" : ""
        }`}
      />
    </>
  );
};
