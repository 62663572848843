import React from "react";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 22"
    >
      <path
        d="M11.8,9.2c0.1,0,0.1,0,0.2,0L16,8.4l0.6,1.6l-4.2,0.9c-0.3,0.1-0.6,0.3-0.7,0.5c-0.2,0.3-0.2,0.6-0.1,0.9l2,5.2
	c0.2,0.4,0.6,0.7,1,0.7c0.1,0,0.3,0,0.4-0.1c0.6-0.2,0.8-0.8,0.6-1.4l-1.6-4l4.2-0.9c0.3-0.1,0.6-0.3,0.7-0.5
	c0.2-0.3,0.2-0.6,0.1-0.9l-2.2-5.6c-0.2-0.6-0.8-0.8-1.4-0.6c-0.6,0.2-0.8,0.8-0.6,1.4l0.4,0.9l-3.7,0.7c-0.5,0.1-0.9,0.6-0.8,1.1
	C10.9,8.9,11.3,9.2,11.8,9.2z"
      />
      <path d="M21.1,7.1l0,0.4l8.4-1.4l0.1-1.6C29.6,4.6,20.4,4.3,21.1,7.1z" />
      <path d="M21.5,11.2h6.2c0,0,1.3-0.2,1.7-1l0.1-3.6l-8.3,1.3L21.5,11.2z" />
      <path
        d="M27.9,16.2l-1.5,0.3c0-0.2-0.1-0.4-0.1-0.7c0.2,0,0.5,0.1,0.7,0.1l0.3-0.2c-0.1-0.5-0.3-1-0.6-1.5c-1.5-2.5-4.7-3.4-7.2-1.9
	c-2.2,1.3-3.1,3.8-2.4,6.1h-5.9l-1-6c0-0.2-0.3-2.2-1.8-3.1C8.5,9.1,8.9,8.7,10,8.8c0.4,0,0.7-0.2,0.7-0.6c0-0.4-0.2-0.7-0.6-0.7
	c-1-0.1-1.8,0.1-2.4,0.6C7.4,8.3,7.3,8.6,7.1,8.9c0,0,0,0,0,0c0,0-0.1,0-0.1,0H4.8c-0.4,0-0.6,0.3-0.6,0.6c0,0.4,0.3,0.6,0.6,0.6H7
	c1.7,0.3,1.9,2.4,1.9,2.5c0,0,0,0,0,0l1.1,6.6c0.1,0.3,0.3,0.5,0.6,0.5h7c0.3,0.4,0.6,0.7,0.9,1l0.4-0.2c0-0.2,0.1-0.3,0.2-0.5
	c1.3,1.3,3.4,1.6,5.1,0.6c1.1-0.6,1.8-1.7,2.1-2.9l1.8-0.4c0.3-0.1,0.6-0.4,0.5-0.8C28.6,16.3,28.3,16.1,27.9,16.2z M22.6,17
	c0.9-0.5,1.7-0.8,2.4-1c0.1,0.3,0.2,0.5,0.2,0.8l-3.5,0.8C21.9,17.4,22.3,17.2,22.6,17z M23.7,19.6c-1.1,0.7-2.5,0.5-3.5-0.3
	l4.7-1.1C24.7,18.8,24.3,19.3,23.7,19.6z"
      />
      <path
        d="M4.7,12.7c-2.4,0-4.3,1.9-4.3,4.3s1.9,4.3,4.3,4.3C7.1,21.3,9,19.4,9,17S7.1,12.7,4.7,12.7z M4.7,20c-1.7,0-3-1.3-3-3
	s1.3-3,3-3c1.7,0,3,1.3,3,3S6.4,20,4.7,20z"
      />
      <path
        d="M11.6,2.5c-0.2,1,0.3,1.9,1.1,2c0.1,0,0.2,0,0.3,0c0,0,0,0,0,0C15.7,4.1,15,4.1,15.2,3c0.2-1.1-0.5-2.1-1.5-2.3
	s-2.1,0.5-2.3,1.5l0.2,0C11.6,2.4,11.6,2.4,11.6,2.5z M12,2.6c0-0.1,0-0.1,0-0.2l1.3,0.2c0.6,0.2,0.5,0.7,0.2,1.2
	c-0.2,0.2-0.5,0.3-0.8,0.3C12.2,4,11.8,3.3,12,2.6z"
      />
    </svg>
  );
};
