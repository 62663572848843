import React from "react";

// Utilities
import { militaryTimeToReadable } from "../../../../utilites/time";
import { listingTimeDetails } from "../../../../utilites/status";

// Styles
import "./time-display.css";

export default ({
  isDateCurrent,
  startTime,
  endTime,
  adStatus,
  isTimeBar = true,
}) => {
  const { remaingWidth, timeLeft } = listingTimeDetails({
    isDateCurrent,
    startTime,
    endTime,
    adStatus,
  });

  return (
    <div className="listing-spacing timeDisplay-wrapper-center">
      <div className="timeDisplay-wrapper">
        <div className="time-left-text-container">
          <p className="timeDisplay-time timeDisplay-time-title">START</p>
          <p className="timeDisplay-time">
            {militaryTimeToReadable(startTime)}
          </p>
        </div>
        <div className="time-left-center-container">
          {isTimeBar && <p className="time-left-text">{timeLeft}</p>}
          <div className="time-left-container">
            {isTimeBar && (
              <div
                className={`time-left-remaing-container time-left-remaing-container-${adStatus}`}
                style={{ maxWidth: `${remaingWidth}%` }}
              />
            )}
          </div>
        </div>
        <div className="time-left-text-container">
          <p className="timeDisplay-time timeDisplay-time-title">END</p>
          <p className="timeDisplay-time">{militaryTimeToReadable(endTime)}</p>
        </div>
      </div>
    </div>
  );
};
