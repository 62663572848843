import React from "react";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 28"
    >
      <circle cx="15.1" cy="4.6" r="4.1" />
      <path
        d="M29.5,18.7c0,0-11.1,5.5-11.8,5.6c-1.3,0.3-3,0.4-4.4,0C12.6,24,0.4,18.3,0.4,18.3s0,2.7,0,3s11.5,5.3,12.4,5.7
        c1.3,0.6,4.3,0.5,5.5,0c0.6-0.3,11-5,11.2-5.2C29.7,21.6,29.5,18.7,29.5,18.7z"
      />
      <path
        d="M1,17.2c0.5,0.2,12,5.5,12.2,5.5c1.5,0.4,2.5,0.5,4.4,0c0.2-0.1,10.8-5,11.2-5.2c0.6-0.4,1-2,0-2.6c-1-0.6-10.6-5-10.9-5.1
        c-0.3-0.1-1.2-0.1-1.2-0.1s0,6.5,0,7.1c0,0.6-1.4,0.8-1.4,0.8s-1.8-0.3-1.8-0.8c0-0.5,0-7.1,0-7.1s-0.8-0.1-1.2,0
        c-0.4,0.1-10.8,4.8-11.4,5.2C0.2,15.4,0.6,16.9,1,17.2z M6.3,14.9c1.1,0,2,0.6,2,1.3c0,0.7-0.9,1.3-2,1.3s-2-0.6-2-1.3
        C4.4,15.5,5.2,14.9,6.3,14.9z"
      />
    </svg>
  );
};
