import React from "react";

export default props => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 27"
    >
      <path
        d="M4.7,0.4c-2.3,0-4.2,1.9-4.2,4.2C0.5,5.3,0.7,6,1,6.7l10.3,17.9c1.2,2,3.7,2.7,5.7,1.5c0.6-0.4,1.2-0.9,1.5-1.5L29,6.7
	c1.2-2,0.5-4.6-1.5-5.7c-0.6-0.4-1.4-0.6-2.1-0.6H4.7z M14.9,2.6c1.7,0,3.1,1.3,3.2,3c0,0,0,0.1,0,0.1c0,1.7-1.4,3.1-3.1,3.1
	c-1.7,0-3.1-1.4-3.1-3.1C11.9,4,13.2,2.6,14.9,2.6z M6.5,2.6c1.7,0,3.1,1.3,3.2,3c0,0,0,0.1,0,0.1c0,1.7-1.4,3.1-3.1,3.1
	c-1.7,0-3.1-1.4-3.1-3.1c0,0,0,0,0,0C3.5,4,4.8,2.6,6.5,2.6z M23.3,2.6c1.7,0,3.1,1.3,3.2,3c0,0,0,0.1,0,0.1c0,1.7-1.4,3.1-3.1,3.1
	c-1.7,0-3.1-1.4-3.1-3.1c0,0,0,0,0,0C20.3,4,21.6,2.6,23.3,2.6z M19.1,9.8c1.7,0,3.1,1.3,3.2,3c0,0,0,0.1,0,0.1
	c0,1.7-1.4,3.1-3.1,3.1c-1.7,0-3.1-1.4-3.1-3.1c0,0,0,0,0,0C16.1,11.3,17.4,9.9,19.1,9.8z M10.7,9.8c1.7,0,3.1,1.3,3.2,3
	c0,0,0,0.1,0,0.1c0,1.7-1.4,3.1-3.1,3.1c-1.7,0-3.1-1.4-3.1-3.1c0,0,0,0,0,0C7.7,11.3,9,9.9,10.7,9.8z M14.9,17.1
	c1.7,0,3.1,1.3,3.2,3c0,0,0,0.1,0,0.1c0,1.7-1.4,3.1-3.1,3.1c-1.7,0-3.1-1.4-3.1-3.1C11.9,18.5,13.2,17.1,14.9,17.1z"
      />
      <path
        d="M4.6,5.3c0.2-1,1-1.7,2-1.7c0.3,0,0.5,0.2,0.5,0.5S6.9,4.6,6.6,4.6c-0.5,0-0.9,0.3-1,0.8C5.5,5.8,5.3,5.9,5,5.9
	C4.7,5.8,4.5,5.5,4.6,5.3L4.6,5.3z"
      />
      <path
        d="M13,5.3c0.2-1,1-1.7,2-1.7c0.3,0,0.5,0.2,0.5,0.5S15.3,4.6,15,4.6c-0.5,0-0.9,0.3-1,0.8c-0.1,0.3-0.3,0.5-0.6,0.4
	C13.1,5.8,12.9,5.6,13,5.3L13,5.3z"
      />
      <path
        d="M21.3,5.3c0.2-1,1-1.7,2-1.7c0.3,0,0.5,0.2,0.5,0.5s-0.2,0.5-0.5,0.5c-0.5,0-0.9,0.3-1,0.8c-0.1,0.3-0.3,0.5-0.6,0.4
	C21.5,5.8,21.3,5.6,21.3,5.3L21.3,5.3z"
      />
      <path
        d="M17.2,12.5c0.2-1,1-1.7,2-1.7c0.3,0,0.5,0.2,0.5,0.5c0,0.3-0.2,0.5-0.5,0.5c-0.5,0-0.9,0.3-1,0.8c-0.1,0.3-0.3,0.5-0.6,0.4
	C17.3,13.1,17.1,12.8,17.2,12.5L17.2,12.5z"
      />
      <path
        d="M8.8,12.5c0.2-1,1-1.7,2-1.7c0.3,0,0.5,0.2,0.5,0.5c0,0.3-0.2,0.5-0.5,0.5c-0.5,0-0.9,0.3-1,0.8c-0.1,0.3-0.3,0.5-0.6,0.4
	C8.9,13.1,8.7,12.8,8.8,12.5L8.8,12.5z"
      />
      <path
        d="M15,18.1c0.3,0,0.5,0.2,0.5,0.5s-0.2,0.5-0.5,0.5c-0.5,0-0.9,0.3-1,0.8c-0.1,0.3-0.3,0.5-0.6,0.4c-0.3-0.1-0.5-0.3-0.4-0.6
	C13.2,18.8,14,18.1,15,18.1L15,18.1z"
      />
    </svg>
  );
};
