import React from "react";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 24"
    >
      <path
        transform="translate(0,-952.36218)"
        d="M29.1,953l-8.7,3.1v19.4l8.7-3.1V953z M9.6,953.2l-8.7,3.1v19.4l8.7-3.1V953.2z M11,953.2v19.4l8,2.9v-19.4L11,953.2z"
      />
    </svg>
  );
};
