import React from "react";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 22 30"
    >
      <path
        d="M17,25.9c2.4-1.7,4-4.5,4.2-7.6c0-0.4-0.2-0.7-0.6-0.7h-3.7c1.3-2.7,1.3-6.3-0.3-9.1c-0.7-1.3-1.5-2.4-2.7-3.4
	c-0.3,1-0.5,1.9-0.8,2.9c-1.5-3.4-3.4-6.2-7.3-7.4C7.2,3.4,7.1,5.9,6,8.5c-0.4,1.1-0.9,2.1-1.3,3.2c-0.9,2.2-0.6,4.3,0.4,6H1.4
	c-0.4,0-0.6,0.3-0.6,0.7C1,21.4,2.6,24.2,5,25.9l-1.9,1.9c-0.4,0.4-0.4,0.9,0,1.3c0.4,0.4,0.9,0.4,1.3,0l2.2-2.2
	c1.3,0.6,2.8,1,4.4,1s3-0.4,4.4-1l2.2,2.2c0.2,0.2,0.4,0.3,0.6,0.3s0.5-0.1,0.6-0.3c0.4-0.4,0.4-0.9,0-1.3L17,25.9z M8.6,17.7
	c0-0.4,0-0.7,0-0.8c0-0.9,0.2-1.8,0.8-2.5c0.8-1,2.1-1,2.8,0.1c0.4,0.7,0.6,1.5,0.8,2.3c0,0.2,0,0.5,0.1,1H8.6z"
      />
    </svg>
  );
};
