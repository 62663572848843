import React from "react";
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
} from "recharts";

// Utilities
import UseGetTheme from "../../../utilites/hooks/useGetTheme";

const Chart = ({ primaryKey, secomdaryKey, promoKey, data }) => {
  const { isNight } = UseGetTheme();
  return data ? (
    <div style={{ width: "100%", height: "250px" }}>
      <ResponsiveContainer>
        <BarChart data={data}>
          <defs>
            <linearGradient id="barPromoKey" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#48C826" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#48C826" stopOpacity={0.2} />
            </linearGradient>
            <linearGradient id="primaryKey" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor="hsl(215, 100%, 65%)"
                stopOpacity={0.8}
              />
              <stop
                offset="95%"
                stopColor="hsl(215, 100%, 65%)"
                stopOpacity={0.2}
              />
            </linearGradient>
            <linearGradient id="secomdaryKey" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor="hsl(339, 100%, 55%)"
                stopOpacity={0.8}
              />
              <stop
                offset="95%"
                stopColor="hsl(339, 100%, 55%)"
                stopOpacity={0.4}
              />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="name"
            type="category"
            stroke={isNight ? "#f1f1f1" : " rgb(80,80,80)"}
          />
          <YAxis
            type="number"
            stroke={isNight ? "#f1f1f1" : " rgb(80,80,80)"}
          />
          <Tooltip />
          {promoKey && (
            <Bar dataKey={promoKey} stroke="#2DA90C" fill="url(#barPromoKey)" />
          )}
          {primaryKey && (
            <Bar
              dataKey={primaryKey}
              stroke="hsl(215, 100%, 65%)"
              fill="url(#primaryKey)"
            />
          )}
          {secomdaryKey && (
            <Bar
              dataKey={secomdaryKey}
              stroke="hsl(339, 100%, 55%)"
              fill="url(#secomdaryKey)"
            />
          )}
        </BarChart>
      </ResponsiveContainer>
    </div>
  ) : null;
};

export default Chart;
