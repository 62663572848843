import React from "react";

// Styles
import "./svg.css";

export default () => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 21"
    >
      <path
        className="svg-clip-rule"
        d="M17.1,2.1c-1,0.3-1.5,1.4-1.2,2.4L16.1,5L3.6,13.9c-0.3-0.7-1.2-1.1-2-0.9
			c-0.9,0.3-1.3,1.2-1.1,2.1l1.3,4C2.2,20,3.1,20.5,4,20.2c0.8-0.3,1.2-1.1,1.1-1.9l1.1,0C6,18.5,6,18.7,6,19c0,0.9,0.7,1.6,1.6,1.6
			l5.6,0c0.2,0,0.3,0,0.5-0.1c0.7-0.2,1.1-0.8,1.1-1.5c0-0.3-0.1-0.5-0.2-0.7l5.7-0.1l0.2,0.6c0.3,1,1.4,1.5,2.4,1.2
			s1.5-1.4,1.2-2.4L19.5,3.3C19.1,2.3,18.1,1.8,17.1,2.1L17.1,2.1z M13.9,19c0,0.3-0.3,0.7-0.7,0.7l-5.6,0c-0.4,0-0.7-0.3-0.7-0.7
			c0-0.3,0.2-0.5,0.5-0.6c0.1,0,0.1,0,0.2,0l5.6,0c0.2,0,0.3,0.1,0.5,0.2C13.8,18.6,13.9,18.8,13.9,19L13.9,19z M4.1,18.4
			c0.1,0.4-0.1,0.8-0.5,0.9s-0.8-0.1-0.9-0.5l-1.3-4c-0.1-0.4,0.1-0.8,0.5-0.9s0.8,0.1,0.9,0.5L4.1,18.4L4.1,18.4z M23.2,17.9
			c0.2,0.5-0.1,1-0.6,1.2c-0.5,0.2-1-0.1-1.2-0.6L16.8,4.2c-0.2-0.5,0.1-1,0.6-1.2s1,0.1,1.2,0.6L23.2,17.9L23.2,17.9z"
      />
      <path
        className="svg-clip-rule"
        d="M23.7,10.3l0.4-0.1c0.2-0.1,0.4-0.3,0.3-0.6c-0.1-0.2-0.3-0.4-0.6-0.3l-0.4,0.1
			C23.2,9.5,23,9.8,23.1,10C23.2,10.3,23.5,10.4,23.7,10.3"
      />
      <path
        className="svg-clip-rule"
        d="M25.7,8.7l-0.4,0.1c-0.2,0.1-0.4,0.3-0.3,0.6s0.3,0.4,0.6,0.3l0.4-0.1
			c0.2-0.1,0.4-0.3,0.3-0.6S25.9,8.6,25.7,8.7"
      />
      <path
        className="svg-clip-rule"
        d="M27.4,8.1L27,8.3c-0.2,0.1-0.4,0.3-0.3,0.6s0.3,0.4,0.6,0.3L27.7,9
			C28,8.9,28.1,8.7,28,8.4S27.7,8,27.4,8.1"
      />
      <path
        className="svg-clip-rule"
        d="M23.7,1.8c0.1,0,0.2-0.1,0.3-0.2l0.2-0.4c0.1-0.2,0-0.5-0.2-0.6
			c-0.2-0.1-0.5,0-0.6,0.2l-0.2,0.4c-0.1,0.2,0,0.5,0.2,0.6C23.5,1.8,23.6,1.8,23.7,1.8"
      />
      <path
        className="svg-clip-rule"
        d="M22.9,3.4c0.1,0,0.2-0.1,0.3-0.2l0.2-0.4c0.1-0.2,0-0.5-0.2-0.6
			c-0.2-0.1-0.5,0-0.6,0.2l-0.2,0.4c-0.1,0.2,0,0.5,0.2,0.6C22.6,3.5,22.8,3.5,22.9,3.4"
      />
      <path
        className="svg-clip-rule"
        d="M22,5.1c0.1,0,0.2-0.1,0.3-0.2l0.2-0.4c0.1-0.2,0-0.5-0.2-0.6
			c-0.2-0.1-0.5,0-0.6,0.2l-0.2,0.4c-0.1,0.2,0,0.5,0.2,0.6C21.8,5.1,21.9,5.1,22,5.1"
      />
      <path
        className="svg-clip-rule"
        d="M24.4,6.7c0,0,0.1,0,0.1-0.1l0.4-0.3c0.2-0.2,0.3-0.4,0.1-0.7
			c-0.2-0.2-0.4-0.3-0.7-0.1L24,5.9c-0.2,0.2-0.3,0.4-0.1,0.7C24,6.7,24.2,6.7,24.4,6.7"
      />
      <path
        className="svg-clip-rule"
        d="M22.8,6.7L22.5,7c-0.2,0.2-0.3,0.4-0.1,0.7c0.1,0.2,0.3,0.2,0.5,0.2
			c0,0,0.1,0,0.1-0.1l0.4-0.3c0.2-0.2,0.3-0.4,0.1-0.7C23.4,6.6,23.1,6.5,22.8,6.7"
      />
      <path
        className="svg-clip-rule"
        d="M25.4,5.4c0.1,0.2,0.3,0.2,0.5,0.2c0,0,0.1,0,0.1-0.1l0.4-0.3
			c0.2-0.2,0.3-0.4,0.1-0.7c-0.2-0.2-0.4-0.3-0.7-0.1l-0.4,0.3C25.3,4.9,25.2,5.2,25.4,5.4"
      />
      <path
        className="svg-clip-rule"
        d="M25.5,14.6c-0.2-0.1-0.5,0-0.6,0.2c-0.1,0.2,0,0.5,0.2,0.6l0.4,0.2
			c0.1,0.1,0.2,0.1,0.4,0c0.1,0,0.2-0.1,0.3-0.2c0.1-0.2,0-0.5-0.2-0.6L25.5,14.6"
      />
      <path
        className="svg-clip-rule"
        d="M27.1,15.5c-0.2-0.1-0.5,0-0.6,0.2c-0.1,0.2,0,0.5,0.2,0.6l0.4,0.2
			c0.1,0.1,0.2,0.1,0.4,0c0.1,0,0.2-0.1,0.3-0.2c0.1-0.2,0-0.5-0.2-0.6L27.1,15.5"
      />
      <path
        className="svg-clip-rule"
        d="M28.8,16.3c-0.2-0.1-0.5,0-0.6,0.2c-0.1,0.2,0,0.5,0.2,0.6l0.4,0.2
			c0.1,0.1,0.2,0.1,0.4,0c0.1,0,0.2-0.1,0.3-0.2c0.1-0.2,0-0.5-0.2-0.6L28.8,16.3"
      />
      <path
        className="svg-clip-rule"
        d="M24.9,11.9l-0.5,0c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0.2,0.5,0.5,0.5l0.5,0
			c0.1,0,0.1,0,0.1,0c0.2-0.1,0.3-0.2,0.3-0.4C25.3,12.1,25.1,11.9,24.9,11.9"
      />
      <path
        className="svg-clip-rule"
        d="M26.3,12.9l0.5,0c0.1,0,0.1,0,0.1,0c0.2-0.1,0.3-0.2,0.3-0.4
			c0-0.3-0.2-0.5-0.5-0.5l-0.5,0c-0.3,0-0.5,0.2-0.5,0.5C25.8,12.6,26,12.9,26.3,12.9"
      />
      <path
        className="svg-clip-rule"
        d="M28.6,11.9l-0.5,0c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0.2,0.5,0.5,0.5l0.5,0
			c0.1,0,0.1,0,0.1,0c0.2-0.1,0.3-0.2,0.3-0.4C29.1,12.2,28.9,11.9,28.6,11.9"
      />
    </svg>
  );
};
