import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import * as dataActions from "../../../../redux/actions/data-actions";

// Components
import Button from "../../../components/common/button";

// Utilities
import { findReviewUpdate } from "../../../../api/review";
import UseGetEmployeeAnalytics from "../../../../utilites/hooks/employeeAnalytics";
import { setEmployeeAnalytics } from "../../../../api/employee";

// Styles
import "./review-button-styles.css";

export default ({ listingId, data, related, setAd }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const employeeAnalytics = UseGetEmployeeAnalytics();
  const { fName, lName } = useSelector((store) => store.user.employee);
  const [isUpdating, setIsUpdating] = useState(false);
  const [failReason, setFailReason] = useState("Doesn't Meet Guidelines");
  const filterRelated = related.filter(({ _id }) => listingId !== _id);
  const agentName = `${fName} ${lName}`;
  const { title, reviewed, categories, images = [], creatorId } = data;
  const { cat1Img } = categories || {};
  const { isReviewed } = reviewed || {};

  const handleReview = async (value) => {
    setIsUpdating(true);
    const employeeData = { ...employeeAnalytics, reviewed_find: true };
    const reviewData = {
      id: listingId,
      notification: {
        userId: creatorId,
        title,
        timeStamp: moment().format("lll"),
      },
    };

    if (value) {
      reviewData.reviewed = {
        isReviewed: true,
        agent: agentName,
        timeStamp: moment().format("lll"),
      };
    } else {
      if (failReason.includes("Duplicate")) {
        const foundSplit = failReason.split(" - ");
        const { _id: foundId } = filterRelated.find(
          ({ title }) => title === foundSplit[1]
        );

        reviewData.reason = foundSplit[0];
        reviewData.notification.postId = foundId;
      } else {
        reviewData.reason = failReason;
      }
      reviewData.images = [cat1Img, ...images];
    }

    const [{ result, message }] = await Promise.all([
      findReviewUpdate(reviewData),
      setEmployeeAnalytics(employeeData),
    ]);

    if (result) {
      setAd(result);
      dispatch(dataActions.updateResultInResults(result));
    }
    if (message) {
      history.push("/review-finds");
    }
    setIsUpdating(false);
  };

  const createSelectOptions = (options = [], allText) => {
    return options.map((option, idx) => (
      <option value={option} key={idx}>
        {option ? option : `All ${allText}`}
      </option>
    ));
  };

  return (
    !isReviewed && (
      <div className="confirm-button-wrapper">
        <div className="confirm-button-container">
          <select
            id="sideNav-subCat-input"
            className="sideNav-border sideNav-subcat-select"
            style={{ margin: 0, backgroundColor: "white", color: "black" }}
            value={failReason}
            onChange={(evt) => setFailReason(evt.target.value)}
          >
            {createSelectOptions([
              "Doesn't Meet Guidelines",
              ...filterRelated.map(({ title }) => `Duplicate - ${title}`),
              "No Business",
            ])}
          </select>

          <Button
            className="submit-button-CreateListing"
            isDisabled={isUpdating}
            isPrimary={false}
            text="Fail"
            handleClick={() => {
              handleReview(false);
            }}
          />

          <Button
            text="Pass"
            isDisabled={isUpdating}
            handleClick={() => {
              handleReview(true);
            }}
          />
        </div>
      </div>
    )
  );
};
