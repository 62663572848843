import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import * as stateActions from "../../../redux/actions/state-actions";
import * as dataActions from "../../../redux/actions/data-actions";
import moment from "moment";

// Components
import Button from "../../components/common/button";
import ListingLoader from "./listing-loader";
import DetailViewLayout from "../../components/detail-view-scroll-layout";
import ShareButton from "../../components/common/share/button";
import ButtonLink from "../business/button-link";
import TimeDisplay from "./time-display";
import Date from "./date";
import Features from "./features";
import AdMedtaData from "./ad-meta-data";
import Yelp from "./yelp";
import Contact from "./contact";
import OtherAds from "./other-ads";
import Modal from "../../components/modal";
import ReviewButton from "../../components/review-button";

// Utilities
import { userCheckin } from "../../../constants/feature-flags";
import UseGetTheme from "../../../utilites/hooks/useGetTheme";
import { formatListingTime, toTitleCase } from "../../../utilites/format";
import { getAdStatus, checkinStatus } from "../../../utilites/status";
import { updateAdInResults } from "../../../utilites/update";
import { viewedListing, updateListing } from "../../../api/listing";
import { getCurrentDate, convertDateToDbDate } from "../../../utilites/date";
import getFeaturesIcon from "../../components/features-input/getFeatureIcon";

// Assets
import DirectionArrow from "../../../assets/svg/directionArrow";

// Styles
import "./listing.css";

const Listing = ({
  ad,
  business,
  marketing,
  setHeaderTitle,
  setImage,
  isUser,
  user,
  history,
  setSavedPage,
  setLoader,
  setMessage,
  setAd,
  results,
  setResults,
  setPromoCode,
  location,
}) => {
  const {
    type,
    isGrowth,
    isActive,
    _id,
    categories,
    checkedIn = {},
    images: adImages,
    title,
    description,
    virtualLink,
    features,
    promoCode,
    ticketLink,
    analyticsId,
    businessName: growthBusinessName,
    reviewed,
  } = ad || {};
  const {
    _id: businessId = "",
    name: businessName = "",
    images: businessImages = [],
    yelp = {},
    ads = [],
  } = business || {};
  const formattedAd = formatListingTime({ ad });
  const currentDay = moment().format("dddd");
  const { days, date } = formattedAd || {};
  const {
    isOpen,
    open: startTime,
    close: endTime,
  } = date && "dateAvailable" in date ? date : days.hours[currentDay] || {};
  const [{ isDateCurrent, status: adStatus, hasStarted }, setAdStatus] =
    useState(getAdStatus(formattedAd));
  const hasViewedPromo = useRef(false);
  const [modalLoginMessage, setModalLoginMessage] = useState("");
  const { isNight } = UseGetTheme();
  const { _id: userId } = user || {};
  const { showCheckIn, checkInStatus } = checkinStatus(ad, userId);
  const cardHeaderStyle = isDateCurrent
    ? `listing-header-container-${adStatus}`
    : "listing-header-container-starting";
  const isCheckIn = isUser && showCheckIn && (hasStarted || checkInStatus);
  const isDarkColor = !isNight && adStatus === "starting";
  const currentDate = getCurrentDate();
  const creatorIsViewing = userId === businessId;
  const { signedUp, entered } = marketing || {};
  const { agent: signedUpAgent, timeStamp: signedUpTimeStamp } = signedUp || {};
  const { agent: enteredAgent, timeStamp: enteredTimeStamp } = entered || {};
  const {
    isReviewed,
    agent: reviewedAgent,
    timeStamp: reviewedTimeStamp,
  } = reviewed || {};
  let images = [...adImages, ...businessImages];
  const { cat1Img, cat2, cat2Img } = categories;
  const { from } = location.state || {};
  if (from && cat2 === from) {
    images = [cat2Img, cat1Img, ...images];
  } else {
    if (cat2Img) images.unshift(cat2Img);
    images.unshift(cat1Img);
  }

  useEffect(() => {
    setHeaderTitle({
      businessId,
      businessName: businessName || growthBusinessName,
    });
    return () => setHeaderTitle(null);
  }, [business]);

  useEffect(() => {
    setInterval(() => setAdStatus(getAdStatus(ad)), 10000);
    return () => clearInterval(setAdStatus);
  }, [ad]);

  const handlePromoClick = () => {
    if (
      !hasViewedPromo.current ||
      (!userId && analyticsId) ||
      (!creatorIsViewing && analyticsId)
    )
      viewedListing({
        analyticsId,
        listingId: _id,
        date: convertDateToDbDate(currentDate),
        isPromo: true,
      });
    hasViewedPromo.current = true;
    setPromoCode(promoCode);
  };

  const handleModalLoginAccept = () => {
    const { location } = history;
    const { pathname } = location;
    setSavedPage(pathname);
    history.push("/login");
  };

  const handleCheckIn = async () => {
    if (user) {
      setLoader(true);
      let updatedTimeStamp = [moment().format()];
      if (checkedIn && userId in checkedIn)
        updatedTimeStamp = [...updatedTimeStamp, ...checkedIn[userId]];
      const { ad: updatedAd } =
        (await updateListing({
          ...ad,
          checkedIn: { ...checkedIn, [userId]: updatedTimeStamp },
        })) || {};
      if (updatedAd) {
        setAd(updatedAd);
        if (results.length)
          setResults({
            results: updateAdInResults(results, updatedAd),
            pathname: "",
          });
      }
      setLoader(false);
    } else {
      setModalLoginMessage(
        "You are not logged in. Would you like to login now?"
      );
    }
  };

  const renderCheckedInMessage = () => {
    const checkInTime = moment(checkInStatus).format("MMM Do @ h:mma");
    return (
      <>
        <p className="checked-in-thanks">
          <i>Thank You!</i>
        </p>
        <div className="checked-in-info">
          <span>Checked-In On:</span>
          <span>{checkInTime}</span>
        </div>
      </>
    );
  };

  return (
    <>
      <DetailViewLayout images={images} name={title} status={adStatus}>
        <div className="listing-content-container">
          <div className="check-in-container">
            <div className="listing-marketing-metedata-conatiner">
              <p className="listing-marketing-metedata">{`Signed Up Agent: ${signedUpAgent}`}</p>
              <p className="listing-marketing-metedata">{`Signed Up Time: ${signedUpTimeStamp}`}</p>
              <p className="listing-marketing-metedata">{`Entered By: ${enteredAgent}`}</p>
              <p className="listing-marketing-metedata">{`Entered Time: ${enteredTimeStamp}`}</p>
              {isReviewed && (
                <>
                  <p className="listing-marketing-metedata">{`Reviewed By: ${reviewedAgent}`}</p>
                  <p className="listing-marketing-metedata">{`Reviewed Time: ${reviewedTimeStamp}`}</p>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="listing-content-container">
          <div className={`listing-header-container ${cardHeaderStyle}`}>
            <div className="lisitng-type-container">
              {getFeaturesIcon(type)}
            </div>
            <ShareButton
              size="30px"
              isDarkColor={isDarkColor}
              title={title}
              name={businessName || growthBusinessName}
            />
            <p
              className={`listing-title-main ${
                isDarkColor ? "listing-title-main-dark" : ""
              }`}
            >
              {title.toUpperCase()}
            </p>
          </div>
          <div className="listing-business-info-conatiner">
            <p
              className="pointer listing-business-name"
              onClick={() => {
                if (!isGrowth) history.push(`/business/${businessId}`);
              }}
            >
              {toTitleCase({
                input: businessName || growthBusinessName,
                lowerCaseRest: false,
              })}
            </p>
            {!isGrowth && (
              <div
                className={`pointer listing-business-info-svg listing-fill-${adStatus}`}
                onClick={() => history.push(`/business/${businessId}`)}
              >
                <DirectionArrow />
              </div>
            )}
          </div>
          <p className="listing-spacing listing-description-main">
            {description}
          </p>

          <ButtonLink
            isDateCurrent={isDateCurrent}
            status={adStatus}
            text="GET TICKETS"
            link={ticketLink}
          />

          <ButtonLink
            isDateCurrent={isDateCurrent}
            status={adStatus}
            text="VIRTUAL LINK"
            link={virtualLink}
          />

          {promoCode && (
            <div className="listing-spacing listing-center-content">
              <div
                className={`redemtion-button-code-button ${cardHeaderStyle}`}
                onClick={handlePromoClick}
              >
                <p
                  className={`redemtion-button-code-text ${
                    hasStarted ? "color-white" : ""
                  }`}
                >
                  REDEMPTION CODE
                </p>
              </div>
            </div>
          )}

          {isOpen && (
            <TimeDisplay
              isDateCurrent={isDateCurrent}
              startTime={startTime}
              endTime={endTime}
              adStatus={adStatus}
            />
          )}
          <Date data={ad} status={adStatus} />
          <AdMedtaData data={ad} />
        </div>

        {userCheckin && isCheckIn && (
          <div className="listing-content-container">
            <div className="check-in-container">
              <span className="check-in-title">
                {!checkInStatus ? "Claim Promo Here" : "You claimed this promo"}
              </span>
              {!checkInStatus && (
                <Button
                  text="claim"
                  size="medium"
                  handleClick={handleCheckIn}
                />
              )}
            </div>
            <div
              className={`checked-in-popup-container ${
                checkInStatus ? "checked-in-popup-container-open" : ""
              }`}
            >
              {!!checkInStatus && renderCheckedInMessage()}
            </div>
          </div>
        )}

        <Features
          adTitle={title}
          name={businessName || growthBusinessName}
          categories={categories}
          features={features}
          adStatus={adStatus}
        />
        <OtherAds currentId={_id} ads={ads} name={businessName} />
        <Yelp yelp={yelp} name={businessName} setImage={setImage} />
        <Contact
          ad={ad}
          business={isGrowth ? ad : business}
          status={adStatus}
        />

        <Modal
          directModalMessage={modalLoginMessage}
          modalAccept="log in"
          modalCancel="cancel"
          handleCancelClick={() => setModalLoginMessage("")}
          handleAcceptClick={handleModalLoginAccept}
        />
      </DetailViewLayout>
      <div className="confirm-button-wrapper">
        <div className="confirm-button-container">
          {!isGrowth && <ReviewButton size="large" listingId={_id} data={ad} />}
          <Button
            className="submit-button-CreateListing"
            isPrimary={false}
            text="Edit"
            size="large"
            handleClick={() => {
              const url = isGrowth
                ? `/growth/listing/${_id}`
                : `/business/profile/${businessId}`;
              history.push(url);
            }}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (store) => ({
  user: store.user.user,
  isUser: store.user.isUser,
  results: store.data.results,
});

const mapDispatchToProps = (dispatch) => ({
  setHeaderTitle: (title) => dispatch(stateActions.setHeaderTitle(title)),
  setImage: (value) => dispatch(stateActions.setImage(value)),
  setSavedPage: (url) => dispatch(stateActions.setSavedPage(url)),
  setLoader: (value) => dispatch(stateActions.setLoader(value)),
  setMessage: (messageObj) => dispatch(stateActions.setMessage(messageObj)),
  setResults: (resultsObj) => dispatch(dataActions.setResults(resultsObj)),
  setPromoCode: (value) => dispatch(stateActions.setPromoCode(value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListingLoader(Listing));
