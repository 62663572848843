import React from "react";

export default props => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 30"
    >
      <path
        d="M25.7,1.4L25.7,1.4l-5.2,5.9c-1.9-0.8-3.7-1.1-5.7-1.1C6.7,6.4,1,14,0.8,14.4L0.3,15l0.5,0.6c2.3,2.8,4.7,4.9,7.1,6.2
	l-5.1,5.9L4.4,29l0.4-0.4l0,0L27.6,2.3L26.1,1L25.7,1.4z M12.8,13c0.9-1,2.3-1.2,3.4-0.7l-3.6,4.2C11.9,15.4,12,14,12.8,13z
	 M2.9,14.9C4.4,13.2,9,8.4,15,8.2c1.4,0,2.8,0.2,4.2,0.7l-1.6,1.8c-2-1.2-4.7-0.8-6.2,1s-1.6,4.5-0.1,6.3l-1.9,2.2
	C7.1,19.2,5,17.4,2.9,14.9z M29.2,14.3l0.5,0.6l-0.5,0.6c-0.2,0.3-5.9,7.9-14.1,8.1c-0.1,0-0.2,0-0.3,0c-1,0-2-0.1-2.9-0.3l1.5-1.8
	c0.5,0.1,0.9,0.1,1.4,0.1c0.1,0,0.2,0,0.3,0c5.9-0.1,10.6-5,12-6.7c-1.5-1.7-2.9-3.1-4.4-4.1L24,9.3C25.8,10.6,27.6,12.2,29.2,14.3z
	"
      />
    </svg>
  );
};
