import React from "react";

export default props => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 16 16"
    >
      <path
        d="M3.7,7.3c0.8,0,1.4,0.3,1.4,0.7c0,0.4-0.6,0.7-1.4,0.7S2.4,8.3,2.4,7.9C2.4,7.6,3,7.3,3.7,7.3z M12.7,14.1
		c0.6,0,1.1,0.2,1.1,0.5s-0.5,0.5-1.1,0.5c-0.6,0-1.1-0.2-1.1-0.5S12.1,14.1,12.7,14.1z M12.9,13.7c-0.1,0.1-0.2,0.1-0.3,0
		c-0.8-0.8-1.8-1.8-1.8-3.1c0-1.1,0.9-1.9,1.9-1.9c1.1,0,2,0.8,2,1.9C14.7,11.9,13.6,13,12.9,13.7L12.9,13.7z M12.7,9.7
		c0.5,0,1,0.4,1,1c0,0.5-0.4,1-1,1c-0.5,0-1-0.4-1-1C11.7,10.1,12.2,9.7,12.7,9.7z M4,9.6C3.7,9.3,4,8.8,4.4,9.1l6.7,5
		c0.3,0.3,0,0.8-0.4,0.5L4,9.6L4,9.6z M3.9,6.9c-0.1,0.1-0.2,0.1-0.3,0c-1-0.9-2.2-2.2-2.2-3.7c0-1.3,1.1-2.3,2.3-2.3
		c1.3,0,2.4,1,2.4,2.3C6.1,4.6,4.9,5.9,3.9,6.9L3.9,6.9z M3.7,1.9c0.7,0,1.2,0.5,1.2,1.2S4.4,4.3,3.7,4.3S2.5,3.7,2.5,3.1
		S3,1.9,3.7,1.9L3.7,1.9z"
      />
    </svg>
  );
};
