import React from "react";

export default props => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 30 "
    >
      <rect x="4.1" y="4" width="2.5" height="1.1" />
      <path
        d="M17.6,23.7c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0
			c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0c0,0,0,0,0.1,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0,0,0,0,0.1,0
			c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0c0.1,0,0.1,0,0.1,0c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0
			c0.1,0,0.1,0,0.2-0.1c0,0,0,0,0,0c1.7-0.5,2.9-2.1,2.9-4c0-2.3-1.9-4.2-4.2-4.2s-4.2,1.9-4.2,4.2C14.7,21.6,15.9,23.1,17.6,23.7
			C17.6,23.7,17.6,23.7,17.6,23.7z M16.4,19.7c0-1.3,1.1-2.4,2.5-2.4c1.4,0,2.5,1.1,2.5,2.4s-1.1,2.4-2.5,2.4
			C17.5,22.2,16.4,21.1,16.4,19.7z"
      />
      <path
        d="M19.8,24.4c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0
			c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2,0-0.2,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0
			c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c-0.3-0.1-0.6-0.2-0.9-0.3v1.2v4l1.9-2.6c0.1-0.1,0.2-0.1,0.3-0.1
			c0.1,0,0.2,0.1,0.3,0.1l1.7,2.5v-3.9v-1.2C20.6,24.2,20.2,24.3,19.8,24.4C19.8,24.4,19.8,24.4,19.8,24.4z"
      />
      <polygon points="5.4,25.1 6.4,22.5 4.3,22.5 		" />
      <path d="M20.7,19.7c0-1-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8C19.9,21.5,20.7,20.7,20.7,19.7z" />
      <polygon points="24.9,1.2 24.9,3.9 27.5,3.9 		" />
      <path
        d="M24.5,4.6c-0.2,0-0.3-0.1-0.3-0.3V0.8H9.7v24.1h6.4v-1.2c-1.3-0.9-2.1-2.3-2.1-4c0-2.7,2.2-4.8,4.8-4.8
			c2.7,0,4.8,2.2,4.8,4.8c0,1.6-0.8,3.1-2.1,4v1.2H28V4.6H24.5z M11.4,3.9h7.5c0.2,0,0.3,0.1,0.3,0.3s-0.1,0.3-0.3,0.3h-7.5
			c-0.2,0-0.3-0.1-0.3-0.3C11.1,4,11.2,3.9,11.4,3.9z M26.3,11.3H11.4c-0.2,0-0.3-0.1-0.3-0.3s0.1-0.3,0.3-0.3h14.9
			c0.2,0,0.3,0.1,0.3,0.3S26.5,11.3,26.3,11.3z M26.3,9H11.4c-0.2,0-0.3-0.1-0.3-0.3s0.1-0.3,0.3-0.3h14.9c0.2,0,0.3,0.1,0.3,0.3
			C26.6,8.9,26.5,9,26.3,9z M26.3,6.8H11.4c-0.2,0-0.3-0.1-0.3-0.3s0.1-0.3,0.3-0.3h14.9c0.2,0,0.3,0.1,0.3,0.3
			C26.6,6.7,26.5,6.8,26.3,6.8z"
      />
      <path d="M4.1,7.6H2.3C2.2,7.6,2,7.7,2,7.9v5.9c0,0.2,0.1,0.3,0.3,0.3c0.2,0,0.3-0.1,0.3-0.3V8.2h1.5v13.6h2.5V5.7H4.1V7.6z" />
      <path d="M5.4,0.8C4.7,0.8,4.1,1.3,4.1,2v1.3h2.5V2C6.6,1.3,6,0.8,5.4,0.8z" />
    </svg>
  );
};
