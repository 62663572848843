import React from "react";
import {
  ResponsiveContainer,
  BarChart,
  YAxis,
  XAxis,
  Legend,
  Bar,
  Tooltip,
} from "recharts";

// Utilities
import UseGetTheme from "../../../utilites/hooks/useGetTheme";

export default ({ data, isValue = false, isTooltip = false }) => {
  const { isNight } = UseGetTheme();
  return data ? (
    <div
      style={{
        width: "100%",
        height: `${data.length * 50}px`,
        minHeight: "100px",
      }}
    >
      <ResponsiveContainer>
        <BarChart data={data} layout="vertical">
          <defs>
            <linearGradient
              id="horizontalBar"
              x1="0"
              y1="0"
              x2="0"
              y2="1"
              gradientTransform="rotate(90 .5 .5)"
            >
              <stop
                offset="5%"
                stopColor="hsl(215, 100%, 65%)"
                stopOpacity={0.8}
              />
              <stop
                offset="95%"
                stopColor="hsl(215, 100%, 65%)"
                stopOpacity={0.2}
              />
            </linearGradient>
          </defs>
          {isValue ? (
            <XAxis
              type="number"
              stroke={isNight ? "#f1f1f1" : " rgb(80,80,80)"}
            />
          ) : (
            <XAxis
              type="number"
              tick=""
              stroke={isNight ? "#f1f1f1" : " rgb(80,80,80)"}
            />
          )}
          <YAxis
            dataKey="term"
            type="category"
            stroke={isNight ? "#f1f1f1" : " rgb(80,80,80)"}
          />
          <Tooltip />
          {isTooltip && <Legend />}
          <Bar
            dataKey="value"
            stroke="hsl(215, 100%, 65%)"
            fill="url(#horizontalBar)"
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  ) : null;
};
