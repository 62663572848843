import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import lottie from "lottie-web";
import * as stateActions from "../../../redux/actions/state-actions";

// Components
import InputLabeled from "../../components/input-labeled";
import Button from "../../components/common/button";
import GetFilterIcon from "../../components/filter-icons";
import GetCategoryIcon from "../../components/category-icons";
import Select from "../../components/common/select";

// Utilities
import { toTitleCase, formatPhoneNumber } from "../../../utilites/format";
import { telemarketingUpdate } from "../../../api/telemarketing";
import { getCurrentDate } from "../../../utilites/date";
import { filterTimeList } from "../../../utilites/time";
import UseGetEmployeeAnalytics from "../../../utilites/hooks/employeeAnalytics";
import { dataEntryClaim } from "../../../api/data-entry";
import { setEmployeeAnalytics } from "../../../api/employee";

// Assets
import Arrow from "../../../assets/svg/back";
import Confetti from "../../../assets/lotti/confetti.json";

// Styles
import "./telemarketing.css";

export default ({ business, agentName, setUpdateLocation, page, idx }) => {
  const {
    _id,
    status,
    phone,
    name,
    address,
    city,
    state,
    zip,
    yelpUrl,
    dateAdded,
    managerName,
    email,
    contactPhone,
    callBackDate,
    callBackTime,
    notes,
    signedUp,
    lastContacted,
    lastSpokeAgent,
  } = business;
  const confettiLotti = useRef();
  const dispatch = useDispatch();
  const { accountType } = useSelector((store) => store.user.employee);
  const currentDate = getCurrentDate();
  const employeeAnalytics = UseGetEmployeeAnalytics();
  const [optionalSection, setOptionalSection] = useState(false);
  const [dateInput, setDateInput] = useState(callBackDate || "");
  const [timeInput, setTimeInput] = useState(callBackTime || "");
  const [managerNameInput, setManagerNameInput] = useState(managerName || "");
  const [emailInput, setEmailInput] = useState(email || "");
  const [contactPhoneInput, setContactPhoneInput] = useState(
    contactPhone || ""
  );
  const [notesInput, setNotesInput] = useState(notes || "");
  const { isSignedUp } = signedUp || {};
  const isClaimed = status ? status !== agentName : false;
  const claimedByAgment = status === agentName;
  const isAdmin = accountType === "admin" || accountType === "developer";
  const defaultParamas = {
    id: _id,
    name,
    managerName: managerNameInput,
    callBackDate: dateInput,
    callBackTime: timeInput,
    email: emailInput,
    contactPhone: contactPhoneInput,
    notes: notesInput,
    lastSpokeAgent: agentName,
    lastContacted: moment().format("lll"),
  };

  useEffect(() => {
    if (!claimedByAgment) setOptionalSection(false);
  }, [business]);

  useEffect(() => {
    if (isSignedUp) {
      lottie.loadAnimation({
        container: confettiLotti.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
        animationData: Confetti,
      });
    }
  }, [isSignedUp]);

  const handleChange = (evt, type, newValue) => {
    const { value } = evt.target;
    switch (type) {
      case "managerNameInput":
        return setManagerNameInput(value);
      case "emailInput":
        return setEmailInput(value);
      case "contactInput":
        const newPhoneValue = value
          .replace("-", "")
          .replace(".", "")
          .match(/^[0-9]*$/gi);
        if (newPhoneValue === null)
          return setContactPhoneInput(contactPhoneInput);
        return setContactPhoneInput(newPhoneValue[0].slice(0, 10));
      case "notesInput":
        return setNotesInput(value);
      case "dateInput":
        const isDayBefore = moment(value).isBefore(currentDate, "d");
        const isDayToday = moment(value).isSame(currentDate, "d");
        const newDate = isDayBefore || isDayToday ? "" : value;
        return setDateInput(newDate);
      case "timeInput":
        return setTimeInput(value);
    }
  };

  const handleClaim = async (status) => {
    dispatch(stateActions.setLoader(true));
    const params = {
      ...defaultParamas,
      status,
    };
    if (status && !isAdmin)
      setEmployeeAnalytics({ ...employeeAnalytics, claimed: true });
    const { location } = status
      ? await dataEntryClaim(params)
      : await telemarketingUpdate(params);
    setUpdateLocation(location);
    dispatch(stateActions.setLoader(false));
  };

  const handleSave = async () => {
    dispatch(stateActions.setLoader(true));
    const params = {
      ...defaultParamas,
    };
    setEmployeeAnalytics({ ...employeeAnalytics, save_notes: true });
    const { location } = await telemarketingUpdate(params);
    setUpdateLocation(location);
    dispatch(stateActions.setLoader(false));
  };

  const handleRemove = async () => {
    dispatch(stateActions.setLoader(true));
    const params = {
      ...defaultParamas,
      doNotContact: true,
    };
    const { location } = await telemarketingUpdate(params);
    setUpdateLocation(location);
    dispatch(stateActions.setLoader(false));
  };

  const handleSendInfo = async () => {
    if (!setEmailInput) return;
    dispatch(stateActions.setLoader(true));
    const params = {
      ...defaultParamas,
      status: "",
      moreInfo: {
        emailed: true,
        agent: agentName,
        timeStamp: moment().format("lll"),
      },
    };
    setEmployeeAnalytics({ ...employeeAnalytics, send_info: true });
    const { location } = await telemarketingUpdate(params);
    setUpdateLocation(location);
    dispatch(stateActions.setLoader(false));
  };

  const handleSignedUp = async () => {
    if (!setEmailInput && !managerNameInput) return;
    dispatch(stateActions.setLoader(true));
    const params = {
      ...defaultParamas,
      status: "",
      agent: agentName,
      timeStamp: moment().format("lll"),
      signedUp: {
        isSignedUp: true,
        agent: agentName,
        timeStamp: moment().format("lll"),
      },
    };
    setEmployeeAnalytics({ ...employeeAnalytics, signed_up: true });
    const { location } = await telemarketingUpdate(params);
    setUpdateLocation(location);
    dispatch(stateActions.setLoader(false));
  };

  const handleClearCallBack = async () => {
    dispatch(stateActions.setLoader(true));
    const { location } = await telemarketingUpdate({
      id: _id,
      callBackDate: "",
      callBackTime: "",
    });
    setUpdateLocation(location);
    dispatch(stateActions.setLoader(false));
  };

  if (isSignedUp)
    return (
      <div className="card card-form" key={_id}>
        <div className="marketing-card-wrapper">
          <div ref={confettiLotti} className="marketing-card-confetti" />
        </div>
      </div>
    );
  return (
    <div className="card card-form" key={_id}>
      <div className="marketing-card-wrapper">
        <div className="employee-card-wrapper">
          <div className="employee-left-container">
            <h3>{`${page * 50 + idx + 1}. ${toTitleCase({ input: name })}`}</h3>
            <p>{formatPhoneNumber({ phone })}</p>
            {address && <p>{toTitleCase({ input: address })}</p>}
            <p>{toTitleCase({ input: `${city} ${state}, ${zip}` })}</p>
          </div>

          <div className="employee-right-container">
            <div className="marketing-metaData-conatiner">
              {status && (
                <div className="marketing-status-info-wrapper">
                  {(claimedByAgment || isAdmin) && (
                    <div
                      className="marketing-callback-clear"
                      onClick={() => handleClaim("")}
                    >
                      {GetFilterIcon("close")}
                    </div>
                  )}
                  <p>Claimed By:</p>
                  <p>{status}</p>
                </div>
              )}
              {(callBackDate || callBackTime) && (
                <div className="marketing-callback-info-wrapper">
                  <div
                    className="marketing-callback-clear"
                    onClick={handleClearCallBack}
                  >
                    {GetFilterIcon("close")}
                  </div>
                  <p>Call Back:</p>
                  <p>{callBackDate}</p>
                  <p>{callBackTime}</p>
                </div>
              )}
            </div>

            <div className="marketing-title-button-container">
              <a
                className="marketing-yelp-button"
                href={yelpUrl}
                target="_blank"
              >
                Yelp
              </a>
              <Button
                styles={{ marginLeft: "20px" }}
                text="Remove"
                size="small"
                isPrimary={false}
                handleClick={handleRemove}
              />
              {!isClaimed && !claimedByAgment && (
                <Button
                  styles={{ marginLeft: "20px" }}
                  text="claim"
                  size="small"
                  handleClick={() => handleClaim(agentName)}
                />
              )}
            </div>
            <label className="label-form" style={{ marginTop: "20px" }}>
              Created Date:
            </label>
            <p>{dateAdded}</p>

            {!!lastSpokeAgent && (
              <>
                <label className="label-form" style={{ marginTop: "15px" }}>
                  Last Contacted:
                </label>
                <p>{`${lastSpokeAgent} on ${lastContacted}`}</p>
              </>
            )}
          </div>
        </div>

        <div className="card-info-form">
          <div
            className="pointer signup-toggle-split-container"
            onClick={() => setOptionalSection(!optionalSection)}
          >
            <label id="fNameInput" className="pointer label-form">
              Entry Section
            </label>
            <div
              className={`signup-toggle-subsection-svg-container ${
                optionalSection
                  ? "signup-toggle-subsection-svg-container-open"
                  : ""
              }`}
            >
              <Arrow />
            </div>
          </div>

          <div
            className={`card-form signup-toggle-subsection ${
              optionalSection ? "signup-toggle-subsection-open" : ""
            }`}
          >
            <label className="label-form">Call Back:</label>
            <div className="marketing-callback-wrapper">
              <div className="filter-date-container">
                <div className="filter-date-svg-container">
                  {GetFilterIcon("date")}
                </div>
                <input
                  className="filter-date-input filter-select-container"
                  type="date"
                  value={dateInput || currentDate}
                  onChange={(evt) => handleChange(evt, "dateInput")}
                  placeholder="yyyy-mm-dd"
                />
              </div>
              <Select
                name="Day"
                classNames="filter-select-container"
                options={["", ...filterTimeList(1)]}
                value={timeInput}
                handleChange={(evt) => handleChange(evt, "timeInput")}
              >
                {GetCategoryIcon("time")}
              </Select>
            </div>

            <InputLabeled
              name="managerNameInput"
              value={managerNameInput}
              label="Owner/Manager Name"
              handleChange={(evt) => handleChange(evt, "managerNameInput")}
            />

            <InputLabeled
              name="emailInput"
              value={emailInput}
              label="Email"
              type="email"
              handleChange={(evt) => handleChange(evt, "emailInput")}
            />

            <InputLabeled
              name="contactInput"
              value={contactPhoneInput}
              label="WE NEED A CONTACT PHONE NUMBER FOR MULTIPLE LOCATIONS (not the business phone number this will not be seen by users)"
              handleChange={(evt) => handleChange(evt, "contactInput")}
              isImportant
            />

            <label className="label-form">Notes:</label>
            <textarea
              className="input-style input-form"
              rows="5"
              maxLength="550"
              value={notesInput}
              onChange={(evt) => handleChange(evt, "notesInput")}
              spellCheck="true"
            />

            {claimedByAgment && (
              <div className="marketing-button-wrapper">
                <Button
                  text="Save Notes"
                  size="small"
                  handleClick={handleSave}
                />
                <Button
                  text="Send Info"
                  size="small"
                  handleClick={handleSendInfo}
                />
                <Button
                  text="Signed up"
                  size="small"
                  handleClick={handleSignedUp}
                />
              </div>
            )}
            <legend className="legend-form"></legend>
          </div>
        </div>
      </div>
    </div>
  );
};
