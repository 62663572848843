import React, { useRef, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import lottie from "lottie-web";
import { useSelector } from "react-redux";

// Assets
import Loader from "../../../assets/lotti/loader.json";

// Styles
import "./loader.css";

export default ({ zIndex = 9999 }) => {
  const loaderElement = useRef();
  const { loader } = useSelector((store) => store.state);

  useEffect(() => {
    lottie.loadAnimation({
      container: loaderElement.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
      animationData: Loader,
    });
  }, []);

  return (
    <CSSTransition in={loader} timeout={250} classNames="fade">
      <div className="loader-page-overlay" style={{ zIndex }}>
        <div ref={loaderElement} className="loader-container" />
      </div>
    </CSSTransition>
  );
};
