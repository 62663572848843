import { callAPI } from ".";
import { baseURL } from "./url";

const marketingURL = baseURL("/marketing");

export function getMarketingLocation(phone) {
  return callAPI(`${marketingURL}?phone=${phone}`, "GET");
}

export function findBusinesses(location, type) {
  return callAPI(
    `${marketingURL}/location?location=${location}&type=${type}`,
    "GET"
  );
}
