import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import * as stateActions from "../../../redux/actions/state-actions";
import * as userActions from "../../../redux/actions/user-actions";

// Components
import ViewLayout from "../../components/view-layout";
import ProfileEditDragDrop from "../../components/profile-edit-drag-drop";
import Button from "../../components/common/button";
import ListingCommon from "../../components/listing-common";
import Modal from "../../components/modal";
import ConfirmListing from "../../components/confirm-listing";

// Utilities
import useGetBusinessStatus from "../../../utilites/hooks/UseGetBusinessStatus";
import { deleteImage, storeImage } from "../../../api/image";
import { setBusinessFree } from "../../../api/business";
import { updateListing } from "../../../api/listing";
import { updateAdInAds } from "../../../utilites/update";
import { convertDateToDbDate } from "../../../utilites/date";
import { deleteListingData } from "../../../utilites/listing";
import { validateListing } from "../../../utilites/validate";
import UseGetEmployeeAnalytics from "../../../utilites/hooks/employeeAnalytics";
import { setEmployeeAnalytics } from "../../../api/employee";
import { formatAfter12Hours } from "../../../utilites/format";

// Styles
import "./edit-listing.css";

const EditListing = ({
  employee,
  user,
  match,
  setLoader,
  setAds,
  setMessage,
  setPaymentModal,
  updateUser,
  history,
}) => {
  const { fName, lName } = employee;
  const { _id: userId, ads, locationType } = user || {};
  const { id } = match.params || {};
  const ad = ads.find(({ _id }) => _id === id);
  const {
    _id: listingId,
    images: adImages,
    title,
    promoCode,
    categories: adCategories,
  } = ad || {};
  const employeeAnalytics = UseGetEmployeeAnalytics();
  const [isConfirm, setIsConfirm] = useState(false);
  const [commonData, setCommonData] = useState(null);
  const [commonImageData, setCommonImageData] = useState({});
  const [modalMessage, setModalMessage] = useState("");
  const [upgradeModalMessage, setUpgradeModalMessage] = useState("");
  const { accountType, uniqueListingIds } = useGetBusinessStatus();
  const agentName = `${fName} ${lName}`;
  const uniqueListingDate = uniqueListingIds[listingId];
  const {
    typeSelect,
    categories,
    titleInput,
    descriptionInput,
    featuresInput,
    days,
    checkInInput,
    checkInOnceInput,
    location,
    ticketInput,
    promoCodeInput,
    virtiualInput,
  } = commonData || {};
  const { images } = commonImageData || {};
  const newData = {
    ...ad,
    type: typeSelect,
    location,
    categories,
    title: titleInput,
    description: descriptionInput,
    features: featuresInput,
    images,
    days,
    checkInRequired: checkInInput,
    checkInOnce: checkInOnceInput,
    lastUpdated: convertDateToDbDate(),
    ticketLink: ticketInput,
    virtualLink: virtiualInput,
  };
  const adData = {
    ...newData,
    promoCode: promoCodeInput,
  };

  const handleAdDelete = async () => {
    setLoader(true);
    setModalMessage("");
    const [{ ads }] = await deleteListingData(ad);
    history.push(`/business/profile/${userId}`);
    if (ads) setAds(ads);
    setLoader(false);
    setMessage({ message: "Successful" });
  };

  const handleSubmit = () => {
    const isAddressRequired = locationType !== "Multiple Locations";
    const createListingAdditionalIds = isAddressRequired
      ? {
          locationInput:
            location && location.coordinates && location.coordinates.length,
        }
      : {};
    const hasRequiredInfo = validateListing(
      newData,
      createListingAdditionalIds,
      "edit-listing"
    );
    if (!hasRequiredInfo) return;

    setIsConfirm(true);
  };

  const handleSave = async () => {
    setLoader(true);
    const { cat1Img: adCat1Img, cat2Img: adCat2Img } = adCategories;
    const { cat1Img, cat2Img } = categories;

    if (adCat1Img !== cat1Img) {
      await deleteImage(adCat1Img);
      newData.categories.cat1Img = "";
    }
    if (!newData.categories.cat1Img && cat1Img) {
      const storedCat1Img = await storeImage(cat1Img, "listing");
      if (storedCat1Img) newData.categories.cat1Img = storedCat1Img;
    }

    if (adCat2Img !== cat2Img) {
      await deleteImage(adCat2Img);
      newData.categories.cat2Img = "";
    }
    if (!newData.categories.cat2Img && cat2Img) {
      const storedCat2Img = await storeImage(cat2Img, "listing");
      if (storedCat2Img) newData.categories.cat2Img = storedCat2Img;
    }

    if (promoCode !== promoCodeInput) {
      await deleteImage(promoCode);
      newData.promoCode = "";
    }
    if (!newData.promoCode && promoCodeInput) {
      const storedPromo = await storeImage(promoCodeInput, "promo");
      if (storedPromo) newData.promoCode = storedPromo;
    }

    if (accountType !== "unlimited" && !uniqueListingDate) {
      const listingData = { listingId, date: moment().format() };
      const { user } = await setBusinessFree(listingData);
      if (user) updateUser(user);
    }

    setEmployeeAnalytics({ ...employeeAnalytics, reviewed_listing: true });
    const { ad: updatedAd } = await updateListing({
      ...newData,
      days: {
        ...newData.days,
        hours: formatAfter12Hours(newData.days.hours),
      },
      reviewed: {
        isReviewed: true,
        agent: agentName,
        timeStamp: moment().format("lll"),
      },
    });
    if (updatedAd) {
      setTimeout(() => {
        setAds(updateAdInAds(ads, updatedAd));
        history.push(`/business/profile/${userId}`);
        setMessage({ message: "Successful" });
        setLoader(false);
      }, 1000);
    } else {
      setMessage({ type: "error", message: "Successful" });
      setLoader(false);
    }
  };

  const renderHeaderButtons = () => (
    <div className="profile-button-container">
      <Button
        size="small"
        text="View"
        handleClick={() => history.push(`/listing/${id}`)}
      />
      <Button
        size="small"
        text="Delete"
        isPrimary={false}
        handleClick={() => {
          const isPremium = accountType === "premium";
          const futureMonth = uniqueListingDate
            ? moment(uniqueListingDate).add(1, "M").format("LLL")
            : "";
          const message = uniqueListingDate
            ? isPremium
              ? `"${title}", is one of your unique listings. This listing will not be able to be replaced till ${futureMonth}. Are you sure you want to continue?`
              : `"${title}", is your free monthly listing. You will not be able to create a new free listing till ${futureMonth}. Are you sure you want to continue?`
            : `Are you sure you want to delete your "${title}" listing?`;
          setModalMessage(message);
        }}
      />
    </div>
  );

  return isConfirm ? (
    <ConfirmListing
      data={adData}
      user={user}
      setIsConfirm={setIsConfirm}
      handleSave={handleSave}
      isCreate={false}
    />
  ) : (
    <ViewLayout>
      <ProfileEditDragDrop
        input={ad}
        dbImages={adImages}
        data={newData.title ? adData : ad}
        isBusiness={false}
        HeaderButtons={renderHeaderButtons}
        handleCommonData={(data) => setCommonImageData(data)}
      >
        <>
          <ListingCommon
            user={user}
            ad={newData.title ? adData : ad}
            uniqueListingDate={uniqueListingDate}
            setUpgradeModalMessage={setUpgradeModalMessage}
            handleCommonData={(data) => setCommonData(data)}
          />
          <div id="consentInput" className="card card-form">
            <div className="listing-edit-submit-container">
              <Button
                className="submit-button-CreateListing"
                text="Cancel"
                isPrimary={false}
                handleClick={() => history.push(`/business/profile/${userId}`)}
              />
              <Button
                className="submit-button-CreateListing"
                text="Submit"
                handleClick={handleSubmit}
              />
            </div>
          </div>
          <Modal
            directModalMessage={modalMessage}
            modalAccept="Delete"
            modalCancel="Cancel"
            handleCancelClick={() => setModalMessage("")}
            handleAcceptClick={handleAdDelete}
          />
          <Modal
            directModalMessage={upgradeModalMessage}
            modalAccept="Upgrade"
            modalCancel="Cancel"
            handleCancelClick={() => setUpgradeModalMessage()}
            handleAcceptClick={() => {
              setUpgradeModalMessage();
              setPaymentModal(true);
            }}
          />
        </>
      </ProfileEditDragDrop>
    </ViewLayout>
  );
};

const mapStateToProps = (store) => ({
  isUser: store.user.isUser,
  user: store.user.user,
  employee: store.user.employee,
});

const mapDispatchToProps = (dispatch) => ({
  setLoader: (loaderState) => dispatch(stateActions.setLoader(loaderState)),
  setMessage: (dataObj) => dispatch(stateActions.setMessage(dataObj)),
  setAds: (adsArray) => dispatch(userActions.setAds(adsArray)),
  setPaymentModal: (boolean) => dispatch(stateActions.setPaymentModal(boolean)),
  updateUser: (userObj) => dispatch(userActions.updateUser(userObj)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EditListing));
