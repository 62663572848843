import React, { useState } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import * as stateActions from "../../../../redux/actions/state-actions";

// Components
import Modal from "../../../components/modal";
import InputLabeled from "../../../components/input-labeled";

// Utilities
import { validateEmail } from "../../../../utilites/validate";
import { reportBusiness } from "../../../../api/business";
import { formatDbDateToReadable } from "../../../../utilites/date";

// Assets
import Flag from "../../../../assets/svg/flag";

//Styles
import "../listing.css";

export default ({ data }) => {
  const { _id, businessId, lastUpdated } = data;
  const dispatch = useDispatch();
  const { user } = useSelector((store) => store.user);
  const { _id: userId, email: userEmail } = user || {};
  const [isReported, setIsReported] = useState(false);
  const [modalReportMessage, setModalReportMessage] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [descriptionInput, setDescriptionInput] = useState("");
  const isUsersAd = _id === userId || businessId === userId;

  const handleChange = (evt, type) => {
    const { value } = evt.target;
    switch (type) {
      case "emailInput":
        setEmailInput(value);
        break;
      case "descriptionInput":
        setDescriptionInput(value);
        break;
    }
  };

  const handleReported = () => {
    if (!isReported)
      setModalReportMessage(
        "Please enter the required information to report this listing."
      );
  };

  const handleModalReportAccept = async () => {
    const hasUserData = userId || validateEmail(emailInput);
    if (!hasUserData || descriptionInput.trim().length < 5)
      return dispatch(
        stateActions.setMessage({
          message: "Please check information",
          messageType: "error",
        })
      );
    setModalReportMessage("");
    dispatch(stateActions.setLoader(true));
    const data = {
      id: _id,
      businessId: businessId || _id,
      userId: userId || "",
      reportedByEmail: userEmail || emailInput || "",
      comment: descriptionInput,
      date: moment().format("LLL"),
    };
    const { reported } = await reportBusiness(data);
    if (reported) {
      setIsReported(true);
      dispatch(stateActions.setMessage({ message: reported }));
    }
    dispatch(stateActions.setLoader(false));
  };

  return (
    <>
      <div className="listing-sub-content-container listing-split-content">
        {!isUsersAd ? (
          <div className="listing-report-container" onClick={handleReported}>
            <div
              className={`listing-flagged-container ${
                isReported ? "listing-flagged-container-true" : ""
              }`}
            >
              <Flag />
            </div>
            <p className="listing-report-text">
              {isReported ? "Reported" : "Report"}
            </p>
          </div>
        ) : (
          <div />
        )}
        <p className="listing-business-hours">{`Last Updated: ${formatDbDateToReadable(
          { date: lastUpdated, isForUser: true }
        )}`}</p>
      </div>

      <Modal
        directModalMessage={modalReportMessage}
        modalAccept="Report"
        modalCancel="cancel"
        handleCancelClick={() => setModalReportMessage("")}
        handleAcceptClick={handleModalReportAccept}
      >
        <div style={{ width: "100%", paddingBottom: "10px" }}>
          {!userId && (
            <div className="card-form">
              <div className="card-info-form">
                <InputLabeled
                  name="emailInput"
                  value={emailInput}
                  label="Email"
                  type="email"
                  handleChange={(evt) => handleChange(evt, "emailInput")}
                />
              </div>
            </div>
          )}

          <div id="descriptionInput" className="card-form">
            <div className="card-info-form">
              <label className="label-form">Description:</label>
              <textarea
                className="input-style input-form"
                rows="4"
                maxLength="300"
                value={descriptionInput}
                onChange={(evt) => handleChange(evt, "descriptionInput")}
              />
              <legend className="legend-form">
                Describe why you are reporting
              </legend>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
