import React, { useState } from "react";

// Components
import Toggle from "../../components/common/toggle";
import TimeSelector from "../../components/common/time-selector";

// Utilities
import defaultData from "./data-weekdats-default";

export default ({
  openLabel = "Open",
  closeLabel = "Close",
  page,
  title,
  isCard = true,
  hours,
  setHours,
  adHours,
  isWhiteText = false,
  isTimesSame = false,
}) => {
  const [isFirstOpenChange, setIsFirstOpenChange] = useState(true);
  const [isFirstCloseChange, setIsFirstCloseChange] = useState(true);

  const handleTimeToggle = (evt, newValue, params) => {
    evt.preventDefault();
    const { day } = params;
    const newHours = {
      ...hours,
      [day]: {
        ...hours[day],
        isOpen: newValue,
      },
    };
    setHours(newHours);
  };

  const handleTimeChange = (evt, name, params) => {
    const { value } = evt.target;
    const isNew =
      page === "sign-up" ||
      page === "create-ad" ||
      page === "create-corporate" ||
      page === "create-tickets";
    const isOpen = name === "open";
    const isOpenFirst = isOpen && isFirstOpenChange ? true : false;
    const isCloseFirst = !isOpen && isFirstCloseChange ? true : false;
    const { day } = params || {};
    const currDay = hours[day];
    const { close } = currDay;
    const isStartGreaterThenEnd = isOpen && value >= close;
    const adjustedEndTime = isStartGreaterThenEnd ? { close: value + 50 } : {};
    const newHours = {
      ...hours,
      [day]: {
        ...currDay,
        ...adjustedEndTime,
        [name]: value,
      },
    };

    if (isTimesSame || (isNew && isOpenFirst) || (isNew && isCloseFirst)) {
      const setting = isOpen ? "open" : "close";
      isOpen ? setIsFirstOpenChange(false) : setIsFirstCloseChange(false);

      for (const key in newHours) {
        newHours[key][setting] = value;
      }

      if (isStartGreaterThenEnd) {
        for (const key in newHours) {
          newHours[key].close = value + 50;
        }
      }
    }
    setHours(newHours);
  };

  const renderDaySelector = () =>
    defaultData.daysOrder.map((day, idx) => {
      if (adHours && !adHours[day].isOpen) return null;
      const { isOpen, open, close } = hours[day];

      return (
        <div className="hour-selector-wrapper-SignUp" key={idx}>
          <div className="signup-toggle-container">
            <label className="signup-toggle-text-container">
              {day.slice(0, 3)}
            </label>
            <Toggle
              value={isOpen}
              handleToggle={handleTimeToggle}
              params={{ day }}
            />
          </div>
          {isOpen && (
            <div className="business-time-selector-container">
              <TimeSelector
                name="open"
                label={openLabel}
                startTime={0}
                value={open}
                handleChange={handleTimeChange}
                params={{ day }}
                isWhiteText={isWhiteText}
              />
              <TimeSelector
                name="close"
                label={closeLabel}
                startTime={open}
                value={close}
                handleChange={handleTimeChange}
                params={{ day }}
                isWhiteText={isWhiteText}
              />
            </div>
          )}
        </div>
      );
    });

  return isCard ? (
    <div className="card card-form">
      <div className="card-info-form">
        <label id="hours" className="label-form">
          {title}
        </label>
        {renderDaySelector()}
      </div>
    </div>
  ) : (
    <>
      {title && (
        <label id="hours" className="label-form">
          {title}
        </label>
      )}
      {renderDaySelector()}
    </>
  );
};
