import React from "react";

// Styles
import "./svg.css";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 26"
    >
      <path
        className="svg-clip-rule"
        d="M15,17.6c-1.3,0-2.3,0.6-2.3,1.3c0,0.6,0.7,1.1,1.7,1.2v0v0l0,0v0v0l0,0v0v0v0l0,0v0v0l0,0v0v0v0v0v0v0l0,0
		l0,0v0v0v0l0,0v0v0v0v0l0,0v0v0v0v0v0v0v0v0v0l0,0l0,0v0v0v0v0v0v0v0l0,0v0v0v0v0v0l0,0v0v0v0v0v0v0v0v0v0v0v0v0l0,0v0v0v0v0
		c0,0.6-0.2,1.1-0.5,1.4c-0.3,0.3-0.7,0.5-1.1,0.5c-0.4,0-0.8-0.2-1.1-0.5c-0.3-0.4-0.5-0.9-0.5-1.4c0-0.4-0.3-0.6-0.7-0.6
		c-0.4,0-0.6,0.3-0.6,0.7c0,0.9,0.3,1.7,0.8,2.2c0.5,0.6,1.2,1,2.1,1c0.8,0,1.5-0.4,2.1-1c0.1-0.1,0.1-0.2,0.2-0.2
		c0.1,0.1,0.1,0.2,0.2,0.2c0.5,0.6,1.2,1,2.1,1c0.8,0,1.5-0.4,2.1-1c0.5-0.6,0.8-1.4,0.8-2.2c0-0.4-0.3-0.7-0.6-0.7
		c-0.4,0-0.7,0.3-0.7,0.6c0,0.6-0.2,1.1-0.5,1.4c-0.3,0.3-0.7,0.5-1.1,0.5c-0.4,0-0.8-0.2-1.1-0.5c-0.3-0.4-0.5-0.9-0.5-1.4v0v0l0,0
		v0v0v0v0v0v0v0v0l0,0v0v0v0v0v0v0l0,0v0v0v0v0v0l0,0v0v0h0v0v0v0v0l0,0v0v0l0,0v0v0v0l0,0v0v0l0,0l0,0v0v0v0v0l0,0v0v0v0v0l0,0v0v0
		l0,0c1-0.2,1.7-0.6,1.7-1.2C17.3,18.1,16.3,17.6,15,17.6L15,17.6z M9.8,10.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6
		c1.5,0,2.6-1.2,2.6-2.6C12.4,11.9,11.2,10.7,9.8,10.7z M15,1.3c-1.7,0-3.3,0.4-4.7,1.2C9.8,1.9,8.5,1.1,8.1,0.8
		C7.4,0.4,7.2,0.5,6.7,1C5.2,2.2,3.9,3,2.2,3.8c0,0-1.7,0.4-1.8,1.7c-0.1,1,0.4,2.3,0.8,3.2c0.2,0.4,0.3,0.9,0.4,1.3
		c0.2,0.7,0,2.6,1.3,1.9c0.5-0.2,1.1-0.9,1.6-1.3c-0.2,0.9-0.3,1.9-0.3,2.9c0,6.1,4.4,12.1,10.9,12.1c6,0,10.9-5.4,10.9-12.1
		c0-1-0.1-1.9-0.3-2.9c0.4,0.4,1.1,1.1,1.6,1.3c1.3,0.7,1.1-1.2,1.3-1.9c0.1-0.4,0.3-0.8,0.4-1.3c0.4-0.9,0.8-2.2,0.8-3.2
		c-0.1-1.3-1.8-1.7-1.8-1.7C26.1,3,24.8,2.2,23.3,1c-0.5-0.4-0.7-0.6-1.4-0.2c-0.4,0.2-1.7,1.1-2.2,1.6C18.3,1.7,16.7,1.3,15,1.3
		L15,1.3z M19,13.3c0,0.6,0.5,1.1,1.1,1.1c0.5,0,0.8-0.3,1.1-0.8c-0.2-0.1-0.4-0.1-0.6-0.4c-0.3-0.3-0.2-0.6,0-1
		C19.8,12.1,19,12.5,19,13.3L19,13.3z M24.9,8.4c-0.9-2.2-2.4-4.1-4.4-5.5c0.5-0.4,1.3-1,1.8-1.3c0.1-0.1,0.2,0,0.3,0.1
		c1.4,1.2,3,2.2,4.7,2.9c0.1,0,1.2,0.4,1.3,0.9c0.1,1.2-0.9,3-1.2,4.1c-0.1,0.4-0.1,0.7-0.2,1.1c-0.7-0.5-1.3-1.2-1.9-1.9
		C25.3,8.8,25.1,8.6,24.9,8.4L24.9,8.4z M20.8,4.3c-0.2,0.2-0.4,0.4-0.6,0.6c-0.6,0.6-1.6,1.1-2.4,1.6c-0.8,0.5-1.3,1-1.5,2
		c-0.2,0.9-0.2,2.2,0,3.5c0.2,1.2,0.4,2.4,1.3,3.6c0.8,1.2,2.2,2.5,3.1,3.8c0.5,0.7,0.9,1.4,1.2,2c-1.8,1.9-4.2,3.1-6.9,3.1
		c-5.9,0-9.9-5.5-9.9-11.1c0-5.6,4-11.1,9.9-11.1C17.2,2.2,19.2,3,20.8,4.3L20.8,4.3z M20.1,11.3c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2
		c1.1,0,2-0.9,2-2C22.2,12.2,21.3,11.3,20.1,11.3z M8.7,13.3c0,0.6,0.5,1.1,1.1,1.1c0.5,0,0.8-0.3,1.1-0.8c-0.2-0.1-0.4-0.1-0.6-0.4
		c-0.2-0.3-0.2-0.6,0-1C9.5,12.1,8.7,12.5,8.7,13.3L8.7,13.3z M9.8,11.7c0.9,0,1.7,0.7,1.7,1.7c0,0.9-0.7,1.7-1.7,1.7
		c-0.9,0-1.7-0.7-1.7-1.7C8.1,12.4,8.9,11.7,9.8,11.7z"
      />
    </svg>
  );
};
