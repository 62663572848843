import React from "react";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 24"
    >
      <path
        d="M3.2,0.8h23.5l2.6,4.5h-5.2H18H12H5.9H0.7L3.2,0.8z M23.6,6.2v1.7c0,0.3,0,0.5-0.1,0.7c0,0,0,0,0,0.1
    c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0
    c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c-0.4,0.6-1,1-1.8,1.1c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0
    c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0
    C20,10.4,19.4,10,19,9.4c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0-0.1
    c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c-0.1-0.2-0.1-0.5-0.1-0.7V6.2
    H23.6z M17.6,6.2v1.7c0,0.3,0,0.5-0.1,0.7c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1
    c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c-0.4,0.6-1,1-1.8,1.1
    c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0
    c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0c-0.7-0.1-1.3-0.5-1.8-1.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1
    c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0
    c0,0,0,0,0-0.1c-0.1-0.2-0.1-0.5-0.1-0.7V6.2H17.6z M11.5,6.2v1.7c0,0.3,0,0.5-0.1,0.7c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1
    c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0
    c0,0,0,0,0,0.1c0,0,0,0,0,0c-0.4,0.6-1,1-1.8,1.1c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0
    c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0C7.9,10.4,7.3,10,6.9,9.4
    c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1
    c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1C6.4,8.4,6.4,8.2,6.4,7.9V6.2H11.5z M1.8,10.2
    c0,0-0.1,0-0.1-0.1C0.9,9.7,0.3,8.9,0.3,7.9V6.2h5.1v1.7c0,0.3,0,0.5-0.1,0.7c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1
    c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0
    c0,0,0,0,0,0.1c0,0,0,0,0,0c-0.4,0.6-1,1-1.8,1.1c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0
    c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.1,0-0.2,0-0.3-0.1
    c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0C1.9,10.3,1.9,10.3,1.8,10.2C1.8,10.2,1.8,10.2,1.8,10.2 M29.7,7.9c0,1-0.5,1.8-1.3,2.2
    c0,0-0.1,0-0.1,0.1c0,0,0,0,0,0c0,0-0.1,0-0.1,0.1c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.1,0-0.2,0.1-0.3,0.1c0,0,0,0,0,0
    c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0h0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0
    c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0c-0.7-0.1-1.3-0.5-1.8-1.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1
    c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0
    c0,0,0,0,0-0.1c-0.1-0.2-0.1-0.5-0.1-0.7V6.2h5.1V7.9z M15.5,19.7h9.2v-5.5h-9.2V19.7z M25.6,20.1c0,0.3-0.2,0.5-0.5,0.5H15
    c-0.3,0-0.5-0.2-0.5-0.5v-6.4c0-0.3,0.2-0.5,0.5-0.5h10.1c0.3,0,0.5,0.2,0.5,0.5V20.1z M28,11.3C28,11.3,28,11.3,28,11.3
    c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0
    c0,0,0,0-0.1,0c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
    c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.9-0.2-1.7-0.7-2.2-1.4c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0-0.1-0.1-0.1c0,0,0,0,0-0.1
    c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0c-0.5,0.7-1.3,1.2-2.2,1.4c0,0,0,0,0,0
    c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c0,0,0,0,0,0
    c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0
    c-0.9-0.2-1.7-0.7-2.2-1.4c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0-0.1-0.1-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.1
    c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0-0.1,0.1c0,0,0,0,0,0c-0.5,0.7-1.3,1.2-2.2,1.3c0,0,0,0,0,0c0,0-0.1,0-0.1,0
    c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0
    c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.9-0.2-1.7-0.7-2.2-1.4c0,0,0,0,0,0
    c0,0,0,0,0-0.1c0,0,0-0.1-0.1-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0.1
    c0,0,0,0,0,0c0,0,0,0-0.1,0.1c0,0,0,0,0,0c-0.5,0.7-1.3,1.2-2.2,1.3c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0
    c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0
    c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.9-0.2-1.7-0.7-2.2-1.4c0,0,0,0,0,0c0,0,0,0,0-0.1
    c0,0,0-0.1-0.1-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0
    c0,0,0,0-0.1,0.1c0,0,0,0,0,0c-0.5,0.7-1.3,1.2-2.2,1.3c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0
    c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0
    c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0
    v11.9h2.9v-9.5c0-0.3,0.2-0.5,0.5-0.5h6.1c0.3,0,0.5,0.2,0.5,0.5v9.5h16.3V11.3C28.1,11.3,28.1,11.3,28,11.3L28,11.3z M9.9,19.7
    c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5v-1.2C9,18.2,9.2,18,9.4,18c0.3,0,0.5,0.2,0.5,0.5V19.7z M5.7,23.2h5.1v-9H5.7V23.2
    z"
      />
    </svg>
  );
};
