import React from "react";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 30"
    >
      <path d="M1.1,1.1v8.1h8.1V1.1H1.1z M6.9,6.9H3.4V3.4h3.5V6.9z" />
      <path d="M1.1,20.8v8.1h8.1v-8.1H1.1z M6.9,26.6H3.4v-3.5h3.5V26.6z" />
      <path d="M20.8,1.1v8.1h8.1V1.1H20.8z M26.6,6.9h-3.5V3.4h3.5V6.9z" />
      <polygon points="26.6,11.5 26.6,18.5 20.8,18.5 20.8,20.8 28.9,20.8 28.9,11.5 " />
      <polygon points="20.8,23.1 20.8,28.9 23.1,28.9 23.1,25.4 26.6,25.4 26.6,28.9 28.9,28.9 28.9,23.1 " />
      <polygon points="11.5,1.1 11.5,3.4 16.2,3.4 16.2,9.2 18.5,9.2 18.5,1.1 " />
      <polygon
        points="16.2,11.5 16.2,16.2 11.5,16.2 11.5,23.1 16.2,23.1 16.2,28.9 18.5,28.9 18.5,20.8 13.8,20.8 13.8,18.5 18.5,18.5 
        18.5,13.8 20.8,13.8 20.8,16.2 23.1,16.2 23.1,11.5 "
      />
      <rect x="11.5" y="25.4" width="2.3" height="3.5" />
      <rect x="5.7" y="16.2" width="3.5" height="2.3" />
      <polygon points="11.5,5.7 11.5,11.5 1.1,11.5 1.1,18.5 3.4,18.5 3.4,13.8 13.8,13.8 13.8,5.7 " />
    </svg>
  );
};
