import React, { useEffect } from "react";

// Utilities
import Debounce from "../../../utilites/hooks/debounce";
import { getAutoComplete } from "../../../api/autocomplete";

// Styles
import "./autocomplete-items.css";

const AutocompleteItems = ({
  searchInput,
  locationInput,
  handleChange,
  handleClick,
  list,
  type,
}) => {
  const debouncedSearchInput = Debounce(searchInput, 350);
  const debouncedLocationInput = Debounce(locationInput, 300);

  useEffect(() => {
    if (debouncedLocationInput.length > 2) {
      (async () => {
        const { autoCompleteList } =
          (await getAutoComplete({
            location: debouncedLocationInput,
          })) || {};
        if (autoCompleteList) {
          handleChange(autoCompleteList, "location");
        }
      })();
    } else {
      handleChange([], "location");
    }
  }, [debouncedLocationInput]);

  useEffect(() => {
    if (debouncedSearchInput.length > 1) {
      (async () => {
        const { autoCompleteList } =
          (await getAutoComplete({
            search: debouncedSearchInput,
          })) || {};
        if (autoCompleteList) {
          handleChange(autoCompleteList, "search");
        }
      })();
    } else {
      handleChange([], "search");
    }
  }, [debouncedSearchInput]);

  return list.map((item, idx) => (
    <div
      className="autocomplete-text-container pointer"
      onClick={() => handleClick(item, type)}
      key={idx}
    >
      <p>{item}</p>
    </div>
  ));
};

export default AutocompleteItems;
