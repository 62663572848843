import React from "react";

export default props => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 16 16"
    >
      <path
        d="M5.1,6.6c-0.1,0-0.2,0-0.2-0.1C4.8,6.4,4.8,6.4,4.8,6.3c0-0.1,0-0.2,0.1-0.2C4.9,6,5,6,5.1,6c0.1,0,0.2,0,0.2,0.1
					c0.1,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2C5.2,6.6,5.2,6.6,5.1,6.6z"
      />
      <path
        d="M5.1,7.6c-0.1,0-0.2,0-0.2-0.1C4.8,7.4,4.8,7.3,4.8,7.2c0-0.1,0-0.2,0.1-0.2C4.9,7,5,6.9,5.1,6.9c0.1,0,0.2,0,0.2,0.1
					c0.1,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2C5.2,7.5,5.2,7.6,5.1,7.6z"
      />
      <path
        d="M5.1,8.5c-0.1,0-0.2,0-0.2-0.1C4.8,8.4,4.8,8.3,4.8,8.2c0-0.1,0-0.2,0.1-0.2C4.9,7.9,5,7.9,5.1,7.9c0.1,0,0.2,0,0.2,0.1
					C5.4,8,5.4,8.1,5.4,8.2s0,0.2-0.1,0.2C5.2,8.5,5.2,8.5,5.1,8.5z"
      />
      <path
        d="M5.1,9.5c-0.1,0-0.2,0-0.2-0.1C4.8,9.3,4.8,9.2,4.8,9.2c0-0.1,0-0.2,0.1-0.2C4.9,8.9,5,8.8,5.1,8.8c0.1,0,0.2,0,0.2,0.1
					C5.4,9,5.4,9.1,5.4,9.2c0,0.1,0,0.2-0.1,0.2C5.2,9.4,5.2,9.5,5.1,9.5z"
      />
      <path
        d="M5.9,5.6H4.3c-0.2,0-0.3-0.1-0.3-0.3v-1c0-0.4-0.1-0.8-0.3-1.1C3.5,2.9,3.5,2.6,3.5,2.4c0,0,0-0.1,0-0.1
					C3.5,1.4,4.2,0.8,5,0.8c0,0,0.1,0,0.1,0c0.9,0,1.6,0.7,1.6,1.6c0,0.3-0.1,0.5-0.2,0.8C6.3,3.5,6.2,3.8,6.2,4.2v1
					C6.2,5.4,6.1,5.6,5.9,5.6z M4.6,4.9h1V4.2c0-0.5,0.1-1,0.4-1.4C6,2.7,6.1,2.5,6.1,2.4c0-0.6-0.5-1-1-1c-0.5,0-0.9,0.4-0.9,0.9
					c0,0,0,0.1,0,0.1c0,0.2,0,0.3,0.1,0.5c0.2,0.4,0.4,0.9,0.4,1.4V4.9z"
      />
      <path d="M5.9,4.3H4.3C4.1,4.3,3.9,4.1,3.9,4s0.1-0.3,0.3-0.3h1.6c0.2,0,0.3,0.1,0.3,0.3C6.2,4.1,6.1,4.3,5.9,4.3z" />
      <path d="M7.2,14H4.3c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0.1-0.3,0.3-0.3h2.9c0.2,0,0.3,0.1,0.3,0.3C7.5,13.8,7.4,14,7.2,14z" />
      <path
        d="M9.8,15.2c-1,0-2-0.3-2.9-0.9c-0.1-0.1-0.2-0.2-0.1-0.3l0.7-2.8c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3l0,0
					c0-0.1,0-0.1-0.1-0.2L6.1,6.8c0-0.1,0-0.3,0.1-0.3C7.2,5.5,8.4,5,9.8,5c2.9,0,5.2,2.3,5.2,5.1c0,0.9-0.3,1.8-0.7,2.6
					c-0.1,0.2-0.3,0.2-0.4,0.1c-0.2-0.1-0.2-0.3-0.1-0.4c0.4-0.7,0.6-1.5,0.6-2.3c0-2.5-2-4.5-4.5-4.5c-1.1,0-2.2,0.4-3,1.1L8,10.2
					c0,0.1,0.1,0.2,0.1,0.3c0,0,0,0,0,0l0,0c0,0.1,0,0.3,0,0.5c0,0.2,0,0.3,0,0.5c0,0,0,0,0,0L7.4,14c0.7,0.4,1.5,0.6,2.3,0.6
					c0.5,0,1.1-0.1,1.6-0.3c0.2-0.1,0.4,0,0.4,0.2c0.1,0.2,0,0.3-0.2,0.4C11,15.1,10.4,15.2,9.8,15.2z M12.9,14.1
					c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.3,0-0.5l0,0c0.1-0.1,0.3-0.1,0.5,0c0.1,0.1,0.1,0.3,0,0.5l0,0C13,14.1,13,14.1,12.9,14.1z
					"
      />
      <path
        d="M6.9,15.2H5.2c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0.1-0.3,0.3-0.3h1.4l0.9-3.4c0-0.1,0-0.2,0-0.3s0-0.2,0-0.3l0,0
					c0-0.1,0-0.1-0.1-0.2L5.7,5.6H4.5l-1.7,4.8c0,0.1-0.1,0.1-0.1,0.2l0,0c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3l0.6,2.3
					c0,0.2-0.1,0.3-0.2,0.4c-0.2,0-0.4-0.1-0.4-0.2l-0.6-2.3c0,0,0,0,0,0c0-0.2,0-0.3,0-0.5c0-0.2,0-0.3,0-0.5l0,0c0,0,0,0,0,0
					c0-0.1,0-0.2,0.1-0.3l1.8-5C4,5,4.1,4.9,4.3,4.9h1.6C6,4.9,6.2,5,6.2,5.1l1.8,5c0,0.1,0.1,0.2,0.1,0.3c0,0,0,0,0,0l0,0
					c0,0.1,0,0.3,0,0.5c0,0.2,0,0.3,0,0.5c0,0,0,0,0,0L7.2,15C7.1,15.1,7,15.2,6.9,15.2z M3.6,15.2L3.6,15.2c-0.2,0-0.4-0.1-0.4-0.3
					c0-0.2,0.1-0.3,0.3-0.3h0c0.2,0,0.3,0.1,0.3,0.3C3.9,15.1,3.8,15.2,3.6,15.2z"
      />
      <path
        d="M11.1,8.8c-0.5,0-1-0.4-1-1s0.4-1,1-1c0.5,0,1,0.4,1,1S11.6,8.8,11.1,8.8z M11.1,7.6c-0.2,0-0.3,0.1-0.3,0.3
					c0,0.2,0.1,0.3,0.3,0.3c0.2,0,0.3-0.1,0.3-0.3C11.4,7.7,11.3,7.6,11.1,7.6z"
      />
      <path
        d="M10.8,11.4c-0.5,0-1-0.4-1-1c0-0.5,0.4-1,1-1c0.5,0,1,0.4,1,1C11.7,11,11.3,11.4,10.8,11.4z M10.8,10.1
					c-0.2,0-0.3,0.1-0.3,0.3c0,0.2,0.1,0.3,0.3,0.3c0.2,0,0.3-0.1,0.3-0.3C11.1,10.3,10.9,10.1,10.8,10.1z"
      />
      <path
        d="M12.7,10.4c-0.5,0-1-0.4-1-1s0.4-1,1-1c0.5,0,1,0.4,1,1S13.2,10.4,12.7,10.4z M12.7,9.2c-0.2,0-0.3,0.1-0.3,0.3
					s0.1,0.3,0.3,0.3c0.2,0,0.3-0.1,0.3-0.3S12.9,9.2,12.7,9.2z"
      />
      <path d="M15,15.2H1c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0.1-0.3,0.3-0.3h14c0.2,0,0.3,0.1,0.3,0.3C15.3,15.1,15.2,15.2,15,15.2z" />
    </svg>
  );
};
