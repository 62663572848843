import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as dataActions from "../../../../redux/actions/data-actions";

// components
import RecommendedSkeleton from "./recommended-skeleton";
import RecommendedCard from "./recommended-card";

// Utilities
import { isMobileAgent } from "../../../../utilites";
import { getLocation } from "../../../../utilites/location";
import { formatSearchQuery } from "../../../../utilites/format";
import { getCurrentDate, convertDateToDbDate } from "../../../../utilites/date";
import { getCurrentTime } from "../../../../utilites/time";
import { searchTopListings } from "../../../../api/search";

// Styles
import "./recommended.css";

const Recommended = ({
  location,
  geoLocation,
  filterDate,
  user,
  setFilter,
  history,
}) => {
  const [recommendations, setRecommendations] = useState(null);
  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  const isRecommendations = recommendations ? true : false;
  const isLargeScreen = width > 750;
  const isMobile = isMobileAgent();
  const currentDate = getCurrentDate();

  useEffect(() => {
    (async () => {
      const searchOption = await formatSearchQuery(
        getLocation(location, geoLocation, user),
        {
          subCat: "",
          search: "",
          date: currentDate,
          time: getCurrentTime(),
          distance: "50 Miles",
          sortBy: "Start Time",
          analyticsDate: convertDateToDbDate(currentDate),
        },
        "",
        {},
        { max: 25 }
      );
      const { results } = (await searchTopListings(searchOption)) || {};
      if (results) {
        setRecommendations(results);
      } else {
        setRecommendations([]);
      }
    })();
  }, []);

  const renderSkeletonUi = () => {
    const skeletonEl = [];
    const padding = width * (isLargeScreen ? 0.2 : 0.1);
    const adjustedWidth = width - padding;
    const numberOfSkeletonEl = !isMobile
      ? 12
      : isLargeScreen
      ? ((adjustedWidth * 2) / 210).toFixed()
      : (adjustedWidth / 210).toFixed();

    for (let i = 0; i < numberOfSkeletonEl; i++) {
      skeletonEl.push(<RecommendedSkeleton key={i} />);
    }
    return skeletonEl;
  };

  return (
    <div
      className="recommended-wrapper no-scroll-bars"
      style={{
        flexWrap: isLargeScreen ? "wrap" : "nowrap",
        maxHeight: isMobile && !isLargeScreen ? "100%" : "calc(65vh - 180px)",
        justifyContent: isLargeScreen ? "center" : "flex-start",
      }}
    >
      {isRecommendations ? (
        <>
          {recommendations.map((recommendation, idx) => {
            const { _id, images, title, businessName } = recommendation;
            return (
              <RecommendedCard
                ad={recommendation}
                filterDate={filterDate}
                images={images}
                title={title}
                businessName={businessName}
                handleClick={() => history.push(`/listing/${_id}`)}
                key={idx}
              />
            );
          })}
          {!!recommendations.length && (
            <div
              className="pointer recommended-card-container recommended-see-more"
              style={{ marginBottom: isMobile ? 0 : "15px" }}
              onClick={() => {
                setFilter({ type: "Events" });
                history.push("/top");
              }}
            >
              <p className="recommended-see-more-text">TOP 40</p>
            </div>
          )}
        </>
      ) : (
        renderSkeletonUi()
      )}
    </div>
  );
};

const mapStateToProps = (store) => ({
  user: store.user.user,
  filterDate: store.data.filters.date,
  location: store.user.location,
  geoLocation: store.user.geoLocation,
});

const mapDispatchToProps = (dispatch) => ({
  setFilter: (filterObj) => dispatch(dataActions.setFilter(filterObj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Recommended);
