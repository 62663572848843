import React from "react";
import LazyLoad from "react-lazyload";

// Utilities
import { isMobileAgent } from "../../../../utilites";
import { charLimitTo, toTitleCase } from "../../../../utilites/format";
import { getAdStatus } from "../../../../utilites/status";

// Assets
import Skyline from "../../../../assets/svg/skyline";

export default ({
  ad,
  filterDate,
  images,
  title,
  businessName,
  handleClick,
}) => {
  const isMobile = isMobileAgent();
  const { status } = getAdStatus(ad, filterDate);
  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  const isLargeScreen = width > 750;

  return (
    <LazyLoad height={120}>
      <div
        className={`pointer recommended-card-container result-card-${status}`}
        style={{ marginBottom: isMobile && !isLargeScreen ? 0 : "15px" }}
        onClick={handleClick}
      >
        <div className={`result-card-overlay result-card-overlay-gradient`} />
        <div
          className={`result-card-overlay result-card-status-overlay result-card-overlay-${status}`}
        />
        {!!images.length && images[0] ? (
          <img
            className="recommended-card-image"
            src={images[0]}
            alt={title}
            effect="blur"
          />
        ) : (
          <div className="recommended-card-skyline">
            <Skyline />
          </div>
        )}
        <h3 className="recommended-card-title">
          {toTitleCase({
            input: charLimitTo(title, 30),
            lowerCaseRest: false,
          })}
        </h3>
        <div className="recommended-card-name">
          <p>{charLimitTo(businessName, 26)}</p>
        </div>
      </div>
    </LazyLoad>
  );
};
