import React from "react";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 42 50"
    >
      <g transform="translate(0,-952.36218)">
        <linearGradient
          id="covid_1_"
          gradientUnits="userSpaceOnUse"
          x1="1.4107"
          y1="977.3622"
          x2="40.5893"
          y2="977.3622"
        >
          <stop offset="0.196" style={{ stopColor: "#E0D400" }} />
          <stop offset="0.4959" style={{ stopColor: "#AA9900" }} />
          <stop offset="0.6092" style={{ stopColor: "#C5B700" }} />
          <stop offset="0.7152" style={{ stopColor: "#D9CC00" }} />
          <stop offset="0.7846" style={{ stopColor: "#E0D400" }} />
        </linearGradient>
        <path
          className="covid0"
          d="M20.8,953.2c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.2,0.1-0.3,0.2c-1.8,1.6-4.9,3.7-8.2,5.4c-3.3,1.7-6.7,2.9-9.1,2.9
        c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.1-0.1,0.2-0.1,0.4c-2.1,17.2,5.1,28.7,18.6,38.4
        c0.2,0.1,0.4,0.2,0.6,0.2s0.5-0.1,0.6-0.2c13.5-9.8,20.7-21.2,18.6-38.4c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.1-0.1-0.2-0.2-0.3
        c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1c-2.4,0-5.8-1.2-9.1-2.9c-3.3-1.7-6.3-3.8-8.2-5.4c-0.1-0.1-0.3-0.2-0.4-0.2
        c-0.2,0-0.3-0.1-0.5,0C20.9,953.2,20.9,953.2,20.8,953.2L20.8,953.2z"
        />
      </g>
      <text
        className="covid1 covid3"
        transform="matrix(0.854 0 0 1 3.8678 22.6782)"
      >
        COVID
      </text>
    </svg>
  );
};
