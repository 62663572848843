import React from "react";

export default () => (
  <svg
    width="100%"
    height="100%"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
  >
    <path d="M84.3,36.7c-1.2-17.9-16.1-32-34.3-32s-33.1,14.2-34.3,32H84.3z" />
    <polygon points="10.9,46.9 89.1,46.9 89.1,41.4 86.7,41.4 13.3,41.4 10.9,41.4" />
    <rect x="10.9" y="79.7" width="78.1" height="5.5" />
    <polygon points="4.7,95.3 95.3,95.3 95.3,89.8 91.4,89.8 8.6,89.8 4.7,89.8" />
    <rect x="18.1" y="49.2" width="8.9" height="28.1" />
    <rect x="36.4" y="49.2" width="8.9" height="28.1" />
    <rect x="54.7" y="49.2" width="8.9" height="28.1" />
    <rect x="73" y="49.2" width="8.9" height="28.1" />
  </svg>
);
