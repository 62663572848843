import React from "react";

// Assets
import Food from "../../../assets/svg/food";
import Drinks from "../../../assets/svg/drinks";
import Activities from "../../../assets/svg/activities";
import Events from "../../../assets/svg/event";
import FoodFilled from "../../../assets/svg/food-filled";
import DrinksFilled from "../../../assets/svg/drinks-filled";
import ActivitiesFilled from "../../../assets/svg/activities-filled";
import EventsFilled from "../../../assets/svg/event-filled";

export default function GetCategoryIcon(name, filled = false) {
  if (!filled) {
    switch (name) {
      case "Food":
        return <Food />;
      case "Drinks":
        return <Drinks />;
      case "Activities":
        return <Activities />;
      case "Events":
        return <Events />;
    }
  } else {
    switch (name) {
      case "Food":
        return <FoodFilled />;
      case "Drinks":
        return <DrinksFilled />;
      case "Activities":
        return <ActivitiesFilled />;
      case "Events":
        return <EventsFilled />;
    }
  }
}
