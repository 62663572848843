import { combineReducers } from "redux";

// Reducers
import stateReducer from "./state-reducer";
import dataReducer from "./data-reducer";
import userReducer from "./user-reducer";

const combinedReducers = combineReducers({
  state: stateReducer,
  data: dataReducer,
  user: userReducer
});

export default combinedReducers;
