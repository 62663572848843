import React from "react";

export default props => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 24 30"
    >
      <path
        d="M7.3,29.4H1c-0.4,0-0.7-0.3-0.7-0.7v-6.3c0-0.4,0.3-0.7,0.7-0.7h6.3c0.4,0,0.7,0.3,0.7,0.7v2.5h3.7c0-2.6,0-5.9,0-9.2H7.9
		v2.5c0,0.4-0.3,0.7-0.7,0.7H1c-0.4,0-0.7-0.3-0.7-0.7v-6.3c0-0.4,0.3-0.7,0.7-0.7h6.3c0.4,0,0.7,0.3,0.7,0.7v2.5h3.6
		c0-3.4,0-6.7,0-9.2H7.9v2.5c0,0.4-0.3,0.7-0.7,0.7H1c-0.4,0-0.7-0.3-0.7-0.7V1.3c0-0.4,0.3-0.7,0.7-0.7h6.3c0.4,0,0.7,0.3,0.7,0.7
		v2.5h4.3c0.4,0,0.7,0.3,0.7,0.7v9.9h3.1v-2.5c0-0.4,0.3-0.7,0.7-0.7H23c0.4,0,0.7,0.3,0.7,0.7v6.3c0,0.4-0.3,0.7-0.7,0.7h-6.3
		c-0.4,0-0.7-0.3-0.7-0.7v-2.5h-3.1v9.9c0,0.4-0.3,0.7-0.7,0.7l-4.3,0v2.5C7.9,29.1,7.6,29.4,7.3,29.4z"
      />
    </svg>
  );
};
