import React from "react";

// Styles
import "./skeleton-card.css";

const SkeletonCard = () => {
  return <div className="skeleton-card-container" />;
};

export default SkeletonCard;
