import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { useDispatch } from "react-redux";
import * as stateActions from "../../../../../redux/actions/state-actions";
import * as userActions from "../../../../../redux/actions/user-actions";

// Components
import ListingSkeleton from "../../../listing/listing-skeleton";

// Utilities
import { businessGet } from "../../../../../api/admin";

export default (WrappedComponent) => {
  const Component = (props) => {
    const { match } = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const [isFetching, setIsFetching] = useState(true);
    const { id: currBusinessId } = match.params || {};

    useEffect(() => {
      getData();
    }, [currBusinessId]);

    const getData = async () => {
      setIsFetching(true);
      const { user } = (await businessGet(currBusinessId)) || {};
      if (!user) {
        dispatch(
          stateActions.setMessage({
            message: "Not Found",
            messageType: "error",
          })
        );
        return history.push("/");
      }

      dispatch(userActions.setUser({ isUser: false, user }));
      setIsFetching(false);
    };

    return (
      <>
        {isFetching ? (
          <ListingSkeleton />
        ) : (
          <CSSTransition in={!isFetching} timeout={250} classNames="fade">
            <WrappedComponent {...props} />
          </CSSTransition>
        )}
      </>
    );
  };
  return Component;
};
