export const setResults = (resultsObj) => ({
  type: "setResults",
  payload: resultsObj,
});

export const addResults = (resultsObj) => ({
  type: "addResults",
  payload: resultsObj,
});

export const updateResultInResults = (result) => ({
  type: "updateResultInResults",
  payload: result,
});

export const setAutoComplete = (autoCompleteList) => ({
  type: "setAutoComplete",
  payload: autoCompleteList,
});

export const setFilter = (filterObj) => ({
  type: "setFilter",
  payload: filterObj,
});

export const resetFilters = () => ({
  type: "resetFilters",
});
