import moment from "moment";
import {
  militaryTimeToReadable,
  miilitaryTimeDifference,
  convertToMinutes,
} from "./time";
import { getDateStatus, getPreviousDate, convertDateToDbDate } from "./date";

export const getAdStatus = (ad) => {
  const day = moment(new Date()).format("dddd");
  const localTime = Number(moment().format("HHmm"));
  const { days } = ad || {};
  const { dateAvailable, hours } = days || {};
  const today = day in hours ? hours[day] : {};
  const { adjustedTime } = today;
  const todaysHours = adjustedTime && localTime < 200 ? adjustedTime : today;
  const { isOpen = false, open, close } = todaysHours || {};
  const isDateCurrent = dateAvailable
    ? dateAvailable === convertDateToDbDate() ||
      (localTime < 200 &&
        dateAvailable === convertDateToDbDate(getPreviousDate()))
    : isOpen;

  const adData = {
    isDateCurrent,
    open,
    close,
    status: "starting",
    hasStarted: false,
    timeText: "Starting At",
    displayTime: militaryTimeToReadable(open),
  };
  if (!isDateCurrent) return adData;

  const configuredStartTime = open % 100 ? open - 20 : open;
  const configuredEndTime = close % 100 ? close - 20 : close;
  const hasEnded = localTime >= configuredEndTime;
  const isEnding =
    configuredEndTime > localTime && configuredEndTime - localTime <= 100;
  const hasStarted = localTime >= configuredStartTime;

  if (hasEnded) {
    return {
      isDateCurrent,
      open,
      close,
      status: "ended",
      hasStarted: false,
      timeText: "Ended At",
      displayTime: militaryTimeToReadable(close),
    };
  } else if (isEnding) {
    return {
      isDateCurrent,
      open,
      close,
      status: "ending",
      hasStarted: true,
      timeText: "Ending In",
      displayTime: miilitaryTimeDifference(localTime, close),
    };
  } else if (hasStarted) {
    return {
      isDateCurrent,
      open,
      close,
      status: "active",
      hasStarted: true,
      timeText: "Ending At",
      displayTime: militaryTimeToReadable(close),
    };
  } else {
    return adData;
  }
};

export const getBusinessStatus = (business) => {
  const { hours } = business || {};
  if (!hours) return {};
  const day = moment(new Date()).format("dddd");
  const localTime = Number(moment().format("HHmm"));
  const today = day in hours ? hours[day] : {};
  const { adjustedTime } = today;
  const todaysHours = adjustedTime && localTime < 200 ? adjustedTime : today;
  const { isOpen = false, open, close } = todaysHours || {};
  const isDateCurrent = isOpen;

  const data = {
    isDateCurrent,
    status: "starting",
    hasStarted: false,
    timeText: "Opens At",
    displayTime: militaryTimeToReadable(open),
  };
  if (!isDateCurrent) return data;

  const configuredStartTime = open % 100 ? open - 20 : open;
  const configuredEndTime = close % 100 ? close - 20 : close;
  const hasEnded = localTime >= configuredEndTime;
  const isEnding = configuredEndTime - localTime <= 100;
  const hasStarted = localTime >= configuredStartTime;

  if (hasEnded) {
    return {
      isDateCurrent,
      status: "ended",
      hasStarted: false,
      timeText: "Closed At",
      displayTime: militaryTimeToReadable(close),
    };
  } else if (isEnding) {
    return {
      isDateCurrent,
      status: "ending",
      hasStarted: true,
      timeText: "Closing In",
      displayTime: miilitaryTimeDifference(localTime, close),
    };
  } else if (hasStarted) {
    return {
      isDateCurrent,
      status: "active",
      hasStarted: true,
      timeText: "Closing At",
      displayTime: militaryTimeToReadable(close),
    };
  } else {
    return data;
  }
};

export function listingTimeDetails({
  isDateCurrent,
  startTime,
  endTime,
  adStatus,
}) {
  const localTime = moment().format("HHmm");
  const timeLeft = miilitaryTimeDifference(localTime, endTime);

  if (!isDateCurrent || timeLeft.indexOf("-") >= 0) {
    return { remaingWidth: 0, timeLeft: false };
  } else if (adStatus === "starting") {
    const timeTillStart = miilitaryTimeDifference(localTime, startTime);
    return { remaingWidth: 100, timeLeft: `Starting in: ${timeTillStart}` };
  } else {
    const formatedStartTime = convertToMinutes(
      startTime % 100 !== 0 ? startTime - 20 : startTime
    );
    const formatedEndTime = convertToMinutes(
      endTime % 100 !== 0 ? endTime - 20 : endTime
    );
    const totalMin = formatedEndTime - formatedStartTime;
    const minLeft = formatedEndTime - convertToMinutes(localTime);
    const remaingWidth = Math.floor((minLeft / totalMin) * 100);
    return { remaingWidth, timeLeft };
  }
}

export function checkinStatus(ad, userId) {
  const { date, days, checkInRequired, checkInOnce, checkedIn } = ad || {};
  const userHasCheckedIn = checkInRequired && userId in checkedIn;
  if (checkInOnce && userHasCheckedIn) {
    return {
      showCheckIn: true,
      checkInStatus: checkedIn[userId][0],
    };
  } else if (userHasCheckedIn) {
    const todaysDate = moment().format();
    const userCheckIns = checkedIn[userId];
    const currentCheckIns = userCheckIns.filter((checkIn) =>
      moment(todaysDate).isSame(checkIn, "day")
    );
    return {
      showCheckIn: getDateStatus({ date, days }),
      checkInStatus: currentCheckIns.length ? currentCheckIns[0] : false,
    };
  } else if (checkInRequired) {
    return {
      showCheckIn: getDateStatus({ date, days }),
      checkInStatus: false,
    };
  } else {
    return {
      showCheckIn: false,
      checkInStatus: false,
    };
  }
}
