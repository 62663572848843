import React from "react";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 22 30"
    >
      <path
        d="M6.6,13.1c0,2.4,2,4.4,4.4,4.4c2.4,0,4.4-2,4.4-4.4c0-2.3-1.7-4.2-4-4.4c0-0.5-0.1-1.3-0.3-2.2c8.7-1.3,6.5-5.4,6.5-5.4
	c-5.1-1.4-6.6,2-7,4.1c-0.5-1-1.3-2.1-2.6-3.1C7.7,1.9,7.3,2,7.1,2.3C6.8,2.6,6.9,3,7.2,3.2c2.6,1.9,2.9,4.5,2.9,5.6
	C8.1,9.3,6.6,11,6.6,13.1z"
      />
      <path
        d="M17.6,9.1c-0.7,0-1.4,0.2-1.9,0.6c0.7,1,1.1,2.2,1.1,3.5c0,1.1-0.3,2.1-0.8,2.9c0.5,0.2,1,0.4,1.6,0.4c2,0,3.7-1.6,3.7-3.7
	S19.6,9.1,17.6,9.1z"
      />
      <path
        d="M17.6,17.8c-0.8,0-1.5-0.2-2.2-0.5l-0.3-0.1c-1.1,1.1-2.5,1.7-4.1,1.7c-0.2,0-0.3,0-0.5,0c-0.2,0.5-0.3,1-0.3,1.6
	c0,2.4,2,4.4,4.4,4.4s4.4-2,4.4-4.4c0-1-0.3-1.9-0.9-2.7C17.9,17.8,17.8,17.8,17.6,17.8z"
      />
      <path
        d="M5.6,15.2c-0.3-0.7-0.4-1.4-0.4-2.1c0-1.4,0.5-2.6,1.3-3.6C6,9.2,5.4,9.1,4.8,9.1c-2.2,0-4,1.8-4,4c0,1.6,0.9,3,2.3,3.6
	C3.7,16,4.6,15.5,5.6,15.2z"
      />
      <path
        d="M7,25.8c-0.1,0-0.3,0-0.4,0c0.1,1.9,1.7,3.4,3.6,3.4c1.8,0,3.3-1.3,3.6-3.1c-1.3-0.2-2.4-0.8-3.3-1.6
	C9.5,25.4,8.3,25.8,7,25.8z"
      />
      <path
        d="M9.7,23.4c-0.5-0.9-0.8-1.9-0.8-3c0-0.6,0.1-1.2,0.3-1.9C8,18.2,7,17.5,6.3,16.5c-1.9,0.3-3.4,2-3.4,4c0,2.2,1.8,4,4,4
	C8,24.5,9,24.1,9.7,23.4z"
      />
    </svg>
  );
};
