import React, { useState, useEffect } from "react";
import { Analytics } from "aws-amplify";
import { connect } from "react-redux";
import moment from "moment";
import * as dataActions from "../../../redux/actions/data-actions";

// Components
import Select from "../common/select";
import GetFilterIcon from "../../components/filter-icons";
import getFeaturesIcon from "../features-input/getFeatureIcon";
import GetCategoryIcon from "../../components/category-icons";

// Utilities
import { toTitleCase } from "../../../utilites/format";
import { getCurrentDate } from "../../../utilites/date";
import { getCurrentTime, filterTimeList } from "../../../utilites/time";
import {
  searchTypes,
  subCatOptions,
  features,
  getFilterList,
} from "../../../constants/data-models";
import getAnalytics from "../../../utilites/hooks/analytics";
import Debounce from "../../../utilites/hooks/debounce";

// Assets
import Logo from "../../../assets/svg/event-hound-icon";

// Styles
import "./header.css";

const Filters = ({
  path,
  filters,
  setFilter,
  isDefaultFilters,
  resetFilters,
}) => {
  const { type, subCat, feature, date, time, distance, sortBy, limit } =
    filters || {};
  const [hasMounted, setHasMounted] = useState(false);
  const [dateInput, setDateInput] = useState(date);
  const debouncedDate = Debounce(dateInput, 400);
  const isMap = path.indexOf("map") > -1;
  const isTop = path.indexOf("top") > -1;
  const mainCategory = toTitleCase({ input: path.split("/")[1] });
  const subCatList = subCatOptions[mainCategory];
  const currentDate = getCurrentDate();
  const currentTime = getCurrentTime();
  const userAnalytics = getAnalytics();

  useEffect(() => {
    if (hasMounted) document.getElementById("filter-wrapper").scrollLeft = 0;
  }, [path]);

  useEffect(() => {
    if (hasMounted) {
      const isTimeBefore = time
        ? moment(moment(time, "h:mma")).isBefore(moment(currentTime, "h:mma"))
        : false;
      const newTime = !debouncedDate && isTimeBefore ? "" : time;
      setFilter({ date: debouncedDate, time: newTime });
    }
    setHasMounted(true);
  }, [debouncedDate]);

  const handleChange = (evt, type, name) => {
    const { value } = evt.target;
    switch (type) {
      case "select":
        setFilter({ [name]: value });
        if (userAnalytics)
          Analytics.record({
            name: "Filter",
            attributes: { path, subCat: value, location: userAnalytics },
          });
        break;
      case "dateFilterInput":
        const isDayBefore = moment(value).isBefore(currentDate, "d");
        const isDayToday = moment(value).isSame(currentDate, "d");
        const newDate = isDayBefore || isDayToday ? "" : value;
        setDateInput(newDate);
        break;
    }
  };

  const handleResetFilters = () => {
    setDateInput("");
    resetFilters();
  };

  const createFilters = () => {
    const values = [time || currentTime, distance, sortBy, limit];
    const filterList = getFilterList(values, filterTimeList(date), type);
    const isBusinessSearch = type === "Business";
    if (isTop || isBusinessSearch) delete filterList["Sort By"];
    if (isMap) {
      delete filterList.Distance;
      delete filterList["Sort By"];
    }
    if (!isMap) {
      delete filterList.Limit;
    }
    return Object.values(filterList).map(({ name, options, value }, idx) => (
      <Select
        classNames="filter-select-container"
        options={options}
        value={value}
        handleChange={(evt) => handleChange(evt, "select", name)}
        key={idx}
      >
        {GetFilterIcon(name)}
      </Select>
    ));
  };

  return (
    <div
      id="mobile-header-filters"
      className="header-el-wrapper header-el-shadow"
    >
      <div className="filter-outer-wrapper-MobileHeader">
        <div
          id="filter-wrapper"
          className="no-scroll-bars filter-inner-wrapper-MobileHeader"
        >
          {!isDefaultFilters && (
            <div
              className="filter-reset-container"
              onClick={handleResetFilters}
            >
              {GetFilterIcon("refresh")}
              <p className="filter-reset-text">Reset</p>
            </div>
          )}

          <Select
            classNames="filter-select-container"
            options={["", ...searchTypes]}
            value={type}
            handleChange={(evt) => handleChange(evt, "select", "type")}
            styles={{ paddingLeft: "8px" }}
            name={"Types"}
          >
            <Logo />
          </Select>

          {subCatList && (
            <Select
              classNames="filter-select-container"
              options={["", ...subCatList]}
              value={subCat}
              handleChange={(evt) => handleChange(evt, "select", "subCat")}
              name={mainCategory}
            >
              {GetCategoryIcon(mainCategory)}
            </Select>
          )}

          <Select
            classNames="filter-select-container"
            options={["", ...features]}
            value={feature}
            handleChange={(evt) => handleChange(evt, "select", "feature")}
            styles={{ paddingLeft: "8px" }}
            name={"Features"}
          >
            {!feature ? GetFilterIcon("features") : getFeaturesIcon(feature)}
          </Select>

          {createFilters()}

          <div className="filter-spacer" />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (store) => ({
  filters: store.data.filters,
  isDefaultFilters: store.data.isDefaultFilters,
});

const mapDispatchToProps = (dispatch) => ({
  setFilter: (filterObj) => dispatch(dataActions.setFilter(filterObj)),
  resetFilters: () => dispatch(dataActions.resetFilters()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
