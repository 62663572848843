import { callAPI } from "./";
import { baseURL } from "./url";

const analyticsURL = baseURL("/analytics");

export function analyticsGet(id) {
  const url = `${analyticsURL}?id=${id}`;
  return callAPI(url, "GET");
}

export function searchAnalytics(data) {
  const url = `${analyticsURL}/search`;
  return callAPI(url, "POST", data);
}

export function communtiyAnalytics(county) {
  const url = `${analyticsURL}/community?county=${county}`;
  return callAPI(url, "GET");
}
