import React from "react";
import { useSelector, useDispatch } from "react-redux";
import * as stateActions from "../../../../redux/actions/state-actions";

// Components
import BottomDrawer from "../../bottom-drawer";

// Styles
import "./promo-drawer.css";

export default () => {
  const dispatch = useDispatch();
  const { promoCode } = useSelector((store) => store.state);

  const handleCancel = () => {
    setTimeout(() => dispatch(stateActions.setPromoCode(null)), 200);
  };

  return (
    <BottomDrawer
      title="Promo Code"
      isOpen={!!promoCode}
      handleCancelClick={handleCancel}
    >
      <div className="promo-drawer-container">
        <img className="promo-drawer-image-container" src={promoCode} />
      </div>
    </BottomDrawer>
  );
};
