import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as dataActions from "../../../../redux/actions/data-actions";

// Components
import VerticalScroll from "../../../components/vertical-scroll";
import GetCategoryIcon from "../../../components/category-icons";
import getFeaturesIcon from "../../../components/features-input/getFeatureIcon";

// Utilites
import { charLimitTo, toTitleCase } from "../../../../utilites/format";

// Styles
import "../listing.css";

export default ({
  adTitle,
  name,
  categories,
  features = {},
  adStatus,
  isClickable = true,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { cat1, cat1Sub, cat2, cat2Sub } = categories || {};
  const formattedAdTitle = adTitle
    ? `${toTitleCase({ input: adTitle })} at `
    : "";
  const title = charLimitTo(
    `Features of: ${formattedAdTitle}${toTitleCase({ input: name })}`,
    50
  );

  const handleClick = (icon, text) => {
    if (!isClickable) return;
    const cat = icon === text ? "" : icon;
    const filters = cat ? { subCat: text } : { feature: text };
    const path = cat ? `/${cat.toLowerCase()}` : "/top";

    dispatch(dataActions.resetFilters());
    dispatch(dataActions.setFilter(filters));
    history.push(path);
  };

  const FeatureCard = ({ isfeature = true, icon, text }) => {
    const title = icon === text ? text : `${icon} - ${text}`;
    return (
      <div
        className={`lesson-category-container lesson-category-${adStatus}`}
        onClick={() => handleClick(icon, text)}
      >
        <div className="listing-category-icon">
          {isfeature ? getFeaturesIcon(icon) : GetCategoryIcon(icon)}
        </div>
        <p className="listing-category-text">{title}</p>
      </div>
    );
  };

  const renderFeatures = () => {
    const featureElements = [];
    for (let key in features) {
      const value = features[key];
      if (value)
        featureElements.push(<FeatureCard icon={key} text={key} key={key} />);
    }
    return featureElements;
  };

  return (
    <div className="listing-content-container">
      <div className="other-ads-specs-container">
        <p>{title}</p>
      </div>
      <VerticalScroll>
        {cat1 && <FeatureCard isfeature={false} icon={cat1} text={cat1Sub} />}
        {cat2 && <FeatureCard isfeature={false} icon={cat2} text={cat2Sub} />}
        {renderFeatures()}
      </VerticalScroll>
    </div>
  );
};
