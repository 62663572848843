import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as userActions from "../../../../../redux/actions/user-actions";

// Components
import Pagination from "../../../../components/pagination";
import SavedAd from "./saved-ad";

// Utilities
import { bookmarksGet, updateUser } from "../../../../../api/user";
import { getAdStatus, getBusinessStatus } from "../../../../../utilites/status";

// Styles
import "../../../list-view/event-card/result.css";
import "./saved-ad.css";

export default ({ user }) => {
  const { saved: savedList } = user;
  const dispatch = useDispatch();
  const { date } = useSelector((store) => store.data.filters);
  const [fetchingListings, setFetchingListings] = useState(false);
  const [saved, setSaved] = useState([]);
  const [savedBusinesses, setSavedBusinesses] = useState([]);
  const [savedSelected, setSavedSelected] = useState(true);
  const [savedPageNumber, setSavedPageNumber] = useState(1);

  useEffect(() => {
    if (savedList.length) getListings();
  }, []);

  const getListings = async () => {
    setFetchingListings(true);
    const { ads: savedListings = [], businesses = [] } =
      (await bookmarksGet(savedList)) || {};

    const orderedAdsList = savedList.reduce((acc, cur) => {
      const savedListing = savedListings.find(({ _id }) => _id === cur);
      if (savedListing) {
        const { isDateCurrent, hasStarted } = getAdStatus(savedListing);
        return isDateCurrent && hasStarted
          ? [savedListing, ...acc]
          : [...acc, savedListing];
      }
      return acc;
    }, []);

    const orderedBusinessList = savedList.reduce((acc, cur) => {
      const savedListing = businesses.find(({ _id }) => _id === cur);
      if (savedListing) {
        const { isDateCurrent, hasStarted } = getBusinessStatus(savedListing);
        return isDateCurrent && hasStarted
          ? [savedListing, ...acc]
          : [...acc, savedListing];
      }
      return acc;
    }, []);

    const activeAdList = orderedAdsList.filter(({ isActive }) => isActive);
    const activeBusinessList = orderedBusinessList.filter(
      ({ isActive }) => isActive
    );
    setSaved(activeAdList);
    setSavedBusinesses(activeBusinessList);
    setFetchingListings(false);
  };

  const handleAdDelete = (evt, deleteId) => {
    evt.stopPropagation();
    const updatedSaved = saved.filter(({ _id }) => _id !== deleteId);
    const updatedSavedList = savedList.filter((id) => id !== deleteId);
    const updatedUser = { ...user, saved: updatedSavedList };
    setSaved(updatedSaved);
    updateUser(updatedUser);
    dispatch(userActions.setUser({ isUser: true, user: updatedUser }));
  };

  const renderAds = () => {
    const ads = savedSelected ? saved : savedBusinesses;
    const sliceStartPosition = savedPageNumber * 5 - 5;
    let sliceEndPosition = savedPageNumber * 5;
    if (sliceEndPosition > ads.length) sliceEndPosition = ads.length;
    const displayAds = ads.slice(sliceStartPosition, sliceEndPosition);

    return displayAds.map((ad, idx) => (
      <SavedAd data={ad} handleAdDelete={handleAdDelete} key={idx} />
    ));
  };

  return (
    <Pagination
      title="SAVED"
      page={savedPageNumber}
      setPage={setSavedPageNumber}
      isLoading={fetchingListings}
      noResultsText="No Saved"
      list={savedSelected ? saved : savedBusinesses}
    >
      <>
        <div className="profile-title-container profile-text-container-split">
          <div
            className={`pagination-title-container ${
              savedSelected ? "pagination-title-selected" : ""
            }`}
            onClick={() => {
              setSavedSelected(true);
              setSavedPageNumber(1);
            }}
          >
            <p>Events</p>
            {!!saved.length && (
              <p className="profile-pagination-total">{`(${saved.length})`}</p>
            )}
          </div>
          <div
            className={`pagination-title-container ${
              savedSelected ? "" : "pagination-title-selected"
            }`}
            onClick={() => {
              setSavedSelected(false);
              setSavedPageNumber(1);
            }}
          >
            <p>Businesses</p>
            {!!savedBusinesses.length && (
              <p className="profile-pagination-total">{`(${savedBusinesses.length})`}</p>
            )}
          </div>
        </div>
        {renderAds()}
      </>
    </Pagination>
  );
};
