import store from "../redux/store";
import {
  setLoader,
  setMessage as setFooterMessage,
} from "../redux/actions/state-actions";
import { setUser } from "../redux/actions/user-actions";

export function handleApiSucess(message) {
  if (message) store.dispatch(setMessage({ message }));
  return store.dispatch(setLoader(false));
}

export function handleApiError(errOBJ) {
  let { error } = errOBJ || {};
  store.dispatch(setLoader(false));
  if (error) {
    const { description = "Error" } = error || {};
    return store.dispatch(
      setMessage({
        messageType: "error",
        message: description,
      })
    );
  }
}

export function setMessage(messageObj) {
  store.dispatch(setFooterMessage(messageObj));
  return store.dispatch(setLoader(false));
}

export function setApiUser(userOBJ) {
  store.dispatch(setUser(userOBJ));
  return store.dispatch(setLoader(false));
}
