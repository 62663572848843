import { callAPI } from ".";
import { baseURL } from "./url";

const employeeURL = baseURL("/platform");

export function getPlatformAnalytics() {
  return callAPI(employeeURL, "GET");
}

export function setPlatformAnalytics() {
  return callAPI(employeeURL, "POST");
}
