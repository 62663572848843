import React from "react";
import { ResponsiveContainer, PieChart, Pie, Cell, Legend } from "recharts";

export default ({ data }) => {
  const featureColors = {
    Animals: "#FFA07A",
    Arcade: "#FA8072",
    Bar: "#ADFF2F",
    "Black Owned": "#FF6347",
    Brewery: "#FFD700",
    "Curbside Pickup": "#FFA500",
    "Dance Floor": "#FFFF00",
    Delivery: "#98FB98",
    "Dog Friendly": "#3CB371",
    "Fire Pit": "#DC143C",
    "Food Truck": "#7FFF00",
    Outdoors: "#40E0D0",
    "Outdoor Dining": "#20B2AA",
    "Outdoor Heater": "	#C71585",
    Patio: "#1E90FF",
    "Pool Table": "#6A5ACD",
    Rooftop: "#00BFFF",
    Speakeasy: "#D8BFD8",
    "Sports Bar": "#DDA0DD",
    "Take Out": "#DA70D6",
    View: "#9370DB",
    Vineyard: "#800080",
    "Water Sport": "#f0553a",
  };

  return (
    data &&
    data.length && (
      <div
        style={{
          width: "100%",
          height: "280px",
        }}
      >
        <ResponsiveContainer>
          <PieChart>
            <defs>
              {Object.keys(featureColors).map((feature, idx) => (
                <linearGradient id={feature.replace(" ", "-")} key={idx}>
                  <stop
                    stopColor={featureColors[`${feature}`]}
                    stopOpacity={0.6}
                  />
                </linearGradient>
              ))}
            </defs>
            <Pie data={data} fill="transparent" dataKey="value">
              {data.map(({ name }) => (
                <Cell
                  fill={`url(#${name.replace(" ", "-")})`}
                  stroke={featureColors[name]}
                  strokeWidth={2}
                  key={`cell-${name}`}
                />
              ))}
            </Pie>
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </div>
    )
  );
};
