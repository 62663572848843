import React from "react";

// Styles
import "./svg.css";

export default () => {
  return (
    <svg
      width="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 200 185"
    >
      <path
        d="M200,61.1h-1.2V47.7h-8v-4.6h-0.9v-1.3h-1.2v1.3h-2.1v-6.8h-0.5v6.8h-2.5v-2.7H182v2.7h-1.2v-5.2h-0.3v5.2h-3
        v9.5h-1.3v-6.2h-0.4v6.2h-1.1v-5.2h-2.3v5.2h-0.9v-2.9h-0.4v2.9H169v20.3h-6.4V62.7h-1.9v-6h-1v-2.3h-2.2v2.3H157v-4.6h-0.4v4.6H156
        v-2.3h-0.9v2.3h-1.3v11.6h-2.2V48.8h-2V45H149v3.8h-0.4l-4.2,2.5V47H144v4.5l-1.1,0.7v-3.7h-0.6v4l-1.3,0.8v-5.9h-0.2v6l-1.6,1v6.6
        h-4.8v7.8h-4V40.5h-1v-2.1h-1.8v2.1h-0.9V37h-0.3v3.5H126l-2.2,4.9h-1.4v-3.1h-0.6v3.1h-0.7v6h-0.4v-6h-0.4v6H119v-2.6h-0.4v2.6H117
        V45h-0.2v6.4H115v-2.2h-2.6v2.2h-1.1v9.7h-2.5V46l-2.2-3.9h-1.2v-2.4h-0.3v2.4h-1v-8.2h-0.5v8.2H103v-2.8h-1.5v2.8h-0.6L98.5,46
        v15.1h-2.1V50.6h-1.1v-1.9h-0.5v1.9H94v-7h-0.3v7h-1.4v-1.9H90v1.9h-0.8l-2.5,3.9V66h-2.2V53.4h-0.9v-2.2h-0.9v2.2h-0.5v-2.2h-0.7
        v2.2h-0.8v-6.9h-0.8v-5.2h-0.3v5.2H79v-1.9h-2.5v1.9h-0.7v-5.2h-0.5v5.2h-1v8h-3V31.7h-1.7v-5.1h-0.3v5.1h-0.9v-2.3h-2.3v2.3h-0.9
        V34h-0.6v-5.6h-0.4V34h-1v-2h-1.9v2h-0.8v44.6h-2.1c0-0.6,0-1.2,0-1.8c0-12.4,0-24.8,0-37.2c-0.5,0-1,0-1.6,0v-4.8h-0.3v4.8h-1.7
        v-3.5h-1v3.5h-1.7V34h-0.4v5.6H49v-2h-3.6v2.2h-0.7v3.9l-0.9-0.1v-2.8h-0.4v2.7l-1.2-0.1v-5.3h-0.4v5.2l-1.3-0.2v-2.9h-3.6v2.5
        l-1.3-0.2V39h-0.3v3.4l-1.6-0.2V61h-8.6v4.7h-3.5V36.8l-1,0.6v-4.4h-0.4v4.6l-1.4,1v-3.5H17v4.4l-1.2,0.7v-4h-0.2v4.1l-1.5,0.9v13.2
        h-3.3V12.6h-1v-2H9.5v2H8.8v-4H7.4v4H6.3v-2.4H6v2.4H5v2.8H3.8v-3.5H3.2v3.6H2.5v-2.3H2.4v2.3H1.7v40.8H0C0,99.1,0,142,0,185
        c66.7,0,133.3,0,200,0C200,143.7,200,102.4,200,61.1z"
      />
    </svg>
  );
};
