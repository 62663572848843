import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as dataActions from "../../../redux/actions/data-actions";
import * as stateActions from "../../../redux/actions/state-actions";

// Components
import Select from "../../components/common/select";
import Button from "../../components/common/button";

// Components
import Spacer from "../../components/common/spacer";
import EventCard from "./event-card";
import SkeletonCard from "../../components/data-loader-hoc/skeleton-card";

// Utilities
import { isMobileAgent } from "../../../utilites";
import GetFilterIcon from "../../components/filter-icons";
import { toTitleCase } from "../../../utilites/format";
import { searchReviewFinds, searchReviewEditedFinds } from "../../../api/find";

// Assets
import Arrow from "../../../assets/svg/back";

// Styles
import "./results.css";

export default () => {
  const dispatch = useDispatch();
  const { user, data, state } = useSelector((store) => store);
  const { accountType, fName } = user.employee;
  const { results } = data;
  const { searchedReviewType, agentInput } = state;
  const [isFetching, setIsFetching] = useState(true);
  const [isPage, setIsPage] = useState(false);
  const [page, setPage] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [reviewTypeInput, setReviewTypeInput] = useState(searchedReviewType);
  const isMobile = isMobileAgent();

  useEffect(() => {
    handleSearch();
  }, [agentInput]);

  const handleChange = (evt, type) => {
    const { value } = evt.target;
    switch (type) {
      case "searchInput":
        return setSearchInput(value);
      case "reviewTypeInput":
        return setReviewTypeInput(value);
    }
  };

  const handleSearch = async (newPage = 0) => {
    setIsFetching(true);
    setPage(newPage);
    dispatch(stateActions.setSearchedReviewType(reviewTypeInput));
    dispatch(dataActions.setResults({ results: [], pathname: "" }));

    switch (reviewTypeInput) {
      case "Edits":
        const { results: newFindEdits } = await searchReviewEditedFinds({
          search: searchInput,
          agent: agentInput,
          skip: newPage,
        });

        setIsPage(newFindEdits && newFindEdits.length === 50);
        if (newFindEdits)
          dispatch(
            dataActions.setResults({
              results: newFindEdits,
              pathname: "",
            })
          );
        break;

      default:
        const { results: newFinds } = await searchReviewFinds({
          search: searchInput,
          skip: newPage,
        });

        setIsPage(newFinds.length === 50);
        dispatch(dataActions.setResults({ results: newFinds, pathname: "" }));
        break;
    }

    setIsFetching(false);
  };

  const handlePageClick = (newPage) => {
    handleSearch(newPage);
  };

  const renderSkeletonUi = () => {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    const height =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    const widthMultiple = Math.floor(width / 358) || 1;
    const heightMultiple = Math.floor(height / 178);
    const numberOfSkeletonEl = widthMultiple * heightMultiple;
    const skeletonEl = [];

    for (let i = 0; i < numberOfSkeletonEl; i++) {
      skeletonEl.push(<SkeletonCard key={i} />);
    }
    return skeletonEl;
  };

  if (isFetching)
    return (
      <div
        id="skelleton-wrapper"
        className={`no-scroll-bars ${
          isMobile ? "results-wrapper-mobile" : "results-wrapper-web"
        }`}
        style={{ width: "100%", marginLeft: "0px" }}
      >
        <Spacer height={isMobile ? 108 : 40} />
        <div id="results-inner-wrapper">{renderSkeletonUi()}</div>
      </div>
    );

  return (
    <>
      <div
        id="results-wrapper"
        className={`no-scroll-bars ${
          isMobile ? "results-wrapper-mobile" : "results-wrapper-web"
        }`}
        style={{ width: "100%", marginLeft: "0px" }}
      >
        <Spacer height={isMobile ? 108 : 40} />
        <div className="card-wrapper signup-wrapper-BusinessSignUp">
          <div className="card card-form">
            <div className="card-info-form">
              <div className="data-entry-reset" onClick={() => handleSearch()}>
                {GetFilterIcon("refresh")}
              </div>
              <h2>{`Hi ${toTitleCase({
                input: fName,
              })}, lets review!`}</h2>
              <label id="searchInput" className="label-form">
                Search:
              </label>
              <input
                className="input-style input-form text-form"
                value={searchInput}
                onChange={(evt) => handleChange(evt, "searchInput")}
              />

              <label id="reviewTypeInput" className="label-form">
                Review Type:
              </label>
              <div className="telemarketing-filter-wrapper">
                <Select
                  classNames="filter-select-container"
                  options={["New", "Edits"]}
                  value={reviewTypeInput}
                  handleChange={(evt) => handleChange(evt, "reviewTypeInput")}
                />
              </div>

              <div
                className="submit-wapper-UserSignUp"
                style={{ height: "45px", paddingTop: "0px" }}
              >
                <div
                  className="submit-button-UserSignUp"
                  style={{ display: "flex", flex: "row" }}
                >
                  <Button
                    text="Search"
                    isDisabled={isFetching}
                    handleClick={() => handleSearch()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {results.length || isPage ? (
          <>
            <div id="results-inner-wrapper">
              {results.map((result, idx) => (
                <EventCard
                  isAdmin={
                    accountType === "admin" || accountType === "developer"
                  }
                  result={result}
                  reviewTypeInput={reviewTypeInput}
                  idx={idx}
                  key={idx}
                />
              ))}
            </div>

            <div className="card card-form">
              <div className="marketing-card-wrapper marketing-paging-wrapper">
                {!!page && (
                  <div
                    className="marketing-page-arrow"
                    onClick={() => handlePageClick(page - 1)}
                  >
                    <Arrow />
                  </div>
                )}
                {(isPage || page) && (
                  <p className="marketing-page-text">{page}</p>
                )}
                {isPage && (
                  <div
                    className="marketing-page-arrow marketing-page-arrow-right"
                    onClick={() => handlePageClick(page + 1)}
                  >
                    <Arrow />
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <div className="no-results-wrapper">
            <h2>Nothing To Review</h2>
          </div>
        )}
      </div>
    </>
  );
};
