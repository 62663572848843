import React from "react";

export default props => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 25"
    >
      <path d="M26.4,0.2H3.6c-1.7,0-3.1,1.4-3.1,3.1v12.9c0,1.7,1.4,3.1,3.1,3.1h8.2L11,23.5H9.8c-0.3,0-0.6,0.3-0.6,0.6
        c0,0.3,0.3,0.6,0.6,0.6h10.5c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6H19l-0.8-4.3h8.2c1.7,0,3.1-1.4,3.1-3.1V3.3
        C29.5,1.6,28.1,0.2,26.4,0.2z M12.2,6.7c0.3,0,0.5,0.2,0.6,0.4l0.8,2.7L14.4,8c0.1-0.2,0.3-0.4,0.6-0.4c0.2,0,0.5,0.2,0.6,0.4
        l0.8,1.8l0.8-2.7c0.1-0.3,0.4-0.5,0.8-0.4c0.3,0.1,0.4,0.3,0.4,0.6c0,0.1,0,0.1,0,0.2l-1.2,4.3c-0.1,0.3-0.3,0.4-0.6,0.4
        c-0.2,0-0.5-0.1-0.6-0.4l-1-2.1l-1,2.2c-0.1,0.2-0.3,0.4-0.5,0.4h0c-0.3,0-0.5-0.2-0.5-0.4l-1.2-4.3c0-0.1,0-0.1,0-0.2
        C11.6,6.9,11.9,6.7,12.2,6.7z M4.2,6.7c0.3,0,0.5,0.2,0.6,0.4l0.8,2.7L6.4,8C6.5,7.7,6.8,7.6,7,7.6c0.2,0,0.5,0.2,0.6,0.4l0.8,1.8
        l0.8-2.7c0.1-0.3,0.4-0.5,0.8-0.4c0.3,0.1,0.4,0.3,0.4,0.6c0,0.1,0,0.1,0,0.2l-1.2,4.3c-0.1,0.3-0.3,0.4-0.6,0.4
        c-0.2,0-0.5-0.1-0.6-0.4L7,9.7l-1,2.2c-0.1,0.2-0.3,0.4-0.5,0.4h0c-0.3,0-0.5-0.2-0.5-0.4L3.6,7.4c0-0.1,0-0.1,0-0.2
        C3.6,6.9,3.9,6.7,4.2,6.7z M12.2,23.5l0.8-4.3H17l0.8,4.3H12.2z M25.5,16.5H1.8v-1.2h23.6c0.3,0,0.6,0.3,0.6,0.6
        C26.1,16.2,25.8,16.5,25.5,16.5z M26.4,7.5l-1.2,4.3c-0.1,0.3-0.3,0.4-0.6,0.4c-0.2,0-0.5-0.1-0.6-0.4l-1-2.1l-1,2.2
        c-0.1,0.2-0.3,0.4-0.5,0.4h0c-0.3,0-0.5-0.2-0.5-0.4l-1.2-4.3c0-0.1,0-0.1,0-0.2c0-0.3,0.3-0.6,0.6-0.6c0.3,0,0.5,0.2,0.6,0.4
        l0.8,2.7L22.4,8c0.1-0.2,0.3-0.4,0.6-0.4c0.2,0,0.5,0.2,0.6,0.4l0.8,1.8l0.8-2.7c0.1-0.3,0.4-0.5,0.8-0.4c0.3,0.1,0.4,0.3,0.4,0.6
        C26.4,7.3,26.4,7.4,26.4,7.5z"/>
    </svg>
  );
};
