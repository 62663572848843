import React from "react";

export default props => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 24 30"
    >
      <path d="M4.6,2.2c0,4.8,0,9.6,0,14.4c6.5,3,11.9-3,18.4,0c0-4.8,0-9.6,0-14.4C16.4-0.7,11,5.2,4.6,2.2z" />
      <path d="M2.3,29.5c-0.7,0-1.2-0.5-1.2-1.2V1.7c0-0.7,0.5-1.2,1.2-1.2S3.5,1,3.5,1.7v26.7C3.5,29,2.9,29.5,2.3,29.5z" />
    </svg>
  );
};
