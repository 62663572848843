import React from "react";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 16 30"
    >
      <path d="M2.5,24.9v2.5h10.9v-2.5c0-1.4-1.1-2.5-2.5-2.5H5C3.7,22.4,2.5,23.6,2.5,24.9z M5.5,23.4h5v3h-5V23.4z" />
      <rect x="6.5" y="24.4" width="3" height="1" />
      <path d="M15.4,3.1c0-0.3-0.2-0.5-0.5-0.5H1c-0.3,0-0.5,0.2-0.5,0.5S0.8,3.6,1,3.6H15C15.2,3.6,15.4,3.4,15.4,3.1z" />
      <path d="M11.7,1c-0.1-0.2-0.3-0.4-0.5-0.4H4.8C4.6,0.6,4.4,0.8,4.3,1L4.2,1.6h7.7L11.7,1z" />
      <rect x="7" y="13.5" width="2" height="7.9" />
      <rect x="1.5" y="28.4" width="12.9" height="1" />
      <circle cx="8" cy="8.5" r="1" />
      <path
        d="M13.5,4.6H2.5V11c0,0.8,0.7,1.5,1.5,1.5h3.5v-2.1C6.6,10.2,6,9.5,6,8.5c0-1.1,0.9-2,2-2c1.1,0,2,0.9,2,2
		c0,0.9-0.6,1.7-1.5,1.9v2.1H12c0.8,0,1.5-0.7,1.5-1.5V4.6z"
      />
    </svg>
  );
};
