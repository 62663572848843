import React from "react";

export default props => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 16 16"
    >
      <path
        d="M11.1,6.7c0-0.3-0.1-0.5-0.3-0.7L10,5.2C9.9,5.1,9.9,4.9,9.9,4.8V4.6c0,0,0.1,0,0.1-0.1c0.1-0.1,0.2-0.2,0.1-0.4l0-0.3
	c0-0.3-0.3-0.5-0.5-0.5H8.7c-0.3,0-0.5,0.2-0.5,0.5l0,0.3c0,0.1,0,0.3,0.1,0.4c0,0,0.1,0.1,0.1,0.1v0.2c0,0.1-0.1,0.2-0.1,0.3
	L7.6,5.8C7.6,5.9,7.6,6,7.7,6.1c0.1,0.1,0.2,0.1,0.3,0l0.6-0.7C8.7,5.2,8.8,5,8.8,4.8V4.7h0.7v0.2c0,0.2,0.1,0.4,0.2,0.6l0.7,0.8
	c0.1,0.1,0.2,0.3,0.2,0.5v1.1H8.6C8.5,7.8,8.4,7.9,8.4,8c0,0.1,0.1,0.2,0.2,0.2h2.1v1.6c0,0.1,0.1,0.2,0.2,0.2s0.2-0.1,0.2-0.2V6.7z
	 M8.7,4.3C8.6,4.3,8.6,4.3,8.7,4.3c-0.1,0-0.1-0.1-0.1-0.1l0-0.3c0-0.1,0.1-0.1,0.1-0.1h0.9c0.1,0,0.1,0.1,0.1,0.1l0,0.3
	c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0L8.7,4.3z"
      />
      <path
        d="M8.4,14.1c0,0.1,0.1,0.2,0.2,0.2h1.8c0.4,0,0.7-0.3,0.7-0.6v-3.2c0-0.1-0.1-0.2-0.2-0.2H9.6c-0.1,0-0.2,0.1-0.2,0.2
	c0,0.1,0.1,0.2,0.2,0.2h1.1v3c0,0.2-0.1,0.3-0.3,0.3H8.6C8.4,13.9,8.4,14,8.4,14.1z"
      />
      <path
        d="M4.1,14.1c0.2,0.1,0.4,0.2,0.6,0.2h2.6c0.5,0,0.8-0.3,0.8-0.7c0,0,0,0,0,0L8,7.6c0-0.3-0.1-0.5-0.3-0.7L7,5.8
	C6.9,5.7,6.9,5.5,6.8,5.4L6.7,3.1L6.8,3C7,2.8,7,2.7,7,2.5L6.9,2.1c0-0.2-0.3-0.4-0.5-0.4H5.7c-0.3,0-0.5,0.2-0.5,0.4L5.1,2.5
	c0,0.2,0,0.3,0.1,0.4l0.1,0.1L5.2,5.4c0,0.1-0.1,0.3-0.1,0.4L4.3,6.9C4.2,7,4.2,7,4.1,7.1c0,0.1,0,0.2,0.1,0.2c0.1,0,0.2,0,0.3-0.1
	c0,0,0,0,0,0c0-0.1,0.1-0.2,0.1-0.2L5.4,6c0.1-0.2,0.2-0.4,0.2-0.5L5.7,3c0,0,0-0.1,0-0.1L5.5,2.7c0-0.1-0.1-0.1-0.1-0.2l0.1-0.4
	c0-0.1,0.1-0.1,0.1-0.1h0.7c0.1,0,0.1,0,0.1,0.1l0.1,0.4c0,0.1,0,0.1-0.1,0.2L6.4,2.9c0,0,0,0.1,0,0.1l0.1,2.4
	c0,0.2,0.1,0.4,0.2,0.5L7.4,7c0.1,0.2,0.2,0.4,0.2,0.6l0,0.2H7c-0.4,0-0.7,0.3-0.8,0.6l-0.4,2.8C5.7,11.6,6,12,6.5,12
	c0,0,0.1,0,0.1,0h0.5c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2H6.6c-0.2,0-0.4-0.2-0.4-0.4c0,0,0,0,0,0l0.4-2.8
	c0-0.2,0.2-0.3,0.4-0.3h0.6l0.1,5.4c0,0.2-0.2,0.4-0.4,0.4c0,0,0,0,0,0H4.7c-0.2,0-0.4-0.2-0.4-0.4c0,0,0,0,0,0l0.1-3.6
	c0.3-0.4,0.5-0.8,0.5-1.3V7.8c0-0.1-0.1-0.2-0.2-0.2H0.3c-0.1,0-0.2,0.1-0.2,0.2v0.9c0,0.1,0,0.3,0.1,0.4c0,0,0,0,0,0
	c0.2,0.9,1.1,1.5,2.1,1.6v3.2H1.7c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2h1.6c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2
	H2.7v-3.2c0.5,0,0.9-0.2,1.3-0.4l-0.1,3.2C3.9,13.7,3.9,13.9,4.1,14.1z M2.5,10.4c-0.8,0-1.6-0.5-1.9-1.1h1.4c0.1,0,0.2-0.1,0.2-0.2
	c0-0.1-0.1-0.2-0.2-0.2H0.5c0-0.1,0-0.1,0-0.2V8h4v0.7C4.5,9.6,3.6,10.4,2.5,10.4z"
      />
      <path
        d="M12.1,14.3h3.2c0.1,0,0.2-0.1,0.2-0.2l0.4-5.5c0-0.1-0.1-0.2-0.2-0.2c0,0,0,0,0,0h-4c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0
	l0.4,5.5C11.9,14.2,12,14.3,12.1,14.3z M15.5,8.8l-0.1,0.9h-0.3C15,9.7,15,9.7,15,9.8C15,9.9,15,10,15.2,10h0.2l-0.3,3.9h-2.9L12,10
	h1.5c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2H12l-0.1-0.9L15.5,8.8z"
      />
      <path d="M3.2,8.9H3C2.9,8.9,2.8,9,2.8,9.1c0,0.1,0.1,0.2,0.2,0.2h0.2c0.1,0,0.2-0.1,0.2-0.2C3.4,9,3.3,8.9,3.2,8.9z" />
      <path d="M8.7,10.6L8.7,10.6c0.2,0,0.3-0.1,0.3-0.2c0-0.1-0.1-0.2-0.2-0.2H8.7c-0.1,0-0.2,0.1-0.2,0.2C8.5,10.6,8.6,10.6,8.7,10.6z" />
      <path d="M14.3,10h0.1c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2h-0.1c-0.1,0-0.2,0.1-0.2,0.2C14.1,9.9,14.2,10,14.3,10z" />
    </svg>
  );
};
