import React from "react";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 25"
    >
      <path d="M0.6,14.7v-0.1C0.6,14.6,0.6,14.6,0.6,14.7C0.6,14.7,0.6,14.7,0.6,14.7z" />
      <path d="M0.6,16.8c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1V16.8z" />
      <path d="M0.6,19.3c0-0.1,0-0.2,0-0.3C0.6,19.1,0.6,19.2,0.6,19.3z" />
      <path d="M0.6,21.5c0-0.1,0-0.1,0-0.2C0.6,21.4,0.6,21.4,0.6,21.5C0.6,21.5,0.6,21.5,0.6,21.5z" />
      <path d="M0.6,12.4C0.6,12.4,0.6,12.4,0.6,12.4L0.6,12.4C0.6,12.4,0.6,12.4,0.6,12.4z" />
      <path
        d="M28.9,22L28.9,22c-0.5,0-1-0.1-1.5-0.3c-0.4-0.1-0.8-0.2-1.3-0.2c-0.4,0-0.9,0.1-1.3,0.2c-0.5,0.2-1,0.2-1.5,0.2
	c-0.5,0-1-0.1-1.5-0.3c-0.8-0.3-1.7-0.3-2.5,0c-0.5,0.2-1,0.3-1.5,0.2c-0.5,0-1-0.1-1.5-0.3c-0.4-0.1-0.8-0.2-1.3-0.2
	c-0.4,0-0.9,0.1-1.3,0.2c-0.5,0.2-1,0.2-1.5,0.2c-0.5,0-1-0.1-1.5-0.3c-0.4-0.1-0.8-0.2-1.3-0.2c-0.4,0-0.9,0.1-1.3,0.2
	c-0.5,0.2-1,0.2-1.5,0.2c-0.5,0-1-0.1-1.5-0.3c-0.4-0.1-0.8-0.2-1.3-0.2c-0.4,0-0.9,0.1-1.3,0.2c-0.5,0.2-1,0.3-1.5,0.2
	c-0.2,0-0.4-0.1-0.4-0.4c0,1.2,0,2.1,0,2.2c0,0.2,0.2,0.5,0.4,0.5c0,0,0,0,0,0c0.5,0,1-0.1,1.5-0.2c0.4-0.1,0.8-0.2,1.3-0.2
	c0.4,0,0.9,0.1,1.3,0.2c0.5,0.2,1,0.3,1.5,0.3c0.5,0,1-0.1,1.5-0.2c0.4-0.1,0.8-0.2,1.3-0.2c0.4,0,0.9,0.1,1.3,0.2
	c0.5,0.2,1,0.3,1.5,0.3c0.5,0,1-0.1,1.5-0.2c0.4-0.1,0.8-0.2,1.3-0.2c0.4,0,0.9,0.1,1.3,0.2c0.5,0.2,1,0.3,1.5,0.3
	c0.5,0,1-0.1,1.5-0.2c0.8-0.3,1.7-0.3,2.5,0c1,0.3,2.1,0.3,3.1,0c0.8-0.3,1.7-0.3,2.5,0c0.5,0.2,1,0.3,1.5,0.3h0
	c0.2,0,0.4-0.2,0.4-0.4c0-0.2,0.1-13.3,0.1-13.5c0-0.2-0.2-0.5-0.4-0.5c0,0,0,0,0,0c-0.4,0-0.9-0.1-1.3-0.2c-1-0.3-2.1-0.3-3.1,0
	c-0.8,0.3-1.7,0.3-2.5,0c-1-0.3-2.1-0.3-3.1,0c-0.4,0.1-0.8,0.2-1.3,0.2c-0.4,0-0.9-0.1-1.3-0.2c-0.5-0.2-1-0.3-1.5-0.3
	c-0.5,0-1,0.1-1.5,0.2c-0.4,0.1-0.8,0.2-1.3,0.2c-0.4,0-0.9-0.1-1.3-0.2c-0.5-0.2-1-0.3-1.5-0.3c-0.5,0-1,0.1-1.5,0.2
	C7.5,9.7,7.1,9.7,6.7,9.7h0c-0.4,0-0.9-0.1-1.3-0.2c-0.5-0.2-1-0.3-1.5-0.3c-0.5,0-1,0.1-1.5,0.2C2,9.6,1.5,9.7,1.1,9.7h0
	c-0.2,0-0.4,0.2-0.5,0.4c0,0.1,0,0.9,0,2.2c0-0.2,0.2-0.4,0.4-0.4c0,0,0,0,0,0c0.4,0,0.8-0.1,1.2-0.2c0.5-0.2,1-0.2,1.5-0.2
	c0.5,0,1,0.1,1.5,0.3C5.8,11.9,6.2,12,6.7,12c0.4,0,0.9-0.1,1.3-0.2c0.5-0.2,1-0.2,1.5-0.2c0.5,0,1,0.1,1.5,0.3
	c0.4,0.1,0.8,0.2,1.3,0.2c0.4,0,0.9-0.1,1.3-0.2c0.5-0.2,1-0.2,1.5-0.2c0.5,0,1,0.1,1.5,0.3c0.4,0.1,0.8,0.2,1.3,0.2h0
	c0.4,0,0.8-0.1,1.2-0.2c0.5-0.2,1-0.2,1.5-0.2c0.5,0,1,0.1,1.5,0.3c0.8,0.3,1.7,0.3,2.5,0c0.5-0.2,1-0.3,1.5-0.2
	c0.5,0,1,0.1,1.5,0.3c0.4,0.1,0.8,0.2,1.3,0.2c0.2,0,0.4,0.2,0.4,0.5c0,0.2-0.2,0.4-0.4,0.4h0c-0.5,0-1-0.1-1.5-0.3
	c-0.4-0.1-0.8-0.2-1.3-0.2c-0.4,0-0.9,0.1-1.3,0.2c-1,0.3-2.1,0.3-3.1,0c-0.8-0.3-1.7-0.3-2.5,0c-1,0.3-2.1,0.3-3.1,0
	c-0.4-0.1-0.8-0.2-1.3-0.2c-0.4,0-0.9,0.1-1.3,0.2c-0.5,0.2-1,0.3-1.5,0.2c-0.5,0-1-0.1-1.5-0.3c-0.4-0.1-0.8-0.2-1.3-0.2
	c-0.4,0-0.9,0.1-1.3,0.2c-0.5,0.2-1,0.2-1.5,0.2c-0.5,0-1-0.1-1.5-0.3c-0.4-0.1-0.8-0.2-1.3-0.2c-0.4,0-0.9,0.1-1.3,0.2
	c-0.5,0.2-1,0.2-1.5,0.2c-0.2,0-0.4-0.2-0.4-0.4c0,0.6,0,1.4,0,2.1c0-0.2,0.2-0.4,0.4-0.4c0,0,0,0,0,0c0.4,0,0.8-0.1,1.2-0.2
	c0.5-0.2,1-0.2,1.5-0.2c0.5,0,1,0.1,1.5,0.3c0.4,0.1,0.8,0.2,1.3,0.2c0.4,0,0.9-0.1,1.3-0.2c0.5-0.2,1-0.2,1.5-0.2
	c0.5,0,1,0.1,1.5,0.3c0.4,0.1,0.8,0.2,1.3,0.2h0c0.4,0,0.8-0.1,1.2-0.2c0.5-0.2,1-0.2,1.5-0.2c0.5,0,1,0.1,1.5,0.3
	c0.4,0.1,0.8,0.2,1.3,0.2c0.4,0,0.9-0.1,1.3-0.2c0.5-0.2,1-0.3,1.5-0.2c0.5,0,1,0.1,1.5,0.3c0.4,0.1,0.8,0.2,1.3,0.2
	c0.4,0,0.9-0.1,1.3-0.2c0.5-0.2,1-0.2,1.5-0.2c0.5,0,1,0.1,1.5,0.3c0.4,0.1,0.8,0.2,1.3,0.2c0.2,0,0.4,0.2,0.4,0.5
	c0,0.2-0.2,0.4-0.4,0.4h0c-0.5,0-1-0.1-1.5-0.3c-0.4-0.1-0.8-0.2-1.3-0.2c-0.4,0-0.9,0.1-1.3,0.2c-0.5,0.2-1,0.2-1.5,0.2
	c-0.5,0-1-0.1-1.5-0.3c-0.8-0.3-1.7-0.3-2.5,0c-0.5,0.2-1,0.2-1.5,0.2c-0.5,0-1-0.1-1.5-0.3c-0.4-0.1-0.8-0.2-1.3-0.2
	c-0.4,0-0.9,0.1-1.3,0.2c-0.5,0.2-1,0.3-1.5,0.2c-0.5,0-1-0.1-1.5-0.3c-0.4-0.1-0.8-0.2-1.3-0.2h0c-0.4,0-0.8,0.1-1.2,0.2
	c-0.5,0.2-1,0.3-1.5,0.2c-0.5,0-1-0.1-1.5-0.3c-0.4-0.1-0.8-0.2-1.3-0.2c-0.4,0-0.9,0.1-1.3,0.2c-0.5,0.2-1,0.2-1.5,0.2
	c-0.2,0-0.4-0.2-0.4-0.4c0,0.7,0,1.4,0,2.1c0.1-0.2,0.2-0.3,0.4-0.3h0c0.4,0,0.8-0.1,1.2-0.2c0.5-0.2,1-0.2,1.5-0.2
	c0.5,0,1,0.1,1.5,0.3c0.4,0.1,0.8,0.2,1.3,0.2h0c0.4,0,0.8-0.1,1.2-0.2c0.5-0.2,1-0.2,1.5-0.2c0.5,0,1,0.1,1.5,0.3
	c0.4,0.1,0.8,0.2,1.3,0.2h0c0.4,0,0.8-0.1,1.2-0.2c0.5-0.2,1-0.3,1.5-0.2c0.5,0,1,0.1,1.5,0.3c0.4,0.1,0.8,0.2,1.3,0.2
	c0.4,0,0.9-0.1,1.3-0.2c0.5-0.2,1-0.2,1.5-0.2c0.5,0,1,0.1,1.5,0.3c0.4,0.1,0.8,0.2,1.3,0.2h0c0.4,0,0.8-0.1,1.2-0.2
	c0.5-0.2,1-0.2,1.5-0.2c0.5,0,1,0.1,1.5,0.3c0.4,0.1,0.8,0.2,1.3,0.2c0.2,0,0.4,0.2,0.4,0.5c0,0.2-0.2,0.4-0.5,0.4h0
	c-0.5,0-1-0.1-1.5-0.3c-0.4-0.1-0.8-0.2-1.3-0.2c-0.4,0-0.9,0.1-1.3,0.2c-1,0.3-2.1,0.3-3.1,0C21.4,17.1,21,17,20.6,17
	c-0.4,0-0.9,0.1-1.3,0.2c-0.5,0.2-1,0.2-1.5,0.2c-0.5,0-1-0.1-1.5-0.3C15.8,17.1,15.4,17,15,17c-0.4,0-0.9,0.1-1.3,0.2
	c-0.5,0.2-1,0.2-1.5,0.2c-0.5,0-1-0.1-1.5-0.3C10.3,17,9.8,17,9.4,17C9,17,8.5,17,8.1,17.2c-0.5,0.2-1,0.3-1.5,0.2
	c-0.5,0-1-0.1-1.5-0.3c-0.4-0.1-0.8-0.2-1.3-0.2c-0.4,0-0.9,0.1-1.3,0.2c-0.5,0.2-1,0.3-1.5,0.2c-0.2,0-0.4-0.1-0.4-0.3
	c0,0.7,0,1.3,0,2c0.1-0.2,0.2-0.3,0.4-0.3h0c0.4,0,0.8-0.1,1.2-0.2c0.5-0.2,1-0.2,1.5-0.2c0.5,0,1,0.1,1.5,0.3
	c0.4,0.1,0.8,0.2,1.3,0.2h0c0.4,0,0.8-0.1,1.2-0.2c0.5-0.2,1-0.3,1.5-0.2c0.5,0,1,0.1,1.5,0.3c0.4,0.1,0.8,0.2,1.3,0.2
	c0.4,0,0.9-0.1,1.3-0.2c0.5-0.2,1-0.2,1.5-0.2c0.5,0,1,0.1,1.5,0.3c0.4,0.1,0.8,0.2,1.3,0.2c0.4,0,0.9-0.1,1.3-0.2
	c0.5-0.2,1-0.2,1.5-0.2c0.5,0,1,0.1,1.5,0.3c0.4,0.1,0.8,0.2,1.3,0.2c0.4,0,0.9-0.1,1.3-0.2c0.5-0.2,1-0.2,1.5-0.2
	c0.5,0,1,0.1,1.5,0.3c0.4,0.1,0.8,0.2,1.3,0.2c0.2,0,0.4,0.2,0.4,0.5c0,0.2-0.2,0.4-0.5,0.4h0c-0.5,0-1-0.1-1.5-0.3
	c-0.4-0.1-0.8-0.2-1.3-0.2c-0.4,0-0.9,0.1-1.3,0.2c-1,0.3-2.1,0.3-3.1,0c-0.8-0.3-1.7-0.3-2.5,0c-0.5,0.2-1,0.3-1.5,0.2
	c-0.5,0-1-0.1-1.5-0.3c-0.4-0.1-0.8-0.2-1.3-0.2h0c-0.4,0-0.8,0.1-1.2,0.2c-0.5,0.2-1,0.2-1.5,0.2c-0.5,0-1-0.1-1.5-0.3
	c-0.4-0.1-0.8-0.2-1.3-0.2c-0.4,0-0.9,0.1-1.3,0.2c-0.5,0.2-1,0.2-1.5,0.2c-0.5,0-1-0.1-1.5-0.3c-0.4-0.1-0.8-0.2-1.3-0.2
	c-0.4,0-0.9,0.1-1.3,0.2c-0.5,0.2-1,0.3-1.5,0.2c-0.2,0-0.4-0.1-0.4-0.3c0,0.7,0,1.4,0,2C0.6,21.1,0.8,21,1,21c0,0,0,0,0,0
	c0.4,0,0.8-0.1,1.2-0.2c0.5-0.2,1-0.2,1.5-0.2c0.5,0,1,0.1,1.5,0.3C5.7,20.9,6.2,21,6.6,21c0,0,0,0,0,0c0.4,0,0.8-0.1,1.2-0.2
	c0.5-0.2,1-0.2,1.5-0.2c0.5,0,1,0.1,1.5,0.3c0.4,0.1,0.8,0.2,1.3,0.2c0.4,0,0.9-0.1,1.3-0.2c0.5-0.2,1-0.2,1.5-0.2
	c0.5,0,1,0.1,1.5,0.3c0.4,0.1,0.8,0.2,1.3,0.2c0.4,0,0.9-0.1,1.3-0.2c0.5-0.2,1-0.2,1.5-0.2c0.5,0,1,0.1,1.5,0.3
	c0.4,0.1,0.8,0.2,1.3,0.2c0.4,0,0.9-0.1,1.3-0.2c0.5-0.2,1-0.2,1.5-0.2c0.5,0,1,0.1,1.5,0.3c0.4,0.1,0.8,0.2,1.3,0.2
	c0.2,0,0.4,0.2,0.4,0.5C29.4,21.8,29.2,22,28.9,22z"
      />
      <path
        d="M0.7,7.8C0.9,8,1.2,8,1.4,7.9c0,0,0,0,0,0L6,3.6l5.6,5.1c0.2,0.2,0.5,0.2,0.6,0c0,0,0,0,0,0c0.2-0.2,0.2-0.5,0-0.6l-2.1-1.9
	l2.9-2.5L15.6,6L14,7.6c-0.2,0.2-0.2,0.5,0,0.6c0.2,0.2,0.5,0.2,0.6,0c0,0,0,0,0,0l6.6-6.5l2.5,2.4l-1.8,1.6c-0.2,0.2-0.2,0.4,0,0.6
	c0.2,0.2,0.4,0.2,0.6,0c0,0,0,0,0,0l3.2-2.9l2.9,2.6c0.2,0.2,0.5,0.1,0.6,0c0.2-0.2,0.1-0.5,0-0.6L26,2.6c-0.2-0.2-0.4-0.2-0.6,0
	l-1.1,1l-2.8-2.8c-0.2-0.2-0.5-0.2-0.6,0l-4.6,4.5l-2.2-1.9h0l0,0l0,0l-0.9-0.8c-0.2-0.2-0.4-0.2-0.6,0L9.4,5.5L6.3,2.6
	c-0.2-0.2-0.4-0.2-0.6,0L4.9,3.4l0,0L4.8,3.4h0L0.7,7.2C0.6,7.4,0.6,7.6,0.7,7.8z"
      />
    </svg>
  );
};
