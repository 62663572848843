import React from "react";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 26"
    >
      <path d="M1.3,8.9h27.4c0.3,0,0.5,0.2,0.5,0.5v1c0,0.3-0.2,0.5-0.5,0.5H1.3c-0.3,0-0.5-0.2-0.5-0.5v-1C0.8,9.2,1,8.9,1.3,8.9z" />
      <polygon
        className="fill-none"
        points="16.6,21.1 16.1,17.1 13.9,17.1 13.4,21.1 	"
      />
      <path
        className="fill-none"
        d="M16.5,15h-3c-0.3,0-0.5,0.2-0.5,0.5V16H17v-0.5C17,15.3,16.8,15,16.5,15z"
      />
      <circle cx="23.1" cy="2.3" r="1.5" />
      <path d="M23.6,4.9h-1c-1.4,0-2.5,1.1-2.5,2.5c0,0.3,0.2,0.5,0.5,0.5h5.1c0.3,0,0.5-0.2,0.5-0.5C26.2,6,25,4.9,23.6,4.9z" />
      <path
        d="M16,4.9h-2c-0.3,0-0.5,0.2-0.5,0.5v1c0,0.6,0.4,1.2,1,1.4v1.6c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5V7.8
      c0.6-0.2,1-0.8,1-1.4v-1C16.5,5.1,16.3,4.9,16,4.9z"
      />
      <polygon points="6.8,17.1 6.3,21.1 9.5,21.1 9,17.1 	" />
      <path d="M17,24.7l-0.3-2.6h-3.4L13,24.7c0,0.3-0.2,0.4-0.5,0.4h5.1C17.3,25.2,17.1,25,17,24.7z" />
      <path d="M9.9,24.7l-0.3-2.6H6.2l-0.3,2.6c0,0.3-0.2,0.4-0.5,0.4h5.1C10.2,25.2,9.9,25,9.9,24.7z" />
      <polygon points="13.9,17.1 13.4,21.1 16.6,21.1 16.1,17.1 	" />
      <path
        d="M27.7,12H2.3c-0.3,0-0.5,0.2-0.5,0.5v12.2c0,0.3,0.2,0.5,0.5,0.5h3c-0.3,0-0.5-0.3-0.4-0.6c0,0,0,0,0,0l1-7.6H5.3
      c-0.3,0-0.5-0.2-0.5-0.5v-1c0-0.8,0.7-1.5,1.5-1.5h3c0.8,0,1.5,0.7,1.5,1.5v1c0,0.3-0.2,0.5-0.5,0.5H10l1,7.6
      c0,0.3-0.2,0.5-0.4,0.6c0,0,0,0,0,0h1.9c-0.3,0-0.5-0.3-0.4-0.6c0,0,0,0,0,0l1-7.6h-0.4c-0.3,0-0.5-0.2-0.5-0.5v-1
      c0-0.8,0.7-1.5,1.5-1.5h3c0.8,0,1.5,0.7,1.5,1.5v1c0,0.3-0.2,0.5-0.5,0.5h-0.4l1,7.6c0,0.3-0.2,0.5-0.4,0.6c0,0,0,0,0,0h10.1
      c0.3,0,0.5-0.2,0.5-0.5V12.5C28.2,12.2,28,12,27.7,12z"
      />
    </svg>
  );
};
