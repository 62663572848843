import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// Components
import ScrollToTop from "./scroll-to-top";
import SplashPage from "./splash-page";
import Routes from "./routes";
import Loader from "../components/loader";
import MessageBar from "../components/message-bar";
import ImageModal from "../components/image-modal";
import PaymentModal from "../components/payment-modal";
import CovidModal from "../components/covid-modal";
import ShareDrawer from "../components/common/share/drawer";
import PromoDrawer from "../components/common/promo-drawer";

// Styles
import "./app.css";

const isProd = window.location.href.indexOf("eventhound.com") > -1;
const key = isProd
  ? "pk_live_51HmoHHKCJhGWJU9a2lhhYJJK9yzssUoPD4OGhdK3RZenIfuPV3EHpTKb3ulvtdW7gIrTEH74tWiTkCoDpInG03xD000Nf0rz96"
  : "pk_test_51HmoHHKCJhGWJU9aghGZFBmWIBINyjLS2118S9eX6X6zbt19tygWG5j7r7eOPpM8XCGxIfJMJk6bQJLHY1iS0OP20038HKLiZU";
const stripePromise = loadStripe(key);

const AppRouter = () => (
  <>
    <Router>
      <Routes />
      <Loader />
      <ScrollToTop />
      <ImageModal />
      <Elements stripe={stripePromise}>
        <PaymentModal />
      </Elements>
      <CovidModal />
      <MessageBar />
      <ShareDrawer />
      <PromoDrawer />
    </Router>
  </>
);

export default SplashPage(AppRouter);
