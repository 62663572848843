import React, { useEffect } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AdminRoute from "./protected-route/admin";
import DataEntryRoute from "./protected-route/data-entry";
import TelemarketingRoute from "./protected-route/telemarketing";
import ReviewRoute from "./protected-route/review";
import RedirectRoute from "./redirect-route";

// Components
import Admin from "../routes/admin";
import Home from "../routes/home";
import Results from "../routes/list-view";
import Map from "../routes/map-view";
import Listing from "../routes/listing";
import Business from "../routes/business";
import BusinessAnalytics from "../routes/analytics-business";
import CommunityAnalytics from "../routes/analytics-community";
import Header from "../components/header";
import SideNavSlider from "../components/side-nav-slider";
import FooterNav from "../components/footer-navbar";
import Login from "../routes/login";
import UserSignUp from "../routes/sign-up/user";
import BusinessSignUp from "../routes/sign-up/business";
import ConfirmEmail from "../routes/confirm-email";
import BusinessProfile from "../routes/profile/business";
import EditBusiness from "../routes/edit-profile/business";
import CreateGrowthListing from "../routes/create-growth-listing";
import CreateListing from "../routes/create-listing";
import EditListing from "../routes/edit-listing";
import EditGrowthListing from "../routes/edit-growth-listing";
import CorporateBusiness from "../routes/corporate-business";
import CreateCorporateBusiness from "../routes/corporate-business/create";
import EditCorporateBusiness from "../routes/corporate-business/edit";
import UserProfile from "../routes/profile/user";
import EditUser from "../routes/edit-profile/user";
import PrivacyPolicy from "../routes/legal/privacy-policy";
import TermsOfService from "../routes/legal/terms-of-service";
import Recovery from "../routes/recovery";
import BusinessLandingStartUp from "../routes/landing-pages/business-start-up";
import Employees from "../routes/employees";
import Employee from "../routes/employee";
import Telemarketing from "../routes/telemarketing";
import DataEntry from "../routes/data-entry";
import Reviews from "../routes/reviews";
import ReviewFinds from "../routes/review-finds";
import Statistics from "../routes/business-stats";
import Giveaway from "../routes/giveaway";
import Find from "../routes/find";
import FindEdit from "../routes/find-edit";

// Utilities
import { isMobileAgent, setTheme } from "../../utilites";

// Styles
import "./app.css";

const MobileRouter = ({ user, location }) => {
  const { pathname } = location;
  const { darkTheme = false } = user || {};
  const isHome = pathname === "/";
  const fullLayout = isFullLayout();
  const isMobile = isMobileAgent();
  const isSideNav = !isMobile && !isHome && fullLayout;

  useEffect(() => {
    setTheme(darkTheme);
    if ("ReactNativeWebView" in window)
      window.ReactNativeWebView.postMessage(JSON.stringify({ darkTheme }));
  }, [pathname]);

  function isFullLayout() {
    switch (pathname.split("/")[1]) {
      case "top":
        return true;
      case "food":
        return true;
      case "drinks":
        return true;
      case "activities":
        return true;
      case "events":
        return true;
      case "map":
        return true;
      default:
        return false;
    }
  }

  return (
    <div className="page-wrapper-AppRouter no-scroll-bars">
      <Header />
      {isSideNav && <SideNavSlider />}
      <Switch>
        <Route path="/" exact component={Admin} />
        <Route path="/statistics" exact component={Statistics} />
        <ReviewRoute
          path={["/top", "/food", "/drinks", "/activities", "/events"]}
          exact
          component={Results}
        />
        <ReviewRoute
          path={[
            "/top/map",
            "/food/map",
            "/drinks/map",
            "/activities/map",
            "/events/map",
          ]}
          exact
          component={Map}
        />
        {/* <RedirectRoute path={"/login"} exact component={Login} />
        <RedirectRoute path="/user/sign-up" component={UserSignUp} />
        <RedirectRoute
          path={["/business/sign-up/:promoCode", "/business/sign-up/"]}
          component={BusinessSignUp}
        />
        <RedirectRoute path="/confirm-email" component={ConfirmEmail} />
        <ProtectedBusinessRoute
          path="/business/profile"
          component={BusinessProfile}
          exact
        /> */}
        <ReviewRoute
          path="/business/analytics"
          component={BusinessAnalytics}
          exact
        />
        <ReviewRoute
          path="/business/community"
          component={CommunityAnalytics}
          exact
        />
        <DataEntryRoute path="/create" exact component={CreateGrowthListing} />
        <TelemarketingRoute
          path="/telemarketing"
          exact
          component={Telemarketing}
        />
        <DataEntryRoute path="/data-entry" exact component={DataEntry} />
        <ReviewRoute
          exact
          path="/business/profile/:id"
          component={BusinessProfile}
        />
        <ReviewRoute exact path="/business/edit/:id" component={EditBusiness} />
        <ReviewRoute
          exact
          path="/business/listing/:id"
          component={EditListing}
        />
        <ReviewRoute
          exact
          path="/growth/listing/:id"
          component={EditGrowthListing}
        />
        <ReviewRoute
          exact
          path="/create-listing"
          exact
          component={CreateListing}
        />
        <ReviewRoute
          exact
          path="/business/corporate/create"
          component={CreateCorporateBusiness}
        />
        <ReviewRoute
          exact
          path="/business/corporate/edit/:id"
          component={EditCorporateBusiness}
        />
        <ReviewRoute
          exact
          path="/business/corporate"
          component={CorporateBusiness}
        />
        <ReviewRoute exact path="/reviews" component={Reviews} />
        <ReviewRoute exact path="/review-finds" component={ReviewFinds} />
        <AdminRoute path="/employees" exact component={Employees} />
        <AdminRoute path="/employee" exact component={Employee} />
        <ReviewRoute exact path="/find/:id" exact component={Find} />
        <ReviewRoute exact path="/find-edit/:id" exact component={FindEdit} />
        <ReviewRoute exact path="/listing/:id" exact component={Listing} />
        <ReviewRoute exact path="/business/:id" exact component={Business} />
        <AdminRoute path="/giveaway" exact component={Giveaway} />
        {/* <ProtectedUserRoute path="/user/profile" component={UserProfile} />
        <ProtectedUserRoute path="/user/edit" component={EditUser} />
        <Route path="/terms-of-service" exact component={TermsOfService} />
        <Route path="/privacy-policy" exact component={PrivacyPolicy} />
        <Route
          path="/welcome/start-up"
          exact
          component={BusinessLandingStartUp}
        /> */}
        <Route
          path="*"
          component={() => (
            <div
              style={{
                width: "100vw",
                height: "90vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h2>Page Not Found</h2>
            </div>
          )}
        />
      </Switch>
      {fullLayout && <FooterNav />}
    </div>
  );
};

const mapStateToProps = (store) => ({
  user: store.user.user,
});

export default connect(mapStateToProps, null)(withRouter(MobileRouter));
