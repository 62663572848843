import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as stateActions from "../../../redux/actions/state-actions";

// Components
import ViewLayout from "../../components/view-layout";
import Select from "../../components/common/select";
import Button from "../../components/common/button";
import BusinessCard from "./busniess-card";

// Utilities
import { toTitleCase } from "../../../utilites/format";
import { subCatOptions } from "../../../constants/data-models";
import GetFilterIcon from "../../components/filter-icons";
import { findBusinesses } from "../../../api/marketing";
import { getTelemarketingBusinesses } from "../../../api/telemarketing";

// Assets
import Arrow from "../../../assets/svg/back";

// Styles
import "./telemarketing.css";

export default () => {
  const dispatch = useDispatch();
  const { accountType, fName, lName } = useSelector(
    (store) => store.user.employee
  );
  const [isPage, setIsPage] = useState(false);
  const [isNameSearch, setIsNameSearch] = useState(false);
  const [page, setPage] = useState(0);
  const [isSearching, setIsSearching] = useState(false);
  const [nameInput, setNameInput] = useState("");
  const [locationInput, setLocationInput] = useState("anaheim, CA");
  const [categorySelect, setCategorySelect] = useState("Events");
  const [distanceInput, setDistanceInput] = useState("10 Miles");
  const [businesses, setBusinesses] = useState(null);
  const categoriesList = Object.keys(subCatOptions);
  const agentName = `${fName} ${lName}`;
  const isAdmin = accountType === "admin" || accountType === "developer";

  useEffect(() => {
    searchLocation(0);
  }, []);

  const handleChange = (evt, type, newValue) => {
    const { value } = evt.target;
    switch (type) {
      case "nameInput":
        return setNameInput(value);
      case "locationInput":
        if (!isAdmin) return;
        return setLocationInput(value);
      case "select":
        return setCategorySelect(value);
      case "distance":
        if (!isAdmin) return;
        return setDistanceInput(value);
    }
  };

  const fulFillLocation = async () => {
    if (!locationInput) return;
    setIsSearching(true);
    dispatch(stateActions.setLoader(true));
    await findBusinesses(locationInput, categorySelect);
    dispatch(stateActions.setLoader(false));
    setIsSearching(false);
  };

  const searchLocation = async (page) => {
    if (nameInput || locationInput) {
      setIsNameSearch(!!nameInput);
      dispatch(stateActions.setLoader(true));
      const params = {
        search: nameInput,
        location: locationInput,
        category: categorySelect,
        distance: distanceInput.split(" ")[0],
        skip: page * 50,
      };
      const { businesses: foundBusinesses } = await getTelemarketingBusinesses(
        params
      );
      if (foundBusinesses) {
        setBusinesses(foundBusinesses);
        if (foundBusinesses.length === 50) {
          setIsPage(true);
        } else {
          setIsPage(false);
        }
      }
      dispatch(stateActions.setLoader(false));
      if (page) {
        const element = document.querySelector(".view-layout");
        element.scrollTo(0, 0);
      }
    }
  };

  const handleSearch = () => {
    setPage(0);
    searchLocation(0);
  };

  const updateLocation = (location) => {
    const { _id: locationId } = location;
    const newBusinesses = businesses.map((business) => {
      const { _id: businessId } = business;
      if (businessId === locationId) return location;
      return business;
    });
    setBusinesses(newBusinesses);
  };

  const setUpdateLocation = (location) => {
    const { _id: locationId, doNotContact, moreInfo } = location;
    const { emailed } = moreInfo || {};
    if (isNameSearch) return updateLocation(location);
    if (doNotContact || emailed) {
      const newBusinesses = businesses.filter(({ _id }) => _id !== locationId);
      setBusinesses(newBusinesses);
    } else {
      updateLocation(location);
    }
  };

  const handlePageClick = (newPage) => {
    setPage(newPage);
    searchLocation(newPage);
  };

  return (
    <ViewLayout>
      <div className="card-wrapper signup-wrapper-BusinessSignUp">
        <div className="card card-form">
          <div className="card-info-form">
            <div className="data-entry-reset" onClick={() => handleSearch()}>
              {GetFilterIcon("refresh")}
            </div>
            <h2>{`Hi ${toTitleCase({
              input: fName,
            })}, where are we calling today?`}</h2>
            <label id="nameInput" className="label-form">
              Name:
            </label>
            <input
              className="input-style input-form text-form"
              value={nameInput}
              onChange={(evt) => handleChange(evt, "nameInput")}
            />

            <label id="locationInput" className="label-form">
              Location:
            </label>
            <input
              className="input-style input-form text-form"
              value={locationInput}
              onChange={(evt) => handleChange(evt, "locationInput")}
            />

            <label id="locationInput" className="label-form">
              Filters:
            </label>
            <div className="telemarketing-filter-wrapper">
              <Select
                classNames="filter-select-container"
                options={categoriesList}
                value={categorySelect}
                handleChange={(evt) => handleChange(evt, "select")}
              >
                {GetFilterIcon(categorySelect)}
              </Select>

              <Select
                classNames="filter-select-container"
                options={["5 Miles", "10 Miles", "20 Miles", "50 Miles"]}
                value={distanceInput}
                handleChange={(evt) => handleChange(evt, "distance")}
              >
                {GetFilterIcon("distance")}
              </Select>
            </div>

            <div className="submit-wapper-UserSignUp">
              <div
                className="submit-button-UserSignUp"
                style={{ display: "flex", flex: "row" }}
              >
                {isAdmin && (
                  <Button
                    styles={{ marginRight: "15px" }}
                    text="Get More"
                    isPrimary={false}
                    isDisabled={isSearching}
                    handleClick={fulFillLocation}
                  />
                )}
                <Button
                  text="Search"
                  isDisabled={isSearching}
                  handleClick={handleSearch}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {!!businesses &&
        !!businesses.length &&
        businesses.map((business, idx) => {
          const { _id } = business;
          return (
            <BusinessCard
              business={business}
              agentName={agentName}
              setUpdateLocation={setUpdateLocation}
              page={page}
              idx={idx}
              key={_id}
            />
          );
        })}

      <div className="card card-form">
        <div className="marketing-card-wrapper marketing-paging-wrapper">
          {!!page && (
            <div
              className="marketing-page-arrow"
              onClick={() => handlePageClick(page - 1)}
            >
              <Arrow />
            </div>
          )}
          {(isPage || page) && <p className="marketing-page-text">{page}</p>}
          {isPage && (
            <div
              className="marketing-page-arrow marketing-page-arrow-right"
              onClick={() => handlePageClick(page + 1)}
            >
              <Arrow />
            </div>
          )}
        </div>
      </div>
    </ViewLayout>
  );
};
