import React, { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import lottie from "lottie-web";

// Utilites
import { convertDateToDbDate } from "../../../../../utilites/date";

// Assets
import Welcome from "../../../../../assets/lotti/welcome.json";
import EventHound from "../../../../../assets/svg/eventHound-name";

// Styles
import "./welcome-banner.css";

export default () => {
  const welcomeLotti = useRef();
  const { user } = useSelector((store) => store.user);
  const { createdDate } = user || {};
  const today = convertDateToDbDate();
  const isFirstDay = Number(createdDate) === today;

  useEffect(() => {
    if (isFirstDay)
      lottie.loadAnimation({
        container: welcomeLotti.current,
        renderer: "svg",
        loop: false,
        autoplay: true,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
        animationData: Welcome,
      });
  }, []);

  return (
    isFirstDay && (
      <div className="welcome-wrapper">
        <div ref={welcomeLotti} className="welcome-animation" />
        <p className="welcome-to-text">To</p>
        <div className="welcome-logo">
          <EventHound />
        </div>
      </div>
    )
  );
};
