import React, { useState } from "react";
import { useSelector } from "react-redux";

// Components
import Map from "../../../components/map";
import BottomDrawer from "../../../components/bottom-drawer";

// Utilities
import { charLimitTo, formatPhoneNumber } from "../../../../utilites/format";

// Assets
import Directions from "../../../../assets/svg/directions";
import Phone from "../../../../assets/svg/phone";
import Website from "../../../../assets/svg/website";

// Styles
import "../listing.css";

export default ({ ad, business, status, isClickable = true }) => {
  const { nativePlatform } = useSelector((store) => store.state);
  const { geoLocation } = useSelector((store) => store.user);
  const [isMap, setIsMap] = useState(false);
  const isGeoLocation = "coordinates" in geoLocation;
  const { location: adLocation } = ad || {};
  const {
    locationIsdefault = true,
    address: adAddress,
    coordinates: adCoordinates,
  } = adLocation || {};
  const { showPhone, phone, website, address, city, state, zip } =
    business || {};
  const { location } = business || {};
  const { coordinates } = location || {};
  const isAddress =
    (!locationIsdefault && adAddress) ||
    ((!adLocation || locationIsdefault) && coordinates && !!coordinates.length);
  const mapCoordinates =
    !locationIsdefault && adAddress ? adCoordinates : coordinates;
  const showComponent = (phone && showPhone) || !!website || isAddress;
  let addressLength = 13;
  if (!showPhone) addressLength += 8;
  if (!website) addressLength += 8;

  const mapsSelector = () => {
    const directionsLocation = locationIsdefault
      ? `${address} ${city}, ${state} ${zip}`
      : adAddress;
    if (!isClickable) return;
    const isWebIOS = !!window.navigator.userAgent.match(/iPhone|iPad|Mac/gi)
      .length;
    if (nativePlatform === "ios" || isWebIOS) {
      window.open(`maps://maps.google.com/maps/dir?q=${directionsLocation}`);
    } else {
      window.open(`https://maps.google.com/maps/dir?q=${directionsLocation}`);
    }
  };

  const renderContactButtons = () => (
    <div className="listing-contact-wrapper">
      {isAddress && (
        <div className="listing-contact-container" onClick={mapsSelector}>
          <div className="listing-contact-svg-container">
            <Directions />
          </div>
          <p className="listing-contact-text">
            {charLimitTo(
              locationIsdefault ? address : adAddress,
              addressLength
            )}
          </p>
        </div>
      )}
      {showPhone &&
        (isClickable ? (
          <a
            className="listing-contact-container listing-contact-container-border-left"
            href={`tel:${formatPhoneNumber({ phone, href: true })}`}
          >
            <div className="listing-contact-svg-container">
              <Phone />
            </div>
            <p className="listing-contact-text">
              {formatPhoneNumber({ phone })}
            </p>
          </a>
        ) : (
          <div className="listing-contact-container listing-contact-container-border-left">
            <div className="listing-contact-svg-container">
              <Phone />
            </div>
            <p className="listing-contact-text">
              {formatPhoneNumber({ phone })}
            </p>
          </div>
        ))}
      {website &&
        (isClickable ? (
          <a
            className="listing-contact-container listing-contact-container-border-left"
            href={`${
              website && website.indexOf("http") > -1 ? "" : "https://"
            }${website}`}
            target="_blank"
          >
            <div className="listing-contact-svg-container">
              <Website />
            </div>
            <p className="listing-contact-text">Website</p>
          </a>
        ) : (
          <div className="listing-contact-container listing-contact-container-border-left">
            <div className="listing-contact-svg-container">
              <Website />
            </div>
            <p className="listing-contact-text">Website</p>
          </div>
        ))}
    </div>
  );

  return showComponent ? (
    <>
      <div className="listing-content-container" style={{ padding: 0 }}>
        {isAddress && (
          <Map
            status={status}
            isGeoLocation={isGeoLocation}
            userMarkerPostion={geoLocation.coordinates}
            mapCenter={mapCoordinates}
            coordinates={mapCoordinates}
            draggable={false}
            containerElement={
              <div
                style={{ height: `360px`, padding: "10px 10px 0px 10px" }}
                onClick={() => setIsMap(true)}
              />
            }
            mapElement={<div style={{ height: "100%" }} />}
          />
        )}
        {renderContactButtons()}
      </div>

      <BottomDrawer
        isOpen={isMap}
        handleCancelClick={() => setIsMap(false)}
        isTall
      >
        <Map
          isGeoLocation={isGeoLocation}
          userMarkerPostion={geoLocation.coordinates}
          mapCenter={mapCoordinates}
          coordinates={mapCoordinates}
          containerElement={
            <div
              style={{
                width: "100%",
                height: "340px",
                padding: "0px 5px",
              }}
            />
          }
          mapElement={<div style={{ height: "100%" }} />}
        />
        {renderContactButtons()}
      </BottomDrawer>
    </>
  ) : null;
};
