import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import * as stateActions from "../../../redux/actions/state-actions";

// Components
import Button from "../../components/common/button";
import GetFilterIcon from "../../components/filter-icons";

// Utilities
import { toTitleCase, formatPhoneNumber } from "../../../utilites/format";
import { telemarketingUpdate } from "../../../api/telemarketing";
import {
  dataEntryClaim,
  dataEntryVerifyAvailability,
} from "../../../api/data-entry";
import UseGetEmployeeAnalytics from "../../../utilites/hooks/employeeAnalytics";
import { setEmployeeAnalytics } from "../../../api/employee";

// Assets
import Arrow from "../../../assets/svg/back";

// Styles
import "./data-entry.css";

export default ({ business, agentName, setUpdateLocation, page, idx }) => {
  const {
    status,
    _id,
    phone,
    name,
    address,
    city,
    state,
    zip,
    yelpUrl,
    dateAdded,
    managerName,
    email,
    contactPhone,
    notes,
    signedUp,
    entered,
  } = business;
  const dispatch = useDispatch();
  const { accountType } = useSelector((store) => store.user.employee);
  const employeeAnalytics = UseGetEmployeeAnalytics();
  const [optionalSection, setOptionalSection] = useState(false);
  const [notesInput, setNotesInput] = useState(notes || "");
  const { agent, timeStamp } = signedUp;
  const { isEntered } = entered;
  const isClaimed = status ? status !== agentName : false;
  const claimedByAgment = status === agentName;
  const isAdmin = accountType === "admin" || accountType === "developer";
  const defaultParamas = {
    id: _id,
    notes: notesInput,
  };

  useEffect(() => {
    setOptionalSection(false);
  }, [business]);

  const handleChange = (evt, type, newValue) => {
    const { value } = evt.target;
    switch (type) {
      case "notesInput":
        return setNotesInput(value);
    }
  };

  const handleClaim = async (status) => {
    dispatch(stateActions.setLoader(true));
    const params = {
      ...defaultParamas,
      status,
    };
    if (status && !isAdmin)
      setEmployeeAnalytics({ ...employeeAnalytics, claimed: true });
    const { location } = status
      ? await dataEntryClaim(params)
      : await telemarketingUpdate(params);
    setUpdateLocation(location);
    dispatch(stateActions.setLoader(false));
  };

  const handleRemove = async () => {
    dispatch(stateActions.setLoader(true));
    const params = {
      doNotContact: true,
      ...defaultParamas,
      entered: {
        isEntered: false,
        agent: agentName,
        timeStamp: moment().format("lll"),
      },
    };
    const { location } = await telemarketingUpdate(params);
    setUpdateLocation(location);
    dispatch(stateActions.setLoader(false));
  };

  const handleSave = async () => {
    dispatch(stateActions.setLoader(true));
    const params = {
      ...defaultParamas,
    };
    setEmployeeAnalytics({
      ...employeeAnalytics,
      save_notes: true,
    });
    const { location } = await telemarketingUpdate(params);
    setUpdateLocation(location);
    dispatch(stateActions.setLoader(false));
  };

  const handleCreate = async () => {
    dispatch(stateActions.setLoader(true));
    const { business } = await dataEntryVerifyAvailability(phone);
    dispatch(stateActions.setLoader(false));

    if (business) {
      return dispatch(
        stateActions.setMessage({
          messageType: "error",
          message: "Business Is Registered",
        })
      );
    }

    const params = {
      ...defaultParamas,
      status: agentName,
      entered: {
        isEntered: false,
        agent: agentName,
        timeStamp: moment().format("lll"),
      },
    };
    const { location } = await telemarketingUpdate(params);
    setUpdateLocation(location);
    setEmployeeAnalytics({ ...employeeAnalytics, create: true });
    let url = `https://eventhound.com/business/sign-up?&name=${name}&managerName=${managerName}&email=${email}&promocode=beta2y&terms=true`;
    url +=
      address && !contactPhone
        ? `&address=${address}&city=${city}&state=${state}&zip=${zip}`
        : "";
    url += contactPhone ? `&contactPhone=${contactPhone}` : `&phone=${phone}`;
    window.open(url, "_blank");
  };

  const handleCompleted = async () => {
    dispatch(stateActions.setLoader(true));
    const params = {
      ...defaultParamas,
      status: "",
      entered: {
        isEntered: true,
        agent: agentName,
        timeStamp: moment().format("lll"),
      },
    };
    setEmployeeAnalytics({ ...employeeAnalytics, completed: true });
    const { location } = await telemarketingUpdate(params);
    setUpdateLocation(location);
    dispatch(stateActions.setLoader(false));
  };

  return (
    !isEntered && (
      <div className="card card-form">
        <div className="marketing-card-wrapper">
          <div className="employee-card-wrapper">
            <div className="employee-left-container">
              <h3>{`${page * 50 + idx + 1}. ${toTitleCase({
                input: name,
              })}`}</h3>
              <p>{toTitleCase({ input: managerName })}</p>
              {contactPhone && (
                <p>{`MULTIPLE LOCATIONS: ${formatPhoneNumber({
                  contactPhone,
                })}`}</p>
              )}
              <p>{formatPhoneNumber({ phone })}</p>
              <p>{email}</p>
              {address && <p>{toTitleCase({ input: address })}</p>}
              <p>{toTitleCase({ input: `${city} ${state}, ${zip}` })}</p>
              <label
                className="pointer"
                style={{ marginTop: "10px", fontWeight: "700" }}
              >
                Signed Up By:
              </label>
              <p>{agent}</p>
            </div>

            <div className="employee-right-container">
              {status && (
                <div className="marketing-callback-info-wrapper">
                  {(claimedByAgment || isAdmin) && (
                    <div
                      className="marketing-callback-clear"
                      onClick={() => handleClaim("")}
                    >
                      {GetFilterIcon("close")}
                    </div>
                  )}
                  <p>Claimed By:</p>
                  <p>{status}</p>
                </div>
              )}

              <div className="marketing-title-button-container">
                {!!yelpUrl && (
                  <a
                    className="marketing-yelp-button"
                    href={yelpUrl}
                    target="_blank"
                  >
                    Yelp
                  </a>
                )}
                {!isClaimed && !claimedByAgment && (
                  <Button
                    styles={{ marginLeft: "20px" }}
                    text="claim"
                    size="small"
                    handleClick={() => handleClaim(agentName)}
                  />
                )}
              </div>
              <label style={{ marginTop: "25px" }}>Created Date:</label>
              <p>{dateAdded}</p>

              <label className="pointer" style={{ marginTop: "10px" }}>
                Signed Up Date:
              </label>
              <p>{timeStamp}</p>
            </div>
          </div>

          {claimedByAgment && (
            <div className="card-info-form">
              <div
                className="pointer signup-toggle-split-container"
                onClick={() => setOptionalSection(!optionalSection)}
              >
                <label id="fNameInput" className="pointer label-form">
                  Entry Section
                </label>
                <div
                  className={`signup-toggle-subsection-svg-container ${
                    optionalSection
                      ? "signup-toggle-subsection-svg-container-open"
                      : ""
                  }`}
                >
                  <Arrow />
                </div>
              </div>

              <div
                className={`card-form signup-toggle-subsection ${
                  optionalSection ? "signup-toggle-subsection-open" : ""
                }`}
              >
                <label className="label-form">Notes:</label>
                <textarea
                  className="input-style input-form"
                  rows="5"
                  maxLength="550"
                  value={notesInput}
                  onChange={(evt) => handleChange(evt, "notesInput")}
                  spellCheck="true"
                />

                <div className="marketing-button-wrapper">
                  <Button
                    text="Remove"
                    size="small"
                    isPrimary={false}
                    handleClick={handleRemove}
                  />
                  <Button
                    text="Save Notes"
                    size="small"
                    handleClick={handleSave}
                  />
                  <Button
                    text="Create"
                    size="small"
                    handleClick={handleCreate}
                  />
                  <Button
                    text="Completed"
                    size="small"
                    handleClick={handleCompleted}
                  />
                </div>
                <legend className="legend-form"></legend>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  );
};
