import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import * as dataActions from "../../../redux/actions/data-actions";

// Components
import Button from "../../components/common/button";

// Utilities
import { updateReviewStatus } from "../../../api/review";
import UseGetEmployeeAnalytics from "../../../utilites/hooks/employeeAnalytics";
import { setEmployeeAnalytics } from "../../../api/employee";

// Styles
import "./review-button-styles.css";

export default ({ listingId, businessId, data }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const employeeAnalytics = UseGetEmployeeAnalytics();
  const { fName, lName } = useSelector((store) => store.user.employee);
  const [isUpdating, setIsUpdating] = useState(false);
  const agentName = `${fName} ${lName}`;
  const { reviewed } = data;
  const { isReviewed } = reviewed || {};

  const handleReview = async () => {
    setIsUpdating(true);
    const reviewData = {
      listingId,
      businessId,
      reviewed: {
        isReviewed: true,
        agent: agentName,
        timeStamp: moment().format("lll"),
      },
    };
    const employeeData = { ...employeeAnalytics };
    if (listingId) employeeData.reviewed_listing = true;
    if (businessId) employeeData.reviewed_business = true;
    setEmployeeAnalytics(employeeData);
    const { result } = await updateReviewStatus(reviewData);
    if (result) {
      dispatch(dataActions.updateResultInResults(result));
      history.goBack();
    }
    setIsUpdating(false);
  };

  return (
    !isReviewed && (
      <Button
        text="Pass Review"
        size="large"
        isDisabled={isUpdating}
        handleClick={handleReview}
      />
    )
  );
};
