const url = window.location.href;
const initialState = {
  loader: false,
  message: "",
  messageType: "",
  headerTitle: "",
  image: "",
  map: false,
  mapMoved: false,
  locationKey: "",
  pagePosition: 0,
  savedPage: "",
  paymentModal: false,
  covidModal: false,
  shareDrawer: null,
  promoCode: null,
  isLiveSearch: true,
  searchedReviewType: "Listing",
  agentInput: "",
};

const stateReducer = (state = initialState, action) => {
  const { payload } = action || {};
  switch (action.type) {
    case "setLoader":
      return { ...state, loader: payload };

    case "setMessage":
      const { message, messageType = "" } = payload || {};
      return { ...state, message, messageType };

    case "setHeaderTitle":
      return { ...state, headerTitle: payload };

    case "setImage":
      return { ...state, image: payload || "" };

    case "setMap":
      return { ...state, map: payload };

    case "setMapMoved":
      return { ...state, mapMoved: payload || false };

    case "setLocationKey":
      return { ...state, locationKey: payload };

    case "setPagePosition":
      return { ...state, pagePosition: payload };

    case "setSavedPage":
      return { ...state, savedPage: payload };

    case "setPaymentModal":
      return { ...state, paymentModal: payload };

    case "setCovidModal":
      return { ...state, covidModal: payload };

    case "setShareDrawer":
      return { ...state, shareDrawer: payload };

    case "setPromoCode":
      return { ...state, promoCode: payload };

    case "setIsLiveSearch":
      return { ...state, isLiveSearch: payload };

    case "setAgentInput":
      return { ...state, agentInput: payload };

    case "setSearchedReviewType":
      return { ...state, searchedReviewType: payload };

    default:
      return state;
  }
};

export default stateReducer;
