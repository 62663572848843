import React from "react";

// Utilities
import { buttonStyles } from "../../../../constants/format"

// Styles
import "./app-style-button.css";

const Button = ({
  size = "medium",
  children,
  text = "submit",
  handleClick,
}) => {

  return (
    <button
      className='app-button-wrapper'
      style={buttonStyles.button[size]}
      onClick={handleClick}
    >
      {children && (
        <div className="app-button-svg-container" style={buttonStyles.svg[size]}>
          {children}
        </div>
      )}
      <p className="app-button-text-Button">{text.toUpperCase()}</p>
    </button>
  );
};

Button.defaultProps = {
  size: "medium",
  isPrimary: true,
  text: "",
};

export default Button;
