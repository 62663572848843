import React, { useState } from "react";

// Components
import Button from "../common/button";

// Assets
import Arrow from "../../../assets/svg/back";

// Styles
import "../../routes/sign-up/sign-up.css";
import "./delete-drop-down.css";

const DeleteDropDown = ({ handleDelete }) => {
  const [deleteSection, setDeleteSection] = useState(false);

  const handleClick = (evt) => {
    evt.preventDefault();
    handleDelete();
  };

  return (
    <div
      id="delete-profile-container"
      className="card card-form signup-toggle-section"
    >
      <div className="card-info-form">
        <div
          className="signup-toggle-text-container"
          onClick={() => setDeleteSection(!deleteSection)}
        >
          <label id="fNameInput" className="label-form">
            Delete Profile
          </label>
          <div
            className={`signup-toggle-subsection-svg-container fill-primary ${
              deleteSection ? "signup-toggle-subsection-svg-container-open" : ""
            }`}
          >
            <Arrow />
          </div>
        </div>
        <div
          className={`card-form signup-toggle-subsection ${
            deleteSection ? "signup-toggle-subsection-open" : ""
          }`}
        >
          <div className="card-info-form">
            <div className="submit-wapper-UserSignUp">
              <div className="submit-button-UserSignUp">
                <Button text="Delete" handleClick={handleClick} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteDropDown;
