import React from "react";
import { CSSTransition } from "react-transition-group";

// Styles
import "./bottom-drawer.css";

const BottomDrawer = ({
  title = "EventHound",
  isOpen,
  handleCancelClick,
  children,
}) => {
  return (
    <>
      <CSSTransition in={isOpen} timeout={250} classNames="fade">
        <div
          className="modal-page-click-overlay"
          style={{ zIndex: 990 }}
          onClick={handleCancelClick}
        />
      </CSSTransition>
      <CSSTransition in={isOpen} timeout={200} classNames="bottom-drawer">
        <div className="bottom-drawer-wrapper">
          <p className="bottom-drawer-title">{title}</p>
          {children}
        </div>
      </CSSTransition>
    </>
  );
};

export default BottomDrawer;
