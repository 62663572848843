import React from "react";

export default props => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 25 30"
    >
      <path d="M9.2,9.2c1.9-1.3,1.7-1.6-0.1-5.1C7.4,0.8,6.6-0.1,4.4,1.6L9.2,9.2z" />
      <path d="M16.9,20.7c-2.4,1.7-4.1,0-6.8-3.7c-2.6-3.7-3.6-5.8-1.3-7.5L4.1,1.8c-5.2,3.7-3.8,9.7,2,17.9
		    c5.8,8.3,11.2,11.7,16.4,8c0,0,0,0,0,0L16.9,20.7z"/>
      <path d="M21.9,22.4c-2.6-2.8-2.9-3.1-4.6-2l5.5,7.1C25,26.1,24.3,25.1,21.9,22.4z" />
    </svg>
  );
};
