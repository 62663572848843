import React from "react";

import "./svg.css";

export default () => (
  <svg
    width="100%"
    height="100%"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 70 100"
  >
    <path
      d="M59.5,8.3h-1.1v10.3c0,3.1-2.3,5.8-5,5.8c-2.7,0-5-2.6-5-5.8V8.3h-2.3v10.3c0,3.1-2.3,5.8-5,5.8c-2.7,0-5-2.6-5-5.8V8.3
h-2.3v10.3c0,3.1-2.3,5.8-5,5.8s-5-2.6-5-5.8V8.3h-2.3v10.3c0,3.1-2.3,5.8-5,5.8s-5-2.6-5-5.8V8.3h-1.1c-4.4,0-8,4.2-8,9.3v13.7h65
V17.6C67.5,12.4,63.9,8.3,59.5,8.3z"
    />
    <path d="M16.7,21.4c-1.4,0-2.5-1.3-2.5-2.9V5.2c0-1.6,1.1-2.9,2.5-2.9s2.5,1.3,2.5,2.9v13.5C19.1,20.2,18,21.4,16.7,21.4z" />
    <path d="M28.9,21.4c-1.4,0-2.5-1.3-2.5-2.9V5.2c0-1.6,1.1-2.9,2.5-2.9c1.4,0,2.5,1.3,2.5,2.9v13.5C31.4,20.2,30.2,21.4,28.9,21.4z" />
    <path d="M41.1,21.4c-1.4,0-2.5-1.3-2.5-2.9V5.2c0-1.6,1.1-2.9,2.5-2.9s2.5,1.3,2.5,2.9v13.5C43.6,20.2,42.5,21.4,41.1,21.4z" />
    <path d="M53.3,21.4c-1.4,0-2.5-1.3-2.5-2.9V5.2c0-1.6,1.1-2.9,2.5-2.9c1.4,0,2.5,1.3,2.5,2.9v13.5C55.8,20.2,54.7,21.4,53.3,21.4z" />
    <path
      d="M2.5,35.1v53.3c0,5.1,3.6,9.3,8,9.3h48.9c4.4,0,8-4.2,8-9.3V35.1H2.5z M50.1,76.9c-2,2.4-5,3.7-8.2,3.1
c-2.4-0.3-4.4-1.6-6-3.3l0.6,9.6c0,0.3-0.1,0.5-0.2,0.7c-0.1,0.1-0.3,0.3-0.6,0.3h-2c-0.2,0-0.5-0.1-0.6-0.3
c-0.1-0.1-0.2-0.4-0.2-0.7l0.6-9.6c-1.5,1.8-3.6,3-6,3.3c-3.2,0.4-6.1-0.9-8.2-3.1c1-2,2.6-3.4,4.5-4.3c-0.2-0.1-0.5-0.4-0.7-0.5
c-3.3-3.1-4.9-7.6-4.5-12c3.2-0.8,6.6-0.3,9.4,1.6c0-0.4-0.1-0.7-0.1-1c0-6,2.7-11.3,6.8-14.7c4.1,3.4,6.8,8.6,6.8,14.7
c0,0.4,0,0.8-0.1,1c2.9-1.8,6.2-2.4,9.4-1.6c0.2,4.5-1.2,8.9-4.5,12c-0.2,0.3-0.5,0.4-0.7,0.5C47.5,73.4,49,74.9,50.1,76.9z"
    />
  </svg>
);
