import { useSelector } from "react-redux";
import moment from "moment";

export default function UseGetEmployeeAnalytics() {
  const { _id: employeeId } = useSelector((state) => state.user.employee);

  return {
    employeeId,
    week: moment().isoWeek(),
    day: moment().format("dddd"),
    hour: moment().format("HH"),
  };
}
