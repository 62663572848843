import React from "react";

// styles
import "./vertical-scroll.css";

const VerticalScroll = ({ classNames, children }) => (
  <div className={`verticalScroll-outer-wrapper ${classNames}`}>
    <div className="no-scroll-bars verticalScroll-inner-wrapper">
      {children}
    </div>
  </div>
);

export default VerticalScroll;
