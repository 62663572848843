import { createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

import reducers from "./reducers";

const isDev =
  window.location.href.indexOf("localhost") >= 0 ||
  window.location.href.indexOf("s3") >= 0;

const store = createStore(reducers, isDev ? composeWithDevTools() : undefined);

export default store;
