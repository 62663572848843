import React from "react";

export default props => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 30"
    >
      <path
        className="profile-panda"
        d="M27.9,9.8c1.1-0.8,1.8-2,1.8-3.3c0-1.1-0.5-2.3-1.3-3.3c-0.9-1-2-1.6-3.1-1.6c-1.4,0-2.8,0.8-3.7,2.2
	c-1.8-0.8-3.9-1.4-6.6-1.4H15c-2.6,0-4.8,0.6-6.5,1.4C7.5,2.4,6.1,1.6,4.8,1.6c-1.1,0-2.3,0.6-3.1,1.6c-0.8,1-1.3,2.2-1.3,3.3
	c0,1.2,0.6,2.4,1.7,3.2C0.9,12,0.2,14.4,0.2,17c0,3.3,1.5,6,4.4,8c2.6,1.7,6.3,2.7,10.3,2.7H15c4,0,7.7-1,10.3-2.7
	c2.9-1.9,4.4-4.7,4.4-8C29.8,14.5,29.1,12,27.9,9.8z M24.7,24c-2.4,1.6-5.9,2.5-9.7,2.5c-3.8,0-7.3-0.9-9.7-2.5
	c-2.6-1.7-3.9-4.1-3.9-7c0-2.5,0.7-5,2-7.1c0,0,0,0,0,0c1.2-2.1,3-3.8,5.1-4.8l0,0c1.7-0.9,3.8-1.5,6.5-1.5c5.2,0,8.4,2.4,10.2,4.4
	c2.2,2.5,3.4,5.8,3.4,9.1C28.6,19.9,27.3,22.3,24.7,24z"
      />
      <path
        className="profile-panda"
        d="M17.1,16.8c0,0.7-1.9,1.6-1.9,1.6s-1.9-1-1.9-1.6s0.9-0.8,1.9-0.8S17.1,16.1,17.1,16.8z"
      />
      <path
        className="profile-panda"
        d="M10.4,20.5c3,1.2,4.7-1.2,4.7-1.2v-0.8"
      />
      <path
        className="profile-panda"
        d="M19.9,20.5c-3,1.2-4.7-1.2-4.7-1.2v-0.8"
      />
      <path
        className="profile-glasses"
        d="M25.3,10c0-0.3-0.3-0.6-0.6-0.5c0,0,0,0,0,0L15.8,11h-1.3L5.6,9.5C5.3,9.5,5,9.7,4.9,10c0,0,0,0,0,0c0,0.1-0.3,3.3,1.2,5.2
	c0.7,0.9,1.6,1.3,2.8,1.5c0.3,0,0.5,0,0.8,0c1.4,0,2.5-0.4,3.4-1.2c0.6-0.5,1-1.2,1.3-1.9h1.3c0.3,0.7,0.8,1.4,1.3,1.9
	c0.9,0.8,2,1.2,3.4,1.2c0.3,0,0.5,0,0.8,0c1.2-0.1,2.1-0.6,2.8-1.5C25.6,13.3,25.3,10.2,25.3,10z"
      />
    </svg>
  );
};
