import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as stateActions from "../../../../redux/actions/state-actions";
import * as userActions from "../../../../redux/actions/user-actions";

// Components
import DetailViewLayout from "../../../components/detail-view-scroll-layout";
import SavedAds from "./saved-ads";
import Toggle from "../../../components/common/toggle";
import Promo from "./promo";

// Utilities
import { userPromoCodes } from "../../../../constants/feature-flags";
import { toTitleCase } from "../../../../utilites/format";
import Debounce from "../../../../utilites/hooks/debounce";
import { updateUser } from "../../../../api/user";
// import { subCatOptions } from "../../../../utilites/data-models";

// Styles
import "../profile.css";
import "./user.css";
import "./category-drop-down/category-drop-down.css";

const UserProfile = ({ user, history, savedPage, setUser, setLoader }) => {
  const {
    fName,
    lName,
    email,
    dob,
    location,
    image,
    darkTheme: userThemeSettings,
    promoId,
  } = user;
  const [mounted, setMounted] = useState(false);
  const [darkTheme, setDarkTheme] = useState(userThemeSettings);
  const debouncedDarkThemeValue = Debounce(darkTheme, 500);
  const { address } = location || {};
  const fullName = `${fName} ${lName}`;

  useEffect(() => {
    if (savedPage) {
      history.push(savedPage);
    }
  }, []);

  useEffect(() => {
    if (mounted) {
      const updatedUser = { ...user, darkTheme: debouncedDarkThemeValue };
      setUser({ isUser: true, user: updatedUser });
      updateUser(updatedUser);
    }
    setMounted(true);
  }, [debouncedDarkThemeValue]);

  const handleToggle = (evt, value, params) => {
    switch (params) {
      case "theme":
        return setDarkTheme(value);
    }
  };

  return (
    <DetailViewLayout image={image}>
      <div className="listing-content-container">
        <div className="profile-text-container profile-text-container-left">
          <p>
            {fullName.length > 1 ? toTitleCase({ input: fullName }) : "No Name"}
          </p>
        </div>
        {dob && (
          <div className="profile-text-container profile-text-container-left">
            <p>{dob}</p>
          </div>
        )}
        <div className="profile-text-container profile-text-container-left">
          <p>{email ? toTitleCase({ input: email }) : ""}</p>
        </div>
        <div className="profile-text-container profile-text-container-left">
          <p>{address || "No Location"}</p>
        </div>
        <div className="profile-text-container profile-text-container-split">
          <div className="profile-ad-info-container">
            <p className="">Dark Theme</p>
          </div>
          <div className="profile-ad-manage-container">
            <Toggle
              value={darkTheme}
              handleDebouceToggle={handleToggle}
              params="theme"
            />
          </div>
        </div>
      </div>

      <SavedAds user={user} />

      {userPromoCodes && (
        <Promo
          promoId={promoId}
          email={email}
          setLoader={setLoader}
          setUser={setUser}
        />
      )}

      {/* 
      <div className="listing-content-container">
        <div className="profile-text-container profile-text-container-split">
          <div className="profile-ad-info-container">
            <p className="">Notifications</p>
          </div>
          <div className="profile-ad-manage-container">
            <Toggle
              value={notifications}
              handleDebouceToggle={(evt, value) => setNotifications(value)}
              params="theme"
            />
          </div>
        </div>
        {notifications &&
          Object.keys(subCatOptions).map((cat, idx) => (
            <CatergoryDropDown cat={cat} key={idx} />
          ))}
      </div> */}
    </DetailViewLayout>
  );
};

const mapStateToProps = (store) => ({
  savedPage: store.state.savedPage,
  user: store.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (userObj) => dispatch(userActions.setUser(userObj)),
  setLoader: (loaderState) => dispatch(stateActions.setLoader(loaderState)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserProfile));
