import { callAPI } from ".";
import { baseURL } from "./url";

const corporateURL = baseURL("/corporate");

export function createCorporate(data) {
  return callAPI(corporateURL, "PUT", data);
}

export function editCorporate(data) {
  return callAPI(corporateURL, "POST", data);
}

export function deleteCorporate({ id, mainBusinessId }) {
  const url = `${corporateURL}?id=${id}&mainBusinessId=${mainBusinessId}`;
  return callAPI(url, "DELETE");
}
