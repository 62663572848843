import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as stateActions from "../../../redux/actions/state-actions";
import moment from "moment";

// Components
import Button from "../../components/common/button";
import businessLoader from "./business-loader";
import DetailViewLayout from "../../components/detail-view-scroll-layout";
import Bookmark from "../listing/bookmark";
import ShareButton from "../../components/common/share/button";
import Features from "../listing/features";
import ButtonLink from "./button-link";
import AdMetaData from "../listing/ad-meta-data";
import Yelp from "../listing/yelp";
import Contact from "../listing/contact";
import Social from "./social";
import OtherAds from "../listing/other-ads";
import Modal from "../../components/modal";
import ReviewButton from "../../components/review-button";

// Utilities
import UseGetTheme from "../../../utilites/hooks/useGetTheme";
import { formatListingTime } from "../../../utilites/format";
import { getBusinessStatus } from "../../../utilites/status";
import { militaryTimeToReadable } from "../../../utilites/time";
import { getCurrentDate, convertDateToDbDate } from "../../../utilites/date";
import { viewedBusiness } from "../../../api/business";
import { covidMessage } from "../../../constants/data-models";

// Assets
import Arrow from "../../../assets/svg/back";
import Covid from "../../../assets/svg/covid";

//Styles
import "../listing/listing.css";
import "./business.css";

const Business = ({ business, marketing, history }) => {
  const { user } = useSelector((store) => store.user);
  const [fullHours, setFullHours] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const { userId } = user || {};
  const { isNight } = UseGetTheme();
  const {
    _id: businessId,
    name,
    hours,
    images,
    description,
    categories,
    features,
    yelp,
    ads,
    analyticsId,
    deliveryLink,
    covid,
  } = business || {};
  const { covidCompliant } = covid || {};
  const dispatch = useDispatch();
  const formattedBusiness = formatListingTime({ business });
  const { isDateCurrent, status } = getBusinessStatus(formattedBusiness);
  const dayOfTheWeek = moment().format("dddd");
  const isDarkColor = !isNight && status === "starting";
  const cardHeaderStyle = isDateCurrent
    ? `listing-header-container-${status}`
    : "listing-header-container-starting";
  const isActiveStatus = status === "active" || status === "ending";
  const { signedUp, entered } = marketing || {};
  const { agent: signedUpAgent, timeStamp: signedUpTimeStamp } = signedUp || {};
  const { agent: enteredAgent, timeStamp: enteredTimeStamp } = entered || {};

  useEffect(() => {
    if ((!userId && analyticsId) || (userId !== businessId && analyticsId)) {
      const currentDate = getCurrentDate();
      viewedBusiness(analyticsId, businessId, convertDateToDbDate(currentDate));
    }
  }, []);

  const renderDay = (day, idx = 0) => {
    const { isOpen, open, close } = hours[day];
    const isToday = day === dayOfTheWeek;
    return (
      <div
        className={`business-hour-conatiner result-card-${
          isToday ? status : "none"
        } ${
          isToday
            ? "business-hour-conatiner-active"
            : "business-hour-conatiner-inactive"
        }`}
        style={{ animationDelay: `${idx * 60}ms` }}
        key={day}
      >
        <p className="business-hour-item">{day}</p>
        {isOpen ? (
          <>
            <p className="business-hour-item">{militaryTimeToReadable(open)}</p>
            <p className="business-hour-item">
              {militaryTimeToReadable(close)}
            </p>
          </>
        ) : (
          <>
            <p className="business-hour-item">Closed</p>
            <p className="business-hour-item">Closed</p>
          </>
        )}
      </div>
    );
  };

  const renderHours = () => {
    if (!fullHours) return renderDay(dayOfTheWeek);
    return (
      <div className="business-hours-container">
        {Object.keys(hours).map((day, key) => {
          return renderDay(day, key);
        })}
      </div>
    );
  };

  return (
    <>
      <DetailViewLayout images={images} name={name} status={status}>
        {covidCompliant && (
          <div
            className="business-card-covid"
            onClick={(evt) => {
              evt.stopPropagation();
              setModalMessage(covidMessage(name));
            }}
          >
            <Covid />
          </div>
        )}
        <div className="listing-content-container">
          <div className="check-in-container">
            <div className="listing-marketing-metedata-conatiner">
              <p className="listing-marketing-metedata">{`Signed Up Agent: ${signedUpAgent}`}</p>
              <p className="listing-marketing-metedata">{`Signed Up Time: ${signedUpTimeStamp}`}</p>
              <p className="listing-marketing-metedata">{`Entered By: ${enteredAgent}`}</p>
              <p className="listing-marketing-metedata">{`Entered Time: ${enteredTimeStamp}`}</p>
            </div>
          </div>
        </div>
        <div className="listing-content-container">
          <div className={`listing-header-container ${cardHeaderStyle}`}>
            {/* <Bookmark data={business} status={status} /> */}
            <ShareButton
              size="30px"
              isDarkColor={isDarkColor}
              title={name}
              name={name}
            />
            <p
              className={`listing-title-main ${
                isDarkColor ? "listing-title-main-dark" : ""
              }`}
            >
              {name.toUpperCase()}
            </p>
          </div>

          <div className="business-sub-content-container">
            <p className="listing-description">{description}</p>
          </div>

          <div
            className="pointer listing-sub-content-container listing-split-content"
            onClick={() => setFullHours(!fullHours)}
          >
            <p className="listing-business-hours">Hours:</p>
            <div
              className={`signup-toggle-subsection-svg-container ${
                fullHours ? "signup-toggle-subsection-svg-container-open" : ""
              }`}
            >
              <Arrow />
            </div>
          </div>
          {renderHours()}

          {isActiveStatus && <ButtonLink link={deliveryLink} />}
          <AdMetaData data={business} />
        </div>

        <Features
          name={name}
          categories={categories}
          features={features}
          adStatus={status}
        />
        <OtherAds isAd={false} ads={ads} name={name} />
        <Yelp
          yelp={yelp}
          name={name}
          setImage={(value) => dispatch(stateActions.setImage(value))}
        />
        <Contact business={business} status={status} />
        <Social business={business} />
      </DetailViewLayout>

      <div className="confirm-button-wrapper">
        <div className="confirm-button-container">
          <ReviewButton size="large" businessId={businessId} data={business} />
          <Button
            className="submit-button-CreateListing"
            isPrimary={false}
            text="Edit"
            size="large"
            handleClick={() => {
              history.push(`/business/profile/${businessId}`);
            }}
          />
        </div>
      </div>

      <Modal
        directModalMessage={!!modalMessage}
        modalAccept="Ok"
        handleAcceptClick={() => setModalMessage("")}
      >
        <div className="modal-covid-icon">
          <Covid />
        </div>
        <p>{modalMessage}</p>
        <a
          className="results-covid-modal-link"
          href="https://files.covid19.ca.gov/pdf/guidance-dine-in-restaurants.pdf"
          target="_blank"
        >
          CDC Guildlines
        </a>
        <p className="results-covid-modal-legal">
          EventHound is not responsible for claims made by the businesses.
        </p>
      </Modal>
    </>
  );
};

export default businessLoader(Business);
