import React from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";

// Components
import VerticalScroll from "../../../components/vertical-scroll";

// Utilities
import { getAdStatus } from "../../../../utilites/status";
import { formatListingTime, toTitleCase } from "../../../../utilites/format";
import { formatDbDateToReadable } from "../../../../utilites/date";

// Assets
import Skyline from "../../../../assets/svg/skyline";

// Styles
import "./other-ads.css";

const OtherAds = ({ isAd = true, currentId = "", ads, name, history }) => {
  const verifyDate = (days) => {
    const { dateAvailable } = days || {};
    const currentDate = moment().format();
    const formattedDate = formatDbDateToReadable({
      date: dateAvailable,
      toFullYear: true,
    });
    return moment(formattedDate).isSameOrAfter(currentDate, "day");
  };

  const filterAds = ads.filter(({ _id, days }) => {
    const isDateValid =
      days && "dateAvailable" in days && days.dateAvailable
        ? verifyDate(days)
        : true;
    return currentId !== _id && isDateValid;
  });

  return (
    !!filterAds.length && (
      <div className="listing-content-container">
        <div className="other-ads-specs-container">
          <p>
            {`${isAd ? "More" : "Events"} hosted by: `}
            <span className="other-ads-name">
              {toTitleCase({ input: name, lowerCaseRest: false })}
            </span>
          </p>
        </div>
        <VerticalScroll>
          {filterAds.map((ad) => {
            const { _id, title, categories } = ad;
            const { cat1Img } = categories;
            const formattedAd = formatListingTime({ ad });
            const { status } = getAdStatus(formattedAd);
            return (
              <div
                className={`pointer other-ads-container result-card-${status}`}
                onClick={() => history.push(`/listing/${_id}`)}
                key={_id}
              >
                {cat1Img ? (
                  <img className="other-ads-image-" alt={name} src={cat1Img} />
                ) : (
                  <div className="other-ads-image-">
                    <Skyline />
                  </div>
                )}
                <div
                  className={`result-card-overlay result-card-overlay-gradient`}
                />
                <div
                  className={`result-card-overlay result-card-status-overlay result-card-overlay-${status}`}
                />
                <div className="other-ads-title-conatiner">
                  <p className="other-ads-title">
                    {toTitleCase({ input: title, lowerCaseRest: false })}
                  </p>
                </div>
              </div>
            );
          })}
        </VerticalScroll>
      </div>
    )
  );
};

export default withRouter(OtherAds);
