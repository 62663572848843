import React, { useState, useEffect } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import moment from "moment";
import { CSSTransition } from "react-transition-group";
import { connect } from "react-redux";
import * as stateActions from "../../../redux/actions/state-actions";
import * as userActions from "../../../redux/actions/user-actions";

// Components
import SwitchButton from "../common/switch-button";
import Checkbox from "../common/checkbox";
import Button from "../common/button";

// Utilities
import useIsBusiness from "../../../utilites/hooks/useIsBusiness";
import { toTitleCase } from "../../../utilites/format";
import {
  getPricing,
  processPayment,
  processUpgradePayment,
  cancelAutoPay,
} from "../../../api/payment";
import { applyBusinessPromo } from "../../../api/business";

// Assets
import ComparisonChart from "../../../assets/svg/comparison-chart.js";

// Styles
import "./payment-modal.css";

const getSubscriptionType = (accountType) => {
  switch (accountType) {
    case "unlimited":
      return accountType;
    case "premium":
      const isEditPage = window.location.href.indexOf("business/listing/") > -1;
      if (isEditPage) return "unlimited";
      return accountType;
  }
  return "premium";
};

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#505050",
      color: "#505050",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#4d97ff" },
      "::placeholder": { color: "#999b9c" },
    },
    invalid: {
      iconColor: "#ff1a6a",
      color: "#ff1a6a",
    },
  },
};

const PaymentModal = ({
  user,
  paymentModal,
  setPaymentModal,
  setMessage,
  updateUser,
  setLoader,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const isBusiness = useIsBusiness();
  const {
    _id: businessId,
    name: userName,
    phone,
    email: userEmail,
    account,
  } = user || {};
  const {
    accountType,
    autoPay: accountAutoPay,
    last4,
    subscriptionCalenderEndDate,
    customerId,
  } = account || {};
  const { enabled: autoPayEnabled, duration: autoPayDuration } =
    accountAutoPay || {};
  const [subscriptionType, setSubscriptionType] = useState(
    getSubscriptionType(accountType)
  );
  const [annually, setAnnually] = useState(false);
  const [name, setName] = useState(userName);
  const [savePaymentInfo, setSavePaymentInfo] = useState(false);
  const [autoPay, setAutoPay] = useState(false);
  const [email, setEmail] = useState(userEmail);
  const [useSavedCard, setUseSavedCard] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [paymentModel, setPaymentModel] = useState({});
  const { plans, upgrade } = paymentModel || {};
  const { monthlyPrice, annualPrice } = plans ? plans[subscriptionType] : {};
  const currentDate = moment().format();
  const isPremium = subscriptionType === "premium";
  const isEditPage = window.location.href.indexOf("business/listing/") > -1;
  const accountIsActive =
    subscriptionCalenderEndDate &&
    moment(subscriptionCalenderEndDate).isSameOrAfter(currentDate);
  const accountStatueText = accountIsActive
    ? `${toTitleCase({ input: accountType })} account is valid to: `
    : `${toTitleCase({ input: accountType })} account expired on: `;

  useEffect(() => {
    (async () => setPaymentModel(await getPricing(businessId)))();
  }, [user]);

  useEffect(() => {
    setSubscriptionType(getSubscriptionType(accountType));
  }, [paymentModal]);

  const handlePromo = async (evt) => {
    evt.preventDefault();
    if (promoCode.length >= 5) {
      setLoader(true);
      const { user } = await applyBusinessPromo({
        phone,
        promoCode: promoCode.toLowerCase(),
        currentDate,
      });
      if (user) {
        updateUser(user);
        setMessage({ message: "Promo Successful!" });
        setPromoCode("");
        setPaymentModal(false);
      }
      setLoader(false);
    }
  };

  const handleSwitch = (name) => {
    if (name === "PREMIUM") {
      const currentPlanIsUnlimited = accountType === "unlimited";
      if (accountIsActive && currentPlanIsUnlimited) {
        return setMessage({
          messageType: "error",
          message: "Cannot downgrade with an active unlimited account",
        });
      }
      setAnnually(false);
      setSubscriptionType("premium");
    } else {
      setSubscriptionType("unlimited");
    }
  };

  const handleChange = (evt, type, newValue) => {
    const { value } = evt.target;
    switch (type) {
      case "annually":
        return setAnnually(newValue);
      case "cardName":
        return setName(value);
      case "savePaymentInfo":
        if (newValue) setUseSavedCard(false);
        return setSavePaymentInfo(newValue);
      case "autoPay":
        setSavePaymentInfo(newValue);
        return setAutoPay(newValue);
      case "email":
        return setEmail(value);
      case "savedCard":
        if (newValue) setSavePaymentInfo(false);
        return setUseSavedCard(newValue);
      case "promoCode":
        return setPromoCode(value.slice(0, 6));
    }
  };

  const clearForm = () => {
    setPaymentModal(false);
    setSavePaymentInfo(false);
    setUseSavedCard(false);
    setAutoPay(false);
  };

  const handleCancel = (evt) => {
    evt.preventDefault();
    clearForm();
  };

  const createNewPayment = async () => {
    if (!name || (savePaymentInfo && !email))
      return setMessage({ messageType: "error", message: "Missing Info" });
    setLoader(true);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (error) {
      const { message } = error;
      setMessage({ messageType: "error", message });
      return setLoader(false);
    }

    const { id: paymentId } = paymentMethod;
    return { paymentId };
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setLoader(true);
    const paymentData = {
      subscriptionType,
      annually,
      autoPay,
      businessId,
      name,
      email,
      currentDate,
    };
    if (savePaymentInfo) paymentData.savePaymentInfo = savePaymentInfo;
    if (isEditPage)
      paymentData.listingId = window.location.href.split("/").pop();
    if (useSavedCard) {
      paymentData.customerId = customerId;
    } else {
      const { paymentId } = await createNewPayment();
      paymentData.paymentId = paymentId;
    }
    if (!paymentData.paymentId && !paymentData.customerId) {
      setLoader(false);
      return setMessage({ message: "Missing Info" });
    }

    const isUpgrade = !isPremium && upgrade;
    const { account } = isUpgrade
      ? await processUpgradePayment(paymentData)
      : await processPayment(paymentData);
    setLoader(false);
    if (account) {
      updateUser({ account });
      setMessage({ message: "Payment Successful!" });
      clearForm();
    } else {
      setMessage({ message: "Charge Failed" });
    }
  };

  const handleCancelAutoPay = async () => {
    setPaymentModal(false);
    setLoader(true);
    const { success } = await cancelAutoPay(businessId);
    if (success) {
      updateUser({
        account: {
          ...user.account,
          autoPay: {
            enabled: false,
          },
        },
      });
      setMessage({ message: success });
      clearForm();
    }
    setLoader(false);
  };

  const createOptions = (end, start = 1) => {
    const options = [];
    for (let i = start; i <= end; i++) {
      options.push(
        <option value={i} key={i}>
          {i}
        </option>
      );
    }
    return options;
  };

  const getUpgradeText = () => {
    const { upgradePrice, daysLeft } = upgrade || {};
    if (upgradePrice)
      return `Your PREMIUM account is valid for ${daysLeft} more days. Or you can upgrade now to UNLIMITED for only $${upgradePrice}`;
    return "";
  };

  return (
    isBusiness && (
      <>
        <CSSTransition in={paymentModal} timeout={250} classNames="fade">
          <div
            className="modal-page-click-overlay"
            style={{ zIndex: 990 }}
            onClick={() => setPaymentModal(false)}
          />
        </CSSTransition>
        <CSSTransition in={paymentModal} timeout={250} classNames="top-slide">
          <div className="no-scroll-bars modal-wrapper" style={{ zIndex: 991 }}>
            <div className="payment-wrapper">
              <div className="comparison-chart-container">
                <ComparisonChart />
              </div>

              {subscriptionCalenderEndDate && (
                <p className="payment-status-text">
                  {accountStatueText}
                  <span
                    className={
                      accountIsActive
                        ? "payment-status-active"
                        : "payment-status-expired"
                    }
                  >
                    {moment(subscriptionCalenderEndDate).format("L")}
                  </span>
                </p>
              )}

              {autoPayEnabled ? (
                <>
                  <p className="payment-row auto-pay-text">{`Your account is scheduled to auto pay. You are subscribed to the ${toTitleCase(
                    { input: accountType }
                  )} plan. The next charge will be processed on ${moment(
                    subscriptionCalenderEndDate
                  )
                    .subtract(1, "days")
                    .format("LL")} in the amount of $${
                    plans
                      ? plans[subscriptionType][
                          autoPayDuration === "annually"
                            ? "annualPrice"
                            : "monthlyPrice"
                        ]
                      : ""
                  }.`}</p>
                  <div
                    className="payment-row payment-form-group"
                    style={{
                      margin: "25px 0px 10px 0px",
                      padding: "0px 25px",
                    }}
                  >
                    <Button
                      text="close"
                      size="small"
                      isPrimary={false}
                      handleClick={(evt) => handleCancel(evt)}
                    />
                    <Button
                      text="Cancel"
                      size="small"
                      handleClick={(evt) => handleCancelAutoPay(evt)}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="payment-selector-container"
                    style={{
                      marginTop: subscriptionCalenderEndDate ? "15px" : "5px",
                    }}
                  >
                    <SwitchButton
                      button1Name="PREMIUM"
                      button2Name="UNLIMITED"
                      isFirst={isPremium}
                      handleClick={handleSwitch}
                    />
                  </div>

                  {isPremium ? (
                    <div className="payment-row payment-form-group">
                      <div />
                      <div className="checkbox-inner-wrapper-CreateListing">
                        <label className="payment-checkbox-label">{`$${monthlyPrice} Monthly`}</label>
                        <Checkbox
                          state={!annually}
                          handleClick={handleChange}
                          params={[
                            { target: { value: "" } },
                            "annually",
                            false,
                          ]}
                        />
                      </div>
                      <div />
                    </div>
                  ) : (
                    <div className="payment-row payment-form-group">
                      <div />
                      {upgrade ? (
                        <p className="payment-upgrade-text">
                          {getUpgradeText()}
                        </p>
                      ) : (
                        <>
                          <div className="checkbox-inner-wrapper-CreateListing">
                            <label className="payment-checkbox-label">
                              {`$${monthlyPrice} Monthly`}
                            </label>
                            <Checkbox
                              state={!annually}
                              handleClick={handleChange}
                              params={[
                                { target: { value: "" } },
                                "annually",
                                false,
                              ]}
                            />
                          </div>

                          <div className="checkbox-inner-wrapper-CreateListing">
                            <label className="payment-checkbox-label">{`$${annualPrice} Annually`}</label>
                            <Checkbox
                              state={annually}
                              handleClick={handleChange}
                              params={[
                                { target: { value: "" } },
                                "annually",
                                true,
                              ]}
                            />
                          </div>
                        </>
                      )}
                      <div />
                    </div>
                  )}

                  <form
                    className="payment-form-container"
                    onSubmit={handleSubmit}
                  >
                    {customerId && last4 && (
                      <div className="payment-row payment-row-right">
                        <label className="payment-label-adjustment">
                          {`Use your card ending in ${last4}`}
                        </label>
                        <Checkbox
                          state={useSavedCard}
                          handleClick={handleChange}
                          params={[
                            { target: { value: "" } },
                            "savedCard",
                            !useSavedCard,
                          ]}
                        />
                      </div>
                    )}

                    {!useSavedCard && (
                      <>
                        <label className="payment-row">NAME</label>
                        <input
                          type="text"
                          className="payment-form-cardNumber"
                          placeholder="Name on Card"
                          name="cardName"
                          value={name}
                          onChange={(evt) => handleChange(evt, "cardName")}
                        />

                        <fieldset className="FormGroup">
                          <div className="FormRow">
                            <CardElement options={CARD_OPTIONS} />
                          </div>
                        </fieldset>

                        {!autoPay && (
                          <div className="payment-row payment-row-right">
                            <label className="payment-label-adjustment">
                              Save Payment Info
                            </label>
                            <Checkbox
                              state={savePaymentInfo}
                              handleClick={handleChange}
                              params={[
                                { target: { value: "" } },
                                "savePaymentInfo",
                                !savePaymentInfo,
                              ]}
                            />
                          </div>
                        )}

                        {savePaymentInfo && (
                          <>
                            <label className="payment-row">EMAIL</label>
                            <input
                              type="text"
                              className="payment-form-cardNumber"
                              placeholder="Email"
                              name="email"
                              value={email}
                              onChange={(evt) => handleChange(evt, "email")}
                            />
                          </>
                        )}
                      </>
                    )}

                    <div className="payment-row payment-row-right">
                      <label className="payment-label-adjustment">
                        Enable Auto Pay
                      </label>
                      <Checkbox
                        state={autoPay}
                        handleClick={handleChange}
                        params={[
                          { target: { value: "" } },
                          "autoPay",
                          !autoPay,
                        ]}
                      />
                    </div>

                    <div className="payment-promo-container">
                      <p className="profile-account-promo-text">PROMO CODE</p>
                      <div className="payment-form-group">
                        <input
                          type="text"
                          className="payment-form-promoCode"
                          placeholder="Enter code"
                          name="promoCode"
                          value={promoCode}
                          onChange={(evt) => handleChange(evt, "promoCode")}
                        />
                        <Button
                          text="Apply"
                          size="small"
                          handleClick={handlePromo}
                        />
                      </div>
                    </div>

                    <div
                      className="payment-row payment-form-group"
                      style={{
                        margin: "25px 0px 10px 0px",
                        padding: "0px 25px",
                      }}
                    >
                      <Button
                        text="Close"
                        size="small"
                        isPrimary={false}
                        handleClick={(evt) => handleCancel(evt)}
                      />
                      <Button
                        text="Submit"
                        size="small"
                        handleClick={(evt) => handleSubmit(evt)}
                      />
                    </div>
                  </form>
                </>
              )}
            </div>
          </div>
        </CSSTransition>
      </>
    )
  );
};

const mapStateToProps = (store) => ({
  user: store.user.user,
  paymentModal: store.state.paymentModal,
});

const mapDispatchToProps = (dispatch) => ({
  setPaymentModal: (boolean) => dispatch(stateActions.setPaymentModal(boolean)),
  setMessage: (messageObj) => dispatch(stateActions.setMessage(messageObj)),
  updateUser: (userObj) => dispatch(userActions.updateUser(userObj)),
  setLoader: (loaderState) => dispatch(stateActions.setLoader(loaderState)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentModal);
