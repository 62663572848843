import React from "react";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 16 16"
    >
      <path d="M15.4,15.2H0.6c-0.2,0-0.3-0.1-0.3-0.3s0.1-0.3,0.3-0.3h14.8c0.2,0,0.3,0.1,0.3,0.3C15.7,15.1,15.6,15.2,15.4,15.2z" />
      <path
        d="M7.6,10.1l-1.8-5V5H3.6v0.1l-1.9,5.3c0,0.2,0,0.3,0,0.5c0,0.2,0,0.3,0,0.5l0.6,2.3l0.4,1.2c0.1,0.2,0.2,0.3,0.4,0.3h1.7h1.7
	c0.1,0,0.2-0.1,0.3-0.2l0.9-3.6c0-0.2,0-0.3,0-0.5c0-0.2,0-0.4,0-0.5C7.7,10.3,7.6,10.2,7.6,10.1z M4.9,9.2c-0.1,0-0.1,0.1-0.2,0.1
	s-0.2,0-0.2-0.1C4.4,9.2,4.4,9,4.4,9c0-0.1,0-0.2,0.1-0.2c0-0.1,0.1-0.2,0.2-0.2s0.2,0,0.2,0.1C5,8.9,5,9,5,9C5,9.2,5,9.2,4.9,9.2z
	 M4.9,8.2C4.8,8.3,4.8,8.3,4.7,8.3c-0.1,0-0.2,0-0.2-0.1C4.4,8.2,4.4,8.1,4.4,8c0-0.1,0-0.2,0.1-0.2c0-0.1,0.1-0.1,0.2-0.1
	c0.1,0,0.2,0,0.2,0.1C5,7.8,5,7.9,5,8C5,8.1,5,8.2,4.9,8.2z M4.9,7.2C4.8,7.3,4.8,7.4,4.7,7.4c-0.1,0-0.2,0-0.2-0.1
	C4.4,7.2,4.4,7.1,4.4,7c0-0.1,0-0.2,0.1-0.2c0,0,0.1-0.1,0.2-0.1c0.1,0,0.2,0,0.2,0.1C5,6.9,5,6.9,5,7C5,7.1,5,7.2,4.9,7.2z
	 M4.9,6.3C4.8,6.4,4.8,6.4,4.7,6.4c-0.1,0-0.2,0-0.2-0.1C4.4,6.2,4.4,6.2,4.4,6.1c0-0.1,0-0.2,0.1-0.2c0-0.1,0.1-0.1,0.2-0.1
	c0.1,0,0.2,0,0.2,0.1C5,6,5,6,5,6.1C5,6.2,5,6.3,4.9,6.3z"
      />
      <path
        d="M3.6,4.2h2.2v0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0-0.3,0.1-0.6,0.3-0.9c0.1-0.3,0.2-0.5,0.2-0.8c0-0.9-0.7-1.6-1.6-1.6H4.6
	c-0.8,0-1.5,0.6-1.5,1.5v0.1c0,0.2,0,0.5,0.2,0.8C3.5,3.5,3.6,3.8,3.6,4.2z"
      />
      <path d="M10.4,7.8c-0.2,0-0.3,0.1-0.3,0.3c0,0.2,0.1,0.3,0.3,0.3s0.3-0.1,0.3-0.3C10.7,7.9,10.6,7.8,10.4,7.8z" />
      <path d="M12.3,9C12.1,9,12,9.1,12,9.3c0,0.2,0.1,0.3,0.3,0.3c0.2,0,0.3-0.1,0.3-0.3C12.6,9.1,12.5,9,12.3,9z" />
      <circle cx="10.4" cy="10.2" r="0.3" />
      <path
        d="M10.5,4.7c-1.4,0-2.6,0.5-3.6,1.5C6.8,6.3,6.8,6.3,6.8,6.5l1.3,3.6l0.1,0.2c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3l-0.7,2.8
		c0,0.2,0,0.2,0.1,0.3c0.9,0.6,1.9,0.9,2.9,0.9c0.5,0,1.1-0.1,1.6-0.3l0.2-0.1c0.5-0.2,2.2-1.2,2.7-2.2c0.4-0.8,0.7-1.7,0.7-2.6
		C15.7,7,13.4,4.7,10.5,4.7z M10.4,7c0.5,0,1,0.4,1,1c0,0.6-0.5,1-1,1s-1-0.4-1-1C9.4,7.4,9.8,7,10.4,7z M10.4,11.2
		c-0.5,0-1-0.4-1-1c0-0.5,0.4-1,1-1c0.5,0,1,0.4,1,1C11.3,10.8,10.9,11.2,10.4,11.2z M12.3,10.2c-0.5,0-1-0.4-1-1c0-0.6,0.4-1,1-1
		c0.5,0,1,0.4,1,1C13.3,9.8,12.8,10.2,12.3,10.2z M11.4,5.3c0.8,0,2.1,0.8,2.4,1.5L13,6.1c-0.2-0.1-0.4-0.3-0.6-0.4L11.4,5.3z"
      />
    </svg>
  );
};
