import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import lottie from "lottie-web";
import moment from "moment";
import { useDispatch } from "react-redux";
import * as stateActions from "../../../../../redux/actions/state-actions";
import * as userActions from "../../../../../redux/actions/user-actions";

// Components
import Toggle from "../../../../components/common/toggle";
import Modal from "../../../../components/modal";

// Assets
import Trash from "../../../../../assets/svg/trash";
import Skyline from "../../../../../assets/svg/skyline";
import CirclePulse from "../../../../../assets/lotti/circle-pulse-2.json";

// Utilities
import Debounce from "../../../../../utilites/hooks/debounce";
import { charLimitTo } from "../../../../../utilites/format";
import { updateAdInAds } from "../../../../../utilites/update";
import { updateListing } from "../../../../../api/listing";
import { setBusinessFree } from "../../../../../api/business";

// Styles
import "../../../list-view/event-card/result.css";
import "../../user/saved-ads/saved-ad.css";
import "./posted-ad.css";

const PostedAd = ({ ad, ads, setAds, handleDelete }) => {
  const { _id, isActive, title, categories } = ad;
  const { cat1Img } = categories;
  const isMounted = useRef(false);
  const isUniqueMark = useRef(false);
  const uniqueMark = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const [modalMessage, setModalMessage] = useState("");
  const [toggleValue, setToggleValue] = useState(isActive);
  const debouncedToggleValue = Debounce(toggleValue, 200);

  useEffect(() => {
    setToggleValue(isActive);
    isMounted.current = true;
    if (uniqueMark.current && !isUniqueMark.current) {
      lottie.loadAnimation({
        container: uniqueMark.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
        animationData: CirclePulse,
      });
      isUniqueMark.current = true;
    }
  }, [isActive]);

  useEffect(() => {
    if (isActive !== debouncedToggleValue) {
      const updateAd = { ...ad, isActive: debouncedToggleValue };
      setAds(updateAdInAds(ads, updateAd));
      updateListing(updateAd);
    }
  }, [debouncedToggleValue]);

  const handleToggle = (evt, value) => {
    return setToggleValue(value);
  };

  const handleSetFreeAd = async () => {
    setModalMessage("");
    dispatch(stateActions.setLoader(true));
    const listingData = { listingId: _id, date: moment().format() };
    const { user } = await setBusinessFree(listingData);
    if (user) {
      dispatch(userActions.setUser({ isUser: false, user }));
      dispatch(stateActions.setMessage({ message: "Your ad is active" }));
    }
    dispatch(stateActions.setLoader(false));
  };

  return (
    <>
      <div
        className={`card saved-ad-wrapper ${
          isActive ? "posted-ad-active" : "posted-ad-inactive"
        }`}
        style={{
          backgroundImage: `url(${cat1Img})`,
        }}
        onClick={() => history.push(`/business/listing/${_id}`)}
      >
        {!cat1Img && (
          <div className="saved-ad-skyline-overlay">
            <Skyline />
          </div>
        )}
        <div className="result-card-overlay posted-ad-overlay-gradient" />
        {isActive && (
          <div className="result-card-overlay result-card-status-overlay result-card-overlay-active" />
        )}
        <div className="saved-ad-container">
          <div
            className="saved-ad-sub-container-split"
            style={{ alignItems: "flex-start" }}
          >
            <div className="posted-ad-flex-container"></div>
            <Toggle value={toggleValue} handleDebouceToggle={handleToggle} />
          </div>
          <div className="saved-ad-sub-container-split">
            <p className="saved-ad-title">
              {charLimitTo(title, 35).toUpperCase()}
            </p>
            <div
              className="profile-ad-delete-container"
              onClick={(evt) => {
                evt.stopPropagation();
                handleDelete(ad);
              }}
            >
              <Trash />
            </div>
          </div>
        </div>
      </div>
      <Modal
        directModalMessage={modalMessage}
        modalAccept="accept"
        modalCancel="Cancel"
        handleCancelClick={() => setModalMessage("")}
        handleAcceptClick={handleSetFreeAd}
      />
    </>
  );
};

export default PostedAd;
