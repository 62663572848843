import React from "react";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 37 26"
    >
      <g transform="translate(0,-952.36218)">
        <path
          d="M5.2,953c-2.3,0-4.3,1.9-4.3,4.1v16.4c0,2.3,1.9,4.1,4.3,4.1h26.5c2.3,0,4.3-1.9,4.3-4.1v-16.4c0-2.3-1.9-4.1-4.3-4.1H5.2z
		 M6.2,955.7h24.6l-12.3,9.7L6.2,955.7z M33.2,957.4v16.2c0,0.8-0.6,1.4-1.4,1.4H5.2c-0.8,0-1.4-0.6-1.4-1.4v-16.1l13.8,10.8
		c0.5,0.4,1.3,0.4,1.8,0L33.2,957.4z"
        />
      </g>
    </svg>
  );
};
