import React from "react";

// Styles
import "./svg.css";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 30"
    >
      <path
        d="M6.6,10.8c-0.7,0-1.7,0.1-2.4,0.2C4.6,10,4.9,8.9,5,7.5h3.1C8.3,8.9,8.6,10,8.9,11C8.3,10.9,7.3,10.8,6.6,10.8z M4.4,11.6
		c0.4,0.1,1.2,0.2,2.3,0.2c1.1,0,1.8-0.1,2.3-0.2c-0.4-0.1-1.2-0.2-2.3-0.2S4.8,11.5,4.4,11.6z M5.2,4.7c0,0.8,0,1.5-0.1,2.1h3
		C8,6.2,8,5.5,8,4.7V2.6h0.4V1.5H8v-1H5.2v1H4.8v1.1h0.4V4.7z M6.8,17.4H2.3v8.7h4.5V17.4z M9.8,13.5c-0.2-0.4-0.4-0.9-0.6-1.4
		c-0.6,0.2-1.8,0.3-2.7,0.3c-0.9,0-2.1-0.1-2.7-0.3c-0.2,0.5-0.4,1-0.6,1.4c-0.5,1.2-0.9,2.2-1,3.3h5.1v10H2.3v2.1
		c0,0.4,0.3,0.7,0.7,0.7h7.1c0.4,0,0.7-0.3,0.7-0.7V17.1C10.8,15.9,10.4,14.8,9.8,13.5z"
      />
      <path
        d="M27.7,12.1c0-0.2-1.4-0.4-3.5-0.5c0.4-0.3,0.7-0.7,1.1-1.1c0,0,0,0,0,0c0.9,0.2,1.7,0,1.9-0.6c0.1-0.3-0.1-0.7-0.5-1
	c0.5-0.5,0.8-0.9,0.8-0.9c-0.3,0.2-0.6,0.5-0.9,0.8c-0.2-0.1-0.4-0.2-0.7-0.3C25,8.2,24.1,8.4,24,9c-0.1,0.5,0.3,1,1,1.3
	c-0.4,0.5-0.8,0.9-1.1,1.2c-1.2-0.1-2.7-0.1-4.3-0.1c-4.5,0-8.1,0.3-8.1,0.6c0,0,0,0,0,0h0l0,0c0,0,0,0,0,0l7.7,6.2V26
	c-2.9,0-5.2,0.5-5.2,1.1c0,0.6,2.5,1.1,5.5,1.1s5.5-0.5,5.5-1.1c0-0.6-2.3-1-5.2-1.1v-7.7L27.7,12.1C27.6,12.1,27.6,12.1,27.7,12.1
	L27.7,12.1L27.7,12.1C27.7,12.1,27.7,12.1,27.7,12.1z M24.4,9.7c-0.2-0.1-0.4-0.4-0.3-0.7s0.3-0.5,0.6-0.4C25,8.7,25.1,9,25,9.3
	S24.7,9.8,24.4,9.7z M19.6,12.7c-4,0-7.3-0.2-7.3-0.5s3.3-0.5,7.3-0.5c1.5,0,2.9,0,4.1,0.1c-0.3,0.4-0.5,0.6-0.5,0.6
	c0.2-0.1,0.5-0.3,0.8-0.6c1.8,0.1,2.9,0.2,2.9,0.4C26.9,12.4,23.6,12.7,19.6,12.7z M19,17.7l-5.2-4.3C17,15.1,19,17.7,19,17.7z
	 M19.4,27.8c-3.4,0.1-4.9-0.7-4.9-0.7C16.2,27.6,19.4,27.8,19.4,27.8z"
      />
    </svg>
  );
};
