import React from "react";

export default props => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 30 "
    >
      <rect x="9.4" y="25" width="1.6" height="1.6" />
      <rect x="8.2" y="3.4" width="4" height="1.6" />
      <rect x="17.4" y="9.4" width="7.2" height="1.6" />
      <rect x="17.4" y="11.8" width="7.2" height="1.6" />
      <rect x="17.4" y="14.2" width="7.2" height="1.6" />
      <path
        d="M21,6.2c-0.5,0-1.1,0-1.6,0.1V3.4C19.4,2,18.3,1,17,1H3.4C2,1,1,2,1,3.4v23.3C1,28,2,29,3.4,29H17c1.3,0,2.4-1.1,2.4-2.4
	v-7.8C19.9,19,20.5,19,21,19c4.4,0,8-2.9,8-6.4S25.4,6.2,21,6.2z M17.8,26.6c0,0.4-0.4,0.8-0.8,0.8H3.4c-0.4,0-0.8-0.4-0.8-0.8v-2.4
	h15.2V26.6z M17.8,22.6H2.6V3.4c0-0.4,0.4-0.8,0.8-0.8H17c0.4,0,0.8,0.4,0.8,0.8v3.3C15,7.7,13,10,13,12.6c0,1.5,0.7,3,1.9,4.1
	c-0.4,0.9-0.9,1.6-1.6,2.2c-0.2,0.2-0.3,0.5-0.3,0.8c0.1,0.3,0.3,0.5,0.6,0.6c0.1,0,0.6,0.1,1.3,0.1c0.8,0,1.9-0.1,2.9-0.6V22.6z
	 M21,17.4c-0.7,0-1.3-0.1-1.9-0.2c-0.3-0.1-0.6,0-0.8,0.3c-0.7,0.9-1.8,1.2-2.7,1.3c0.4-0.6,0.8-1.3,1-2c0.1-0.3,0-0.7-0.3-0.9
	c-1.1-0.9-1.7-2.1-1.7-3.3c0-2.7,2.9-4.8,6.4-4.8s6.4,2.2,6.4,4.8S24.6,17.4,21,17.4z"
      />
    </svg>
  );
};
