import React from "react";

// Utilities
import {
  militaryTimeToReadable,
  timeSelectTimeList,
  timeToMilitary,
} from "../../../../utilites/time";

// Styles
import "./time-selector.css";

const TimeSelector = ({
  name,
  startTime,
  label,
  value,
  handleChange,
  params,
}) => {
  const displayTime = militaryTimeToReadable(value);
  const selectOptions = () => {
    return timeSelectTimeList(startTime).map((option, idx) => (
      <option value={option} key={idx}>
        {option}
      </option>
    ));
  };

  const handleSelect = (evt) => {
    const { value } = evt.target || {};
    const isEndTime = name === "close";
    const newMilitaryValue = timeToMilitary({ time: value, isEndTime });
    handleChange({ target: { value: newMilitaryValue } }, name, params);
  };

  return (
    <div className="time-outer-wrapper-TimeSelector">
      <label>{label}</label>
      <select
        className="time-select"
        value={displayTime}
        onChange={handleSelect}
      >
        {selectOptions()}
      </select>
    </div>
  );
};

export default TimeSelector;
