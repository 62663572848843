import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as stateActions from "../../../../redux/actions/state-actions";
import * as userActions from "../../../../redux/actions/user-actions";

// Components
import ViewLayout from "../../../components/view-layout";
import ImageUpload from "../../../components/image-upload";
import Button from "../../../components/common/button";
import PasswordInput from "../../../components/common/password-input";

// Assets
import Arrow from "../../../../assets/svg/back";

// Utilities
import { verifyForm, shakeElement } from "../../../../utilites/animation";
import { validateEmail, validatePassword } from "../../../../utilites/validate";
import { createUser } from "../../../../api/user";
import { storeImage, deleteImage } from "../../../../api/image";
import { convertDateToDbDate } from "../../../../utilites/date";

// Styles
import "../sign-up.css";
import "./user.css";

const UserSignUp = ({ setLoader, setUser, history }) => {
  const [optionalSection, setOptionalSection] = useState(false);
  const [fNameInput, setfNameInput] = useState("");
  const [lNameInput, setlNameInput] = useState("");
  const [dobInput, setdobInput] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [zipInput, setZipInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [verifyPasswordInput, setVerifyPasswordInput] = useState("");

  function handleChange(evt, type, newValue) {
    const { value } = evt.target;
    switch (type) {
      case "fNameInput":
        return setfNameInput(value);
      case "lNameInput":
        return setlNameInput(value);
      case "zipInput":
        return setZipInput(value);
      case "dobInput":
        return setdobInput(value);
      case "imageInput":
        return setImageFile(newValue);
      case "emailInput":
        return setEmailInput(value);
      case "passwordInput":
        return setPasswordInput(value);
      case "verifyPasswordInput":
        return setVerifyPasswordInput(value);
    }
  }

  async function handleSubmit(evt) {
    evt.preventDefault();
    const passwordVerified =
      passwordInput === verifyPasswordInput && validatePassword(passwordInput);
    if (!passwordVerified) {
      setPasswordInput("");
      setVerifyPasswordInput("");
    }

    const validateIds = {
      emailInput: validateEmail(emailInput),
      userPasswordInput: passwordVerified,
      userVerifyPasswordInput: passwordVerified,
    };
    const hasRequiredInfo = verifyForm(validateIds);
    if (passwordVerified && hasRequiredInfo) {
      setLoader(true);
      const userData = {
        email: emailInput.toLowerCase(),
        password: passwordInput,
        createdDate: convertDateToDbDate(),
      };
      if (fNameInput) userData.fName = fNameInput;
      if (lNameInput) userData.lName = lNameInput;
      if (zipInput) userData.zip = zipInput;
      if (dobInput) {
        const dobSplit = dobInput.split("-");
        userData.dob = `${dobSplit[1]}/${dobSplit[2]}/${dobSplit[0]}`;
      }
      if (imageFile) userData.image = await storeImage(imageFile, "user");
      const { user } = await createUser(userData);
      if (user) {
        setUser({ isUser: true, user });
        history.push("/user/profile");
      } else {
        await deleteImage(userData.image);
      }
      setLoader(false);
    } else {
      shakeElement("form-business-sign-up");
    }
  }

  return (
    <ViewLayout>
      <form
        id="form-business-sign-up"
        className="card-wrapper signup-wrapper-BusinessSignUp"
      >
        <div className="card card-form">
          <div className="card-info-form">
            <h2 className="card-title-SignUp">User Sign-Up</h2>
          </div>
        </div>

        <div className="card card-form">
          <div className="card-info-form">
            <div
              className="signup-toggle-split-container"
              onClick={() => setOptionalSection(!optionalSection)}
            >
              <label id="fNameInput" className="label-form">
                Optional Section
              </label>
              <div
                className={`signup-toggle-subsection-svg-container ${
                  optionalSection
                    ? "signup-toggle-subsection-svg-container-open"
                    : ""
                }`}
              >
                <Arrow />
              </div>
            </div>

            <div
              className={`card-form signup-toggle-subsection ${
                optionalSection ? "signup-toggle-subsection-open" : ""
              }`}
            >
              <label id="fNameInput" className="label-form">
                First Name:
              </label>
              <input
                className="input-style input-form text-form"
                type="text"
                value={fNameInput}
                onChange={(evt) => handleChange(evt, "fNameInput")}
              />
              <label id="lNameInput" className="label-form">
                Last Name:
              </label>
              <input
                className="input-style input-form text-form"
                type="text"
                value={lNameInput}
                onChange={(evt) => handleChange(evt, "lNameInput")}
              />
              <label id="zipInput" className="label-form">
                Zip:
              </label>
              <input
                className="input-style input-form text-form"
                type="number"
                value={zipInput}
                onChange={(evt) => handleChange(evt, "zipInput")}
              />
              <label id="dobInput" className="label-form">
                Date Of Birth:
              </label>
              <input
                className="input-style input-form text-form"
                type="date"
                value={dobInput}
                onChange={(evt) => handleChange(evt, "dobInput")}
              />
              <label className="label-form">Image:</label>
              <div className="time-selector-wrapper-CreateListing">
                <ImageUpload
                  imageInput={imageFile}
                  handleChange={handleChange}
                />
              </div>
              <legend className="legend-form"></legend>
            </div>
          </div>
        </div>

        <div className="card card-form">
          <div className="card-info-form">
            <label id="emailInput" className="label-form">
              Email:
            </label>
            <input
              className="input-style input-form text-form"
              type="email"
              value={emailInput}
              onChange={(evt) => handleChange(evt, "emailInput")}
            />
            <label id="userPasswordInput" className="label-form">
              Password:
            </label>
            <PasswordInput
              value={passwordInput}
              handleChange={(evt) => handleChange(evt, "passwordInput")}
            />
            <label id="userVerifyPasswordInput" className="label-form">
              Verify Password:
            </label>
            <PasswordInput
              value={verifyPasswordInput}
              handleChange={(evt) => handleChange(evt, "verifyPasswordInput")}
            />
            <legend className="legend-form">
              Requires: Min length 8, letter(lower & uppercase), number, and a
              special character.
            </legend>
            <div className="submit-wapper-UserSignUp">
              <div className="submit-button-UserSignUp">
                <Button text="Submit" handleClick={handleSubmit} />
              </div>
            </div>
          </div>
        </div>
      </form>
    </ViewLayout>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setLoader: (loaderState) => dispatch(stateActions.setLoader(loaderState)),
  setUser: (userObj) => dispatch(userActions.setUser(userObj)),
});

export default connect(null, mapDispatchToProps)(withRouter(UserSignUp));
