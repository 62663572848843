import { useSelector } from "react-redux";

// Utilities
import { getLocation } from "../location";

export default function useAnalytics() {
  const isDev =
    window.location.href.indexOf("localhost") >= 0 ||
    window.location.href.indexOf("s3") >= 0;
  const { location: enteredLocation, geoLocation, user } = useSelector(
    (store) => store.user
  );
  const location = getLocation(enteredLocation, geoLocation, user);
  return isDev ? null : location;
}
