import React from "react";

export default props => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 95 85"
    >
      <path
        d="M91.2,64.9L57.7,8.2c-2.2-3.6-6-5.8-10.2-5.8s-8,2.2-10.2,5.8L3.8,64.9C1.6,68.5,1.6,73,3.7,76.6c2.2,3.8,6,6,10.2,6h67.2
          c4.2,0,8.1-2.2,10.2-6C93.4,73,93.4,68.5,91.2,64.9z M87.3,74.3c-1.3,2.2-3.6,3.6-6.1,3.6H13.8c-2.5,0-4.9-1.3-6.1-3.6
          c-1.3-2.2-1.3-4.9,0-7l33.7-56.8c1.3-2.2,3.6-3.4,6.1-3.4s4.9,1.3,6.1,3.4l33.7,56.7C88.5,69.4,88.5,72.1,87.3,74.3z"
      />
      <path d="M47.5,24c-1.7,0-3.1,1.4-3.1,3.1v25.1c0,1.7,1.4,3.1,3.1,3.1s3.1-1.4,3.1-3.1V27.1C50.6,25.4,49.2,24,47.5,24z" />
      <path d="M47.5,61c-1.7,0-3.1,1.4-3.1,3.1v1.6c0,1.7,1.4,3.1,3.1,3.1s3.1-1.4,3.1-3.1v-1.6C50.6,62.2,49.2,61,47.5,61z" />
    </svg>
  );
};
