import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Analytics } from "aws-amplify";
import * as stateActions from "../../../redux/actions/state-actions";
import * as dataActions from "../../../redux/actions/data-actions";

// Components
import Title from "./title";
import SearchBar from "../searchbar";
import Filters from "./filters";

// Utilites
import { isMobileAgent } from "../../../utilites";
import getAnalytics from "../../../utilites/hooks/analytics";

// Assets
import Map from "../../../assets/svg/map";
import Results from "../../../assets/svg/results";

// Styles
import "./header.css";

const Header = ({ location, map, setResults, history }) => {
  const { pathname } = location;
  const rootPath = pathname.split("/")[1];
  const pathOptions = ["top", "food", "drinks", "activities", "events", "map"];
  const foundInOptions = pathOptions.indexOf(rootPath);
  const showFullHeader = foundInOptions >= 0;
  const userAnalytics = getAnalytics();
  const isMobile = isMobileAgent();

  function handleMapClick() {
    let url = "";
    if (map) {
      url = `/${rootPath}`;
      if (rootPath === "map") url = "/";
      if (userAnalytics)
        Analytics.record({
          name: "navigation",
          attributes: { view: "list", location: userAnalytics },
        });
    } else {
      url = `/${rootPath}/map`;
      if (!rootPath) url = "/map";
      setResults({ results: [], pathname: "" });
      if (userAnalytics)
        Analytics.record({
          name: "navigation",
          attributes: { view: "map", location: userAnalytics },
        });
    }
    history.push(url);
  }

  return (
    <>
      <Title isFullHeader={showFullHeader} isMobile={isMobile} />
      {isMobile && showFullHeader && (
        <>
          <div
            id="mobile-header-search"
            className="header-el-wrapper header-search-outer-wrapper-MobileHeader"
          >
            <div className="header-search-inner-wrapper-MobileHeader">
              <SearchBar />
              <div
                className="header-svg-container-MobileHeader header-map-container-MobileHeader"
                onClick={handleMapClick}
              >
                {map ? <Results /> : <Map />}
              </div>
            </div>
          </div>
          <Filters path={pathname} />
        </>
      )}
    </>
  );
};

const mapStateToProps = (store) => ({
  map: store.state.map,
});

const mapDispatchToProps = (dispatch) => ({
  setMap: (value) => dispatch(stateActions.setMap(value)),
  setResults: (resultsObj) => dispatch(dataActions.setResults(resultsObj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
