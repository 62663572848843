export function shakeElement(id) {
  const element = document.getElementById(id);
  if (element) element.classList.add("shake");
  setTimeout(() => {
    if (element) element.classList.remove("shake");
  }, 1000);
}

export function removeClass(ids, className) {
  ids.forEach((id) => {
    const element = document.getElementById(id);
    if (element) element.classList.remove(className);
  });
}

export function addClass(ids, className) {
  ids.forEach((id) => {
    const element = document.getElementById(id);
    if (element) element.classList.add(className);
  });
}

export function verifyForm(formObj) {
  return Object.keys(formObj).reduce((acc, cur) => {
    removeClass([cur], "color-secondary");
    if (!formObj[cur]) {
      addClass([cur], "color-secondary");
      return (acc = false);
    }
    return acc;
  }, true);
}
