import React, { useMemo, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as stateActions from "../../../redux/actions/state-actions";
import * as dataActions from "../../../redux/actions/data-actions";
import * as userActions from "../../../redux/actions/user-actions";

// Componets
import MapComponent from "../../components/map";
import ResultCard from "./result-card";
import BusniessCard from "./business-card";
import Button from "../../components/common/app-style-button";
import RadarLoader from "./radar-loader";

// Assetes
import Radar from "../../../assets/svg/radar";

// Utilities
import Debounce from "../../../utilites/hooks/debounce";
import { isMobileAgent } from "../../../utilites";
import { getLocation } from "../../../utilites/location";
import {
  formatSearchAnalytics,
  formatMapSearchQuery,
} from "../../../utilites/format";
import { toTitleCase } from "../../../utilites/format";
import { getAdStatus, getBusinessStatus } from "../../../utilites/status";
import { searchMapListing, searchBusinessMap } from "../../../api/search";
import { searchMapGrowth } from "../../../api/growth";
import { searchAnalytics } from "../../../api/analytics";

// Styles
import "./map.css";

const Map = ({
  isUser,
  user,
  filters,
  results,
  userLocation,
  geoLocation,
  setHeaderMapIcon,
  setResults,
  addResults,
  match,
  setMessage,
  setMapLocation,
  history,
  locationKey,
  setLocationKey,
  autoComplete,
  setAutoComplete,
  setSearchInput,
  setLocationInput,
}) => {
  const { type, subCat, feature, search, date, time, limit } = filters || {};
  const geoWatchMemo = useMemo(() => geoLocation, []);
  const [enteredLocation, setEnteredLocation] = useState(userLocation);
  const [skip, setSkip] = useState(0);
  const [hasMounted, setHasMounted] = useState(false);
  const [enteredLocationChanged, setEnteredLocationChanged] = useState(false);
  const [selectedId, setSelectedId] = useState(false);
  const [mapMoved, setMapMoved] = useState(false);
  const [isShowMoreButton, setIsShowMoreButton] = useState(false);
  const [loading, setLoading] = useState(true);
  const [mapCoords, setMapCoords] = useState(false);
  const debouncedMapCoords = Debounce(mapCoords, 600);
  const debouncedSearch = Debounce(search, 750);
  const debouncedDate = Debounce(date, 600);
  const isGeoLocation = "coordinates" in geoWatchMemo;
  const mapCenter = getLocation(userLocation, geoWatchMemo, user);
  const isMobile = isMobileAgent();
  const mapClassName = isMobile ? "map-container-mobile" : "map-container-web";
  const { path } = match || {};
  const pathName = path.split("/")[1];
  const category = toTitleCase({ input: pathName });
  const isTopPage = pathName === "top";
  const isMapButton = mapMoved || isShowMoreButton;
  const markersData = {};
  (() => {
    const isBusinessSearch = type === "Business";
    const eventMakers = () => {
      results.forEach((result) => {
        const { _id, businessId, location } = result;
        const { coordinates } = location;
        const { status } = getAdStatus(result, date);
        if (markersData[businessId]) {
          const markerData = markersData[businessId];
          markerData.status[_id] = status;
          markerData.ads = [...markerData.ads, result];
        } else {
          markersData[businessId] = {
            id: businessId,
            status: { [_id]: status },
            coordinates,
            ads: [result],
          };
        }
      });
    };
    const businessMarkers = () => {
      results.forEach((result) => {
        const { _id, location } = result;
        const { coordinates } = location;
        const { status } = getBusinessStatus(result, date);
        markersData[_id] = {
          id: _id,
          status: { [_id]: status },
          coordinates,
          ads: [result],
        };
      });
    };

    isBusinessSearch ? businessMarkers() : eventMakers();
  })();

  useEffect(() => {
    if (!hasMounted && debouncedMapCoords) {
      locationKey !== history.location.key ? getData() : setLoading(false);
      setHasMounted(true);
      setLocationKey(history.location.key);
    } else if (enteredLocationChanged) {
      setEnteredLocationChanged(false);
      getData();
    } else if (hasMounted) {
      setMapMoved(true);
    }
  }, [debouncedMapCoords]);

  useEffect(() => {
    if (userLocation !== enteredLocation) {
      setEnteredLocation(userLocation);
      setEnteredLocationChanged(true);
    }
  }, [userLocation]);

  useEffect(() => {
    setSelectedId(false);
    if (debouncedMapCoords) getData();
    if (hasMounted) setLocationKey(history.location.key);
    setHeaderMapIcon();
  }, [
    type,
    path,
    debouncedSearch,
    subCat,
    feature,
    debouncedDate,
    time,
    limit,
  ]);

  const handleMarkerClick = (listing) => {
    setSelectedId(listing);
  };

  const handleCenterMovedCoords = (windowCoords, centerCoords) => {
    setMapCoords(windowCoords);
    setMapLocation(centerCoords);
    if (autoComplete.length) {
      setSearchInput("");
      setLocationInput("");
      setAutoComplete([]);
    }
  };

  const handleNewMapSearch = () => {
    if (mapMoved) {
      setMapMoved(false);
      getData();
    } else {
      getData(skip);
    }
  };

  const getData = async (skipValue = 0) => {
    if (!skipValue) {
      setResults({ results: [], pathname: "" });
    }
    setLoading(true);
    setSelectedId(false);
    setMapMoved(false);
    const searchOption = formatMapSearchQuery(
      debouncedMapCoords,
      filters,
      isTopPage ? "" : category,
      skipValue,
      isUser && user ? user : {},
      geoLocation
    );
    let newResults = [];
    const isBusinessSearch = type === "Business";

    if (isBusinessSearch) {
      const { results: topResults = [] } = await searchBusinessMap(
        searchOption
      );
      newResults = topResults;
    } else {
      const { results: searchResults = [] } = await searchMapGrowth(
        searchOption
      );
      newResults = searchResults;
    }

    if (newResults && newResults.length) {
      skipValue
        ? addResults({ results: newResults, pathname: "" })
        : setResults({ results: newResults, pathname: "" });
    } else {
      setMessage({ messageType: "error", message: "No Results" });
    }
    const totalResultsLength = skipValue
      ? results.length + newResults.length
      : newResults.length;
    const isMoreResults =
      skipValue && totalResultsLength % 20 === 0 ? true : false;
    setIsShowMoreButton(isMoreResults);
    setSkip(totalResultsLength + 1);
    setLoading(false);
  };

  const sortAds = (ads, statusObj) => {
    const sortedAds = [];
    const notStarted = [];
    for (const id in statusObj) {
      const ad = ads.find(({ _id }) => _id === id);
      const status = statusObj[id];
      if (status === "ending") {
        sortedAds.unshift(ad);
      } else if (status === "active") {
        sortedAds.push(ad);
      } else {
        notStarted.push(ad);
      }
    }
    return [...sortedAds, ...notStarted];
  };

  const renderResults = () => {
    if (!(selectedId in markersData)) return null;
    let { status, ads } = markersData[selectedId];
    if (ads.length > 1) ads = sortAds(ads, status);
    return ads
      .slice(0, 4)
      .map((ad, idx) => (
        <ResultCard
          category={category}
          result={ad}
          filterDate={date}
          key={idx}
        />
      ));
  };

  return (
    <div className={mapClassName}>
      <MapComponent
        mapCenter={mapCenter}
        isGeoLocation={isGeoLocation}
        userMarkerPostion={geoLocation.coordinates}
        results={loading ? [] : Object.values(markersData)}
        isUserMarker={true}
        defaultZoom={13}
        handleMarkerClick={handleMarkerClick}
        handleClick={() => setSelectedId(false)}
        handleCenterMovedCoords={handleCenterMovedCoords}
        selectedId={selectedId}
        containerElement={<div className={mapClassName}></div>}
        mapElement={
          <div style={{ height: isMobile ? "calc(100% + 28px)" : "100%" }} />
        }
      />
      {isMapButton && (
        <div
          className={`map-update-container ${isMobile ? "zoomIn" : ""}`}
          style={{ transform: `translateX(-${isMobile ? 75 : 118}px)` }}
        >
          <Button
            size="medium"
            text={mapMoved ? "search here" : "show more"}
            handleClick={handleNewMapSearch}
          >
            <Radar isMobile={isMobile} />
          </Button>
        </div>
      )}
      {loading && <RadarLoader />}
      {selectedId && (
        <div
          className="card-center-conatiner"
          style={{
            width: isMobile ? "100%" : "calc(100% - 70px)",
            bottom: isMobile ? "95px" : "100px",
          }}
        >
          {type === "Business" ? (
            selectedId in markersData && (
              <BusniessCard
                date={date}
                result={markersData[selectedId].ads[0]}
                filterDate={date}
              />
            )
          ) : (
            <div className="card-stack-conatiner">{renderResults()}</div>
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (store) => ({
  isUser: store.user.isUser,
  user: store.user.user,
  filters: store.data.filters,
  userLocation: store.user.location,
  geoLocation: store.user.geoLocation,
  results: store.data.results,
  locationKey: store.state.locationKey,
  autoComplete: store.data.autoComplete,
});

const mapDispatchToProps = (dispatch) => ({
  setHeaderMapIcon: () => dispatch(stateActions.setMap(true)),
  setResults: (resultsObj) => dispatch(dataActions.setResults(resultsObj)),
  addResults: (resultsObj) => dispatch(dataActions.addResults(resultsObj)),
  setMessage: (messageObj) => dispatch(stateActions.setMessage(messageObj)),
  setMapLocation: (mapCoordsArr) =>
    dispatch(userActions.setMapLocation(mapCoordsArr)),
  setLocationKey: (key) => dispatch(stateActions.setLocationKey(key)),
  setSearchInput: (input) =>
    dispatch(dataActions.setFilter({ searchInput: input })),
  setLocationInput: (input) => dispatch(userActions.setLocationInput(input)),
  setAutoComplete: (autoCompleteList) =>
    dispatch(dataActions.setAutoComplete(autoCompleteList)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Map));
