import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as stateActions from "../../../redux/actions/state-actions";
import * as dataActions from "../../../redux/actions/data-actions";

// Components
import Spacer from "../../components/common/spacer";
import SkeletonCard from "./skeleton-card";

// Utilities
import { isMobileAgent } from "../../../utilites";
import { getLocation } from "../../../utilites/location";
import { formatSearchQuery } from "../../../utilites/format";
import SetMapLocation from "../../../utilites/hooks/setMapLocation";
import Debounce from "../../../utilites/hooks/debounce";
import { listingSearch, searchBusiness } from "../../../api/admin";

// Styles
import "./data-loader-hoc.css";

const DataLoader = (WrappedComponent) => {
  const Component = (props) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [skip, setSkip] = useState(0);
    const [scrollY, setScrollY] = useState(0);
    const debouncedScrollFilter = Debounce(scrollY, 25);
    const debouncedScrollSearch = Debounce(debouncedScrollFilter, 350);
    const [naviatingBack, setNaviatingBack] = useState(false);
    const {
      isUser,
      user,
      location,
      geoPermission,
      geoLocation,
      setResults,
      match,
      filters,
      setMap,
      results,
      addResults,
      locationKey,
      setLocationKey,
      isLiveSearch,
    } = props;
    const { type, subCat, feature, search, date, time, distance, sortBy } =
      filters || {};
    const { path } = match || {};
    const { isUpdatingLocation } = SetMapLocation();
    const isMobile = isMobileAgent();
    const isTopPage = path.indexOf("top") > 0;
    const isBusinessSearch = type === "Business";
    const limit = isTopPage ? 40 : isMobile ? 20 : 30;

    useEffect(() => {
      setMap();
      if (isUpdatingLocation) return;
      if (locationKey !== props.history.location.key) {
        setNaviatingBack(false);
        setSkip(0);
        getData(0);
      } else {
        setNaviatingBack(true);
        setIsLoaded(true);
      }
      setMap();
      setLocationKey(props.history.location.key);
    }, [isUpdatingLocation, path]);

    useEffect(() => {
      if (isLoaded) {
        setSkip(0);
        getData(0);
      }
    }, [
      type,
      geoPermission,
      date,
      subCat,
      feature,
      location,
      search,
      time,
      distance,
      sortBy,
    ]);

    useEffect(() => {
      if (!isTopPage && !isFetching) {
        const resultsWrapper = document.getElementById("results-wrapper");
        if (!resultsWrapper) return;
        const windowHeight =
          resultsWrapper.scrollHeight - window.screen.height - 60;
        const scrollAtBottom = debouncedScrollSearch > windowHeight;
        const totalResults = results.length;
        const isMoreResults =
          totalResults && totalResults % limit === 0 ? true : false;

        if (scrollAtBottom && isMoreResults) {
          setSkip(totalResults);
          getData(totalResults + 1);
        }
      }
    }, [debouncedScrollSearch]);

    const getData = async (skip) => {
      const category = path.split("/")[1];
      if (skip) {
        setIsFetching(true);
      } else {
        setIsLoaded(false);
        setResults({ results: [], pathname: "" });
      }

      const searchOption = formatSearchQuery(
        getLocation(location, geoLocation, user),
        filters,
        isTopPage ? "" : category,
        { skip, limit },
        isUser && user ? user : {},
        geoLocation
      );
      let results = [];

      if (isBusinessSearch) {
        const { results: newResults = [] } = await searchBusiness(searchOption);
        results = newResults;
      } else {
        let { results: newResults = [] } = await listingSearch({
          ...searchOption,
          isGrowth: !isLiveSearch,
        });
        results = newResults;
      }

      if (skip) {
        addResults({ results, pathname: path });
        setIsFetching(false);
      } else {
        setResults({ results, pathname: path });
        setIsLoaded(true);
      }
    };

    const renderSkeletonUi = () => {
      const width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      const height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
      const widthMultiple = Math.floor(width / 358) || 1;
      const heightMultiple = Math.floor(height / 178);
      const numberOfSkeletonEl = widthMultiple * heightMultiple;
      const skeletonEl = [];

      for (let i = 0; i < numberOfSkeletonEl; i++) {
        skeletonEl.push(<SkeletonCard key={i} />);
      }
      return skeletonEl;
    };

    return isLoaded ? (
      <WrappedComponent
        {...props}
        isFetching={isFetching}
        path={path}
        setScrollY={setScrollY}
        debouncedScroll={debouncedScrollFilter}
        navigatingBack={naviatingBack}
        isFetching={isFetching}
      />
    ) : (
      <div
        id="skelleton-wrapper"
        className={`no-scroll-bars ${
          isMobile ? "results-wrapper-mobile" : "results-wrapper-web"
        }`}
      >
        <div id="results-inner-wrapper">
          <Spacer height={isFetching ? 0 : isMobile ? 108 : 40} />
          {renderSkeletonUi()}
        </div>
      </div>
    );
  };

  const mapStateToProps = (store) => ({
    isUser: store.user.isUser,
    user: store.user.user,
    location: store.user.location,
    geoPermission: store.user.geoPermission,
    geoLocation: store.user.geoLocation,
    filters: store.data.filters,
    results: store.data.results,
    locationKey: store.state.locationKey,
    isLiveSearch: store.state.isLiveSearch,
  });

  const mapDispatchToProps = (dispatch) => ({
    setMap: () => dispatch(stateActions.setMap(false)),
    setResults: (resultsObj) => dispatch(dataActions.setResults(resultsObj)),
    addResults: (resultsObj) => dispatch(dataActions.addResults(resultsObj)),
    setLocationKey: (key) => dispatch(stateActions.setLocationKey(key)),
  });

  return connect(mapStateToProps, mapDispatchToProps)(Component);
};

export default DataLoader;
