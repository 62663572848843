import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import * as dataActions from "../../../../redux/actions/data-actions";

// Components
import Button from "../../../components/common/button";

// Utilities
import { findEditReviewUpdate } from "../../../../api/review";
import { deleteFind } from "../../../../api/admin";
import UseGetEmployeeAnalytics from "../../../../utilites/hooks/employeeAnalytics";
import { setEmployeeAnalytics } from "../../../../api/employee";

// Styles
import "./review-button-styles.css";

export default ({ edited, setEdit }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const employeeAnalytics = UseGetEmployeeAnalytics();
  const { fName, lName } = useSelector((store) => store.user.employee);
  const [isUpdating, setIsUpdating] = useState(false);
  const [failReason, setFailReason] = useState("Doesn't Meet Guidelines");
  const { requestDelete } = edited;
  const agentName = `${fName} ${lName}`;
  console.log("test: edited ", edited);

  const handleReview = async (value) => {
    setIsUpdating(true);
    const employeeData = { ...employeeAnalytics, reviewed_find_edit: true };
    const reviewData = {
      ...edited,
      reviewed: {
        isReviewed: true,
        agent: agentName,
        timeStamp: moment().format("lll"),
      },
    };

    if (!value) reviewData.reason = failReason;

    const [{ result, message }] = await Promise.all([
      findEditReviewUpdate(reviewData),
      setEmployeeAnalytics(employeeData),
    ]);

    if (result) {
      setEdit(result);
      dispatch(dataActions.updateResultInResults(result));
    }
    if (message) {
      history.push("/review-finds");
    }
    setIsUpdating(false);
  };

  const handleDelete = async (value) => {
    setIsUpdating(true);
    const { _id, originalId, userId, title } = edited;
    const employeeData = { ...employeeAnalytics, reviewed_find_edit: true };
    const reviewData = {
      _id,
      userId,
      title,
      timeStamp: moment().format("lll"),
    };

    if (value) reviewData.originalId = originalId;
    console.log("test: reviewData ", reviewData);
    const [{ message }] = await Promise.all([
      deleteFind(reviewData),
      setEmployeeAnalytics(employeeData),
    ]);

    if (message) {
      history.push("/review-finds");
    }
    setIsUpdating(false);
  };

  const createSelectOptions = (options = [], allText) => {
    return options.map((option, idx) => (
      <option value={option} key={idx}>
        {option ? option : `All ${allText}`}
      </option>
    ));
  };

  return (
    <div className="confirm-button-wrapper">
      <div className="confirm-button-container">
        {requestDelete ? (
          <>
            <Button
              className="submit-button-CreateListing"
              isDisabled={isUpdating}
              isPrimary={false}
              text="Delete"
              handleClick={() => {
                handleDelete(true);
              }}
            />

            <Button
              className="submit-button-CreateListing"
              isDisabled={isUpdating}
              isPrimary={true}
              text="Don't Delete"
              handleClick={() => {
                handleDelete(false);
              }}
            />
          </>
        ) : (
          <>
            <select
              id="sideNav-subCat-input"
              className="sideNav-border sideNav-subcat-select"
              style={{ margin: 0, backgroundColor: "white", color: "black" }}
              value={failReason}
              onChange={(evt) => setFailReason(evt.target.value)}
            >
              {createSelectOptions(["Doesn't Meet Guidelines"])}
            </select>

            <Button
              className="submit-button-CreateListing"
              isDisabled={isUpdating}
              isPrimary={false}
              text="Fail"
              handleClick={() => {
                handleReview(false);
              }}
            />

            <Button
              text="Pass"
              isDisabled={isUpdating}
              handleClick={() => {
                handleReview(true);
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};

const ud = {
  type: "Promotions",
  title: "waterfall trail",
  description: "Secret trail ",
  categories: {
    cat1: "Activities",
    cat1Sub: "Recreational",
    cat1Img:
      "https://eventhoundassets163325-prod.s3.us-west-2.amazonaws.com/public/findc934cd74-79dc-4153-867e-9146c3fd47c4",
  },
  images: [
    "https://eventhoundassets163325-prod.s3.us-west-2.amazonaws.com/public/find9c2d2c9d-836f-4a5e-b994-96ee98c7ad1a",
    "https://eventhoundassets163325-prod.s3.us-west-2.amazonaws.com/public/finde79fc775-afee-4cf5-b41e-ed83abc28db1",
  ],
  features: {
    Outdoors: true,
    Kids: true,
    View: true,
    Instaworthy: true,
    "Dog Friendly": true,
  },
  ticketLink: "",
  days: {
    hours: {
      Monday: [Object],
      Tuesday: [Object],
      Wednesday: [Object],
      Thursday: [Object],
      Friday: [Object],
      Saturday: [Object],
      Sunday: [Object],
    },
    rate: 7,
    isBusinessHours: false,
    startDate: 210919,
    endDate: 999999,
  },
  collaborators: {
    "6024837e443218000810e0cd": "Sep 17, 2021 11:09 AM",
    "6025cdbb546342000957464f": "Sep 19, 2021 4:20 PM",
  },
  isActive: true,
  reviewed: {
    isReviewed: true,
    agent: "greg shamalta",
    timeStamp: "Sep 19, 2021 4:20 PM",
  },
};
