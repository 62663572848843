export const buttonStyles = {
  button: {
    extraLarge: {
      height: "48px",
      width: "220px",
      minWidth: "220px",
      fontSize: "1.2rem",
    },
    large: {
      height: "35px",
      width: "200px",
      minWidth: "200px",
      fontSize: "1.2rem",
    },
    medium: {
      height: "30px",
      minWidth: "150px",
      fontSize: "1.1rem",
    },
    small: {
      height: "25px",
      minWidth: "100px",
    },
    xs: {
      height: "20px",
      minWidth: "50px",
    },
  },
  svg: {
    large: {
      minHeight: "20px",
      width: "20px",
    },
    medium: {
      minHeight: "20px",
      width: "20px",
    },
    small: {
      minHeight: "15px",
      width: "15px",
    },
    xs: {
      minHeight: "10px",
      width: "10px",
    },
  },
};