import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import * as stateActions from "../../../../redux/actions/state-actions";
import * as userActions from "../../../../redux/actions/user-actions";

// Components
import ViewLayout from "../../../components/view-layout";
import ProfileEditDragDrop from "../../../components/profile-edit-drag-drop";
import Toggle from "../../../components/common/toggle";
import BusinessCommon from "../../../components/business-common";
import Button from "../../../components/common/button";
import DeleteDropDown from "../../../components/delete-drop-down";
import Modal from "../../../components/modal";

// Utilities
import { validateBusines } from "../../../../utilites/validate";
import { convertDateToDbDate } from "../../../../utilites/date";
import { updateBusiness } from "../../../../api/business";
import { deleteBusiness } from "../../../../api/admin";
import { deleteImage } from "../../../../api/image";
import UseGetEmployeeAnalytics from "../../../../utilites/hooks/employeeAnalytics";
import { setEmployeeAnalytics } from "../../../../api/employee";
import { formatAfter12Hours } from "../../../../utilites/format";

// styles
import "./business-profile.css";

const EditBusiness = ({
  employee,
  user,
  setLoader,
  updateUser,
  setUser,
  setMessage,
  history,
}) => {
  const {
    _id,
    isActive: isActiveProp = true,
    images: userImages,
    ads,
    locations,
  } = user;
  const employeeAnalytics = UseGetEmployeeAnalytics();
  const [commonData, setCommonData] = useState({});
  const [commonImageData, setCommonImageData] = useState({});
  const [isActiveInput, setIsActiveInput] = useState(isActiveProp);
  const [modalMessage, setModalMessage] = useState("");
  const { images = [] } = commonImageData || {};
  const { fName, lName } = employee;
  const agentName = `${fName} ${lName}`;

  const handleActiveToggle = (evt, value) => {
    evt.preventDefault();
    setIsActiveInput(value);
  };

  const handleCommonData = (businessData) => {
    setCommonData(businessData);
  };

  const handleCommonImageData = (data) => {
    setCommonImageData(data);
  };

  const createBusinessData = () => {
    const {
      locationType,
      nameInput,
      managerNameInput,
      emailInput,
      phoneInput,
      showPhone,
      websiteInput,
      addressInput,
      cityInput,
      stateInput,
      zipInput,
      hours,
      categories,
      featuresInput,
      deliveryLinkInput,
      descriptionInput,
    } = commonData;
    const businessData = {
      isActive: isActiveInput,
      locationType,
      name: nameInput,
      managerName: managerNameInput,
      email: emailInput,
      showPhone,
      phone: phoneInput,
      address: addressInput,
      city: cityInput,
      state: stateInput,
      zip: zipInput,
      hours,
      categories,
      features: featuresInput,
      images,
      deliveryLink: deliveryLinkInput,
      description: descriptionInput,
      website: websiteInput,
      lastUpdated: convertDateToDbDate(),
    };
    return businessData;
  };

  const handleSave = async () => {
    const businessData = createBusinessData();
    const hasRequiredInfo = validateBusines(
      businessData,
      {},
      "edit-business-profile"
    );
    if (!hasRequiredInfo) return;

    setLoader(true);
    const newBusinessData = {
      ...user,
      ...businessData,
      hours: formatAfter12Hours(businessData.hours),
      reviewed: {
        isReviewed: true,
        agent: agentName,
        timeStamp: moment().format("lll"),
      },
    };

    setEmployeeAnalytics({ ...employeeAnalytics, reviewed_business: true });
    const { user: updatedBusiness } =
      (await updateBusiness(newBusinessData)) || {};
    if (updatedBusiness) {
      updateUser(updatedBusiness);
      history.push(`/business/profile/${_id}`);
    }
    setLoader(false);
  };

  const handleAcceptClick = async () => {
    setModalMessage("");
    setLoader(true);
    const { deleted } = (await deleteBusiness({ id: _id })) || {};
    const verifyImageDelete = (imagesToBeDeleted) => {
      if (imagesToBeDeleted && imagesToBeDeleted.length) {
        return imagesToBeDeleted.map((image) => deleteImage(image));
      } else {
        return [Promise.resolve()];
      }
    };
    let adsImageDeleteArray = [];

    if (deleted) {
      if (locations && locations.length) {
        locations.forEach(({ images: locationImages }) => {
          if (
            locationImages &&
            locationImages.length &&
            userImages.indexOf(locationImages[0]) < 0
          ) {
            adsImageDeleteArray.push(deleteImage(locationImages[0]));
          }
        });
      }

      if (ads && ads.length) {
        ads.forEach(({ categories, images: adImages, promoCode }) => {
          const { cat1Img, cat2Img } = categories;
          if (adImages && adImages.length)
            adsImageDeleteArray = [
              ...adsImageDeleteArray,
              deleteImage(cat1Img),
              ...adImages.map((adImage) => deleteImage(adImage)),
            ];
          if (cat2Img) adsImageDeleteArray.push(deleteImage(cat2Img));
          if (promoCode) adsImageDeleteArray.push(deleteImage(promoCode));
        });
      }

      await Promise.all([
        ...verifyImageDelete(userImages),
        ...adsImageDeleteArray,
      ]).then(() => {
        localStorage.removeItem("business");
        history.push("/");
        setUser();
        setMessage({ message: "Successful" });
      });
    }
    setLoader(false);
  };

  const renderHeaderButtons = () => (
    <div className="profile-button-container">
      <Button
        size="small"
        text="View"
        handleClick={() => history.push(`/business/edit/${_id}`)}
      />
    </div>
  );

  return (
    <ViewLayout id="edit-business-profile">
      <ProfileEditDragDrop
        input={user}
        dbImages={userImages}
        isBusiness={true}
        HeaderButtons={renderHeaderButtons}
        handleCommonData={handleCommonImageData}
      >
        <>
          <div className="card card-form">
            <div className="card-info-form">
              <label className="label-form">Is Active:</label>
              <div className="edit-business-profile-item-right">
                <Toggle
                  value={isActiveInput}
                  handleToggle={handleActiveToggle}
                  params={"isActive"}
                />
              </div>
              <legend className="legend-form">
                Use to deactivate your account and its listings.
              </legend>
            </div>
          </div>

          <BusinessCommon
            user={user}
            handleCommonData={handleCommonData}
            page="edit-profile"
          />

          <div id="consentInput" className="card card-form">
            <div className="card-info-form">
              <div className="listing-edit-submit-container">
                <Button
                  className="submit-button-CreateListing"
                  text="Cancel"
                  isPrimary={false}
                  handleClick={() => history.push(`/business/profile/${_id}`)}
                />
                <Button
                  className="submit-button-CreateListing"
                  text="Save"
                  handleClick={handleSave}
                />
              </div>
            </div>
          </div>

          <DeleteDropDown
            handleDelete={() =>
              setModalMessage("Are you sure you want to delete your profile?")
            }
          />

          <Modal
            directModalMessage={modalMessage}
            modalAccept="Confirm"
            modalCancel="Cancel"
            handleCancelClick={() => setModalMessage("")}
            handleAcceptClick={handleAcceptClick}
          />
        </>
      </ProfileEditDragDrop>
    </ViewLayout>
  );
};

const mapStateToProps = (store) => ({
  user: store.user.user,
  employee: store.user.employee,
});

const mapDispatchToProps = (dispatch) => ({
  setLoader: (loaderState) => dispatch(stateActions.setLoader(loaderState)),
  setMessage: (dataObj) => dispatch(stateActions.setMessage(dataObj)),
  setUser: (userObj) => dispatch(userActions.setUser(userObj)),
  updateUser: (userObj) => dispatch(userActions.updateUser(userObj)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EditBusiness));
