import React from "react";

// Styles
import "./svg.css";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 24"
    >
      <path
        d="M11.8,13.1c1.1,1.8,2.2,3.7,2.6,5.4c0.1,0.3,0.3,0.5,0.6,0.5c0.3,0,0.5-0.2,0.6-0.5c0.3-1.7,1.5-3.6,2.6-5.4
		c0.8-1.3,1.6-2.6,2-3.7c0.4-0.8,0.6-1.7,0.6-2.6c0-3.2-2.6-5.8-5.8-5.8S9.2,3.5,9.2,6.7c0,0.9,0.2,1.8,0.6,2.6
		C10.3,10.5,11,11.7,11.8,13.1z M15,5.3c0.7,0,1.4,0.6,1.4,1.4C16.3,7.4,15.7,8,15,8s-1.4-0.6-1.4-1.4S14.2,5.3,15,5.3z"
      />
      <path d="M10.6,15.4c-0.3,0-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6h1.7c0.3,0,0.6-0.3,0.6-0.6s-0.3-0.6-0.6-0.6H10.6z" />
      <path d="M19.2,15.4h-1.7c-0.3,0-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6h1.7c0.3,0,0.6-0.3,0.6-0.6S19.5,15.4,19.2,15.4z" />
      <path
        d="M29,22.2l-3.6-6.9c-0.2-0.3-0.5-0.4-0.8-0.2c-0.3,0.2-0.4,0.5-0.2,0.8l3.2,6H2.5l3.4-6c0.2-0.3,0.1-0.6-0.2-0.8
		C5.4,15,5,15.1,4.8,15.4L1,22.2c-0.1,0.2-0.1,0.4,0,0.6c0.1,0.2,0.3,0.3,0.5,0.3h27c0.2,0,0.4-0.1,0.5-0.3
		C29.1,22.6,29.1,22.4,29,22.2z"
      />
      <path
        d="M5.1,14c0.9,1.4,1.7,2.9,2,4.2c0.1,0.3,0.3,0.5,0.6,0.5c0.3,0,0.5-0.2,0.6-0.5c0.3-1.3,1.1-2.8,2-4.2
		c0.2-0.4,0.4-0.7,0.6-1.1c0.1-0.2,0.1-0.4,0-0.6c-0.7-1.1-1.1-2-1.5-2.7C9.3,9.4,9.1,9.3,8.9,9.2c-0.2,0-0.4,0.1-0.5,0.2
		C8.2,9.7,8,9.8,7.7,9.8c-0.5,0-0.9-0.4-0.9-0.9S7.2,8,7.7,8l0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0.3,0,0.6-0.3,0.6-0.6
		c0-0.1,0-0.2-0.1-0.3C8.3,6.6,8.3,6.2,8.3,5.7c0-0.3,0-0.5,0.1-0.8c0-0.2,0-0.3-0.1-0.5C8.1,4.3,8,4.3,7.8,4.3c0,0-0.1,0-0.1,0
		C5.1,4.3,3,6.3,3,8.9c0,0.7,0.2,1.5,0.5,2.1C3.9,11.9,4.5,12.9,5.1,14z"
      />
      <path
        d="M22.2,9.8c-0.3,0-0.5-0.1-0.7-0.4c-0.1-0.2-0.3-0.2-0.5-0.2c-0.2,0-0.4,0.1-0.5,0.3c-0.4,0.8-0.8,1.6-1.5,2.7
		c-0.1,0.2-0.1,0.4,0,0.6c0.2,0.4,0.4,0.7,0.6,1.1c0.9,1.4,1.7,2.9,2,4.2c0.1,0.3,0.3,0.5,0.6,0.5c0.3,0,0.5-0.2,0.6-0.5
		c0.3-1.3,1.1-2.8,2-4.2c0.6-1,1.2-2,1.6-2.9c0.3-0.7,0.5-1.4,0.5-2.1c0-2.6-2.1-4.7-4.7-4.7c-0.2,0-0.3,0.1-0.5,0.2
		c-0.1,0.1-0.2,0.3-0.1,0.5c0,0.3,0.1,0.5,0.1,0.8c0,0.5-0.1,1-0.2,1.6c0,0.2,0,0.4,0.1,0.5C21.7,7.9,21.9,8,22.1,8c0,0,0.1,0,0.1,0
		c0.5,0,0.9,0.4,0.9,0.9C23.2,9.4,22.7,9.8,22.2,9.8z"
      />
    </svg>
  );
};
