import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { useDispatch } from "react-redux";
import * as stateActions from "../../../../redux/actions/state-actions";

// Components
import ListingSkeleton from "../listing-skeleton";

// Utilities
import SetMapLocation from "../../../../utilites/hooks/setMapLocation";
import { getFind } from "../../../../api/find";

export default (WrappedComponent) => {
  const Component = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { match } = props || {};
    const { id: currLessonId } = match.params || {};
    const [ad, setAd] = useState(false);
    const [business, setBusiness] = useState(false);
    const [related, setRelated] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const showComponent = !!(isLoaded && ad && business);
    SetMapLocation();

    useEffect(() => {
      getData();
    }, []);

    const getData = async () => {
      if (!inProgress) {
        setIsLoaded(false);
        setInProgress(true);

        const {
          original,
          business: foundBusiness,
          related: foundRelated,
        } = (await getFind({ id: currLessonId })) || {};

        if (!original) {
          dispatch(
            stateActions.setMessage({
              message: "Not Found",
              messageType: "error",
            })
          );
          return history.push("/");
        }

        setAd(original);
        setBusiness(foundBusiness);
        setRelated(foundRelated);
        setInProgress(false);
        return setIsLoaded(true);
      }
    };

    return (
      <>
        {showComponent ? (
          <CSSTransition in={showComponent} timeout={250} classNames="fade">
            <WrappedComponent
              {...props}
              ad={ad}
              business={business}
              related={related}
              setAd={setAd}
            />
          </CSSTransition>
        ) : (
          <ListingSkeleton />
        )}
      </>
    );
  };
  return Component;
};
