import React from "react";
import { withRouter } from "react-router-dom";
import LazyLoad from "react-lazyload";

// Utilities
import { isMobileAgent } from "../../../../utilites";
import { charLimitTo } from "../../../../utilites/format";
import { getBusinessStatus } from "../../../../utilites/status";
import getFeaturesIcon from "../../../components/features-input/getFeatureIcon";
import { covidMessage } from "../../../../constants/data-models";

// Assets
import Skyline from "../../../../assets/svg/skyline";
import Covid from "../../../../assets/svg/covid";
import RatingStarsClip from "../../../../assets/svg/rating-stars-clip";

// Styles
import "../event-card/result.css";
import "./business-card.css";

const BusinessCard = ({
  result,
  filterDate,
  history,
  setPagePosition,
  setModalMessage,
  idx = 0,
}) => {
  const { _id, images, name, covid, features, yelp } = result || {};
  const { covidCompliant } = covid || {};
  const { status, timeText, displayTime } = getBusinessStatus(
    result,
    filterDate
  );
  // const animationDelay = navigatingBack ? "0ms" : `${(idx + 1) * 100}ms`;
  const isFeatures = features && Object.values(features).find((value) => value);
  const { rating = 0, review_count } = yelp || {};
  const ratingPercent = (rating / 5) * 100;
  const isMobile = isMobileAgent();

  const renderFeatures = () => {
    const featureElements = [];
    for (let key in features) {
      const value = features[key];
      if (value)
        featureElements.push(
          <div className="result-feature-container" key={key}>
            {getFeaturesIcon(key)}
          </div>
        );
    }
    return featureElements;
  };

  const handleClick = () => {
    const element = document.getElementById("results-wrapper");
    if (setPagePosition && element) {
      const scrollPos = element.scrollTop;
      setPagePosition(scrollPos);
    }
  };

  return (
    <a
      className={`pointer zoomIn result-card-container result-card-${status} ${
        isMobile ? "result-card-mobile" : "result-card-web"
      }`}
      onClick={handleClick}
      href={`/business/${_id}`}
      target="_blank"
    >
      {!!images.length && images[0] ? (
        <LazyLoad height={180} scrollContainer="#results-wrapper">
          <img className="result-card-image" src={images[0]} alt={name} />
        </LazyLoad>
      ) : (
        <div className="result-skyline-overlay">
          <Skyline />
        </div>
      )}

      <div className={`result-card-overlay result-card-overlay-gradient`} />
      <div
        className={`result-card-overlay result-card-status-overlay result-card-overlay-${status}`}
      />

      {covidCompliant && (
        <div
          className="result-card-covid"
          onClick={(evt) => {
            evt.stopPropagation();
            setModalMessage(covidMessage(name));
          }}
        >
          <Covid />
        </div>
      )}

      {isFeatures && (
        <div className="result-features-container">{renderFeatures()}</div>
      )}

      <div className="result-info-container result-info-bottom">
        <h3 className="result-title">{charLimitTo(name, 55).toUpperCase()}</h3>
        {yelp && (
          <div className="result-yelp-conatiner">
            <p className="result-yelp-text">{`Yelp (${review_count})`}</p>
            <div className="yelp-review-result-conatiner">
              <div
                className="yelp-review-fill-clip"
                style={{ width: `${ratingPercent}%` }}
              >
                <RatingStarsClip />
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="result-time-container">
        <p className={`result-time-title card-is-${status}`}>{timeText}</p>
        <p className="result-time">{displayTime}</p>
      </div>
    </a>
  );
};

export default withRouter(BusinessCard);
