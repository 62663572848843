import React from "react";

// Styles
import "./svg.css";

export default (props) => {
  return (
    <svg
      width="0"
      height="0"
      // version="1.1"
      // id="Layer_1"
      // xmlns="http://www.w3.org/2000/svg"
      // x="0px"
      // y="0px"
      // viewBox="0 0 250 48"
      // style={{ position: "absolute", top: 0, left: 0 }}
    >
      <defs>
        <clipPath id="rating-clip">
          <polygon
            className="svg-clip-rule"
            points="8.2,2.1 10.6,7.6 16.5,8.1 12.1,12.2 13.4,17.9 8.2,14.9 3.2,17.9 4.3,12.2 0,8.1 5.9,7.6 	"
          />
          <polygon
            className="svg-clip-rule"
            points="24.8,2.1 27.3,7.6 33.2,8.1 28.7,12.2 30,17.9 24.8,14.9 19.7,17.9 21,12.2 16.5,8.1 22.4,7.6 	"
          />
          <polygon
            className="svg-clip-rule"
            points="41.5,2.1 43.8,7.6 49.7,8.1 45.4,12.2 46.5,17.9 41.5,14.9 36.3,17.9 37.6,12.2 33.2,8.1 39.1,7.6 	
		"
          />
          <polygon
            className="svg-clip-rule"
            points="58,2.1 60.5,7.6 66.3,8.1 61.9,12.2 63.2,17.9 58,14.9 52.8,17.9 54.1,12.2 49.7,8.1 55.6,7.6 	"
          />
          <polygon
            className="svg-clip-rule"
            points="74.7,2.1 77,7.6 83,8.1 78.5,12.2 79.7,17.9 74.7,14.9 69.5,17.9 70.8,12.2 66.3,8.1 72.2,7.6 	"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
