import React from "react";

export default props => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 20 30 "
    >
      <path
        d="M1.2,1.4c0-0.4,0.3-0.7,0.7-0.7h16.2c0.4,0,0.7,0.3,0.7,0.7v27.2c0,0.8-0.5,1-1.1,0.5l-7.1-5.9
			c-0.3-0.3-0.8-0.3-1.1,0l-7.1,5.9c-0.6,0.5-1.1,0.3-1.1-0.5V1.4z"
      />
    </svg>
  );
};
