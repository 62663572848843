import { deleteListing } from "../api/admin";
import { deleteImage } from "../api/image";

export const deleteListingData = async ({
  _id,
  isGrowth = false,
  categories,
  images,
  promoCode,
}) => {
  const { cat1Img, cat2Img } = categories;

  const verifyImageDelete = () => {
    if (images.length) {
      return images.map((image) => deleteImage(image));
    } else {
      return [Promise.resolve()];
    }
  };

  const promiseDeleteArray = [
    deleteListing({ isGrowth, id: _id }),
    deleteImage(cat1Img),
    ...verifyImageDelete(),
  ];
  if (cat2Img) promiseDeleteArray.push(deleteImage(cat2Img));
  if (promoCode) promiseDeleteArray.push(deleteImage(promoCode));

  return await Promise.all(promiseDeleteArray);
};
