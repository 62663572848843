import React from "react";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 30"
    >
      <path
        d="M29.7,8.4v2.1c-0.1,0.4-0.1,0.8-0.2,1.2c-0.3,1.9-0.9,3.6-2,5.2c-1.5,2.4-3.4,4.4-5.6,6.3c-2.8,2.3-5.7,4.4-8.8,6.3
	c-0.1-0.1-0.1-0.3-0.2-0.4c-1.4-3-3.3-5.8-5.7-8.1c-1.2-1.2-2.5-2.4-3.7-3.7c-1-1-1.8-2.1-2.4-3.4c-0.7-1.6-1-3.4-0.8-5.1
	c0.1-1.3,0.5-2.5,1.2-3.5c1-1.5,2.5-2.3,4.2-2.7c2.1-0.4,4.3-0.1,6.1,0.9c1.1,0.6,2,1.4,2.7,2.4c0.1-0.2,0.2-0.3,0.3-0.5
	c0.6-1.2,1.4-2.2,2.3-3.1c1.4-1.3,3.3-2,5.2-1.7c1.9,0.2,3.7,1,5,2.4c1.3,1.3,2.1,3,2.3,4.8C29.6,8,29.7,8.2,29.7,8.4z"
      />
    </svg>
  );
};
