import React from "react";

export default props => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 16 16"
    >
      <path
        d="M7.7,4.6C8,5.3,8.3,6.1,8.6,6.8c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.2,0.2,0.3,0.2c0.8,0.1,1.6,0.1,2.4,0.2c0.1,0,0.2,0,0.3,0
			c-0.1-0.2-0.2-0.4-0.2-0.6c-0.6,0.5-1.2,1-1.8,1.6C9.6,8.5,9.5,8.6,9.5,8.7C9.4,8.8,9.4,8.9,9.4,9c0.2,0.8,0.4,1.5,0.5,2.3
			c0,0.1,0,0.2,0.1,0.3c0.2-0.1,0.3-0.3,0.5-0.4c-0.7-0.4-1.4-0.8-2-1.2C8.4,10,8.3,9.9,8.2,9.9c-0.1-0.1-0.2-0.1-0.3,0
			c-0.7,0.4-1.4,0.8-2,1.2c-0.1,0.1-0.2,0.1-0.3,0.2c0.2,0.1,0.3,0.3,0.5,0.4c0.2-0.8,0.4-1.5,0.5-2.3c0-0.1,0-0.2,0.1-0.3
			c0-0.1,0-0.3-0.1-0.3c-0.6-0.5-1.2-1-1.8-1.6C4.7,7.1,4.6,7,4.5,6.9C4.4,7.1,4.3,7.3,4.3,7.5c0.8-0.1,1.6-0.1,2.4-0.2
			c0.1,0,0.2,0,0.3,0c0.1,0,0.2-0.1,0.3-0.2c0.3-0.7,0.6-1.4,0.9-2.2c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.2,0-0.3
			c0-0.1-0.1-0.2-0.2-0.2C8,4.1,7.8,4.1,7.7,4.3C7.4,5,7.1,5.7,6.8,6.4c0,0.1-0.1,0.2-0.1,0.3C6.8,6.7,6.9,6.6,7,6.6
			C6.2,6.6,5.4,6.7,4.6,6.8c-0.1,0-0.2,0-0.3,0C4.1,6.8,4,6.9,4,7c0,0.1,0,0.3,0.1,0.4c0.6,0.5,1.2,1,1.8,1.6C5.9,9,6,9.1,6.1,9.2
			C6,9.1,6,8.9,6,8.8c-0.2,0.8-0.4,1.5-0.5,2.3c0,0.1,0,0.2-0.1,0.3c0,0.1,0,0.3,0.1,0.4c0.1,0.1,0.3,0.1,0.4,0
			c0.7-0.4,1.4-0.8,2-1.2c0.1-0.1,0.2-0.1,0.3-0.2c-0.1,0-0.2,0-0.3,0c0.7,0.4,1.4,0.8,2,1.2c0.1,0.1,0.2,0.1,0.3,0.2
			c0.3,0.2,0.6-0.1,0.5-0.4c-0.2-0.8-0.4-1.5-0.5-2.3c0-0.1,0-0.2-0.1-0.3C10,9,10,9.1,10,9.2c0.6-0.5,1.2-1,1.8-1.6
			c0.1-0.1,0.2-0.1,0.2-0.2c0.1-0.1,0.1-0.3,0.1-0.4c-0.1-0.1-0.2-0.2-0.3-0.2C11,6.7,10.2,6.7,9.4,6.6c-0.1,0-0.2,0-0.3,0
			c0.1,0.1,0.2,0.1,0.3,0.2C9,6,8.7,5.3,8.4,4.6c0-0.1-0.1-0.2-0.1-0.3C8.2,4.1,8,4.1,7.8,4.1C7.7,4.3,7.6,4.4,7.7,4.6z"
      />
      <path
        d="M13.3,8c0,1,0.6,1.8,1.5,2.1c0.2,0.1,0.5,0.1,0.8,0.1c-0.1-0.1-0.2-0.2-0.3-0.3c0,0.7,0,1.4,0,2.1c0,0.1,0,0.2,0,0.3
			c0,0,0,0.1,0,0.1c0,0.1,0-0.1,0,0c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0-0.1,0,0,0,0c0,0,0,0,0,0c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1c0,0,0.1,0,0,0c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1c0,0,0.1,0,0,0c0,0,0,0-0.1,0
			c0,0,0,0-0.1,0c0,0-0.1,0,0,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.4,0-0.8,0-1.2,0c-0.7,0-1.5,0-2.2,0c-0.9,0-1.8,0-2.7,0
			c-0.9,0-1.9,0-2.8,0c-0.8,0-1.6,0-2.4,0c-0.5,0-1,0-1.5,0c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0.1,0,0.1,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.1,0,0.1,0,0,0c0,0-0.1,0-0.1-0.1c0,0,0,0,0,0c-0.1,0,0.1,0,0,0
			c0,0-0.1,0-0.1-0.1c0,0,0,0,0,0c0-0.1,0,0,0,0c0,0,0-0.1-0.1-0.1c0,0,0,0,0,0c0-0.1,0,0.1,0,0c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
			c0,0,0,0.1,0,0c0,0,0-0.1,0-0.1c0-0.7,0-1.5,0-2.2c0-0.1,0-0.2,0-0.2c-0.1,0.1-0.2,0.2-0.3,0.3c1,0,1.8-0.6,2.1-1.5
			c0.3-0.9,0-1.9-0.7-2.5C1.5,5.9,1,5.7,0.4,5.7c0.1,0.1,0.2,0.2,0.3,0.3c0-0.7,0-1.4,0-2.1c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0-0.1
			c0-0.1,0,0.1,0,0c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0.1,0,0,0,0c0,0,0,0,0,0c0,0,0-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0.1-0.1,0.1-0.1c0,0-0.1,0,0,0c0,0,0,0,0,0c0,0,0.1-0.1,0.1-0.1c0,0-0.1,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0.1,0,0,0
			c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.4,0,0.8,0,1.2,0c0.7,0,1.5,0,2.2,0c0.9,0,1.8,0,2.7,0c0.9,0,1.9,0,2.8,0c0.8,0,1.6,0,2.4,0
			c0.5,0,1,0,1.5,0c0.1,0,0.2,0,0.3,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.1,0,0,0c0,0,0.1,0,0.1,0
			c0,0,0,0,0,0c0.1,0-0.1,0,0,0c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0c0.1,0-0.1,0,0,0c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0c0,0.1,0,0,0,0
			c0,0,0,0.1,0.1,0.1c0,0,0,0,0,0c0,0.1,0-0.1,0,0c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0-0.1,0,0c0,0,0,0.1,0,0.1
			c0,0.7,0,1.5,0,2.2c0,0.1,0,0.2,0,0.2c0.1-0.1,0.2-0.2,0.3-0.3c-1,0-1.8,0.6-2.1,1.5C13.3,7.5,13.3,7.7,13.3,8
			c0,0.2,0.2,0.3,0.3,0.3C13.8,8.3,14,8.2,14,8c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0c0,0.1,0,0.1,0,0c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2
			c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2c0,0,0,0,0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1-0.1c0,0,0.1-0.1,0.2-0.1c0,0,0.1-0.1,0.2-0.1
			c0,0-0.1,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0
			c0.2,0,0.3-0.2,0.3-0.3c0-0.7,0-1.4,0-2.2c0-0.1,0-0.2,0-0.3c0-0.4-0.2-0.7-0.4-1c-0.3-0.2-0.6-0.4-1-0.4c-0.4,0-0.8,0-1.2,0
			c-0.7,0-1.5,0-2.2,0c-0.9,0-1.9,0-2.8,0c-0.9,0-1.9,0-2.8,0c-0.8,0-1.6,0-2.4,0c-0.5,0-1,0-1.5,0c-0.1,0-0.2,0-0.2,0
			c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0,1.5,0,2.2c0,0.1,0,0.2,0,0.2c0,0.2,0.2,0.3,0.3,0.3c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0
			c0.1,0-0.1,0,0,0c0.1,0,0.2,0,0.3,0.1c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0.1,0,0.2,0.1,0.2,0.2
			c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1c0,0,0,0,0,0.1c0,0,0-0.1,0,0c0,0,0,0,0,0C2,7.5,2,7.6,2,7.7
			c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0.1,0,0.2C2,8,2,8.1,2,8.2c0,0.1,0-0.1,0,0c0,0,0,0,0,0.1c0,0.1,0,0.1,0,0.2
			c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0,0,0.1C1.6,9.1,1.5,9.2,1.4,9.3c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1
			c0,0,0,0-0.1,0c0,0,0.1,0,0,0c0,0,0,0,0,0c-0.1,0-0.2,0.1-0.3,0.1c0,0-0.1,0-0.1,0c0.1,0,0.1,0,0,0c0,0,0,0,0,0
			c-0.1,0-0.1,0-0.2,0c-0.2,0-0.3,0.2-0.3,0.3c0,0.7,0,1.4,0,2.2c0,0.1,0,0.2,0,0.3c0,0.4,0.2,0.7,0.4,1c0.3,0.2,0.6,0.4,1,0.4
			c0.4,0,0.8,0,1.2,0c0.7,0,1.5,0,2.2,0c0.9,0,1.9,0,2.8,0c0.9,0,1.9,0,2.8,0c0.8,0,1.6,0,2.4,0c0.5,0,1,0,1.5,0c0.1,0,0.2,0,0.2,0
			c0.7,0,1.3-0.6,1.3-1.3c0-0.7,0-1.5,0-2.2c0-0.1,0-0.2,0-0.2c0-0.2-0.2-0.3-0.3-0.3c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0
			c0.1,0,0.1,0,0,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1-0.1-0.2-0.1
			c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0
			c0,0,0,0,0,0c0,0,0,0-0.1-0.1c0,0-0.1-0.1-0.1-0.2c0,0-0.1-0.1-0.1-0.2c0,0,0,0.1,0,0c0,0,0,0,0,0c0,0,0-0.1,0-0.1
			c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2c0-0.2-0.2-0.3-0.3-0.3C13.4,7.7,13.3,7.8,13.3,8z"
      />
    </svg>
  );
};
