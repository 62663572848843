import React from "react";
import { withRouter } from "react-router-dom";
import LazyLoad from "react-lazyload";

// Utilities
import { isMobileAgent } from "../../../../utilites";
import { charLimitTo, toTitleCase } from "../../../../utilites/format";
import { getAdStatus } from "../../../../utilites/status";
import getFeaturesIcon from "../../../components/features-input/getFeatureIcon";

// Assets
import Skyline from "../../../../assets/svg/skyline";

// Styles
import "./result.css";

const EventCard = ({
  result,
  reviewTypeInput,
  filterDate,
  setPagePosition,
}) => {
  const {
    _id,
    categories,
    title,
    businessName,
    features,
    authorName,
    timeStamp,
    type,
  } = result || {};
  const { cat1Img } = categories;
  const { status, timeText, displayTime } = getAdStatus(result, filterDate);
  const isFeatures = features && Object.values(features).find((value) => value);
  const isMobile = isMobileAgent();
  const isEdit = reviewTypeInput === "Edits";

  const renderFeatures = () => {
    const featureElements = [];
    for (let key in features) {
      const value = features[key];
      if (value)
        featureElements.push(
          <div className="result-feature-container" key={key}>
            {getFeaturesIcon(key)}
          </div>
        );
    }
    return featureElements;
  };

  const handleClick = () => {
    const element = document.getElementById("results-wrapper");
    if (setPagePosition && element) {
      const scrollPos = element.scrollTop;
      setPagePosition(scrollPos);
    }
  };

  return (
    <a
      className={`pointer zoomIn result-card-container result-card-${status} ${
        isMobile ? "result-card-mobile" : "result-card-web"
      }`}
      onClick={handleClick}
      href={isEdit ? `/find-edit/${_id}` : `/find/${_id}`}
      target="_blank"
    >
      {cat1Img ? (
        <LazyLoad height={180} scrollContainer="#results-wrapper">
          <img className="result-card-image" src={cat1Img} alt={title} />
        </LazyLoad>
      ) : (
        <div className="result-skyline-overlay">
          <Skyline />
        </div>
      )}

      <div className={`result-card-overlay result-card-overlay-gradient`} />
      <div
        className={`result-card-overlay result-card-status-overlay result-card-overlay-${status}`}
      />

      {isFeatures && (
        <div className="result-features-container">{renderFeatures()}</div>
      )}

      <div className="result-info-container result-info-bottom">
        <p className="result-meta-data">{type}</p>
        <p className="result-meta-data">{authorName}</p>
        <p className="result-meta-data">{timeStamp}</p>
        <h3 className="result-title">{charLimitTo(title, 55).toUpperCase()}</h3>
        <div className="result-name-container">
          <p className="result-business-name">
            {toTitleCase({
              input: charLimitTo(businessName, 40),
              lowerCaseRest: false,
            })}
          </p>
        </div>
      </div>

      <div className="result-time-container">
        <p className={`result-time-title card-is-${status}`}>{timeText}</p>
        <p className="result-time">{displayTime}</p>
      </div>
    </a>
  );
};

export default withRouter(EventCard);
