import React from "react";

export default props => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 30"
    >
      <path
        d="M15,1.7c7.3,0,13.3,5.9,13.3,13.3S22.3,28.3,15,28.3S1.7,22.3,1.7,15S7.7,1.7,15,1.7 M15,0.4C7,0.4,0.4,7,0.4,15
	C0.4,23,7,29.6,15,29.6c8,0,14.6-6.5,14.6-14.6C29.6,7,23,0.4,15,0.4L15,0.4z"
      />
      <path d="M15,13.4c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6s1.6-0.7,1.6-1.6C16.6,14.1,15.9,13.4,15,13.4L15,13.4z" />
      <path d="M15,24.1c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6s1.6-0.7,1.6-1.6C16.6,24.8,15.9,24.1,15,24.1L15,24.1z" />
      <path d="M21.9,11.3c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3C23.2,11.9,22.6,11.3,21.9,11.3L21.9,11.3z" />
      <path d="M7.1,6.9c-0.5,0-1,0.4-1,1c0,0.5,0.4,1,1,1s1-0.4,1-1C8.1,7.4,7.6,6.9,7.1,6.9L7.1,6.9z" />
      <path
        d="M7.9,22c0,0,0.2,0.2,0.5,0.4c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0.1,0.2,0.2,0.4,0.3
	c0.1,0.1,0.3,0.2,0.4,0.3c0.6,0.4,1.5,0.8,2.5,1.1c1,0.3,2.2,0.5,3.5,0.4c0.4,0,0.7-0.1,1-0.1c0.3,0,0.7-0.1,1-0.2
	c0.7-0.2,1.4-0.4,2-0.8c1.3-0.7,2.6-1.7,3.6-3.1c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.2-0.4,0.3-0.5c0.1-0.2,0.2-0.4,0.3-0.6
	c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.2,0.2-0.4,0.2-0.6c0.1-0.2,0.1-0.4,0.2-0.6c0.1-0.4,0.2-0.8,0.3-1.3c0-0.2,0-0.4,0.1-0.7
	c0-0.2,0-0.5,0-0.6l0-0.7l0-0.3l0-0.3L25.1,13c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.4-0.3-0.8-0.4-1.3c-0.4-0.8-0.8-1.6-1.3-2.3
	c-0.2-0.4-0.6-0.7-0.9-1c-0.2-0.2-0.3-0.3-0.5-0.5c-0.2-0.1-0.3-0.3-0.5-0.4c-1.4-1.1-3-1.9-4.7-2.1c-1.7-0.3-3.3-0.2-4.8,0.2
	C10.4,5.4,9.1,6.1,8.1,7c-1,0.9-1.8,1.9-2.4,2.8c-0.1,0.2-0.2,0.5-0.4,0.7c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.1-0.1,0.2-0.1,0.3
	c-0.1,0.2-0.2,0.4-0.2,0.6c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.4-0.2,0.7-0.2,1c-0.1,0.3-0.1,0.6-0.1,0.8c0,0.4,0,0.7,0,0.7
	s0-0.2,0-0.7c0-0.2,0-0.5,0.1-0.8c0.1-0.3,0.1-0.7,0.2-1.1c0-0.2,0.1-0.4,0.2-0.6c0.1-0.2,0.1-0.4,0.2-0.7c0-0.1,0.1-0.2,0.1-0.4
	c0-0.1,0.1-0.2,0.2-0.4c0.1-0.2,0.2-0.5,0.4-0.7c0.5-1,1.3-2,2.3-2.9c1-0.9,2.4-1.7,3.9-2.2c1.5-0.5,3.2-0.6,5-0.4
	c1.7,0.3,3.5,1,4.9,2.1c0.2,0.1,0.4,0.3,0.5,0.4c0.2,0.2,0.4,0.3,0.5,0.5c0.3,0.3,0.7,0.7,0.9,1.1c0.6,0.7,1,1.6,1.4,2.4
	c0.2,0.4,0.4,0.9,0.5,1.3c0.1,0.2,0.1,0.5,0.2,0.7l0.1,0.7l0.1,0.4l0,0.3l0,0.7c0,0.3,0,0.5,0,0.7c0,0.2,0,0.5,0,0.7
	c-0.1,0.5-0.1,0.9-0.2,1.4c-0.1,0.2-0.1,0.5-0.2,0.7c-0.1,0.2-0.2,0.4-0.2,0.7c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.2-0.2,0.4-0.3,0.6
	c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.2-0.2,0.4-0.4,0.6c-1,1.5-2.4,2.6-3.8,3.4c-0.7,0.4-1.5,0.7-2.2,0.9c-0.4,0.1-0.7,0.2-1.1,0.3
	c-0.4,0.1-0.8,0.1-1.1,0.1c-1.4,0.1-2.8,0-3.9-0.4c-1.1-0.3-2.1-0.7-2.8-1.2c-0.2-0.1-0.4-0.2-0.5-0.3c-0.2-0.1-0.3-0.2-0.4-0.3
	c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.2-0.2-0.3-0.2C7.2,23.2,7,23,7,23L7.9,22z"
      />
      <path
        d="M10.2,19.8c0,0,0.1,0.1,0.3,0.3c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0,0.1,0.1,0.2,0.2c0.1,0.1,0.3,0.3,0.5,0.4
	c0.4,0.3,1,0.6,1.7,0.8c0.7,0.2,1.5,0.3,2.4,0.3c0.3,0,0.5,0,0.7-0.1c0.2,0,0.5-0.1,0.7-0.1c0.5-0.1,0.9-0.3,1.4-0.5
	c0.9-0.5,1.8-1.2,2.4-2.1c0.7-0.9,1.1-2,1.3-3.2c0-0.1,0-0.3,0-0.4c0-0.1,0-0.3,0-0.4l0-0.5l0-0.2l0-0.2L22,13.6
	c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.3-0.2-0.6-0.3-0.9c-0.2-0.6-0.5-1.1-0.9-1.6c-0.2-0.3-0.4-0.5-0.6-0.7c-0.1-0.1-0.2-0.2-0.3-0.3
	c-0.1-0.1-0.2-0.2-0.3-0.3c-0.9-0.8-2.1-1.3-3.2-1.5c-1.1-0.2-2.3-0.1-3.3,0.1c-1,0.3-1.9,0.8-2.7,1.4c-0.7,0.6-1.3,1.3-1.7,2
	c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.2-0.2,0.3-0.2,0.5C8,12.5,8,12.7,7.9,12.8c0,0.1-0.1,0.3-0.1,0.4c-0.1,0.3-0.1,0.5-0.1,0.7
	c0,0.2-0.1,0.4-0.1,0.5c0,0.3,0,0.5,0,0.5s0-0.2,0-0.5c0-0.2,0-0.3,0-0.6c0-0.2,0-0.5,0.1-0.7c0-0.1,0.1-0.3,0.1-0.4
	c0-0.1,0.1-0.3,0.2-0.5c0.1-0.2,0.1-0.3,0.2-0.5c0.1-0.2,0.2-0.3,0.2-0.5c0.4-0.7,0.9-1.4,1.6-2.1c0.7-0.6,1.6-1.2,2.7-1.6
	c1.1-0.3,2.3-0.5,3.5-0.3c1.2,0.2,2.4,0.7,3.5,1.5C19.9,9,20,9.2,20.1,9.3c0.1,0.1,0.2,0.2,0.4,0.3c0.2,0.2,0.5,0.5,0.7,0.7
	c0.4,0.5,0.7,1.1,1,1.7c0.1,0.3,0.3,0.6,0.3,0.9c0,0.2,0.1,0.3,0.1,0.5l0.1,0.5l0,0.3l0,0.2l0,0.5c0,0.2,0,0.3,0,0.5
	c0,0.2,0,0.3,0,0.5c-0.2,1.3-0.6,2.6-1.3,3.6c-0.7,1-1.7,1.9-2.7,2.4c-0.5,0.3-1,0.5-1.6,0.7c-0.3,0.1-0.5,0.2-0.8,0.2
	c-0.3,0-0.6,0.1-0.8,0.1c-1,0.1-2,0-2.8-0.3c-0.8-0.2-1.5-0.5-2-0.9c-0.3-0.1-0.5-0.3-0.7-0.5c-0.1-0.1-0.2-0.1-0.3-0.2
	c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2-0.2-0.3-0.3-0.3-0.3L10.2,19.8z"
      />
      <path
        d="M12.5,17.5c0,0,0.1,0.1,0.2,0.2c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.1,0.3,0.2c0.2,0.2,0.5,0.3,0.9,0.4
	c0.4,0.1,0.8,0.2,1.3,0.2c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4-0.1c0.2-0.1,0.5-0.2,0.7-0.3c0.5-0.2,1-0.6,1.3-1.1
	c0.4-0.5,0.6-1.1,0.7-1.7c0-0.2,0-0.3,0-0.5l0-0.3l0-0.1l0-0.1c0-0.3-0.1-0.6-0.2-1c-0.2-0.6-0.6-1.2-1.2-1.6
	c-0.5-0.4-1.1-0.7-1.8-0.8c-0.6-0.1-1.3-0.1-1.9,0.1c-0.6,0.2-1.1,0.4-1.5,0.8c-0.4,0.3-0.7,0.7-0.9,1.1c-0.2,0.4-0.4,0.7-0.4,1
	c-0.1,0.3-0.1,0.5-0.1,0.7c0,0.2,0,0.3,0,0.3s0-0.1,0-0.3c0-0.2,0-0.4,0.1-0.7c0.1-0.3,0.2-0.7,0.4-1.1c0.2-0.4,0.5-0.8,0.9-1.2
	c0.4-0.4,0.9-0.7,1.5-0.9c0.6-0.2,1.3-0.3,2-0.2c0.7,0.1,1.4,0.4,2,0.8c0.6,0.4,1.1,1.1,1.4,1.8c0.1,0.2,0.2,0.4,0.2,0.5l0.1,0.3
	l0.1,0.3l0,0.2l0,0.1l0,0.3c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3c-0.1,0.8-0.3,1.5-0.8,2.1c-0.4,0.6-1,1.1-1.6,1.5
	c-0.3,0.2-0.6,0.3-0.9,0.4c-0.2,0-0.3,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.6,0.1-1.2,0-1.7-0.1c-0.5-0.1-0.9-0.3-1.2-0.5
	c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1,0-0.1-0.1-0.2-0.1c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.2-0.2-0.2L12.5,17.5z"
      />
      <rect
        x="3"
        y="19.3"
        transform="matrix(0.7072 -0.7071 0.7071 0.7072 -11.16 12.9478)"
        width="14.1"
        height="1.3"
      />
    </svg>
  );
};
