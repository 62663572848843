import React from "react";
import { useDispatch } from "react-redux";
import * as stateActions from "../../../../redux/actions/state-actions";

// Assets
import Share from "../../../../assets/svg/share";

// Styles
import "./share.css";

export default ({ size, isDarkColor = false, title, name }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(stateActions.setShareDrawer({ title, name }));
  };

  return (
    <div
      className={`share-button-container ${
        isDarkColor ? "share-button-container-dark" : ""
      }`}
      style={{ width: size, height: size }}
      onClick={handleClick}
    >
      <Share />
    </div>
  );
};
