import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as stateActions from "../../../redux/actions/state-actions";
import moment from "moment";

// Components
import WeekdaysTimeInput from "../../components/WeekdaysTimeInput";
import Checkbox from "../common/checkbox";
import TimeSelector from "../common/time-selector";
import Map from "../map";
import Categories from "../categories-input";
import Features from "../features-input";
import ImageUpload from "../image-upload";
import Toggle from "../common/toggle";

// Utilities
import {
  features as featuresModel,
  searchTypes,
  searchTypesDescriptions,
} from "../../../constants/data-models";
import { userCheckin } from "../../../constants/feature-flags";
import { formatEndTime, militaryTimeToReadable } from "../../../utilites/time";
import {
  getCurrentDate,
  convertDateToDbDate,
  formatDbDateToReadable,
  plus7Days,
} from "../../../utilites/date";
import { evaluateAvailabilityDays } from "../../../utilites/validate";
import defaultData from "../../routes/sign-up/business/data-weekdats-default";

// Utilities
import { getCoordinates } from "../../../api/search";
import useDebounce from "../../../utilites/hooks/debounce";
import { createSingleDayHours } from "../../../utilites/format";

// Assets
import Close from "../../../assets/svg/close";
import Location from "../../../assets/svg/location";
import Arrow from "../../../assets/svg/back";

// Styles
import "../../routes/sign-up/sign-up.css";
import "./listing-common.css";

export default ({ isGrowth, page, user = {}, ad, handleCommonData }) => {
  const { user: reduxUser } = useSelector((store) => store.user);
  const { _id: reduxUserId, location: reduxUserLocation } = reduxUser || {};
  const {
    location: userLocation,
    features: userFeatures = {},
    hours: businessHours,
    locationType,
  } = user;
  const {
    businessId: businessAdId,
    isGrowth: adIsGrowth,
    type: adtype = "",
    title = "",
    categories: adCategories,
    description = "",
    days,
    checkInRequired,
    checkInOnce = true,
    ticketLink,
    promoCode = "",
    features: adFeatures,
    virtualLink = "",
  } = ad || {};
  const {
    isBusinessHours: adIsBusinessHours,
    dateAvailable: adDateAvailable,
    startDate,
    endDate,
    hours: adHours,
  } = days || {};
  const currentDBDate = convertDateToDbDate();
  let isRepeatListing = true;
  let formattedDate = "";
  let startTime = 800;
  let endTime = 2000;
  if (adDateAvailable) {
    startTime = days.hours.Monday.open;
    endTime = days.hours.Monday.close;
    isRepeatListing = false;
    const dateToFiormat =
      adDateAvailable >= currentDBDate ? adDateAvailable : currentDBDate;
    const splitDate = String(dateToFiormat).split("");
    formattedDate = splitDate.reduce((acc, cur, idx) => {
      if (idx === 2 || idx === 4) {
        return `${acc}-${cur}`;
      }
      return `${acc}${cur}`;
    }, "20");
  }
  const dispatch = useDispatch();
  const [typeSelect, setTypeSelect] = useState(adtype);
  const [titleInput, setTitleInput] = useState(title);
  const [categories, setCategories] = useState(
    adCategories || {
      cat1: "",
      cat1Sub: "",
      cat1Img: "",
      cat2: "",
      cat2Sub: "",
      cat2Img: "",
    }
  );
  const [featuresInput, setFeaturesInput] = useState(
    adFeatures || userFeatures || {}
  );
  const [virtiualInput, setVirtiualInput] = useState(virtualLink);
  const [descriptionInput, setDescriptionInput] = useState(description);
  const debouncedDescriptionInput = useDebounce(descriptionInput, 200);
  const [repeatListingInput, setRepeatListingInput] = useState(isRepeatListing);
  const [checkInInput, setCheckInInput] = useState(checkInRequired);
  const [checkInOnceInput, setCheckInOnceInput] = useState(checkInOnce);
  const [dateInput, setDateInput] = useState(formattedDate || getCurrentDate());
  const [startDateInput, setStartDateInput] = useState(
    startDate
      ? formatDbDateToReadable({
          date: startDate >= currentDBDate ? startDate : currentDBDate,
          toFullYear: true,
        })
      : getCurrentDate()
  );
  const [endDateInput, setEndDateInput] = useState(
    endDate && endDate !== 999999
      ? formatDbDateToReadable({ date: endDate, toFullYear: true })
      : plus7Days()
  );
  const [isIndefinite, setIsIndefinite] = useState(
    endDate && endDate !== 999999 ? false : true
  );
  const [isMap, setIsMap] = useState(
    isGrowth || adIsGrowth ? isGrowth || adIsGrowth : !userLocation
  );
  const [location, setLocation] = useState(
    ad && ad.location ? ad.location : userLocation
  );
  const [ticketInput, setTicketInput] = useState(ticketLink);
  const [promoCodeInput, setPromoCodeInput] = useState(promoCode);
  const [enteredLocation, setEnteredLocation] = useState("");
  const [isBusinessHours, setIsBusinessHours] = useState(
    adIsBusinessHours || false
  );
  const [hours, setHours] = useState(
    !adIsBusinessHours && adHours ? adHours : businessHours || defaultData.hours
  );
  const [startTimeInput, setStartTimeInput] = useState(startTime || 800);
  const [endTimeInput, setEndTimeInput] = useState(endTime || 2000);
  const { locationIsdefault = true, address, coordinates } = location || {};
  const isDiscriptionComplete = descriptionInput.length < 40;
  const isCorporate = locationType === "Multiple Locations";
  const discriptionLegend = isDiscriptionComplete
    ? `${40 - descriptionInput.trim().length} more characters needed`
    : `${descriptionInput.trim().length} characters`;

  useEffect(() => {
    handleCommonData({
      typeSelect,
      categories,
      titleInput: (titleInput || "").trim().toLowerCase(),
      descriptionInput: debouncedDescriptionInput,
      featuresInput,
      days: repeatListingInput
        ? {
            rate: evaluateAvailabilityDays(
              isBusinessHours ? businessHours : hours
            ),
            isBusinessHours,
            startDate: convertDateToDbDate(startDateInput),
            endDate: isIndefinite ? 999999 : convertDateToDbDate(endDateInput),
            hours: isBusinessHours ? businessHours : hours,
          }
        : {
            rate: 1,
            dateAvailable: convertDateToDbDate(dateInput),
            hours: createSingleDayHours(startTimeInput, endTimeInput),
          },
      checkInInput,
      checkInOnceInput,
      isBusinessHours,
      startTimeInput,
      endTimeInput,
      location,
      promoCodeInput,
      ticketInput: (ticketInput || "").trim(),
      virtiualInput: (virtiualInput || "").trim(),
    });
  }, [
    typeSelect,
    categories,
    titleInput,
    debouncedDescriptionInput,
    featuresInput,
    repeatListingInput,
    dateInput,
    isIndefinite,
    checkInInput,
    checkInOnceInput,
    isBusinessHours,
    startDateInput,
    endDateInput,
    startTimeInput,
    endTimeInput,
    hours,
    location,
    promoCodeInput,
    ticketInput,
    virtiualInput,
  ]);

  (() => {
    const allTerms = [
      "specialty",
      "instagram",
      "promote",
      ...searchTypes.map((term) => term.toLowerCase().split(" ")).flat(),
    ];
    const found = allTerms.find((term) =>
      titleInput.toLowerCase().includes(term)
    );
    if (found) {
      const cleanedInput = titleInput.replace(found, "");
      setTitleInput(cleanedInput || "");
      dispatch(
        stateActions.setMessage({
          messageType: "error",
          message: `Cannot use term "${found}"`,
        })
      );
    }
  })();

  const handleChange = (evt, type, newValue) => {
    const { value } = evt.target;
    switch (type) {
      case "titleInput":
        return setTitleInput(value);
      case "descriptionInput":
        return setDescriptionInput(value);
      case "repeatListingInput":
        if (newValue) {
          setDateInput(null);
        } else {
          setDateInput(getCurrentDate());
        }
        setCheckInOnceInput(true);
        return setRepeatListingInput(newValue);
      case "dateInput":
        const isValidDate = moment().diff(value, "days") < 1;
        let message = "";
        if (!isValidDate) message = "Invalid date";
        if (message) {
          return dispatch(
            stateActions.setMessage({ messageType: "error", message })
          );
        } else {
          return setDateInput(value);
        }
      case "startDateInput":
        if (!(moment().diff(value, "days") < 1)) {
          return dispatch(
            stateActions.setMessage({
              messageType: "error",
              message: "Invalid date",
            })
          );
        } else {
          if (!isIndefinite && moment(value).isSameOrAfter(endDateInput)) {
            setEndDateInput(plus7Days(value));
          }
          return setStartDateInput(value);
        }
      case "endDateInput":
        if (moment(value).isAfter(startDateInput)) {
          return setEndDateInput(value);
        } else {
          return dispatch(
            stateActions.setMessage({
              messageType: "error",
              message: "Invalid date",
            })
          );
        }
      case "startTimeInput":
        return setStartTimeInput(value);
      case "endTimeInput":
        return setEndTimeInput(formatEndTime(value));
      case "checkInRequired":
        return setCheckInInput(!checkInInput);
      case "checkInOnceBox":
        return setCheckInOnceInput(newValue);
      case "location":
        return setEnteredLocation(value);
      case "ticketInput":
        return setTicketInput(value);
      case "promoCodeInput":
        return setPromoCodeInput(newValue);
      case "VirtualInput":
        return setVirtiualInput(value);
      case "typeSelect":
        return setTypeSelect(value);
      case "isBusinessHours":
        return setIsBusinessHours(newValue);
    }
  };

  const handleToggle = (evt, value) => {
    if (!value && moment(startDateInput).isSameOrAfter(endDateInput)) {
      setEndDateInput(plus7Days(startDateInput));
    }
    setIsIndefinite(value);
  };

  const handleFeatureClick = (feature) => {
    const value = featuresInput[feature] || false;
    if (!value) return setFeaturesInput({ ...featuresInput, [feature]: true });
    const newFeaturesInput = { ...featuresInput };
    delete newFeaturesInput[feature];
    setFeaturesInput(newFeaturesInput);
  };

  const handleLocationEnter = async () => {
    const { location } =
      (await getCoordinates({ address: enteredLocation })) || {};
    if (location && "coordinates" in location) {
      location.locationIsdefault = false;
      setEnteredLocation("");
      setLocation(location);
    }
  };

  const handleKeyDown = (evt) => {
    const { which, keyCode } = evt;
    const isEnter = which === 13 || keyCode === 13;
    if (isEnter) handleLocationEnter();
  };

  const renderCheckInOnceBoxes = () =>
    repeatListingInput &&
    checkInInput && (
      <div className="card-info-form">
        <label className="label-form">User can claim special:</label>
        <div className="checkbox-outer-wrapper-CreateListing">
          <div className="checkbox-inner-wrapper-CreateListing">
            <label>Only Once</label>
            <Checkbox
              state={checkInOnceInput}
              handleClick={handleChange}
              params={[{ target: { value: "" } }, "checkInOnceBox", true]}
              checkbox-inner-wrapper-CreateListing
            />
          </div>
          <div className="checkbox-inner-wrapper-CreateListing">
            <label>Every Repeat</label>
            <Checkbox
              state={!checkInOnceInput}
              handleClick={handleChange}
              params={[{ target: { value: "" } }, "checkInOnceBox", false]}
            />
          </div>
        </div>
      </div>
    );

  const renderBusinessDays = () => {
    const filteredHours = Object.keys(hours).filter(
      (day) => day !== "isBusinessHours"
    );
    return filteredHours.map((day, key) => {
      const { isOpen, open, close } = businessHours[day] || {};
      return (
        <div
          className="business-hour-conatiner business-hour-conatiner-inactive"
          key={key}
        >
          <p className="business-hour-item">{day}</p>
          {isOpen ? (
            <>
              <p className="business-hour-item">
                {militaryTimeToReadable(open)}
              </p>
              <p className="business-hour-item">
                {militaryTimeToReadable(close)}
              </p>
            </>
          ) : (
            <>
              <p className="business-hour-item">Closed</p>
              <p className="business-hour-item">Closed</p>
            </>
          )}
        </div>
      );
    });
  };

  return (
    <>
      <div className="card card-form">
        <div className="card-info-form">
          <label id="typeSelect" className="label-form">
            Type:
          </label>
          <select
            className="input-style input-form select-form"
            value={typeSelect}
            onChange={(evt) => handleChange(evt, "typeSelect")}
          >
            {["", ...searchTypes].slice(0, -1).map((type, idx) => {
              const text = type ? type : "- Select -";
              return (
                <option value={type} key={idx}>
                  {text}
                </option>
              );
            })}
          </select>
          {/* <p className="card-SignUp-description">
            {searchTypesDescriptions[typeSelect]}
          </p> */}
          <legend className="legend-form" />
        </div>
      </div>

      <div id="titleInput" className="card card-form">
        <div className="card-info-form">
          <label className="label-form">Title:</label>
          <input
            className="input-style input-form text-form"
            type="text"
            value={titleInput}
            onChange={(evt) => handleChange(evt, "titleInput")}
            spellCheck="true"
          />
          <legend className="legend-form" />
        </div>
      </div>

      <Categories
        isListing
        categories={categories}
        setCategories={setCategories}
      />

      <div id="descriptionInput" className="card card-form">
        <div className="card-info-form">
          <div
            className="legend-form-split-container"
            style={{ paddingTop: "10px" }}
          >
            <label className="label-form">Description:</label>
            <label className="label-form">
              <p
                className={`legend-form-text ${
                  isDiscriptionComplete
                    ? "legend-form-incomplete"
                    : "legend-form-complete"
                }`}
              >
                {discriptionLegend}
              </p>
            </label>
          </div>
          <textarea
            className="input-style input-form"
            rows="5"
            maxLength="1500"
            value={descriptionInput}
            onChange={(evt) => handleChange(evt, "descriptionInput")}
            spellCheck="true"
          />
          <legend className="legend-form" />
        </div>
      </div>

      <div className="card card-form">
        <div className="card-info-form">
          <label className="label-form">Length Of Listing:</label>
          <div className="checkbox-outer-wrapper-CreateListing">
            <div className="checkbox-inner-wrapper-CreateListing">
              <label className="checkbox-label">One Time</label>
              <Checkbox
                state={!repeatListingInput}
                handleClick={handleChange}
                params={[
                  { target: { value: "" } },
                  "repeatListingInput",
                  false,
                ]}
              />
            </div>
            <div className="checkbox-inner-wrapper-CreateListing">
              <label className="checkbox-label">Repeat</label>
              <Checkbox
                state={repeatListingInput}
                handleClick={handleChange}
                params={[{ target: { value: "" } }, "repeatListingInput", true]}
              />
            </div>
          </div>
          {repeatListingInput ? (
            <>
              <label id="startDateInput" className="label-form">
                Start Date:
              </label>
              <input
                className="input-style input-form text-form"
                type="date"
                value={startDateInput}
                onChange={(evt) => handleChange(evt, "startDateInput")}
                placeholder="yyyy-mm-dd"
              />
              <label id="endDateInput" className="label-form">
                End Date:
                <div className="show-form-toggle">
                  <p className="show-form-toggle-text">Indefinite</p>
                  <Toggle
                    value={isIndefinite}
                    handleDebouceToggle={handleToggle}
                  />
                </div>
              </label>
              {!isIndefinite && (
                <input
                  className="input-style input-form text-form"
                  type="date"
                  value={endDateInput}
                  onChange={(evt) => handleChange(evt, "endDateInput")}
                  placeholder="yyyy-mm-dd"
                />
              )}
              {businessHours && (
                <div
                  className="checkbox-outer-wrapper-CreateListing"
                  style={{ marginTop: "35px" }}
                >
                  <div className="checkbox-inner-wrapper-CreateListing">
                    <label className="checkbox-label">Custom hours</label>
                    <Checkbox
                      state={!isBusinessHours}
                      handleClick={handleChange}
                      params={[
                        { target: { value: "" } },
                        "isBusinessHours",
                        false,
                      ]}
                    />
                  </div>
                  <div className="checkbox-inner-wrapper-CreateListing">
                    <label className="checkbox-label">Use Business Hours</label>
                    <Checkbox
                      state={isBusinessHours}
                      handleClick={handleChange}
                      params={[
                        { target: { value: "" } },
                        "isBusinessHours",
                        true,
                      ]}
                    />
                  </div>
                </div>
              )}
              {isBusinessHours ? (
                isCorporate ? (
                  <p className="use-business-hours-corporate-text">
                    Will use indivdual locations hours of operation.
                  </p>
                ) : (
                  renderBusinessDays()
                )
              ) : (
                <>
                  <label className="label-form" style={{ marginTop: "20px" }}>
                    Days & Times:
                  </label>
                  <WeekdaysTimeInput
                    openLabel="Start"
                    closeLabel="End"
                    page={page}
                    text="Availability days & hours:"
                    hours={hours}
                    setHours={setHours}
                    isCard={false}
                  />
                </>
              )}
            </>
          ) : (
            <>
              <label id="oneTimeDate" className="label-form">
                Date:
              </label>
              <input
                className="input-style input-form text-form"
                type="date"
                value={dateInput}
                onChange={(evt) => handleChange(evt, "dateInput")}
                placeholder="yyyy-mm-dd"
              />
              <label className="label-form" style={{ marginTop: "25px" }}>
                Time:
              </label>
              <div className="time-selector-wrapper-CreateListing">
                <TimeSelector
                  name="startTimeInput"
                  label="Start"
                  startTime={0}
                  value={startTimeInput}
                  handleChange={handleChange}
                />
                <TimeSelector
                  name="endTimeInput"
                  label="End"
                  startTime={startTimeInput}
                  value={endTimeInput}
                  handleChange={handleChange}
                />
              </div>
            </>
          )}
          <legend className="legend-form" />
        </div>
      </div>

      {userCheckin && (
        <div className="card card-form">
          <div className="card-info-form">
            <label className="label-form">User Check-In Required:</label>
            <div className="checkbox-outer-wrapper-CreateListing">
              <div className="checkbox-inner-wrapper-CreateListing" />
              <div className="checkbox-inner-wrapper-CreateListing">
                <Checkbox
                  state={checkInInput}
                  handleClick={handleChange}
                  params={[{ target: { value: "" } }, "checkInRequired", false]}
                />
              </div>
            </div>
            {renderCheckInOnceBoxes()}
            <legend className="legend-form" />
          </div>
        </div>
      )}

      <Features
        options={[...featuresModel, "Virtual"]}
        values={featuresInput}
        handleClick={handleFeatureClick}
        linkValue={virtiualInput}
        handleChange={handleChange}
        isVirtualOption
      />

      {!isCorporate && (
        <div className="card card-form">
          <div className="card-info-form">
            <div
              className="signup-toggle-split-container"
              onClick={() => setIsMap(!isMap)}
            >
              <label id="locationInput" className="label-form">
                Location:
              </label>
              <div
                className={`signup-toggle-subsection-svg-container "
              ${isMap ? "signup-toggle-subsection-svg-container-open" : ""}`}
              >
                <Arrow />
              </div>
            </div>

            <div
              className={`card-form signup-toggle-subsection ${
                isMap ? "signup-toggle-subsection-open" : ""
              }`}
            >
              <Map
                mapCenter={coordinates}
                coordinates={coordinates}
                isMarkerShown={false}
                containerElement={
                  <div style={{ height: `300px`, width: "100%" }} />
                }
                mapElement={<div style={{ height: "100%" }} />}
              />
              <div className="input-style input-form text-form listingCommon-location-conatiner">
                {businessAdId === reduxUserId && !locationIsdefault && (
                  <div
                    className="listingCommon-close-svg-conatiner"
                    onClick={() => {
                      setEnteredLocation("");
                      setLocation(reduxUserLocation);
                    }}
                  >
                    <Close />
                  </div>
                )}
                <input
                  className="listingCommon-location-input"
                  placeholder={address ? address : "Enter full address"}
                  type="text"
                  value={enteredLocation}
                  onKeyDown={handleKeyDown}
                  onChange={(evt) => handleChange(evt, "location")}
                  spellCheck="true"
                />
                <div
                  className="listingCommon-location-button"
                  onClick={handleLocationEnter}
                >
                  <div className="listingCommon-location-svg-conatiner">
                    <Location />
                  </div>
                  <p>FIND</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div id="ticketInput" className="card card-form">
        <div className="card-info-form">
          <label className="label-form">Tickets Link:</label>
          <input
            className="input-style input-form text-form"
            type="text"
            value={ticketInput}
            onChange={(evt) => handleChange(evt, "ticketInput")}
            spellCheck="true"
          />
        </div>
      </div>

      <div id="ImageInputContainer" className="card card-form">
        <div className="card-info-form">
          <label className="label-form">Promo Code :</label>
          <div className="time-selector-wrapper-CreateListing">
            <ImageUpload
              text={"add code"}
              type={"promoCodeInput"}
              imageInput={promoCodeInput}
              handleChange={handleChange}
              isPromoCode
            />
          </div>
          <legend className="legend-form">
            Upload QR or bar code code if needed to redeem.
          </legend>
        </div>
      </div>
    </>
  );
};
