import React from "react";

// Assets
import Animals from "../../../assets/svg/animals";
import Arcade from "../../../assets/svg/arcade";
import Bar from "../../../assets/svg/bar";
import Patio from "../../../assets/svg/patio";
import Rooftop from "../../../assets/svg/rooftop";
import Dog from "../../../assets/svg/dog";
import Brewery from "../../../assets/svg/brewery";
import Speakeasy from "../../../assets/svg/speakeasy";
import SportsBar from "../../../assets/svg/sportsbar";
import Dance from "../../../assets/svg/dance";
import Pool from "../../../assets/svg/pool";
import FoodTruck from "../../../assets/svg/foodtruck";
import FirePit from "../../../assets/svg/firpit";
import Outdoors from "../../../assets/svg/outdoors";
import View from "../../../assets/svg/view";
import Vineyard from "../../../assets/svg/vineyard";
import WaterSport from "../../../assets/svg/watersport";
import IndoorDining from "../../../assets/svg/indoor dining";
import OutdoorDining from "../../../assets/svg/outdoor dining";
import TakeOut from "../../../assets/svg/take-out";
import CurbsidePickup from "../../../assets/svg/curbside pickup";
import Delivery from "../../../assets/svg/delivery";
import Heart from "../../../assets/svg/heart";
import Video from "../../../assets/svg/video";
import BlackOwned from "../../../assets/svg/black-owned";
import OutdoorHeater from "../../../assets/svg/outdoor heater";
import Business from "../../../assets/svg/business";
import ManyLocations from "../../../assets/svg/many-locations";
import Instaworthy from "../../../assets/svg/instaworthy";
import Award from "../../../assets/svg/award";
import Promote from "../../../assets/svg/promote";
import Deals from "../../../assets/svg/deals";
import LGBTQ from "../../../assets/svg/lgbtq";
import Kids from "../../../assets/svg/kids";
import Seasonal from "../../../assets/svg/seasonal";
import Attraction from "../../../assets/svg/attraction";
import Landmark from "../../../assets/svg/landmark";

export default function getFeaturesIcon(name) {
  switch (name) {
    case "Animals":
      return <Animals />;
    case "Arcade":
      return <Arcade />;
    case "Bar":
      return <Bar />;
    case "Brewery":
      return <Brewery />;
    case "Dance Floor":
      return <Dance />;
    case "Dog Friendly":
      return <Dog />;
    case "Fire Pit":
      return <FirePit />;
    case "Food Truck":
      return <FoodTruck />;
    case "Outdoors":
      return <Outdoors />;
    case "Patio":
      return <Patio />;
    case "Pool Table":
      return <Pool />;
    case "Rooftop":
      return <Rooftop />;
    case "Speakeasy":
      return <Speakeasy />;
    case "Sports Bar":
      return <SportsBar />;
    case "View":
      return <View />;
    case "Vineyard":
      return <Vineyard />;
    case "Water Sport":
      return <WaterSport />;
    case "Indoor Dining":
      return <IndoorDining />;
    case "Outdoor Dining":
      return <OutdoorDining />;
    case "Take Out":
      return <TakeOut />;
    case "Curbside Pickup":
      return <CurbsidePickup />;
    case "Delivery":
      return <Delivery />;
    case "Singles":
      return <Heart />;
    case "Virtual":
      return <Video />;
    case "Black Owned":
      return <BlackOwned />;
    case "Outdoor Heater":
      return <OutdoorHeater />;
    case "Business":
      return <Business />;
    case "No Physical Location":
      return <FoodTruck />;
    case "One Location":
      return <Business />;
    case "Multiple Locations":
      return <ManyLocations />;
    case "Instaworthy":
      return <Instaworthy />;
    case "Signature Specialities":
      return <Award />;
    case "Business":
      return <Business />;
    case "Promotions":
      return <Promote />;
    case "deals":
      return <Deals />;
    case "LGBTQ":
      return <LGBTQ />;
    case "Kids":
      return <Kids />;
    case "Seasonal":
      return <Seasonal />;
    case "Attraction":
      return <Attraction />;
    case "Landmark":
      return <Landmark />;
    default:
      return null;
  }
}
