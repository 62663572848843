export default {
  hours: {
    Monday: {
      isOpen: false,
      open: 800,
      close: 2000,
    },
    Tuesday: {
      isOpen: false,
      open: 800,
      close: 2000,
    },
    Wednesday: {
      isOpen: false,
      open: 800,
      close: 2000,
    },
    Thursday: {
      isOpen: false,
      open: 800,
      close: 2000,
    },
    Friday: {
      isOpen: false,
      open: 800,
      close: 2000,
    },
    Saturday: {
      isOpen: false,
      open: 800,
      close: 2000,
    },
    Sunday: {
      isOpen: false,
      open: 800,
      close: 2000,
    },
  },
  daysOrder: [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ],
};
