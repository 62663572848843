import React from "react";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 28"
    >
      <path d="M11.5,19.9c0.5,0.1,1-0.2,1.1-0.8c0.1-0.5-0.2-1-0.8-1.1s-1,0.2-1.1,0.8S10.9,19.8,11.5,19.9z" />
      <path
        d="M11.3,22.9c0-0.5,0-1,0.1-1.4c-0.1,0-0.3-0.1-0.4-0.2c-0.2-0.1-0.4-0.2-0.4-0.2c-0.1-0.1-0.2-0.2-0.2-0.3
		c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0,0.2,0,0.2,0.1l0,0c0,0,0,0,0.1,0c0,0,0.1,0.1,0.2,0.1c0,0,0.1,0,0.2,0.1
		c0-0.3-0.2-0.6-0.5-0.6c-0.4-0.1-0.9,0.1-1.1,0.8c-0.2,0.9-0.3,1.4-0.1,2.3c0.1,0.4,0.3,0.6,0.5,0.7c0.1,0,0.1,0,0.2,0
		c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2,0c0.2,0,0.5,0,0.7,0.1c0.1,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0.3,0.3,0.6,0.3,0.9
		c0.1,0.3,0.1,0.6,0.1,0.7c0,0,0,0,0,0c0,0,0,0,0,0v0c0,0.3,0.2,0.5,0.4,0.5c0,0,0,0,0,0c0.2,0,0.5-0.2,0.5-0.4c0,0,0,0,0-0.1
		c0-0.1,0-0.4-0.1-0.8c-0.1-0.4-0.2-0.8-0.5-1.2c-0.1-0.2-0.3-0.4-0.5-0.5c-0.3-0.2-0.6-0.3-0.9-0.4C11.6,22.9,11.5,22.9,11.3,22.9z
		"
      />
      <path
        d="M13.5,19.4l-0.8,0.4l0.1,0.3c0,0,0,0,0-0.1c0.1-0.2,0.2-0.2,0.4-0.2c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0.1,0.2,0.2
		c0,0.1,0,0.2,0,0.3c-0.1,0.1-0.1,0.2-0.2,0.3l0.3-0.1c0.1-0.1,0.2-0.2,0.1-0.4L13.5,19.4z"
      />
      <path
        d="M13.4,19.9c-0.2-0.1-0.4,0-0.4,0.2c0,0,0,0.1-0.1,0.1c-0.1,0.2-0.3,0.4-0.4,0.4c-0.2,0.1-0.3,0.1-0.5,0.1
		c-0.2,0-0.3,0-0.5-0.1c-0.1,0-0.2-0.1-0.2-0.1c-0.1,0-0.2-0.1-0.2-0.1c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0v0
		c-0.2-0.1-0.4-0.1-0.5,0.1c-0.1,0.2-0.1,0.4,0.1,0.5h0c0,0,0.2,0.1,0.4,0.2c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0,0.4,0.1,0.6,0.1
		c0,0,0,0,0,0c0.3,0,0.6-0.1,0.9-0.2c0.2-0.1,0.3-0.3,0.5-0.5c0.1-0.1,0.2-0.3,0.2-0.4C13.6,20.2,13.6,20,13.4,19.9z"
      />
      <path
        d="M15.9,21.4l-0.3-0.1c-0.1,0-0.1,0-0.1,0.1c0,0,0,0.1,0,0.1c-0.2,0-0.3,0.1-0.3,0.1c0,0.1,0.3,0.3,0.7,0.3l0.1,0.1h0.6
		l0.1-0.1c0.3,0,0.5-0.1,0.7-0.2c0.1,0,0.2,0,0.3,0c0,0,0,0,0,0c0.1,0,0.3,0,0.4,0c-0.2,0-0.4,0-0.7-0.1c-0.1,0-0.2,0-0.2-0.1
		c-0.1,0-0.2-0.1-0.2-0.1c0,0-0.1,0-0.1,0c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0-0.1-0.1
		c-0.1,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0.1-0.2,0.2C16.1,21.4,16,21.4,15.9,21.4C16,21.4,15.9,21.4,15.9,21.4z M16.5,21.4
		C16.5,21.4,16.5,21.4,16.5,21.4L16.5,21.4C16.4,21.4,16.4,21.4,16.5,21.4L16.5,21.4C16.4,21.4,16.5,21.4,16.5,21.4z M16.4,21.3
		C16.4,21.3,16.4,21.3,16.4,21.3C16.4,21.3,16.4,21.3,16.4,21.3L16.4,21.3c-0.1,0.1-0.1,0.1-0.1,0.1L16.4,21.3z"
      />
      <path d="M18.5,19.9c0.5-0.1,0.9-0.6,0.8-1.1c-0.1-0.5-0.6-0.9-1.1-0.8c-0.5,0.1-0.9,0.6-0.8,1.1C17.5,19.6,18,20,18.5,19.9z" />
      <path
        d="M18.5,20.5c0,0.1-0.1,0.2-0.2,0.3c0.1,0,0.1,0,0.2,0c0.1,0,0.1-0.1,0.2-0.1c0.1-0.1,0.2-0.2,0.2-0.2l0,0
		c0.1-0.1,0.2-0.2,0.4-0.2c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0.1c0.2,0.1,0.3,0.4,0.2,0.6c0,0.1-0.1,0.2-0.4,0.4
		c-0.1,0.1-0.3,0.2-0.6,0.3c0.1,0.4,0.1,0.9,0.1,1.3c-0.2,0-0.5,0.1-0.7,0.1c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.4,0.3-0.5,0.5
		c-0.3,0.4-0.4,0.9-0.5,1.2c-0.1,0.4-0.1,0.7-0.1,0.8c0,0,0,0.1,0,0.1c0,0.2,0.2,0.4,0.5,0.4c0,0,0,0,0,0c0.3,0,0.5-0.2,0.4-0.5
		c0,0,0,0,0,0c0-0.1,0-0.4,0.1-0.7c0.1-0.3,0.2-0.6,0.3-0.9c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.3,0,0.5-0.1
		c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2,0c0.2-0.1,0.4-0.2,0.5-0.7c0.2-0.9,0.1-1.5-0.1-2.3
		c-0.2-0.6-0.7-0.8-1.1-0.8C18.7,20,18.5,20.2,18.5,20.5z"
      />
      <path
        d="M16.8,21.2C16.8,21.2,16.8,21.2,16.8,21.2c0,0,0.1,0.1,0.1,0.1c0.5,0.2,0.9,0.3,1.2,0.3c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0
		c0.1,0,0.2,0,0.3,0c0.2-0.1,0.4-0.2,0.6-0.3c0.2-0.2,0.3-0.3,0.3-0.4c0.1-0.2,0-0.4-0.1-0.5c0,0-0.1,0-0.1,0
		c-0.1,0-0.3,0.1-0.3,0.2l0,0c0,0-0.1,0.1-0.2,0.2c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3,0.1c0,0,0,0,0,0c-0.2,0-0.4,0-0.7-0.1
		c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0-0.1,0c-0.2-0.1-0.4,0-0.4,0.2C16.6,20.9,16.6,21,16.8,21.2C16.7,21.2,16.7,21.2,16.8,21.2z"
      />
      <path
        d="M15,0.6L0.5,6.9v2.2l1.3-0.6V27c0,0.3,0.2,0.5,0.5,0.5h25.3c0.3,0,0.5-0.2,0.5-0.5V8.5l1.4,0.6V6.9L15,0.6z M13.6,3.4
		L13.6,3.4L13.6,3.4L13.6,3.4z M9.3,26.5v-0.1h2.5v0.1H9.3z M18.1,26.5v-0.1h2.5v0.1H18.1z M27.2,26.5h-6.1v-0.1h0.1
		c0,0,0.7-4.9,0.7-5.6c0-0.6-1.3,0-1.5,0.7c-0.2,0.8,1.1,2.5-1.1,2.5c-2.3,0-1.9,2.3-1.9,2.3h0.1v0.1h-1.5c-0.3-0.4-0.2-0.1-0.8-0.3
		c-0.6-0.2-0.1-3.4,0.5-3.7c0.6-0.3,1.3-0.1,2-0.5c-0.3,0-5.4,0-5.7,0c0.7,0.5,1.4,0.2,2,0.5c0.6,0.3,1,3.4,0.5,3.7
		c-0.7,0.2-0.5-0.1-0.8,0.3h-1.4v-0.1h0.1c0,0,0.4-2.3-1.9-2.3c-2.2,0-0.9-1.7-1.1-2.5C9.3,20.8,8,20.2,8,20.8
		c0,0.6,0.7,5.6,0.7,5.6h0.1v0.1H2.8V8.4h11.9v6.2v1.2c-0.5,0.1-0.9,0.4-0.9,0.8v0.2h2.5v-0.2c0-0.4-0.4-0.7-0.9-0.8v-1.2V8.4h12
		V26.5z"
      />
    </svg>
  );
};
