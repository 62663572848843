import React from "react";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 22"
    >
      <polygon points="26.8,21.2 29.6,17 28.7,17 28.7,17 1.3,17 0.4,17 3.2,21.2 " />
      <path
        d="M15.9,4.2h-1.7C7.5,4.2,2,9.5,1.8,16.1h26.4C28,9.5,22.5,4.2,15.9,4.2z M12.4,8.4c-3,0-5.5,3.5-5.5,5.5
	c0,0.2-0.2,0.4-0.4,0.4C6.2,14.4,6,14.2,6,14c0-1.2,0.7-2.8,1.9-4.1c1.3-1.5,2.9-2.3,4.5-2.3c0.2,0,0.4,0.2,0.4,0.4
	C12.9,8.2,12.7,8.4,12.4,8.4z"
      />
      <path d="M15,3.3c0.7,0,1.3-0.6,1.3-1.3S15.7,0.8,15,0.8c-0.7,0-1.3,0.6-1.3,1.3S14.3,3.3,15,3.3z" />
    </svg>
  );
};
