import React from "react";

export default props => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 30"
    >
      <path
        d="M19.8,4.3V0.5h-10v3.9H1.5v2.1H4l1.8,23.1h18.2l1.8-23.1h2.8V4.3C28.5,4.3,19.8,4.3,19.8,4.3z M17.7,2.5v1.8h-5.9V2.5
		C11.9,2.5,17.7,2.5,17.7,2.5z M7.6,27.4L6,6.4h17.6l-1.6,21H7.6z"
      />
      <rect x="13.8" y="9.4" width="2.1" height="15.1" />
      <rect
        x="11.5"
        y="15.9"
        transform="matrix(5.421528e-02 -0.9985 0.9985 5.421528e-02 1.0771 35.0305)"
        width="15.2"
        height="2.1"
      />
      <rect
        x="9.5"
        y="9.4"
        transform="matrix(0.9985 -5.409198e-02 5.409198e-02 0.9985 -0.9012 0.5958)"
        width="2.1"
        height="15.2"
      />
    </svg>
  );
};
