import React from "react";

export default props => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 29 30"
    >
      <polygon points="15.4,20.8 18.4,20.5 19.5,19.4 16.9,16.8 15.9,17.9 		" />
      <polygon points="25.6,8.1 24,9.8 26.5,12.3 28.2,10.7 		" />
      <path
        d="M20.8,19.4l-1.9,1.9l-4.6,0.6l0.2-1H3.6V20h10.9l0.6-2.6l1.2-1.2l6.8-6.8V0.5H0.8v29.1h22.2V17.1L20.8,19.4L20.8,19.4z
			 M3.6,3.7h15v0.9h-15V3.7z M3.6,7.7h12.3v0.9H3.6V7.7z M3.6,11.8h15v0.9h-15V11.8z M3.6,15.9h10.9v0.9H3.6V15.9z M18.5,25h-15
			v-0.9h15V25z"
      />
      <polygon points="17.6,16.2 20.1,18.7 25.9,13 23.3,10.4 		" />
    </svg>
  );
};
