import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as stateActions from "../../../../redux/actions/state-actions";
import * as userActions from "../../../../redux/actions/user-actions";
import moment from "moment";

// Components
import BusinessLoader from "./business-loader";
import DetailViewLayout from "../../../components/detail-view-scroll-layout";
import VerifyUser from "./verify-user";
import WelcomeBanner from "./welcome-banner";
import SwitchButton from "../../../components/common/switch-button";
import Pagination from "../../../components/pagination";
import PostedAd from "./posted-ad";
import Button from "../../../components/common/button";
import BarChart from "../../../components/chart/horizontal-bar-chart";
import BarChartDynamic from "../../../components/chart/horizontal-bar-chart-dynamic";
import Yelp from "../../listing/yelp";
import Modal from "../../../components/modal";

// Utilities
import useGetBusinessStatus from "../../../../utilites/hooks/UseGetBusinessStatus";
import { charLimitTo, toTitleCase } from "../../../../utilites/format";
import { analyticsGet, communtiyAnalytics } from "../../../../api/analytics";
import { deleteListingData } from "../../../../utilites/listing";

// Styles
import "../profile.css";
import "./business.css";

const BusinessProfile = ({
  user,
  setLoader,
  setMessage,
  setAds,
  history,
  setImage,
  setPaymentModal,
  setCovidModal,
  updateUser,
}) => {
  const [isGrow, setIsGrow] = useState(true);
  const [savedPageNumber, setSavedPageNumber] = useState(1);
  const [analytics, setAnalytics] = useState(null);
  const [terms, setTerms] = useState(null);
  const [modalMessage, setModalMessage] = useState("");
  const [deleteAd, setDeleteAd] = useState(null);
  const {
    _id,
    isActive: busniessIsActive,
    verified,
    name,
    hours,
    ads = [],
    images,
    yelp,
    account,
    analyticsId,
    covid,
    location,
    locationType,
  } = user;
  const { subscriptionCalenderEndDate, accountType } = account || {};
  const { accountIsActive, uniqueListingIds } = useGetBusinessStatus();
  const formattedAccountType = toTitleCase({ input: accountType || "free" });
  const businessIsOpen = !!Object.values(hours).find(({ isOpen }) => isOpen);
  const sortedAds = ads.sort(({ views: a }, { views: b }) => b - a);
  const isUnlimited = formattedAccountType === "unlimited";
  const isFree = !accountType || accountType.toLowerCase() === "free";
  const { county } = location || {};
  const isCorporate = locationType === "Multiple Locations";

  const getAnalytics = async () => {
    const { analytics } = await analyticsGet(analyticsId);
    if (analytics) {
      const {
        seenTotals = {},
        viewTotals = {},
        promoViewsTotals = {},
      } = analytics;
      const Impressions = Object.values(seenTotals).reduce(
        (acc, cur) => acc + cur,
        0
      );
      const Views = Object.values(viewTotals).reduce(
        (acc, cur) => acc + cur,
        0
      );
      const Promos = Object.values(promoViewsTotals).reduce(
        (acc, cur) => acc + cur,
        0
      );
      setAnalytics([
        { name: "Totals", Impressions, Views, "Promos Clicked": Promos },
      ]);
    }
  };

  const getCommunity = async () => {
    const { analytics } = await communtiyAnalytics(county);

    const getAllTerms = (week) => {
      const { weeks } = analytics || {};
      if (!weeks && !weeks[week]) return [];
      const weekValues = weeks[week] || {};
      if (!weekValues) return [];
      const { terms: topTerms = {} } = weekValues.top || {};
      const { terms: foodTerms = {} } = weekValues.food || {};
      const { terms: drinksTerms = {} } = weekValues.drinks || {};
      const { terms: activitiesTerms = {} } = weekValues.activities || {};
      const { terms: eventsTerms = {} } = weekValues.events || {};
      return [topTerms, foodTerms, drinksTerms, activitiesTerms, eventsTerms];
    };

    const sortFeild = (field) =>
      Object.keys(field).sort(
        (fieldA, fieldB) => field[fieldB] - field[fieldA]
      );

    if (analytics) {
      const previousTerms = getAllTerms(moment().format("w"));
      const currentTerms = getAllTerms(
        moment().subtract(7, "days").format("w")
      );
      const allTerms = [...previousTerms, ...currentTerms];
      const combinedTerms = allTerms.reduce((acc, terms) => {
        const updatedTerms = { ...acc };
        for (const key in terms) {
          if (updatedTerms[key]) {
            updatedTerms[key] = updatedTerms[key] + terms[key];
          } else {
            updatedTerms[key] = terms[key];
          }
        }
        return updatedTerms;
      }, {});
      const sortedTerms = sortFeild(combinedTerms).slice(0, 5).reverse();
      const topTerms = sortedTerms.map((term) => {
        const termData = {
          term,
          value: combinedTerms[term],
        };
        if (combinedTerms[term])
          termData.value = termData.value + combinedTerms[term];
        return termData;
      });
      setTerms(topTerms);
    }
  };

  useEffect(() => {
    const { covidCompliant } = covid || {};
    const { covidModal: covidModalSeen } = window.localStorage || {};
    getAnalytics();
    if (!isFree) getCommunity();
    if (!covidCompliant && !covidModalSeen) setCovidModal(true);
  }, [user]);

  const handleConfirmAdDelete = (adData) => {
    const { title } = adData;
    const message = `Are you sure you want to delete your "${title}" listing?`;
    setDeleteAd(adData);
    setModalMessage(message);
  };

  const handleAdCancel = () => {
    setModalMessage("");
    setDeleteAd(null);
  };

  const handleAdDelete = async () => {
    setLoader(true);
    setModalMessage("");
    const [{ ads }] = await deleteListingData(deleteAd);
    setDeleteAd(null);
    if (ads && !(ads.length % 5)) {
      const newPageNumber = savedPageNumber - 1;
      setSavedPageNumber(newPageNumber > 0 ? newPageNumber : 1);
    }
    if (ads) setAds(ads);
    setLoader(false);
    setMessage({ message: "Successful" });
  };

  const handleSwitchClick = (type) => {
    setIsGrow(type === "Grow");
  };

  const renderAds = () => {
    const sliceStartPosition = savedPageNumber * 5 - 5;
    let sliceEndPosition = savedPageNumber * 5;
    if (sliceEndPosition > sortedAds.length)
      sliceEndPosition = sortedAds.length;
    const displayAds = sortedAds.slice(sliceStartPosition, sliceEndPosition);
    return displayAds.map((ad, idx) => (
      <PostedAd
        ad={ad}
        ads={ads}
        setAds={setAds}
        verified={verified}
        businessIsOpen={businessIsOpen}
        busniessIsActive={busniessIsActive}
        handleDelete={handleConfirmAdDelete}
        key={idx}
      />
    ));
  };

  return (
    <DetailViewLayout images={images} name={name} showImage={false}>
      <VerifyUser
        verified={verified}
        id={_id}
        setLoader={setLoader}
        setMessage={setMessage}
        updateUser={updateUser}
      />

      <div className="listing-content-container">
        <WelcomeBanner />
        <div className="business-title-container">
          <div className="business-line-break" />
          <p className="business-title-text">
            {charLimitTo(name.toUpperCase(), 22)}
          </p>
          <div className="business-line-break" />
        </div>
        {isCorporate && (
          <div
            className="profile-pagination-title"
            style={{ marginBottom: "5px" }}
          >
            <p>Locations:</p>
            <Button
              size="small"
              text="Manage"
              handleClick={() => history.push("/business/corporate")}
            />
          </div>
        )}

        <div className="profile-pagination-title">
          <p>
            Account Type:{" "}
            <span className="profile-account-type">{formattedAccountType}</span>
          </p>
          <Button
            size="small"
            text="Manage"
            handleClick={() => setPaymentModal(true)}
          />
        </div>
        {subscriptionCalenderEndDate && (
          <div
            className="profile-text-container profile-text-container-split"
            style={{ marginTop: "10px" }}
          >
            <p>{accountIsActive ? "Valid To: " : "Expired: "}</p>
            <p
              className={
                accountIsActive
                  ? "payment-status-active"
                  : "payment-status-expired"
              }
            >
              {moment(subscriptionCalenderEndDate).format("L")}
            </p>
          </div>
        )}
        {!isUnlimited &&
          Object.keys(uniqueListingIds).map((listing, idx) => {
            const listingDate = uniqueListingIds[listing];
            const futureDate = moment(listingDate).add(1, "M").format("L");
            const { title = "Ad was deleted" } =
              ads.find(({ _id }) => _id === listing) || {};
            return (
              <div
                className="profile-text-container profile-text-container-split"
                style={{ marginTop: "10px" }}
                key={idx}
              >
                <p className="profile-ad-unique-text">
                  {`Ad ${idx + 1}: ${charLimitTo(
                    toTitleCase({ input: title }),
                    19
                  )}`}
                </p>

                <p className="profile-ad-unique-text">
                  {`${moment(listingDate).format("L")} - ${futureDate}`}
                </p>
              </div>
            );
          })}
      </div>

      <div className="listing-content-container">
        <div className="business-selector-container">
          <SwitchButton
            button1Name="Grow"
            button2Name="Learn"
            isFirst={isGrow}
            handleClick={handleSwitchClick}
          />
        </div>
        {isGrow ? (
          <>
            <div className="profile-pagination-title">
              <p>Analytics</p>
              <Button
                size="small"
                text="See More"
                handleClick={() => history.push("/business/analytics")}
              />
            </div>
            <BarChart
              height="220px"
              primaryKey="Impressions"
              secondaryKey="Views"
              promoKey="Promos Clicked"
              data={analytics}
              layout="vertical"
              isToolTip={false}
            />
          </>
        ) : isFree ? (
          <div className="profile-category-upgarde-container">
            <p className="profile-category-upgarde-text">
              Upgrade now to get your local commuity staistics to find out what
              people are looking for!
            </p>
            <Button
              size="small"
              text="Upgrade"
              handleClick={() => setPaymentModal(true)}
            />
          </div>
        ) : (
          <>
            <div className="profile-pagination-title">
              <p>Top Searched Terms</p>
              <Button
                size="small"
                text="See Yours"
                handleClick={() => history.push("/business/community")}
              />
            </div>
            <BarChartDynamic data={terms} />
          </>
        )}
      </div>

      <Pagination
        page={savedPageNumber}
        setPage={setSavedPageNumber}
        noResultsText="No Posted Ads"
        list={sortedAds}
      >
        <>
          <div className="profile-pagination-title">
            <div className="profile-pagination-title">
              <p style={{ marginRight: "5px" }}>Total Ads:</p>
              {!!sortedAds.length && (
                <p className="profile-account-type">{sortedAds.length}</p>
              )}
            </div>
            <Button
              size="small"
              text="Post Ad"
              handleClick={() => history.push("/create-listing")}
            />
          </div>
          {renderAds()}
        </>
      </Pagination>

      <Yelp yelp={yelp} name={name} setImage={setImage} />

      <Modal
        directModalMessage={modalMessage}
        modalAccept="Delete"
        modalCancel="Cancel"
        handleCancelClick={handleAdCancel}
        handleAcceptClick={handleAdDelete}
      />
    </DetailViewLayout>
  );
};

const mapStateToProps = (store) => ({
  user: store.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  setLoader: (loaderState) => dispatch(stateActions.setLoader(loaderState)),
  setMessage: (dataObj) => dispatch(stateActions.setMessage(dataObj)),
  setAds: (adsArray) => dispatch(userActions.setAds(adsArray)),
  setImage: (value) => dispatch(stateActions.setImage(value)),
  setPaymentModal: (boolean) => dispatch(stateActions.setPaymentModal(boolean)),
  setCovidModal: (boolean) => dispatch(stateActions.setCovidModal(boolean)),
  updateUser: (userObj) => dispatch(userActions.updateUser(userObj)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BusinessLoader(BusinessProfile)));
