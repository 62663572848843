import { callAPI } from "./";
import { baseURL } from "./url";
import { formatQueryParams } from "../utilites/format";

const locationURL = baseURL("/location");
const AutoCompleteURL = baseURL("/autocomplete");
const SearchURL = baseURL("/search");

export function getCoordinates({ address = "", latlng }) {
  let url = `${locationURL}?location=${address.replace(/'|’/g, "")}`;
  if (latlng) url = `${locationURL}?latlng=${latlng}`;
  return callAPI(url, "GET");
}

export function getAutoComplete({ search, location }) {
  let url = `${AutoCompleteURL}?search=${search}`;
  if (location) url = `${AutoCompleteURL}?location=${location}`;
  return callAPI(url, "GET");
}

export function searchListing(options) {
  const url = `${SearchURL}/listings?${formatQueryParams(options)}`;
  return callAPI(url, "GET");
}

export function searchTopListings(options) {
  const url = `${SearchURL}/top/listings?${formatQueryParams(options)}`;
  return callAPI(url, "GET");
}

export function searchBusiness(options) {
  const url = `${SearchURL}/business?${formatQueryParams(options)}`;
  return callAPI(url, "GET");
}

export function searchTopBusiness(options) {
  const url = `${SearchURL}/top/business?${formatQueryParams(options)}`;
  return callAPI(url, "GET");
}

export function searchMapListing(options) {
  const url = `${SearchURL}/map?${formatQueryParams(options)}`;
  return callAPI(url, "GET");
}

export function searchTopMapListings(options) {
  const url = `${SearchURL}/top/map?${formatQueryParams(options)}`;
  return callAPI(url, "GET");
}

export function searchBusinessMap(options) {
  const url = `${SearchURL}/map/business?${formatQueryParams(options)}`;
  return callAPI(url, "GET");
}

export function searchTopBusinessMap(options) {
  const url = `${SearchURL}/top/map/business?${formatQueryParams(options)}`;
  return callAPI(url, "GET");
}
