import moment from "moment";

export function getPreviousDate(date) {
  const dateSplit = date
    ? moment(date).subtract(1, "days").format("L").split("/")
    : moment().subtract(1, "days").format("L").split("/");
  return `${dateSplit[2]}-${dateSplit[0]}-${dateSplit[1]}`;
}

export function getCurrentDate(date) {
  const dateSplit = date
    ? moment(date).format("L").split("/")
    : moment().format("L").split("/");
  return `${dateSplit[2]}-${dateSplit[0]}-${dateSplit[1]}`;
}

export function convertDateToDbDate(date) {
  const dateSplit =
    date && date.length ? date.split("-") : getCurrentDate().split("-");
  return Number(`${dateSplit[0].slice(-2)}${dateSplit[1]}${dateSplit[2]}`);
}

export function formatDbDateToReadable({
  date,
  toFullYear = false,
  isForUser = false,
}) {
  const dateSplit = String(date).split("");
  const month = dateSplit.slice(2, 4).join("");
  const day = dateSplit.slice(4, 6).join("");
  let year = dateSplit.slice(0, 2).join("");
  if (toFullYear) year = `20${year}`;
  return isForUser ? `${month}-${day}-${year}` : `${year}-${month}-${day}`;
}

export function getDateStatus({ date, hours }) {
  const momentDate = moment();
  if (date) {
    const adDate = formatDbDateToReadable({ date, toFullYear: true });
    const formatedDate = momentDate.format();
    return moment(formatedDate).isSame(adDate, "day");
  }
  if (hours) {
    const formatedDay = momentDate.format("dddd");
    return hours[formatedDay].isOpen;
  }
  return false;
}

export const isDayBefore = (date, days) => {
  const currentDate = moment();
  if (date) {
    const beforeDateSplit = currentDate
      .clone()
      .subtract(1, "days")
      .format("L")
      .split("/");
    const formattedBeforeDate = `${beforeDateSplit[2]}-${beforeDateSplit[0]}-${beforeDateSplit[1]}`;
    const beforeDbDate = convertDateToDbDate(formattedBeforeDate);
    return date === beforeDbDate;
  }
  const beforeDay = currentDate.clone().subtract(1, "days").format("dddd");
  return days[beforeDay];
};

export function plus7Days(date) {
  const futureDate = moment(date || new Date())
    .add(7, "days")
    .format("L")
    .split("/");

  return `${futureDate[2]}-${futureDate[0]}-${futureDate[1]}`;
}
