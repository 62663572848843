import React from "react";

export default props => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 26 30"
    >
      <g transform="translate(0,-952.36218)">
        <path
          d="M1.5,952.9c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3,0.1-0.5,0.2c-0.3,0.2-0.5,0.6-0.5,1.1v9.8v6.8v9.8c0,0.9,0.5,1.3,1.2,1.3
		c0.1,0,0.2,0,0.3-0.1l8.6-2.1c0.9-0.2,1.8-1.3,1.8-2.3v-3.5v-3.8H8.1c-0.8,0-1.4-0.6-1.4-1.4v-2.6c0-0.8,0.6-1.4,1.4-1.4h4.1v-3.8
		v-3.5c0-1-0.8-2-1.8-2.3L1.9,953C1.8,952.9,1.6,952.9,1.5,952.9z M18.2,959.9c-0.1,0-0.3,0.1-0.4,0.2l-1.9,1.9
		c-0.2,0.2-0.2,0.6,0,0.8l2.1,2.1c0.2,0.2,0.2,0.4,0,0.6c-0.1,0.1-0.2,0.1-0.3,0.1H8.1c-0.3,0-0.6,0.2-0.6,0.6v2.6
		c0,0.3,0.2,0.6,0.6,0.6h9.6c0.2,0,0.4,0.2,0.4,0.4c0,0.1,0,0.2-0.1,0.3l-2.1,2.1c-0.2,0.2-0.2,0.6,0,0.8l1.9,1.9
		c0.2,0.2,0.5,0.2,0.8,0l5-5l1.9-1.9c0.2-0.2,0.2-0.6,0-0.8l-1.9-1.9l-5.1-5.1C18.5,959.9,18.3,959.9,18.2,959.9
		C18.2,959.9,18.2,959.9,18.2,959.9L18.2,959.9z"
        />
      </g>
    </svg>
  );
};
