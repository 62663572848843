import React from "react";

// Styles
import "./svg.css";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 30"
    >
      <path
        className="svg-clip-rule"
        d="M23.3,19.5c1.7,0,3,1.4,3,3s-1.4,3-3,3s-3-1.4-3-3S21.7,19.5,23.3,19.5z M7,10.7h1.3v1.9H7V10.7z M3.7,11.8
		h2.5v0.8H3.7C3.7,12.6,3.7,11.8,3.7,11.8z M2.4,3.5L1.8,7.1c0,2.1,2.8,1.9,2.8,0.1c0,1.9,2.8,1.9,2.8,0c0,1.9,2.8,1.9,2.8,0
		c0,1.9,2.8,1.9,2.8,0C13.1,9,16,9,16,7.2c0,1.8,2.8,2,2.8-0.1l-0.7-3.6C12.9,3.5,7.7,3.5,2.4,3.5L2.4,3.5z M3,14.3h10.9v0.8H3
		C3,15.1,3,14.3,3,14.3z M17.4,14.3H15v0.8h2.4V14.3z M22.4,4.1v9h4.5V11c0-2.3-0.7-4.8-2-6.9L22.4,4.1L22.4,4.1z M17.4,13.1v-4
		c1.1,0,2.2-1.2,1.9-2.2l-0.7-3.5c-0.1-0.3-0.3-0.5-0.6-0.5H2.4c-0.3,0-0.5,0.2-0.6,0.5L1.2,6.9C1.1,8.2,2,9.2,3.2,9.1v4H17.4
		L17.4,13.1z M3.8,22.7L3.8,22.7l0-0.2c0-2.1,1.7-3.8,3.8-3.8c1.1,0,2.1,0.5,2.8,1.2h10.2c0.7-0.8,1.7-1.2,2.8-1.2
		c2.1,0,3.7,1.6,3.8,3.7c1.2-0.5,2.4-1.7,2.6-2.9h-1.9c-0.3,0-0.6-0.3-0.6-0.6v-4.2c0-0.3,0.3-0.6,0.6-0.6h2V11
		c0-4.6-2.8-10.6-8-10.6H3.3c-1.6,0-3.1,1.3-3.1,2.9v15.6C0.3,21.1,1.6,22.7,3.8,22.7L3.8,22.7z M11,20.9c0.2,0.5,0.4,1.1,0.4,1.7
		l0,0.2h8.2l0-0.2c0-0.6,0.1-1.2,0.4-1.7H11L11,20.9z M29.7,18.3v-3h-1.4v3H29.7z M7.6,19.5c1.7,0,3,1.4,3,3c0,1.7-1.4,3-3,3
		c-1.7,0-3-1.4-3-3C4.6,20.9,5.9,19.5,7.6,19.5z"
      />
    </svg>
  );
};
