import React from "react";

export default props => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 27"
    >
      <path
        d="M7.8,21.1c-0.1-0.3-0.4-0.5-0.7-0.5H3.4l-1.3-3.8c-0.1-0.4-0.5-0.6-0.9-0.4c-0.4,0.1-0.6,0.5-0.5,0.9l1.4,4.1l-1.4,4.1
		c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5l1.3-3.8h3.3l1.3,3.8c0.1,0.4,0.5,0.6,0.9,0.4c0.4-0.1,0.6-0.5,0.5-0.9L7.8,21.1
		z"
      />
      <path
        d="M20,16.4h-4.3V9.2h11.4c0.4,0,0.7-0.3,0.7-0.7c0,0,0,0,0,0V7.1c0-0.3-0.2-0.5-0.4-0.6L15.3,0.7c-0.2-0.1-0.4-0.1-0.6,0
		L2.6,6.4C2.3,6.5,2.1,6.8,2.1,7.1v1.4c0,0.4,0.3,0.7,0.7,0.7c0,0,0,0,0,0h11.4v7.1H10c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7h4.3
		v7.1h-0.7c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h2.9c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7h-0.7v-7.1H20
		c0.4,0,0.7-0.3,0.7-0.7S20.4,16.4,20,16.4z"
      />
      <path
        d="M27.9,21.4l1.4-4.1c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5l-1.3,3.8h-3.8c-0.3,0-0.6,0.2-0.7,0.5l-1.4,4.3
		c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5l1.3-3.8h3.3l1.3,3.8c0.1,0.4,0.5,0.6,0.9,0.4c0.4-0.1,0.6-0.5,0.5-0.9
		L27.9,21.4z"
      />
    </svg>
  );
};
