import React from "react";
import { connect } from "react-redux";
import * as dataActions from "../../../redux/actions/data-actions";
import * as stateActions from "../../../redux/actions/state-actions";

// Components
import Button from "../../components/common/button";

// Styles
import "./admin.css";

const Admin = ({
  employee,
  isLiveSearch,
  setIsLiveSearch,
  history,
  setAgentInput,
  setResults,
}) => {
  const { accountType } = employee;
  const isTelemarketing =
    accountType === "telemarketing" ||
    accountType === "flex" ||
    accountType === "review" ||
    accountType === "admin" ||
    accountType === "developer";
  const isDataEntry =
    accountType === "telemarketing" ||
    accountType === "data entry" ||
    accountType === "flex" ||
    accountType === "review" ||
    accountType === "admin" ||
    accountType === "developer";
  const isReview =
    accountType === "review" ||
    accountType === "admin" ||
    accountType === "developer";
  const isAdmin = accountType === "admin" || accountType === "developer";

  return (
    <div className="growth-wrapper">
      {isReview && (
        <Button
          size="large"
          text="Search Live"
          isPrimary={!isLiveSearch}
          handleClick={() => {
            setIsLiveSearch(true);
            setAgentInput();
            setResults();
            history.push("/top");
          }}
        />
      )}
      {isReview && (
        <Button
          size="large"
          text="Search Growth"
          isPrimary={isLiveSearch}
          handleClick={() => {
            setIsLiveSearch(false); // Hack to switch to growth search
            setAgentInput();
            setResults();
            history.push("/top");
          }}
        />
      )}
      {isDataEntry && (
        <Button
          size="large"
          text="Create Growth Ad"
          handleClick={() => history.push("/create")}
        />
      )}
      {isReview && (
        <Button
          size="large"
          text="Review Finds"
          handleClick={() => history.push("/review-finds")}
        />
      )}
      {isReview && (
        <Button
          size="large"
          text="Review Posts"
          handleClick={() => history.push("/reviews")}
        />
      )}
      {isTelemarketing && (
        <Button
          size="large"
          text="Telemarketing"
          handleClick={() => history.push("/telemarketing")}
        />
      )}
      {isDataEntry && (
        <Button
          size="large"
          text="Data Entry"
          handleClick={() => history.push("/data-entry")}
        />
      )}
      {isAdmin && (
        <Button
          size="large"
          text="Giveaway"
          handleClick={() => history.push("/giveaway")}
        />
      )}
      <Button
        size="large"
        text="Business Stats"
        handleClick={() => history.push("/statistics")}
      />
      {isAdmin && (
        <Button
          size="large"
          text="management"
          handleClick={() => history.push("/employees")}
        />
      )}
    </div>
  );
};

const mapStateToProps = (store) => ({
  employee: store.user.employee,
  isLiveSearch: store.state.isLiveSearch,
});

const mapDispatchToProps = (dispatch) => ({
  setLoader: (loaderState) => dispatch(stateActions.setLoader(loaderState)),
  setMessage: (dataObj) => dispatch(stateActions.setMessage(dataObj)),
  setIsLiveSearch: (value) => dispatch(stateActions.setIsLiveSearch(value)),
  setAgentInput: () => dispatch(stateActions.setAgentInput("")),
  setResults: () =>
    dispatch(
      dataActions.setResults({
        results: [],
        pathname: "",
      })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
