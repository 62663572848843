import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";

// Assets
import Settings from "../../../assets/svg/settings";
import EventHoundName from "../../../assets/svg/eventHound-name";

// Styles
import "./side-menu.css";
import "../header/header.css";

const SideMenu = ({ children }) => {
  const [menu, setMenu] = useState(false);
  return (
    <>
      <div
        className="header-profile-svg-container-MobileHeader"
        onClick={() => setMenu(!menu)}
      >
        <Settings />
      </div>
      <CSSTransition in={menu} timeout={250} classNames="fade">
        <div
          className="menu-page-click-overlay"
          onClick={() => setMenu(!menu)}
        ></div>
      </CSSTransition>
      <CSSTransition in={menu} appear={true} timeout={200} classNames="slide">
        <div className="sidebar-menu-wrapper flex-center-row">
          <div className="sidebar-title">
            <img
              className="pointer title-bascity-logo"
              src="/img/icons/event-hound-icon.png"
              alt="EventHound - Event Finder"
            />
            <EventHoundName />
          </div>
          <div className="sidebar-menu-item-wrapper">{children}</div>
        </div>
      </CSSTransition>
    </>
  );
};

export default SideMenu;
