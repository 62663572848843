import { callAPI } from ".";
import { baseURL } from "./url";
import { formatQueryParams } from "../utilites/format";

const employeeURL = baseURL("/employee");

export function getEmployees(id) {
  const url = id ? `${employeeURL}?id=${id}` : employeeURL;
  return callAPI(url, "GET");
}

export function sendEmployeeInvite(options) {
  const url = `${employeeURL}/request?${formatQueryParams(options)}`;
  return callAPI(url, "GET");
}

export function createNewEmployee(employee) {
  return callAPI(employeeURL, "PUT", employee);
}

export function employeeLogin(loginData) {
  return callAPI(`${employeeURL}/login`, "POST", loginData);
}

export function setEmployeeAnalytics(data) {
  return callAPI(`${employeeURL}/analytics`, "POST", data);
}

export function employeeAnalyticsGet(id) {
  return callAPI(`${employeeURL}/analytics?id=${id}`, "GET");
}
