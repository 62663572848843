import React from "react";

export default props => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 30 "
    >
      <path
        d="M12.9,28.2c-0.3,0.9-1.3,1.3-2.1,1.1L10,29c-0.9-0.3-1.4-1.2-1.1-2.1L9,26.6c0.2-0.7-0.1-1.7-0.7-2.1
			l-0.2-0.1c-0.6-0.5-1.6-0.5-2.3,0l-0.3,0.2c-0.8,0.5-1.8,0.3-2.3-0.4l-0.5-0.7c-0.5-0.7-0.4-1.8,0.4-2.3l0.3-0.2
			C4,20.4,4.3,19.4,4,18.7L4,18.5c-0.2-0.7-1.1-1.3-1.8-1.3l-0.3,0c-0.9,0-1.7-0.8-1.7-1.7v-0.9c0-0.9,0.8-1.7,1.7-1.7l0.3,0
			c0.8,0,1.6-0.6,1.8-1.3L4,11.3C4.3,10.6,4,9.6,3.3,9.2L3.1,9C2.3,8.4,2.2,7.4,2.7,6.6l0.5-0.7C3.8,5.2,4.8,5,5.6,5.5l0.3,0.2
			c0.6,0.4,1.6,0.4,2.3,0l0.2-0.1C8.9,5.1,9.2,4.2,9,3.4L8.9,3.1C8.6,2.2,9.1,1.3,10,1l0.8-0.3c0.9-0.3,1.8,0.2,2.1,1.1l0.1,0.3
			c0.2,0.7,1.1,1.3,1.8,1.3h0.2c0.8,0,1.6-0.6,1.8-1.3l0.1-0.3c0.3-0.9,1.3-1.3,2.1-1.1L20,1c0.9,0.3,1.4,1.2,1.1,2.1L21,3.4
			c-0.2,0.7,0.1,1.7,0.7,2.1l0.2,0.1c0.6,0.5,1.6,0.5,2.3,0l0.3-0.2c0.8-0.5,1.8-0.3,2.3,0.4l0.5,0.7c0.5,0.7,0.4,1.8-0.4,2.3
			l-0.3,0.2c-0.6,0.5-0.9,1.4-0.7,2.2l0.1,0.2c0.2,0.7,1.1,1.3,1.8,1.3l0.3,0c0.9,0,1.7,0.8,1.7,1.7v0.9c0,0.9-0.8,1.7-1.7,1.7
			l-0.3,0c-0.8,0-1.6,0.6-1.8,1.3L26,18.7c-0.2,0.7,0.1,1.7,0.7,2.2l0.3,0.2c0.7,0.6,0.9,1.6,0.4,2.3l-0.5,0.7
			c-0.5,0.7-1.6,0.9-2.3,0.4l-0.3-0.2c-0.6-0.4-1.6-0.4-2.3,0l-0.2,0.1c-0.6,0.5-0.9,1.4-0.7,2.1l0.1,0.3c0.3,0.9-0.2,1.8-1.1,2.1
			l-0.8,0.3c-0.9,0.3-1.8-0.2-2.1-1.1l-0.1-0.3c-0.2-0.7-1.1-1.3-1.8-1.3h-0.2c-0.8,0-1.6,0.6-1.8,1.3L12.9,28.2z M15,22.2
			c4,0,7.2-3.2,7.2-7.2S19,7.8,15,7.8S7.8,11,7.8,15S11,22.2,15,22.2z"
      />
    </svg>
  );
};
