import React, { useEffect, useState } from "react";

// Components
import Spacer from "../../components/common/spacer";

// Assets
import Arrow from "../../../assets/svg/arrow";

// Utilities
import { isMobileAgent, isBottomSpacing } from "../../../utilites";
import { isLargeImage } from "../../../utilites/image";

//Styles
import "./detail-scroll-view.css";

export default ({
  isTicketMaster,
  images = [],
  heightFromTop = 70,
  name = "EventHound - Event Finder",
  showImage = true,
  status,
  children,
}) => {
  const [isMounted, setIsMounted] = useState(false);
  const [imageNumber, setImageNumber] = useState(0);
  const verifiedImages = images.filter((img) => img);
  const isImages = !!verifiedImages.length;
  const isMobile = isMobileAgent();
  const isRightArrow = imageNumber < verifiedImages.length - 1;

  useEffect(() => {
    if (isMounted) {
      const imageWrapper = document.getElementById("listingImageWrapper");
      const value = imageNumber * imageWrapper.offsetWidth;
      imageWrapper.style.transform = `translateX(-${value}px)`;
    } else {
      setIsMounted(true);
    }
  }, [imageNumber]);

  const handleArrowClick = (evt, direction) => {
    evt.stopPropagation();
    switch (direction) {
      case "left":
        if (imageNumber) setImageNumber(imageNumber - 1);
        break;
      case "right":
        if (isRightArrow) setImageNumber(imageNumber + 1);
        break;
    }
  };

  const renderImageDots = () => {
    const dots = [];
    for (let i = 0; i < verifiedImages.length; i++) {
      const isActive = i === imageNumber;
      const dotStatus = isActive ? "listing-image-dot-selected" : "";
      dots.push(
        <div
          className={`listing-image-dot listing-image-dot-${status} ${dotStatus}`}
          key={i}
        />
      );
    }
    return dots;
  };

  return (
    <div className="detail-view-outer-wrapper">
      <div className="listing-page-background-image-container">
        <img
          className="listing-page-background-image"
          alt="EventHound Concert"
          src={
            isImages
              ? typeof verifiedImages[0] === "string"
                ? verifiedImages[0]
                : URL.createObjectURL(verifiedImages[0])
              : "/img/food.jpg"
          }
        />
      </div>
      <div className="listing-background-gradient-overlay" />
      <div className="no-scroll-bars detail-view-inner-wrapper">
        <div className="listing-content-wrapper">
          <Spacer height={heightFromTop} />
          {showImage && (
            <div className="listing-content-images-container">
              <div id="listingImageWrapper" className="listing-images-Wrapper">
                {isImages ? (
                  verifiedImages.map((image) => (
                    <img
                      className="listing-image"
                      src={
                        typeof image === "string"
                          ? `${image}${isLargeImage(isTicketMaster)}`
                          : URL.createObjectURL(image)
                      }
                      alt={name}
                      key={image}
                    />
                  ))
                ) : (
                  <img className="listing-image" src="/img/food.jpg" />
                )}
              </div>
              {verifiedImages.length > 1 && (
                <>
                  <div className="listing-image-arrow-wrapper">
                    {!!imageNumber && (
                      <div
                        className="listing-image-arrow-container listing-image-arrow-left"
                        style={{
                          width: isMobile ? "27px" : "35px",
                        }}
                        onClick={(evt) => handleArrowClick(evt, "left")}
                      >
                        <Arrow />
                      </div>
                    )}
                    {isRightArrow && (
                      <div
                        className="listing-image-arrow-container"
                        style={{
                          width: isMobile ? "27px" : "35px",
                        }}
                        onClick={(evt) => handleArrowClick(evt, "right")}
                      >
                        <Arrow />
                      </div>
                    )}
                  </div>
                  <div className="listing-image-dots-container">
                    {renderImageDots()}
                  </div>
                </>
              )}
            </div>
          )}

          {children}
          <Spacer height={isBottomSpacing()} />
        </div>
      </div>
    </div>
  );
};
