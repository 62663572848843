import React from "react";

export default props => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 30"
    >
      <path
        d="M16.3,4.6H9.8c-0.7,0-1.3,0.6-1.3,1.3v0.4L4.3,8.1C4.1,8.1,4,8.4,4.1,8.6C4.2,8.9,4.5,9,4.7,8.9l3.8-1.6v2l-3.1,1.3
			c-0.2,0.1-0.4,0.4-0.2,0.6c0.1,0.2,0.3,0.4,0.6,0.2l2.8-1.2v2L6.4,13c-0.2,0.1-0.4,0.4-0.2,0.6c0.1,0.2,0.4,0.4,0.6,0.2l1.8-0.7v2
			l-1.1,0.4c-0.2,0.1-0.4,0.4-0.2,0.6c0.1,0.3,0.4,0.3,0.6,0.2L8.5,16c0,10.6,0,9.4,0,9.6c0,0-0.1,0-0.1,0c0,0-0.1,0-0.2,0
			c-0.1,0-0.2-0.1-0.2-0.2L0.3,6.9c-0.1-0.2-0.1-0.4,0-0.7s0.3-0.4,0.5-0.5l12.8-5.3c0.4-0.2,1,0,1.2,0.5L16.3,4.6z"
      />
      <polygon points="29.5,9.5 25.6,5.7 25.6,9.5 	" />
      <path
        d="M24.8,9.9V5.4H9.8c-0.2,0-0.4,0.2-0.4,0.4v23.3c0,0.2,0.2,0.4,0.4,0.4h19.4c0.3,0,0.4-0.2,0.4-0.4V10.4h-4.5
		C24.9,10.4,24.8,10.2,24.8,9.9z M18.1,8.2h4.5c0.3,0,0.4,0.2,0.4,0.4s-0.2,0.4-0.4,0.4h-4.5c-0.3,0-0.4-0.2-0.4-0.4
		S17.9,8.2,18.1,8.2z M12.5,8.2h3.5c0.3,0,0.4,0.2,0.4,0.4s-0.2,0.4-0.4,0.4h-3.5c-0.3,0-0.4-0.2-0.4-0.4S12.2,8.2,12.5,8.2z
		 M12.5,11.8h8.1c0.3,0,0.4,0.2,0.4,0.4c0,0.3-0.2,0.4-0.4,0.4h-8.1c-0.3,0-0.4-0.2-0.4-0.4C12,12,12.2,11.8,12.5,11.8z M12.5,15.3
		h3.7c0.3,0,0.4,0.2,0.4,0.4s-0.2,0.4-0.4,0.4h-3.7c-0.3,0-0.4-0.2-0.4-0.4S12.2,15.3,12.5,15.3z M12.5,18.8h8.8
		c0.3,0,0.4,0.2,0.4,0.4c0,0.3-0.2,0.4-0.4,0.4h-8.8c-0.3,0-0.4-0.2-0.4-0.4C12,19,12.2,18.8,12.5,18.8z M12.5,22.4h3.9
		c0.3,0,0.4,0.2,0.4,0.4c0,0.3-0.2,0.4-0.4,0.4h-3.9c-0.3,0-0.4-0.2-0.4-0.4C12,22.5,12.2,22.4,12.5,22.4z M21.1,26.8h-8.7
		c-0.3,0-0.4-0.2-0.4-0.4c0-0.3,0.2-0.4,0.4-0.4h8.7c0.3,0,0.4,0.2,0.4,0.4C21.6,26.6,21.3,26.8,21.1,26.8z M26.6,26.8h-3.3
		c-0.3,0-0.4-0.2-0.4-0.4c0-0.3,0.2-0.4,0.4-0.4h3.3c0.3,0,0.4,0.2,0.4,0.4C27.1,26.6,26.9,26.8,26.6,26.8z M26.6,23.3h-8.2
		c-0.3,0-0.4-0.2-0.4-0.4c0-0.3,0.2-0.4,0.4-0.4h8.2c0.3,0,0.4,0.2,0.4,0.4C27.1,23.1,26.9,23.3,26.6,23.3z M26.6,19.7h-2.7
		c-0.3,0-0.4-0.2-0.4-0.4c0-0.3,0.2-0.4,0.4-0.4h2.7c0.3,0,0.4,0.2,0.4,0.4C27.1,19.5,26.9,19.7,26.6,19.7z M26.6,16.2h-7.8
		c-0.3,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4h7.8c0.3,0,0.4,0.2,0.4,0.4S26.9,16.2,26.6,16.2z M27.1,12.2c0,0.3-0.2,0.4-0.4,0.4h-3.3
		c-0.3,0-0.4-0.2-0.4-0.4c0-0.3,0.2-0.4,0.4-0.4h3.3C26.9,11.8,27.1,12,27.1,12.2z"
      />
    </svg>
  );
};
