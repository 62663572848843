import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./redux/store";
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";

// Components
import App from "./app/app-router/App";

// Universal styles
import "./styles.css";
import "./styles-transitions.css";

const WrappedApp = () => (
  <Provider store={store}>
    <App />
  </Provider>
);

Amplify.configure(awsconfig);
ReactDOM.render(<WrappedApp />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
