import React from "react";

// Styles
import "./svg.css";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 24 30"
    >
      <path
        d="M12,0.3C5.5,0.3,0.3,5.5,0.3,12S12,29.7,12,29.7S23.7,18.5,23.7,12S18.5,0.3,12,0.3z M17,17v-3.6l-2.9-0.5v5.9
      c-0.2,0.1-0.5,0.1-0.7,0.2v-7.7l-0.5,0.2V10h-0.5v1.6l-1.9,0.6v6.7c-0.3-0.1-0.5-0.1-0.7-0.2V14H8.8v-0.6h-2V14v0.4v2.4
      c-1.1-1.3-1.8-2.9-1.8-4.7c0-3.9,3.2-7.1,7.1-7.1s7.1,3.2,7.1,7.1C19.1,14,18.3,15.7,17,17z"
      />
    </svg>
  );
};
