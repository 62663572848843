import React from "react";

// Assets
import Map from "../../../assets/svg/map";
import Results from "../../../assets/svg/results";
import Search from "../../../assets/svg/search";
import Location from "../../../assets/svg/location";
import LocationStroke from "../../../assets/svg/location-stroke";
import Close from "../../../assets/svg/close";
import Refresh from "../../../assets/svg/refresh";
import Calender from "../../../assets/svg/calender-filled";
import Time from "../../../assets/svg/time";
import Category from "../../../assets/svg/category";
import Features from "../../../assets/svg/features";
import Distance from "../../../assets/svg/distance";
import Sort from "../../../assets/svg/sort";
import Limit from "../../../assets/svg/limit";

export default function GetFilterIcon(name, filled = false) {
  if (!filled) {
    switch (name) {
      case "map":
        return <Map />;
      case "results":
        return <Results />;
      case "search":
        return <Search />;
      case "location":
        return <LocationStroke />;
      case "refresh":
        return <Refresh />;
      case "subCat":
        return <Category />;
      case "features":
        return <Features />;
      case "date":
        return <Calender />;
      case "time":
        return <Time />;
      case "distance":
        return <Distance />;
      case "sortBy":
        return <Sort />;
      case "close":
        return <Close />;
      case "limit":
        return <Limit />;
    }
  } else {
    switch (name) {
      case "location":
        return <Location />;
    }
  }
}
