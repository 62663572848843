import React from "react";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 30"
    >
      <path
        d="M27.4,3.1v1.4c0,0.5-0.4,0.9-1,0.9h-0.9c0.5,0,0.9-0.4,0.9-0.9V1.7c0-0.5-0.4-1-0.9-1c-0.5,0-1,0.4-1,1v1.4h-2.4v1.4
	c0,0.5-0.4,0.9-1,0.9h-0.9c0.5,0,0.9-0.4,0.9-0.9V1.7c0-0.5-0.4-1-0.9-1c-0.5,0-1,0.4-1,1v1.4h-2.4v1.4c0,0.5-0.4,0.9-0.9,0.9H15
	c0.5,0,0.9-0.4,0.9-0.9V1.7c0-0.5-0.4-1-0.9-1s-0.9,0.4-0.9,1v1.4h-2.4v1.4c0,0.5-0.4,0.9-0.9,0.9h-1c0.5,0,1-0.4,1-0.9V1.7
	c0-0.5-0.4-1-1-1c-0.5,0-0.9,0.4-0.9,1v1.4H6.4v1.4c0,0.5-0.4,0.9-0.9,0.9H4.6c0.5,0,0.9-0.4,0.9-0.9V1.7c0-0.5-0.4-1-0.9-1
	s-1,0.4-1,1v1.4H0.8v5.2h28.5V3.1H27.4z"
      />
      <path
        d="M0.8,27.4c0,1,0.9,1.9,1.9,1.9h24.7c1,0,1.9-0.9,1.9-1.9v-18H0.8V27.4z M23.1,11.2h3.8V15h-3.8V11.2z M23.1,17.4h3.8v3.8
	h-3.8V17.4z M23.1,23.5h3.8v3.8h-3.8V23.5z M16.4,11.2h3.8V15h-3.8V11.2z M16.4,17.4h3.8v3.8h-3.8V17.4z M16.4,23.5h3.8v3.8h-3.8
	V23.5z M9.8,11.2h3.8V15H9.8V11.2z M9.8,17.4h3.8v3.8H9.8V17.4z M9.8,23.5h3.8v3.8H9.8V23.5z M3.1,11.2h3.8V15H3.1V11.2z M3.1,17.4
	h3.8v3.8H3.1V17.4z M3.1,23.5h3.8v3.8H3.1V23.5z"
      />
    </svg>
  );
};
