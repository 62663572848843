import React from "react";

export default props => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 30"
    >
      <path
        d="M0.8,14.9c-0.6-0.3-0.6-1.2,0.1-1.5L28.4,0.9c0.8-0.3,1.5,0.5,1.1,1.2L13.2,28.8c-0.4,0.6-1.4,0.5-1.6-0.2l-2.5-9.1
	L0.8,14.9z"
      />
    </svg>
  );
};
