import React from "react";

export default props => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 21"
    >
      <path
        d="M29.2,0.7H0.8c-0.2,0-0.4,0.2-0.4,0.4v16.9c0,0.2,0.2,0.4,0.4,0.4h5.4v0.8c0,0.6,0.4,1,0.9,1h15.8c0.5,0,0.9-0.5,0.9-1v-0.8
	h5.4c0.2,0,0.4-0.2,0.4-0.4V1.1C29.5,0.9,29.4,0.7,29.2,0.7z M23,19.3c0,0.1-0.1,0.2-0.1,0.2H7.1c0,0-0.1-0.1-0.1-0.2v-0.8h16V19.3z
	 M28.8,17.7h-5.4H6.6H1.2V1.5h27.6V17.7z"
      />
      <path
        d="M12.7,7.3c-1.3,1.3-2.2,2.9-2.5,4.5l2.5,2.5c1.6-0.4,3.2-1.2,4.5-2.5c1.3-1.3,2.2-2.9,2.5-4.5l-2.5-2.5
	C15.6,5.2,14.1,6,12.7,7.3z M17.2,8.8l0.3,0.3L17,9.6l-0.3-0.3l-0.5-0.5l-0.8,0.8l0.5,0.5l0.3,0.3l-0.5,0.5l-0.3-0.3L15,10.1
	l-0.8,0.8l0.5,0.5l0.3,0.3l-0.5,0.5l-0.3-0.3l-0.5-0.5l-0.5,0.5L13,12.1l-0.5-0.5l0.3-0.3l0.5-0.5l-0.5-0.5l-0.3-0.3L13,9.6l0.3,0.3
	l0.5,0.5l0.8-0.8L14,9.1l-0.3-0.3l0.5-0.5l0.3,0.3L15,9.1l0.8-0.8l-0.5-0.5L15,7.6l0.5-0.5l0.3,0.3l0.5,0.5l0.5-0.5L17,7.1l0.5,0.5
	l-0.3,0.3l-0.5,0.5L17.2,8.8z"
      />
      <path d="M10.1,12.7c-0.1,0.6-0.1,1.2,0,1.8c0.6,0.1,1.2,0.1,1.8,0h0L10.1,12.7z" />
      <path d="M18.1,4.7l1.8,1.8c0.1-0.6,0.1-1.2,0-1.8C19.3,4.6,18.7,4.6,18.1,4.7z" />
      <path
        d="M28.2,1.9H1.8C1.7,1.9,1.6,2,1.6,2.1v15.1c0,0.1,0.1,0.2,0.2,0.2h26.4c0.1,0,0.2-0.1,0.2-0.2V2.1C28.4,2,28.3,1.9,28.2,1.9z
	 M17.7,12.3c-2.3,2.3-5.3,3.3-8.1,2.9c-0.2,0-0.3-0.1-0.3-0.3C9,12.2,10,9.1,12.2,6.8c2.3-2.3,5.3-3.3,8.1-2.8
	c0.2,0,0.3,0.1,0.3,0.3C21,7,20,10,17.7,12.3z"
      />
    </svg>
  );
};
