import React from "react";

// Components
import Button from "../common/button";

// Assets
import Camera from "../../../assets/svg/camera";
import QR from "../../../assets/svg/qr";
import Close from "../../../assets/svg/close";

// Styles
import "./image-upload.css";

export default ({
  text = "Add Image",
  type = "imageInput",
  imageInput = "",
  handleChange,
  isPromoCode = false,
}) => {
  const imageUrl =
    typeof imageInput === "string"
      ? imageInput
      : URL.createObjectURL(imageInput);

  const handleImageChange = (evt) => {
    const { files } = evt.target || [];
    if (files.length) {
      handleChange(evt, type, files[0]);
    } else {
      handleChange(evt, type, "");
    }
  };

  const handleClose = async () => {
    handleChange({ target: { value: "" } }, type, "");
  };

  const clickImageElement = (evt) => {
    evt.preventDefault();
    document.getElementById(type).click();
  };

  return (
    <>
      <Button text={text} size="medium" handleClick={clickImageElement}>
        {isPromoCode ? <QR /> : <Camera />}
      </Button>
      <input
        id={type}
        type="file"
        name="photo"
        accept="image/png, image/jpeg, image/jpg"
        hidden="hidden"
        onChange={handleImageChange}
      />
      <div className="image-preview-create-listing">
        {imageInput && (
          <>
            <div
              className="image-preview-close-container"
              onClick={handleClose}
            >
              <Close />
            </div>
            <img
              className="image-preview"
              src={imageUrl}
              height="100%"
              width="100%"
            />
          </>
        )}
      </div>
    </>
  );
};
