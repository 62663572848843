import React from "react";
import { withRouter } from "react-router-dom";
import LazyLoad from "react-lazyload";

// Utilities
import { isMobileAgent } from "../../../../utilites";
import { charLimitTo, toTitleCase } from "../../../../utilites/format";
import { getAdStatus } from "../../../../utilites/status";
import getFeaturesIcon from "../../../components/features-input/getFeatureIcon";
import { formatDbDateToReadable } from "../../../../utilites/date";

// Assets
import Skyline from "../../../../assets/svg/skyline";
import Trash from "../../../../assets/svg/trash";

// Styles
import "./result.css";

const EventCard = ({
  pathName,
  result,
  filterDate,
  history,
  setPagePosition,
  setModalDeleteMessage,
  setDeleteId,
}) => {
  const {
    _id,
    isGrowth = false,
    categories,
    title,
    businessName,
    features,
    authorName,
    timeStamp,
    lastUpdated,
    type,
  } = result || {};
  const { cat1Img, cat2, cat2Img } = categories;
  const { status, timeText, displayTime } = getAdStatus(result, filterDate);
  const isFeatures = features && Object.values(features).find((value) => value);
  const isMobile = isMobileAgent();
  const image = cat2 === pathName ? cat2Img : cat1Img;

  const renderFeatures = () => {
    const featureElements = [];
    for (let key in features) {
      const value = features[key];
      if (value)
        featureElements.push(
          <div className="result-feature-container" key={key}>
            {getFeaturesIcon(key)}
          </div>
        );
    }
    return featureElements;
  };

  const handleClick = () => {
    const element = document.getElementById("results-wrapper");
    if (element) {
      const scrollPos = element.scrollTop;
      setPagePosition(scrollPos);
    }
  };

  return isGrowth ? (
    <div
      className={`pointer zoomIn result-card-container result-card-${status} ${
        isMobile ? "result-card-mobile" : "result-card-web"
      }`}
      onClick={() => {
        handleClick();
        history.push({
          pathname: `/listing/${_id}`,
          state: { from: pathName },
        });
      }}
    >
      {image ? (
        <LazyLoad height={180} scrollContainer="#results-wrapper">
          <img className="result-card-image" src={image} alt={title} />
        </LazyLoad>
      ) : (
        <div className="result-skyline-overlay">
          <Skyline />
        </div>
      )}

      <div className={`result-card-overlay result-card-overlay-gradient`} />
      <div
        className={`result-card-overlay result-card-status-overlay result-card-overlay-${status}`}
      />

      <div
        className="profile-ad-delete-container list-ad-delete-container"
        onClick={(evt) => {
          evt.stopPropagation();
          const message = `Are you sure you want to delete your "${title}" listing?`;
          setModalDeleteMessage(message);
          setDeleteId(_id);
        }}
      >
        <Trash />
      </div>

      {isFeatures && (
        <div className="result-features-container">{renderFeatures()}</div>
      )}

      <div className="result-info-container result-info-bottom">
        <p className="result-meta-data">{authorName}</p>
        <p className="result-meta-data">{type}</p>
        <p className="result-meta-data">{timeStamp}</p>
        <h3 className="result-title">{charLimitTo(title, 55).toUpperCase()}</h3>
        <div className="result-name-container">
          <p className="result-business-name">
            {toTitleCase({
              input: charLimitTo(businessName, 40),
              lowerCaseRest: false,
            })}
          </p>
        </div>
      </div>

      <div className="result-time-container">
        <p className={`result-time-title card-is-${status}`}>{timeText}</p>
        <p className="result-time">{displayTime}</p>
      </div>
    </div>
  ) : (
    <a
      className={`pointer zoomIn result-card-container result-card-${status} ${
        isMobile ? "result-card-mobile" : "result-card-web"
      }`}
      onClick={handleClick}
      href={`/listing/${_id}`}
      target="_blank"
    >
      {image ? (
        <LazyLoad height={180} scrollContainer="#results-wrapper">
          <img className="result-card-image" src={image} alt={title} />
        </LazyLoad>
      ) : (
        <div className="result-skyline-overlay">
          <Skyline />
        </div>
      )}

      <div className={`result-card-overlay result-card-overlay-gradient`} />
      <div
        className={`result-card-overlay result-card-status-overlay result-card-overlay-${status}`}
      />

      <div
        className="profile-ad-delete-container list-ad-delete-container"
        onClick={(evt) => {
          evt.stopPropagation();
          const message = `Are you sure you want to delete your "${title}" listing?`;
          setModalDeleteMessage(message);
          setDeleteId(_id);
        }}
      >
        <Trash />
      </div>

      {isFeatures && (
        <div className="result-features-container">{renderFeatures()}</div>
      )}

      <div className="result-info-container result-info-bottom">
        <p className="result-meta-data">{authorName}</p>
        <p className="result-meta-data">{type}</p>
        <p className="result-meta-data">
          {formatDbDateToReadable({ date: lastUpdated, isForUser: true })}
        </p>
        <h3 className="result-title">{charLimitTo(title, 55).toUpperCase()}</h3>
        <div className="result-name-container">
          <p className="result-business-name">
            {toTitleCase({
              input: charLimitTo(businessName, 40),
              lowerCaseRest: false,
            })}
          </p>
        </div>
      </div>

      <div className="result-time-container">
        <p className={`result-time-title card-is-${status}`}>{timeText}</p>
        <p className="result-time">{displayTime}</p>
      </div>
    </a>
  );
};

export default withRouter(EventCard);
