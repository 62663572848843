import React from "react";

// Components
import BusinessHours from "../../../routes/business/business-hours";

// Utilities
import { militaryTimeToReadable } from "../../../../utilites/time";
import { formatDbDateToReadable } from "../../../../utilites/date";

// Styles
import "../listing.css";

export default ({ data, status }) => {
  const { days } = data || {};
  const { dateAvailable, startDate, endDate, hours } = days || {};
  const { open, close } = hours["Monday"];
  const isEndDate = endDate && endDate !== 999999;
  const endDateText = isEndDate
    ? `to ${formatDbDateToReadable({ date: endDate, isForUser: true })} `
    : "";

  return dateAvailable ? (
    <div className="listing-spacing listing-center-content">
      <p>{`Only Available: ${formatDbDateToReadable({
        date: days.dateAvailable,
        toFullYear: true,
        isForUser: true,
      })} from ${militaryTimeToReadable(open)} to ${militaryTimeToReadable(
        close
      )}`}</p>
    </div>
  ) : (
    <BusinessHours
      Details={`${isEndDate ? "Starting" : "Starting"} ${formatDbDateToReadable(
        {
          date: startDate,
          isForUser: true,
        }
      )} ${endDateText}`}
      title="Available Every:"
      text="Not Available"
      hours={hours}
      status={status}
    />
  );
};
