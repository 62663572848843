import React from "react";

export default props => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 25"
    >
      <path
        d="M27,4.5h-6.8l-0.9-2.2c-0.4-0.9-1.2-1.4-2.1-1.4h-4.3c-0.9,0-1.7,0.5-2.1,1.4L9.9,4.5h-2V4c0-0.5-0.4-0.8-0.8-0.8H5.3
			C4.8,3.2,4.5,3.6,4.5,4v0.4H3.1C1.7,4.5,0.6,5.6,0.6,7v3.4h8.8c1.2-1.9,3.3-3.1,5.7-3.1c2.4,0,4.5,1.2,5.7,3.1h8.8V7
			C29.5,5.6,28.3,4.5,27,4.5z"
      />
      <path
        d="M21.7,14c0,3.7-3,6.7-6.7,6.7s-6.7-3-6.7-6.7c0-0.7,0.1-1.3,0.3-1.9h-8v9.5c0,1.4,1.1,2.5,2.5,2.5h23.9
			c1.4,0,2.5-1.1,2.5-2.5v-9.5h-8C21.6,12.7,21.7,13.3,21.7,14z"
      />
      <path
        d="M15,19c2.8,0,5.1-2.3,5.1-5.1c0-2.8-2.3-5.1-5.1-5.1c-2.8,0-5.1,2.3-5.1,5.1C9.9,16.7,12.2,19,15,19z M15,10.9
			c0.5,0,0.8,0.4,0.8,0.8c0,0.5-0.4,0.8-0.8,0.8c-0.8,0-1.5,0.6-1.5,1.5c0,0.5-0.4,0.8-0.8,0.8c-0.5,0-0.8-0.4-0.8-0.8
			C11.9,12.3,13.3,10.9,15,10.9z"
      />
    </svg>
  );
};
