import React from "react";

// Styles
import "./spacer.css";

const Spacer = ({ height }) => {
  return <div className="spacer" style={{ height: `${height}px` }} />;
};

export default Spacer;
