import React from "react";

export default props => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 30"
    >
      <path d="M10.8,17.8h8.5c2.3,0,4.2,1.7,4.5,3.9c-2,2.7-5.2,4.5-8.8,4.5s-6.8-1.8-8.8-4.5C6.5,19.5,8.4,17.8,10.8,17.8z" />
      <path d="M15,5.8c2.4,0,4.4,2.2,4.4,5s-1.9,5-4.4,5c-2.4,0-4.3-2.2-4.3-5S12.6,5.8,15,5.8z" />
      <path
        d="M15,0.7C22.9,0.7,29.3,7,29.3,15S22.9,29.3,15,29.3S0.7,22.9,0.7,15S7,0.7,15,0.7z M15,2.4C8.1,2.4,2.4,8.1,2.4,15
		c0,7,5.6,12.5,12.5,12.5c7,0,12.5-5.6,12.5-12.5C27.5,8.1,21.9,2.4,15,2.4z"
      />
    </svg>
  );
};
