import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Analytics } from "aws-amplify";
import moment from "moment";
import * as dataActions from "../../../redux/actions/data-actions";
import * as userActions from "../../../redux/actions/user-actions";
import * as stateActions from "../../../redux/actions/state-actions";

// Components
import AutocompleteItems from "../../components/autocomplete-items.js";
import GetCategoryIcon from "../../components/category-icons";
import GetFilterIcon from "../../components/filter-icons";
import getFeaturesIcon from "../../components/features-input/getFeatureIcon";

// Utilities
import { toTitleCase } from "../../../utilites/format";
import { getCurrentDate } from "../../../utilites/date";
import { getCurrentTime, filterTimeList } from "../../../utilites/time";
import { getCityLocation } from "../../../utilites/location";
import {
  searchTypes,
  subCatOptions,
  getFilterList,
  features,
} from "../../../constants/data-models";
import getAnalytics from "../../../utilites/hooks/analytics";
import { getCoordinates } from "../../../api/search";

// Assets
import Arrow from "../../../assets/svg/arrow";
import DirectionArrow from "../../../assets/svg/directionArrow";
import Logo from "../../../assets/svg/event-hound-icon";

// Styles
import "./side-nav-slider.css";

const SideNavSlider = () => {
  const [isMenu, setIsMenu] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [locationInput, setLocationInput] = useState("");
  const [autoCompleteListType, setAutoCompleteListType] = useState("");
  const [autoCompleteList, setAutoCompleteList] = useState([]);
  const { pathname } = useLocation();
  const history = useHistory();
  const isMap = pathname.indexOf("map") > -1;
  const isTop = pathname.indexOf("top") > -1;
  const userAnalytics = getAnalytics();
  const { isDefaultFilters, filters } = useSelector((store) => store.data);
  const user = useSelector((store) => store.user);
  const { geoPermission, location } = user;
  const dispatch = useDispatch();
  const mainCategory = toTitleCase({ input: pathname.split("/")[1] });
  const subCatList = subCatOptions[mainCategory];
  const { type, search, subCat, feature, date, time, distance, sortBy, limit } =
    filters || {};
  const isSearchValue = !!search || !!searchInput;
  const isLocation = "coordinates" in location;
  const isLocationValue = !!isLocation || !!locationInput;
  const geoLocationIsCurrent = geoPermission && !isLocation;
  const isSearchAutoComplete = autoCompleteListType === "search";
  const cityLocation = getCityLocation(user);
  const currentDate = getCurrentDate();
  const currentTime = getCurrentTime();

  const handleChange = (evt, type, name) => {
    const { value } = evt.target;
    switch (type) {
      case "search":
        setSearchInput(value);
        break;
      case "location":
        setLocationInput(value);
        break;
      case "select":
        dispatch(dataActions.setFilter({ [name]: value }));
        if (userAnalytics)
          Analytics.record({
            name: "filter",
            attributes: {
              path: pathname,
              [name]: value,
              location: userAnalytics,
            },
          });
        break;
      case "date":
        const isDayBefore = moment(value).isBefore(currentDate, "d");
        const isDayToday = moment(value).isSame(currentDate, "d");
        const isTimeBefore = time
          ? moment(moment(time, "h:mma")).isBefore(
              moment(getCurrentTime(), "h:mma")
            )
          : false;
        const newDate = isDayBefore || isDayToday ? "" : value;
        const newTime = isDayBefore || (isDayToday && isTimeBefore) ? "" : time;
        dispatch(dataActions.setFilter({ date: newDate, time: newTime }));
        break;
    }
  };

  const handleViewChange = () => {
    const rootPath = pathname.split("/")[1];
    let url = "";
    if (isMap) {
      url = `/${rootPath}`;
      if (rootPath === "map") url = "/";
      if (userAnalytics)
        Analytics.record({
          name: "navigation",
          attributes: { view: "list", location: userAnalytics },
        });
    } else {
      url = `/${rootPath}/map`;
      if (!rootPath) url = "/map";
      dispatch(dataActions.setResults({ results: [], pathname: "" }));
      if (userAnalytics)
        Analytics.record({
          name: "navigation",
          attributes: { view: "map", location: userAnalytics },
        });
    }
    history.push(url);
  };

  const handleInputClick = (id) => {
    setIsMenu(true);
    if (!isMenu)
      setTimeout(() => {
        const element = document.getElementById(id);
        if (element) return element.focus();
      }, 750);
  };

  const handleClearInput = (type) => {
    switch (type) {
      case "search":
        if (searchInput) setSearchInput("");
        if (search) dispatch(dataActions.setFilter({ search: "" }));
        break;
      case "location":
        if (locationInput) setLocationInput("");
        if (isLocation) dispatch(userActions.setLocation({}));
        break;
    }
  };

  const handleLocationClick = () => {
    if (!geoPermission)
      dispatch(
        stateActions.setMessage({
          messageType: "error",
          message: "Enable Location Permissions",
        })
      );
    if (isLocation) dispatch(userActions.setLocation({}));
  };

  const handleEnteredLocation = async () => {
    if (locationInput) {
      const { location } =
        (await getCoordinates({ address: locationInput })) || {};
      if ("coordinates" in location) {
        dispatch(userActions.setLocation(location));
      } else {
        dispatch(
          stateActions.setMessage({
            messageType: "error",
            message: "Location Not found",
          })
        );
      }
    } else {
      dispatch(userActions.setLocation({}));
    }
  };

  const handleKeyDown = (evt, type) => {
    const { which, keyCode, charCode } = evt;
    const isEnter = which === 13 || keyCode === 13;
    const isDelete = keyCode == 8 || charCode == 46;
    if (isEnter) {
      setAutoCompleteList([]);
      switch (type) {
        case "search":
          setSearchInput("");
          dispatch(dataActions.setFilter({ search: searchInput }));
          break;
        case "location":
          handleEnteredLocation();
          setLocationInput("");
          break;
      }
    }
    if (isDelete) {
      switch (type) {
        case "search":
          if (searchInput.length === 1)
            dispatch(dataActions.setFilter({ search: "" }));
          break;
        // case "location":
        //   if (locationInput.length === 1) setLocation({});
        //   break;
      }
    }
  };

  const handleAutoCompleteChange = (list, type) => {
    setAutoCompleteListType(type);
    setAutoCompleteList(list);
  };

  const handleAutoCompleteClick = async (item, type) => {
    setAutoCompleteList([]);
    switch (type) {
      case "search":
        setSearchInput("");
        if (searchInput) dispatch(dataActions.setFilter({ search: item }));
        break;
      case "location":
        const { location } = (await getCoordinates({ address: item })) || {};
        if ("coordinates" in location) {
          dispatch(userActions.setLocation(location));
          setLocationInput("");
        }
        break;
    }
  };

  const createSelectOptions = (options = [], allText) => {
    return options.map((option, idx) => (
      <option value={option} key={idx}>
        {option ? option : `All ${allText}`}
      </option>
    ));
  };

  function createFilters() {
    const values = [time || currentTime, distance, sortBy, limit];
    const filterList = getFilterList(
      values,
      filterTimeList(date || currentDate)
    );
    // if (isTop) delete filterList["Sort By"];
    if (isMap) {
      delete filterList.Distance;
      delete filterList["Sort By"];
    }
    if (!isMap) {
      delete filterList.Limit;
    }

    const getActiveStatus = (name) => {
      switch (name) {
        case "time":
          return !!time;
        case "distance":
          return distance !== "50 Miles";
        case "sortBy":
          return sortBy !== "Start Time";
      }
    };

    return Object.values(filterList).map(({ name, options, value }, idx) => {
      const isActive = getActiveStatus(name);
      return (
        <div className="pointer sideNav-container" key={idx}>
          <div
            className={`sideNav-svg-dark pointer sideNav-border sideNav-icon-path-container ${
              isActive ? "sideNav-isActive" : ""
            }`}
            onClick={() => handleInputClick(`sideNav-${name}-input`)}
          >
            {GetFilterIcon(name)}
          </div>
          {isMenu && (
            <div
              className="sideNav-description-container pointer"
              style={{ animationDelay: `${(idx + 1) * 50 + 350}ms` }}
            >
              <div className="sideNav-svg-dark sideNav-description-icon">
                <DirectionArrow />
              </div>
              <select
                id={`sideNav-${name}-input`}
                className="sideNav-border sideNav-subcat-select"
                value={value}
                onChange={(evt) => handleChange(evt, "select", name)}
              >
                {createSelectOptions(options, name)}
              </select>
            </div>
          )}
        </div>
      );
    });
  }

  return (
    <>
      {isMenu && (
        <div
          className="sideNav-page-overlay"
          onClick={() => {
            setAutoCompleteList([]);
            setIsMenu(false);
          }}
        />
      )}
      <div
        className={`sideNav-wrapper ${isMenu ? "sideNav-wrapper-open" : ""}`}
      >
        <div className="sideNav-container">
          <div
            className={`pointer sideNav-svg-arrow ${
              isMenu ? "sideNav-svg-arrow-open" : ""
            }`}
            onClick={() => {
              setAutoCompleteList([]);
              setIsMenu(!isMenu);
            }}
          >
            <Arrow />
          </div>
        </div>
        <div className="pointer sideNav-container" onClick={handleViewChange}>
          <div className="sideNav-svg-dark pointer sideNav-border sideNav-border sideNav-icon-path-container">
            {isMap ? GetFilterIcon("results") : GetFilterIcon("map")}
          </div>
          {isMenu && (
            <div
              className="sideNav-description-container pointer"
              style={{ animationDelay: "50ms" }}
            >
              <div className="sideNav-svg-dark sideNav-description-icon">
                <DirectionArrow />
              </div>
              <p className="sideNav-description">
                {isMap ? "List View" : "Map View"}
              </p>
            </div>
          )}
        </div>
        <div
          className="sideNav-container sideNav-container-input"
          onClick={() => handleInputClick("sideNav-search-input")}
        >
          <div className="sideNav-input-autoComplete-wrapper">
            <div
              className={`pointer sideNav-border sideNav-icon-input-container ${
                !isMenu && search ? "sideNav-isActive" : ""
              }`}
            >
              <div className="sideNav-svg-dark sideNav-input-icon">
                {GetFilterIcon("search")}
              </div>
              {isMenu && (
                <>
                  <input
                    id="sideNav-search-input"
                    className="sideNav-input"
                    style={{ animationDelay: "100ms" }}
                    placeholder={search || "Search titles & desc..."}
                    value={searchInput}
                    onKeyDown={(evt) => handleKeyDown(evt, "search")}
                    onChange={(evt) => handleChange(evt, "search")}
                    spellCheck="true"
                  />
                  {isSearchValue && (
                    <div
                      className="searchbar-input-clear-container"
                      onClick={() => handleClearInput("search")}
                    >
                      {GetFilterIcon("close")}
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="sideNav-autoComplete-container">
              {AutocompleteItems({
                searchInput,
                locationInput: "",
                handleChange: handleAutoCompleteChange,
                handleClick: handleAutoCompleteClick,
                list: isSearchAutoComplete ? autoCompleteList : [],
                type: autoCompleteListType,
              })}
            </div>
          </div>
        </div>
        <div
          className="sideNav-container sideNav-container-input"
          onClick={() => handleInputClick("sideNav-location-input")}
        >
          <div className="sideNav-input-autoComplete-wrapper">
            <div
              className={`pointer sideNav-border sideNav-icon-input-container ${
                !isMenu && isLocationValue ? "sideNav-isActive" : ""
              }`}
            >
              <div
                className="sideNav-svg-dark sideNav-input-icon"
                onClick={isMenu ? handleLocationClick : () => {}}
              >
                {geoLocationIsCurrent
                  ? GetFilterIcon("location", true)
                  : GetFilterIcon("location")}
              </div>
              {isMenu && (
                <>
                  <input
                    id="sideNav-location-input"
                    className="sideNav-input"
                    style={{ animationDelay: "150ms" }}
                    placeholder={cityLocation || "Location"}
                    value={locationInput}
                    onKeyDown={(evt) => handleKeyDown(evt, "location")}
                    onChange={(evt) => handleChange(evt, "location")}
                    spellCheck="true"
                  />
                  {isLocationValue && (
                    <div
                      className="searchbar-input-clear-container"
                      onClick={() => handleClearInput("location")}
                    >
                      {GetFilterIcon("close")}
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="sideNav-autoComplete-container">
              {AutocompleteItems({
                searchInput: "",
                locationInput,
                handleChange: handleAutoCompleteChange,
                handleClick: handleAutoCompleteClick,
                list: isSearchAutoComplete ? [] : autoCompleteList,
                type: autoCompleteListType,
              })}
            </div>
          </div>
        </div>

        <div className="sideNav-container">
          <div
            className={`sideNav-svg-dark pointer sideNav-border sideNav-icon-path-container ${
              feature ? "sideNav-isActive" : ""
            }`}
            onClick={() => handleInputClick("sideNav-feature-input")}
          >
            <Logo />
          </div>
          {isMenu && (
            <div
              className="sideNav-description-container pointer"
              style={{ animationDelay: "200ms" }}
            >
              <div className="sideNav-svg-dark sideNav-description-icon">
                <DirectionArrow />
              </div>
              <select
                id="sideNav-feature-input"
                className="sideNav-border sideNav-subcat-select"
                value={type}
                onChange={(evt) => handleChange(evt, "select", "type")}
              >
                {createSelectOptions(["", ...searchTypes], "Types")}
              </select>
            </div>
          )}
        </div>

        {!isTop && (
          <div className="pointer sideNav-container">
            <div
              className={`sideNav-svg-dark pointer sideNav-border sideNav-icon-path-container ${
                subCat ? "sideNav-isActive" : ""
              }`}
              onClick={() => handleInputClick("sideNav-subCat-input")}
            >
              {GetCategoryIcon(mainCategory, true)}
            </div>
            {isMenu && (
              <div
                className="sideNav-description-container pointer"
                style={{ animationDelay: "250ms" }}
              >
                <div className="sideNav-svg-dark sideNav-description-icon">
                  <DirectionArrow />
                </div>
                <select
                  id="sideNav-subCat-input"
                  className="sideNav-border sideNav-subcat-select"
                  value={subCat}
                  onChange={(evt) => handleChange(evt, "select", "subCat")}
                >
                  {createSelectOptions(["", ...subCatList], mainCategory)}
                </select>
              </div>
            )}
          </div>
        )}

        <div className="sideNav-container">
          <div
            className={`sideNav-svg-dark pointer sideNav-border sideNav-icon-path-container ${
              feature ? "sideNav-isActive" : ""
            }`}
            onClick={() => handleInputClick("sideNav-feature-input")}
          >
            {feature ? getFeaturesIcon(feature) : GetFilterIcon("features")}
          </div>
          {isMenu && (
            <div
              className="sideNav-description-container pointer"
              style={{ animationDelay: "300ms" }}
            >
              <div className="sideNav-svg-dark sideNav-description-icon">
                <DirectionArrow />
              </div>
              <select
                id="sideNav-feature-input"
                className="sideNav-border sideNav-subcat-select"
                value={feature}
                onChange={(evt) => handleChange(evt, "select", "feature")}
              >
                {createSelectOptions(["", ...features], "Features")}
              </select>
            </div>
          )}
        </div>

        {createFilters()}

        {!isDefaultFilters && (
          <div
            className="pointer sideNav-container sideNav-container-secondary"
            onClick={() => {
              dispatch(dataActions.resetFilters());
              setIsMenu(false);
            }}
          >
            <div className="sideNav-svg-secondary pointer sideNav-icon-path-container sideNav-icon-path-container-secondary">
              {GetFilterIcon("refresh")}
            </div>
            {isMenu && (
              <div
                className="sideNav-description-container pointer"
                style={{ animationDelay: isMap ? "450ms" : "550ms" }}
              >
                <div className="sideNav-svg-dark sideNav-description-icon">
                  <DirectionArrow />
                </div>
                <p className="sideNav-description">Reset Filters</p>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default SideNavSlider;
