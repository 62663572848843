const mapDefaultCenter = [-118.542586, 34.3916641];

export function getCurrentPosition(options = {}) {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, options);
  });
}

export function getLocation(location, geoLocation, user) {
  const { coordinates: enteredCoords } = location || {};
  const { coordinates: geoCoords } = geoLocation || {};
  if (enteredCoords) return enteredCoords;
  if (geoCoords) return geoCoords;
  if (
    user &&
    user.location &&
    user.location.coordinates &&
    user.location.coordinates.length > 1
  )
    return user.location.coordinates;
  return mapDefaultCenter;
}

export const getCityLocation = (reduxUser) => {
  const { location: enteredLocation, geoLocation, user } = reduxUser;
  const { location: userDefaultLocation } = user || {};
  if ("address" in enteredLocation) return enteredLocation.address;
  if ("address" in geoLocation) return geoLocation.address;
  if (userDefaultLocation && "address" in userDefaultLocation)
    return userDefaultLocation.address;
};
