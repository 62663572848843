import React from "react";

export default props => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 30"
    >
      <path
        d="M23.6,14.6L8.9,0.2C8.6,0,8.3,0,8.1,0.2L6.4,1.9C6.3,2,6.2,2.1,6.2,2.3c0,0.2,0.1,0.3,0.2,0.4L19,15L6.4,27.3
      c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.2,0.1,0.3,0.2,0.4l1.7,1.7C8.2,29.9,8.3,30,8.5,30c0.1,0,0.3-0.1,0.4-0.2l14.7-14.4
      c0.1-0.1,0.2-0.3,0.2-0.4C23.8,14.8,23.7,14.7,23.6,14.6z"
      />
    </svg>
  );
};
