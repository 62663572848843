import React from "react";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 30"
    >
      <style>{".heartColor{fill:black;}"}</style>
      <g>
        <polygon points="24.3,23.7 23.9,23.7 22.5,23.7 21.7,23.7 20.2,23.7 19.8,23.7 19.8,24.1 24.3,24.1 	" />
        <rect x="20.6" y="21.3" width="1.1" height="1.6" />
        <rect x="22.5" y="21.3" width="1.1" height="1.6" />
        <rect x="22.5" y="19" width="1.1" height="1.6" />
        <rect x="20.6" y="19" width="1.1" height="1.6" />
        <polygon points="8.3,23.7 7.5,23.7 6.1,23.7 5.7,23.7 5.7,24.1 10.2,24.1 10.2,23.7 9.8,23.7 	" />
        <rect x="6.4" y="21.3" width="1.1" height="1.6" />
        <rect x="8.3" y="21.3" width="1.1" height="1.6" />
        <rect x="8.3" y="19" width="1.1" height="1.6" />
        <rect x="6.4" y="19" width="1.1" height="1.6" />
        <polygon points="18.4,28.7 11.6,28.7 4.2,28.7 3.8,28.7 3.8,29.5 26.2,29.5 26.2,28.7 25.8,28.7 	" />
        <path
          d="M19.1,19h-0.4v8.9h6.7V13.2H4.6v14.8h6.7V19h-0.4c-0.2,0-0.4-0.2-0.4-0.4c0-2.6,2-4.7,4.5-4.7s4.5,2.1,4.5,4.7
		C19.5,18.8,19.3,19,19.1,19z M10.9,23.3v1.2c0,0.2-0.2,0.4-0.4,0.4H5.3c-0.2,0-0.4-0.2-0.4-0.4v-1.2c0-0.2,0.2-0.4,0.4-0.4h0.4
		v-4.3c0-0.2,0.2-0.4,0.4-0.4h3.7c0.2,0,0.4,0.2,0.4,0.4v4.3h0.4C10.7,22.9,10.9,23.1,10.9,23.3z M19.8,22.9v-4.3
		c0-0.2,0.2-0.4,0.4-0.4h3.7c0.2,0,0.4,0.2,0.4,0.4v4.3h0.4c0.2,0,0.4,0.2,0.4,0.4v1.2c0,0.2-0.2,0.4-0.4,0.4h-5.2
		c-0.2,0-0.4-0.2-0.4-0.4v-1.2c0-0.2,0.2-0.4,0.4-0.4H19.8z"
        />
        <path
          d="M14.6,14.8c-1.8,0.2-3.2,1.6-3.3,3.5h1.5v-1.6h0.7v1.6h1.1v-1.6h0.7v1.6h1.1v-1.6h0.7v1.6h1.5
		C18.5,16.1,16.7,14.5,14.6,14.8z"
        />
        <path d="M12,27.9h2.6V19H12V27.9z M13.5,22.9h0.7v1.6h-0.7V22.9z" />
        <path d="M15.4,27.9H18V19h-2.6V27.9z M15.7,22.9h0.7v1.6h-0.7V22.9z" />
        <path
          d="M11.6,10.9h6.7c0.6,0,1.1-0.5,1.1-1.2V7.4c0-0.6-0.5-1.2-1.1-1.2h-6.7c-0.6,0-1.1,0.5-1.1,1.2v2.3
		C10.5,10.3,11,10.9,11.6,10.9z"
        />
        <path
          d="M26.2,10.9V9.7c0-0.6-0.5-1.2-1.1-1.2h-4.8v1.2c0,1.1-0.8,1.9-1.9,1.9h-6.7c-1,0-1.9-0.9-1.9-1.9c0,0,0,0,0,0V8.5H8L6.6,10
		c0,0,0,0,0,0c-0.1,0.1-0.2,0.1-0.3,0L4.9,8.5c-0.6,0-1,0.5-1,1.1v1.2h5.6v0.8H3.8v0.8h22.4v-0.8h-5.6v-0.8H26.2z"
        />
      </g>
      <path
        className="heartColor"
        d="M10.9,1.1C9.8,0.1,8.1,0.1,7,1.1L6.4,1.8L5.8,1.1C4.7,0.1,3,0.1,2,1.1C0.9,2.2,0.9,3.9,2,5l4.3,4.3c0.1,0.1,0.2,0.1,0.3,0
	c0,0,0,0,0,0L10.9,5C11.9,3.9,11.9,2.2,10.9,1.1z"
      />
    </svg>
  );
};
