import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as stateActions from "../../../redux/actions/state-actions";

// Components
import ViewLayout from "../../components/view-layout";
import Button from "../../components/common/button";

// Utilities
import { toTitleCase } from "../../../utilites/format";
import { getGiveawayWinner } from "../../../api/giveaway";

// Assets
import Arrow from "../../../assets/svg/back";

// Styles
import "./giveaway.css";

export default () => {
  const dispatch = useDispatch();
  const [locationInput, setLocationInput] = useState("long beach ca");
  const [winner, setWinner] = useState(null);

  const handleChange = (evt, type, newValue) => {
    const { value } = evt.target;
    switch (type) {
      case "locationInput":
        return setLocationInput(value);
    }
  };

  const handleSearch = async () => {
    if (locationInput) {
      dispatch(stateActions.setLoader(true));
      const { winner: newWinnner } = await getGiveawayWinner({
        location: locationInput,
      });
      if (newWinnner) setWinner(newWinnner);
      dispatch(stateActions.setLoader(false));
    }
  };

  return (
    <ViewLayout>
      <div className="card-wrapper signup-wrapper-BusinessSignUp">
        <div className="card card-form">
          <div className="card-info-form">
            <label id="locationInput" className="label-form">
              Location:
            </label>
            <input
              className="input-style input-form text-form"
              value={locationInput}
              onChange={(evt) => handleChange(evt, "locationInput")}
            />

            <div className="submit-wapper-UserSignUp">
              <div
                className="submit-button-UserSignUp"
                style={{ display: "flex", flex: "row" }}
              >
                <Button text="Search" handleClick={handleSearch} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {!!businesses &&
        !!businesses.length &&
        businesses.map((business, idx) => {
          const { _id } = business;
          return (
            <BusinessCard
              business={business}
              agentName={agentName}
              setUpdateLocation={setUpdateLocation}
              page={page}
              idx={idx}
              key={_id}
            />
          );
        })} */}
    </ViewLayout>
  );
};
