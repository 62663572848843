import React from "react";

export default props => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 16 16"
    >
      <path
        d="M8,1.2c-1.8,0-3.6,0.7-4.9,2s-2,3-2,4.8s0.7,3.5,2,4.8s3,2,4.9,2s3.6-0.7,4.9-2s2-3,2-4.8s-0.7-3.5-2-4.8S9.8,1.2,8,1.2z
		 M8,13.9c-3.3,0-6-2.6-6-5.9s2.7-5.9,6-5.9s6,2.6,6,5.9S11.3,13.9,8,13.9z"
      />
      <path
        d="M11,10L8.4,8V4.1c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5v4.1c0,0.1,0.1,0.3,0.2,0.4l2.8,2.1
		c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.3-0.1,0.4-0.2C11.2,10.4,11.2,10.1,11,10z"
      />
    </svg>
  );
};
