import React from "react";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 24"
    >
      <rect x="0.5" y="0.6" width="5.4" height="5.4" />
      <rect x="8" y="0.6" width="21.5" height="5.4" />
      <rect x="0.5" y="18.1" width="5.4" height="5.4" />
      <rect x="8" y="18.1" width="21.5" height="5.4" />
      <rect x="0.5" y="9.3" width="5.4" height="5.4" />
      <rect x="8" y="9.3" width="21.5" height="5.4" />
    </svg>
  );
};
