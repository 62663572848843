import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Route, Redirect } from "react-router-dom";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import * as userActions from "../../../redux/actions/user-actions";

export default ({ component: Component, ...rest }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { accountType } = useSelector((store) => store.user.employee);
  const isAccess =
    accountType === "telemarketing" ||
    accountType === "data entry" ||
    accountType === "flex" ||
    accountType === "review" ||
    accountType === "admin" ||
    accountType === "developer";

  useEffect(() => {
    const { employee: employeeStorage } = window.localStorage || {};
    if (employeeStorage) {
      const { date } = JSON.parse(employeeStorage);
      if (!moment(date).isSame(moment(), "day")) {
        localStorage.setItem("employee", {});
        dispatch(userActions.setEmployee(null));
        window.location.reload();
      }
    }
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAccess) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};
