import React from "react";

export default props => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 24"
    >
      <path d="M20.5,13.8h-0.3V1h-0.3v7.4h-0.6V1h-1.2v12.8h-0.3V15h2.7V13.8z M19.3,9h0.6v0.6h-0.6V9z" />
      <path
        d="M8.9,21.8v-3.4l7.6-3l-4.1-1.6H9.8l-7.6,3H11v0.6H4.7v1.8H4.2v-1.8H1.5v3.9H0.6v0.6h0.9v0C1.5,21.8,8.9,21.8,8.9,21.8z
		 M4.2,19.7h0.6v0.6H4.2V19.7z"
      />
      <path
        d="M29.4,23h-0.9l0-3.9h-3v-0.6h2.2l-7.3-3h-2.7l-7.6,3h2.9l5.2-2.1l0.2,0.6l-3.8,1.5h9v0.6H13.4v1.8h-0.6v-1.8H9.5V23H8.6
		v0.6h0.9v0h19v0h0.9V23z M13.4,22.1h-0.6v-0.6h0.6V22.1z"
      />
      <path d="M12.5,12h-0.3V0.4h-0.3v6.5h-0.6V0.4h-1.2V12H9.8v1.2h2.7V12z M11.3,7.5h0.6v0.6h-0.6V7.5z" />
    </svg>
  );
};
