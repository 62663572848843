import React from "react";

// Components
import PasswordInput from "../common/password-input";
import Toggle from "../common/toggle";

// Styles
import "./input-labeled.css";

const InputLabeled = ({
  name,
  value,
  label,
  type = "text",
  handleChange,
  toggle = false,
  toggleValue,
  toggleText = "Show to Users",
  handleToggle,
  isImportant = false,
}) => {
  const isPassword = type.toLowerCase() === "password";

  return !toggle ? (
    <>
      <label
        id={name}
        className={`label-form ${isImportant ? "color-secondary" : ""}`}
      >{`${label}:`}</label>
      {isPassword ? (
        <PasswordInput value={value} handleChange={handleChange} />
      ) : (
        <input
          className="input-style input-form text-form"
          type={type}
          value={value}
          onChange={handleChange}
          spellCheck="true"
        />
      )}
    </>
  ) : (
    <>
      <label id={name} className="label-form">
        {`${label}:`}
        <div className="show-form-toggle">
          <p className="show-form-toggle-text">{toggleText}</p>
          <Toggle
            value={toggleValue}
            handleToggle={handleToggle}
            params={name}
          />
        </div>
      </label>
      <input
        className="input-style input-form text-form"
        type={type}
        value={value}
        onChange={handleChange}
        spellCheck="true"
      />
    </>
  );
};

export default InputLabeled;
