import React from "react";
import { CSSTransition } from "react-transition-group";
import { connect } from "react-redux";
import * as stateActions from "../../../redux/actions/state-actions";

// Assets
import Close from "../../../assets/svg/close";

// Styles
import "./image-modal.css";

const ImageModal = ({
  image,
  setImage,
  name = "EventHound - Event Finder",
}) => {
  const isImage = image ? true : false;
  const isPromo = image.includes("promo");

  const handleClick = () => {
    setImage();
  };

  return (
    <CSSTransition in={isImage} timeout={250} classNames="fade">
      <div
        className="image-modal-wrapper"
        style={{
          backgroundColor: isPromo ? "rgba(0,0,0,0.9)" : "var(--opacity-dark)",
        }}
        onClick={handleClick}
      >
        <div className="image-modal-close-container" onClick={handleClick}>
          <Close />
        </div>
        <img
          className="image-modal-image"
          style={{ width: isPromo ? "250px" : "95%" }}
          src={image}
          alt={name}
        />
      </div>
    </CSSTransition>
  );
};

const mapStateToProps = (store) => ({
  image: store.state.image,
});

const mapDispatchToProps = (dispatch) => ({
  setImage: (value) => dispatch(stateActions.setImage(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageModal);
