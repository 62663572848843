import React from "react";

export default props => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 30"
    >
      <path
        d="M25.3,4.7c-5.7-5.7-14.8-5.7-20.5,0c-5.7,5.7-5.7,14.8,0,20.5c5.7,5.7,14.8,5.7,20.5,0
			C30.9,19.6,30.9,10.4,25.3,4.7z M22.7,20.1l-2.6,2.6L15,17.6l-5.1,5.1l-2.6-2.6l5.1-5.1L7.3,9.9l2.6-2.6l5.1,5.1l5.1-5.1l2.6,2.6
			L17.6,15L22.7,20.1z"
      />
    </svg>
  );
};
