import React from "react";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 16"
    >
      <path
        d="M7,14.9L7,14.9c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0.1,0.3,0.1,0.4,0.1c0.1,0,0.3,0,0.4-0.1c0.1,0,0.2-0.1,0.3-0.2
		c0.1-0.1,0.2-0.2,0.2-0.3c0-0.1,0.1-0.2,0.1-0.4V9h12.4v5.2c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.2
		c0.1,0,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1c0.1,0,0.2-0.1,0.3-0.2l0,0l6.2-6.2c0.4-0.4,0.4-1,0-1.4L23,1.1l0,0c-0.1-0.1-0.2-0.2-0.3-0.2
		c-0.1-0.1-0.3-0.1-0.4-0.1s-0.3,0-0.4,0.1c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.1-0.1,0.3-0.1,0.4V7H8.8V1.8
		c0-0.1,0-0.3-0.1-0.4c0-0.1-0.1-0.2-0.2-0.3C8.4,1,8.3,0.9,8.2,0.9C8.1,0.8,7.9,0.8,7.8,0.8c-0.1,0-0.3,0-0.4,0.1
		C7.2,0.9,7.1,1,7,1.1l0,0L0.8,7.3c-0.4,0.4-0.4,1,0,1.4L7,14.9z"
      />
    </svg>
  );
};
