import React from "react";

export default props => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 24 30"
    >
      <path
        d="M20.5,12.3l-0.8,0l0-4c0-4.4-3.6-7.9-7.9-7.9S3.8,3.9,3.8,8.3l0,4H3.5c-1.5,0-2.7,1.2-2.7,2.7l0,12c0,1.5,1.2,2.7,2.7,2.7
		l17,0c1.5,0,2.7-1.2,2.7-2.7l0-12C23.2,13.5,22,12.3,20.5,12.3z M12.7,21.4l0,3.4l-1.2,0l0-3.4c-0.7-0.3-1.2-0.9-1.2-1.7
		c0-1,0.8-1.8,1.8-1.8c1,0,1.8,0.8,1.8,1.8C13.9,20.5,13.4,21.2,12.7,21.4z M16.7,12.3l-10,0l0-4c0-2.8,2.2-5,5-5c2.8,0,5,2.2,5,5
		L16.7,12.3z"
      />
    </svg>
  );
};
