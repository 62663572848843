import React, { useState } from "react";

// Assets
import Privacy from "../../../../assets/svg/privacy";

// styles
import "./password-input-styles.css";

const PasswordInput = ({ value, handleChange }) => {
  const [isHidden, setIsHidden] = useState(true);

  return (
    <div className="input-password-container">
      <input
        className="input-style input-form text-form"
        type={isHidden ? "password" : "text"}
        value={value}
        onChange={handleChange}
      />
      <div
        className={`input-password-svg-container ${
          isHidden ? "" : "input-password-svg-container-show"
        }`}
        onClick={() => setIsHidden(!isHidden)}
      >
        <Privacy />
      </div>
    </div>
  );
};

export default PasswordInput;
