import React from "react";
import { useSelector, useDispatch } from "react-redux";
import * as stateActions from "../../../../redux/actions/state-actions";

// Components
import BottomDrawer from "../../bottom-drawer";

// Assets
import DirectionArrow from "../../../../assets/svg/directionArrow";
import SMS from "../../../../assets/svg/sms";
import Email from "../../../../assets/svg/contact";

// Styles
import "./share.css";

export default () => {
  const dispatch = useDispatch();
  const { shareDrawer } = useSelector((store) => store.state);
  const { title, name } = shareDrawer || {};

  const handleCancel = () => {
    setTimeout(() => dispatch(stateActions.setShareDrawer(null)), 200);
  };

  return (
    <BottomDrawer
      title="Share"
      isOpen={!!shareDrawer}
      handleCancelClick={handleCancel}
    >
      <div className="share-options-container">
        <a
          className="share-option-container"
          href={`sms:?&body=${window.location.href}`}
          onClick={handleCancel}
        >
          <div className="share-text-conatainer">
            <p>Text</p>
            <div className="share-arrow">
              <DirectionArrow />
            </div>
          </div>
          <SMS />
        </a>
        <a
          className="share-option-container"
          href={`mailto:?&subject=${title} - ${name}&body=${window.location.href}`}
          onClick={handleCancel}
        >
          <div className="share-text-conatainer">
            <p>Email</p>
            <div className="share-arrow">
              <DirectionArrow />
            </div>
          </div>
          <Email />
        </a>
      </div>
    </BottomDrawer>
  );
};
