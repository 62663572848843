import { callAPI } from ".";
import { baseURL } from "./url";
import { formatQueryParams } from "../utilites/format";

const findsURL = baseURL("/admin/find");

export function searchReviewFinds(options) {
  const url = `${findsURL}/search?${formatQueryParams(options)}`;
  return callAPI(url, "GET");
}

export function searchReviewEditedFinds(options) {
  const url = `${findsURL}/edit?${formatQueryParams(options)}`;
  return callAPI(url, "GET");
}

export function updateFinds(data) {
  return callAPI(findsURL, "POST", data);
}

export function updateEditedFinds(data) {
  return callAPI(`${findsURL}/edit`, "POST", data);
}

export async function getFind(options) {
  const url = `${await findsURL}?${formatQueryParams(options)}`;
  return callAPI(url, "GET");
}
