import { useSelector } from "react-redux";
import moment from "moment";

export default function UseGetBusinessStatus() {
  const currentDate = moment().format();
  const { user } = useSelector((state) => state.user);
  const { account } = user;
  const { subscriptionCalenderEndDate, accountType, uniqueListings } =
    account || {};

  const accountIsActive = !!(
    subscriptionCalenderEndDate &&
    moment(subscriptionCalenderEndDate).isSameOrAfter(currentDate)
  );

  const verifiedUniqueListingIds = Object.keys(uniqueListings).reduce(
    (acc, key) => {
      const uniqueListingDate = uniqueListings[key];
      const futureDate = moment(uniqueListingDate).add(1, "M");
      if (moment(futureDate).isSameOrAfter(currentDate))
        return { ...acc, [key]: uniqueListingDate };
      return acc;
    },
    {}
  );

  const canPostAd = (() => {
    const activeUniqueAds = Object.keys(verifiedUniqueListingIds).length;
    if (accountIsActive) {
      switch (accountType) {
        case "unlimited":
          return true;
        case "premium":
          return activeUniqueAds < 3;
      }
    }
    if (!accountIsActive && activeUniqueAds < 1) return true;
    return false;
  })();

  return {
    accountIsActive,
    accountType,
    uniqueListingIds: verifiedUniqueListingIds,
    canPostAd,
  };
}
