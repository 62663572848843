import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

// Components
import Modal from "../../../components/modal";

// Utilities
import { charLimitTo } from "../../../../utilites/format";
import { getBusinessStatus } from "../../../../utilites/status";
import { covidMessage } from "../../../../constants/data-models";

// Assets
import Covid from "../../../../assets/svg/covid";
import RatingStarsClip from "../../../../assets/svg/rating-stars-clip";

// Styles
import "../../list-view/event-card/result.css";
import "../result-card/result-card.css";
import "./busniess-card.css";

const Result = ({ result, filterDate, history }) => {
  const [modalMessage, setModalMessage] = useState("");
  const [{ status, timeText, displayTime }, setbusinessStatus] = useState(
    getBusinessStatus(result, filterDate)
  );
  const { _id, images, name, covid, yelp } = result || {};
  const { covidCompliant } = covid || {};
  const { rating = 0, review_count } = yelp || {};
  const ratingPercent = (rating / 5) * 100;

  useEffect(() => {
    setbusinessStatus(getBusinessStatus(result, filterDate));
  }, [result, filterDate]);

  return (
    <>
      <div
        className={`zoomIn result-map-card-container result-map-card-container-${status} result-card-${status}`}
        onClick={() => history.push(`/business/${_id}`)}
      >
        {!!images.length && images[0] && (
          <>
            <div
              className="result-card-image"
              style={{ backgroundImage: `url(${images[0]})` }}
            />
            <div className="result-card-overlay result-card-map-overlay-gradient" />
            <div
              className={`result-card-overlay result-card-status-overlay result-card-overlay-${status}`}
            />
          </>
        )}

        {covidCompliant && (
          <div
            className="map-card-covid"
            onClick={(evt) => {
              evt.stopPropagation();
              setModalMessage(covidMessage(name));
            }}
          >
            <Covid />
          </div>
        )}

        <div className="result-map-info-container">
          <h3 className="result-map-title">
            {charLimitTo(name, 55).toUpperCase()}
          </h3>

          {yelp && (
            <div className="result-yelp-conatiner">
              <p className="result-yelp-text">{`Yelp (${review_count})`}</p>
              <div className="yelp-review-result-conatiner">
                <div
                  className="yelp-review-fill-clip"
                  style={{ width: `${ratingPercent}%` }}
                >
                  <RatingStarsClip />
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="result-card-right-container">
          <div className="result-map-time-container">
            <p
              className={`result-map-time-title card-is-${
                !!images.length ? status : "none"
              }`}
            >
              {timeText.toUpperCase()}
            </p>
            <p className="result-map-time">{displayTime}</p>
          </div>
        </div>
      </div>

      <Modal
        directModalMessage={!!modalMessage}
        modalAccept="Ok"
        handleAcceptClick={() => setModalMessage("")}
      >
        <div className="modal-covid-icon">
          <Covid />
        </div>
        <p>{modalMessage}</p>
        <a
          className="results-covid-modal-link"
          href="https://files.covid19.ca.gov/pdf/guidance-dine-in-restaurants.pdf"
          target="_blank"
        >
          CDC Guildlines
        </a>
        <p className="results-covid-modal-legal">
          EventHound is not responsible for claims made by the businesses.
        </p>
      </Modal>
    </>
  );
};

export default withRouter(Result);
