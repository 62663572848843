import React from "react";

export default props => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 16 16"
    >
      <path
        d="M14.1,12.5V1.9c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7v10.6l-0.3-0.3c-0.3-0.3-0.7-0.3-1,0
				c-0.3,0.3-0.3,0.7,0,1l1.4,1.4c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.3-0.1,0.4-0.2l1.4-1.4c0.3-0.3,0.3-0.7,0-1c-0.3-0.3-0.7-0.3-1,0
				L14.1,12.5z M0.5,1.9c0-0.4,0.3-0.7,0.7-0.7H10c0.4,0,0.7,0.3,0.7,0.7c0,0.4-0.3,0.7-0.7,0.7H1.2C0.8,2.6,0.5,2.3,0.5,1.9z
				 M0.5,6c0-0.4,0.3-0.7,0.7-0.7h7.5C9,5.3,9.3,5.6,9.3,6c0,0.4-0.3,0.7-0.7,0.7H1.2C0.8,6.6,0.5,6.3,0.5,6z M0.5,10
				c0-0.4,0.3-0.7,0.7-0.7h6.1C7.7,9.4,8,9.7,8,10c0,0.4-0.3,0.7-0.7,0.7H1.2C0.8,10.7,0.5,10.4,0.5,10z M0.5,14.1
				c0-0.4,0.3-0.7,0.7-0.7h4.1c0.4,0,0.7,0.3,0.7,0.7c0,0.4-0.3,0.7-0.7,0.7H1.2C0.8,14.8,0.5,14.5,0.5,14.1z"
      />
    </svg>
  );
};
