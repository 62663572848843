import React from "react";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 21 30"
    >
      <path
        d="M15.3,9.1c0.5,0.7,0.8,1.7,0.8,2.7v3.6c0,0.6-1,0.6-1,0v-3.6c0-0.5-0.1-1-0.3-1.5L15.3,9.1z M6.1,10.4
		c-0.2,0.4-0.3,0.9-0.3,1.4v3.6c0,0.6-1,0.6-1,0v-3.6c0-0.8,0.2-1.5,0.5-2.1L6.1,10.4z M2.4,12.6h1.3v2.8c0,0.9,0.7,1.6,1.6,1.6l0,0
		c0.8,0,1.5-0.7,1.5-1.6v-2.8H14v2.8c0,0.9,0.7,1.6,1.5,1.6l0,0c0.9,0,1.6-0.7,1.6-1.6v-2.8h1.4c0.6,0,1.1,0.4,1.2,1l0.7,14.8
		c0,0.5-0.5,1-1.2,1H1.6c-0.6,0-1.2-0.5-1.1-1l0.8-14.8C1.2,13.1,1.7,12.6,2.4,12.6z M10.1,0.5L10.1,0.5c2.3,0,4.3,1.9,4.3,4.3v4
		c0,0.9-0.7,2.3-1.5,2c-0.3-0.1-0.8-0.6-1.1-0.3c-0.7,0.6-1.2,0.2-1.8,0c-0.6-0.2-1.4,0.3-2.5,0c-0.9-0.2-1.6-0.7-1.6-1.6v-4
		C5.9,2.4,7.8,0.5,10.1,0.5z M14.2,3.5c1.5,2.1,2.2,3.9-0.3,4.6L14.2,3.5z"
      />
    </svg>
  );
};
