import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as stateActions from "../../../../redux/actions/state-actions";
import * as userActions from "../../../../redux/actions/user-actions";

// Components
import ViewLayout from "../../../components/view-layout";
import CorporateCommon from "../corporate-common";
import Button from "../../../components/common/button";

// Utilities
import { verifyForm, shakeElement } from "../../../../utilites/animation";
import { convertDateToDbDate } from "../../../../utilites/date";
import { createCorporate } from "../../../../api/corporate";
import { storeImage, deleteImage } from "../../../../api/image";
import { formatAfter12Hours } from "../../../../utilites/format";

// Styles
import "../corporate-business-styles.css";

export default () => {
  const history = useHistory();
  const { _id, images: userImages } = useSelector((store) => store.user.user);
  const dispatch = useDispatch();
  const [commonData, setCommonData] = useState({});
  const userImage = userImages[0] || "";
  const {
    phoneInput,
    showPhone,
    addressInput,
    cityInput,
    stateInput,
    zipInput,
    hours,
    featuresInput,
    websiteInput,
    facebookInput,
    instagramInput,
    deliveryLinkInput,
    locationImageInput,
  } = commonData || {};
  const locationData = {
    mainBusinessId: _id,
    phone: phoneInput,
    showPhone,
    address: addressInput,
    city: cityInput,
    state: stateInput,
    zip: zipInput,
    hours,
    features: featuresInput,
    website: websiteInput,
    facebook: facebookInput,
    instagram: instagramInput,
    deliveryLink: deliveryLinkInput,
    images: userImage ? [userImage] : [],
    lastUpdated: convertDateToDbDate(),
    createdDate: convertDateToDbDate(),
  };

  const handleCommonData = (commonData) => {
    setCommonData(commonData);
  };

  const handleSubmit = async () => {
    const ids = {
      phoneInput,
      addressInput,
      cityInput,
      stateInput,
      zipInput,
    };
    const hasRequiredInfo = verifyForm(ids);

    if (hasRequiredInfo) {
      dispatch(stateActions.setLoader(true));
      if (
        locationImageInput &&
        locationImageInput.length &&
        locationImageInput[0] !== userImage
      ) {
        const newImage = await storeImage(locationImageInput[0], "business");
        locationData.images = [newImage];
      }

      const { locations } =
        (await createCorporate({
          ...locationData,
          hours: formatAfter12Hours(locationData.hours),
        })) || {};
      if (locations) {
        setTimeout(() => {
          dispatch(userActions.updateUser({ locations }));
          history.push("/business/corporate");
          dispatch(stateActions.setLoader(false));
        }, 1500);
      } else {
        if (locationImageInput) await deleteImage(locationData.images[0]);
        dispatch(stateActions.setLoader(false));
      }
    } else {
      shakeElement("create-corporate-profile");
    }
  };

  return (
    <ViewLayout id="create-corporate-profile">
      <div className="card card-form">
        <div className="card-info-form">
          <div className="corporate-title-container">
            <h2>ADD LOCATION</h2>
          </div>
        </div>
      </div>

      <CorporateCommon
        page="create-corporate"
        handleCommonData={handleCommonData}
      />

      <div id="consentInput" className="card card-form">
        <div className="card-info-form">
          <div className="listing-edit-submit-container">
            <Button
              className="submit-button-CreateListing"
              text="Cancel"
              isPrimary={false}
              handleClick={() => history.goBack()}
            />
            <Button
              className="submit-button-CreateListing"
              text="Submit"
              handleClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </ViewLayout>
  );
};
