import React from "react";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 23"
    >
      <path
        d="M11,17.1v2.2c0,0.5,0.4,0.8,0.8,0.8h1.2c0.5,0,0.8-0.4,0.8-0.8v-1.8C12.6,17.4,11.7,17.3,11,17.1C11,17.1,11,17.1,11,17.1z
		"
      />
      <path d="M22.2,17.5v1.8c0,0.5,0.4,0.8,0.8,0.8h1.2c0.5,0,0.8-0.4,0.8-0.8v-2.2H25C24.4,17.3,23.5,17.4,22.2,17.5z" />
      <path
        d="M27,8.2h-1.6c-0.3,0-0.5,0.2-0.6,0.4l-0.5-1.8c-0.5-2-2.3-3.4-4.4-3.4h-4c-2.1,0-3.9,1.4-4.4,3.4l-0.4,1.8
		c-0.1-0.2-0.4-0.3-0.6-0.3H8.9C8.5,8.2,8.1,8.6,8.1,9v0.5c0,0.4,0.4,0.8,0.8,0.8h1.3c-0.6,0.9-0.8,1.9-0.6,3c0,0,0.1,1.6,0.5,2.8
		c0.3,1.2,15.8,1.4,16,0l0.5-2.8c0.2-1.1-0.1-2.2-0.7-3H27c0.4,0,0.8-0.4,0.8-0.8V9C27.8,8.6,27.5,8.2,27,8.2z M14.5,13.1
		c0,0.4-0.3,0.6-0.6,0.6H12c-0.4,0-0.6-0.3-0.6-0.6v-0.4c0-0.4,0.3-0.6,0.6-0.6l1.9,0.3C14.2,12.5,14.5,12.7,14.5,13.1L14.5,13.1z
		 M14.3,8.3c-0.7,0-1.3,0.1-1.9,0.3c-0.1,0-0.2,0.1-0.3,0.1L12.5,7c0.4-1.6,1.8-2.7,3.4-2.7h4c1.6,0,3.1,1.1,3.4,2.7l0.4,1.6
		c-0.1,0-0.3-0.1-0.4-0.1c-0.5-0.2-1-0.2-1.6-0.2H14.3z M24.8,13.1c0,0.4-0.3,0.6-0.6,0.6h-1.9c-0.4,0-0.6-0.3-0.6-0.6V13
		c0-0.4,0.3-0.6,0.6-0.6l1.9-0.3c0.4,0,0.6,0.3,0.6,0.6V13.1z"
      />
      <path d="M29.5,22.4H6.3v-1.8c0-0.7-0.6-1.3-1.3-1.3H0.5v-1.3H5c1.4,0,2.6,1.2,2.6,2.6v0.5h21.9L29.5,22.4L29.5,22.4z" />
      <path
        d="M5.4,0.6H3.7c-1,0-1.8,0.8-1.8,1.8v3.4c0,1,0.8,1.8,1.8,1.8h0.4V17h1V7.5h0.3c1,0,1.8-0.8,1.8-1.8V2.4
		C7.1,1.4,6.4,0.6,5.4,0.6z M6.2,5.8c0,0.5-0.4,0.8-0.8,0.8H3.7c-0.5,0-0.8-0.4-0.8-0.8V2.4c0-0.5,0.4-0.8,0.8-0.8h1.6
		c0.5,0,0.8,0.4,0.8,0.8V5.8z"
      />
      <path
        d="M4.6,2.3c-0.5,0-0.9,0.4-1,0.9c0,0,0,0,0,0.1l0.4,0.4c0.2-0.2,0.4-0.3,0.7-0.3s0.5,0.1,0.7,0.3l0.4-0.4c0,0,0-0.1,0-0.1
		C5.5,2.7,5.1,2.3,4.6,2.3z"
      />
    </svg>
  );
};
