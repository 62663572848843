import React from "react";

// Styles
import "./select.css";

export default ({
  classNames = "",
  options,
  value,
  handleChange,
  children,
  styles,
  name,
}) => {
  const createOptions = () => {
    return options.map((option, idx) => (
      <option value={option} key={idx}>
        {option ? option : `All ${name}`}
      </option>
    ));
  };

  return (
    <div className="input-container-Input">
      <div className="input-svg-container-Input">{children}</div>
      <select
        className={classNames}
        style={styles}
        onChange={handleChange}
        value={value}
      >
        {createOptions()}
      </select>
    </div>
  );
};
