import { callAPI } from ".";
import { baseURL } from "./url";
import { formatQueryParams } from "../utilites/format";

const telemarketingURL = baseURL("/telemarketing");

export function getTelemarketingBusinesses(options) {
  const url = `${telemarketingURL}?${formatQueryParams(options)}`;
  return callAPI(url, "GET");
}

export function telemarketingUpdate(data) {
  return callAPI(telemarketingURL, "POST", data);
}
