import React from "react";
import { CSSTransition } from "react-transition-group";
import { connect } from "react-redux";
import * as stateActions from "../../../redux/actions/state-actions";

// Assets
import ErrorSvg from "../../../assets/svg/error";

// Styles
import "./message-bar.css";

const MessageBar = ({ messageType, message, clearMessage }) => {
  const isMessage = message ? true : false;
  const isError = messageType === "error";
  let color = "hsl(197, 99%, 40%)";
  if (message && isError) color = "hsl(6, 100%, 60%)";
  if (message !== "") {
    setTimeout(() => {
      clearMessage();
    }, 4000);
  }
  return (
    <CSSTransition in={isMessage} timeout={200} classNames="error">
      <div className="error-bar-wrapper" style={{ backgroundColor: color }}>
        {isError && (
          <div className="error-bar-svg-container">
            <ErrorSvg />
          </div>
        )}
        <p className="error-text">{message}</p>
      </div>
    </CSSTransition>
  );
};

const mapStateToProps = store => ({
  messageType: store.state.messageType,
  message: store.state.message
});

const mapDispatchToProps = dispatch => ({
  clearMessage: () => dispatch(stateActions.setMessage({ message: "" }))
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageBar);
