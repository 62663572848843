import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { useDispatch, useSelector } from "react-redux";
import * as stateActions from "../../../../redux/actions/state-actions";

// Components
import ListingSkeleton from "../listing-skeleton";

// Utilities
import SetMapLocation from "../../../../utilites/hooks/setMapLocation";
import { listingGet } from "../../../../api/admin";
import { businessGet } from "../../../../api/business";
import { getMarketingLocation } from "../../../../api/marketing";

export default (WrappedComponent) => {
  const Component = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { match, results } = props || {};
    const { id: currLessonId } = match.params || {};
    const [ad, setAd] = useState(false);
    const [business, setBusiness] = useState(false);
    const [marketing, setMarketing] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const showComponent = !!(isLoaded && ad && business);
    SetMapLocation();

    useEffect(() => {
      const { _id } = ad || {};
      if (_id === currLessonId) return;
      const foundAd = results.find(({ _id }) => _id === currLessonId);

      getData(foundAd);
    }, [currLessonId, ad]);

    const getData = async (foundAd) => {
      if (!inProgress) {
        setIsLoaded(false);
        setInProgress(true);
        if (!foundAd) {
          const { ad } =
            (await listingGet({ id: currLessonId, isGrowth: false })) || {};

          if (!ad) {
            const { ad: listingAd } =
              (await listingGet({ id: currLessonId, isGrowth: true })) || {};

            if (!listingAd) {
              dispatch(
                stateActions.setMessage({
                  message: "Not Found",
                  messageType: "error",
                })
              );
              return history.push("/");
            } else {
              foundAd = listingAd;
            }
          } else {
            foundAd = ad;
          }
        }

        const { businessId: adBusinessId } = foundAd;
        setAd(foundAd);

        if (!adBusinessId) {
          setBusiness({});
          setInProgress(false);
          return setIsLoaded(true);
        }

        const { id: businessId } = business || {};
        if (adBusinessId !== businessId) {
          const { user } = (await businessGet(adBusinessId)) || {};
          const { phone: newPhone } = user;
          const { marketing: newMarketing } = await getMarketingLocation(
            newPhone
          );

          setBusiness(user);
          setMarketing(newMarketing);
        }

        setInProgress(false);
        setIsLoaded(true);
      }
    };

    return (
      <>
        {showComponent ? (
          <CSSTransition in={showComponent} timeout={250} classNames="fade">
            <WrappedComponent
              {...props}
              ad={ad}
              business={business}
              marketing={marketing}
              setAd={setAd}
            />
          </CSSTransition>
        ) : (
          <ListingSkeleton />
        )}
      </>
    );
  };
  return Component;
};
