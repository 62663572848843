import React from "react";
import { useDispatch } from "react-redux";
import * as stateActions from "../../../redux/actions/state-actions";
import moment from "moment";

// Components
import DetailViewLayout from "../../components/detail-view-scroll-layout";
import ButtonLink from "../../routes/business/button-link";
import TimeDisplay from "../../routes/listing/time-display";
import Date from "../../routes/listing/date";
import Features from "../../routes/listing/features";
import Contact from "../../routes/listing/contact";
import Button from "../../components/common/button";

// Utilities
import { toTitleCase } from "../../../utilites/format";

// Assets
import DirectionArrow from "../../../assets/svg/directionArrow";

// Styles
import "../../routes/listing/listing.css";
import "./confirm-listing.css";

export default ({ data, user, setIsConfirm, handleSave }) => {
  const dispatch = useDispatch();
  const {
    images,
    title,
    businessName,
    description,
    virtualLink,
    promoCode,
    days,
    categories,
    features,
  } = data || {};
  const { cat1Img, cat2Img } = categories;
  const allImages = [...images];
  const currentDay = moment().format("dddd");
  const { open: startTime, close: endTime } = days.hours[currentDay];
  if (cat2Img) allImages.unshift(cat2Img);
  allImages.unshift(cat1Img);

  const handlePromoClick = () => {
    const imageUrl =
      typeof promoCode === "string"
        ? promoCode
        : URL.createObjectURL(promoCode);
    dispatch(stateActions.setPromoCode(imageUrl));
  };

  return (
    <>
      <DetailViewLayout images={allImages} status="active">
        <div className="listing-content-container">
          <div className="listing-header-container listing-header-container-active">
            <p className="listing-title-main">{title.toUpperCase()}</p>
          </div>
          <div className="listing-business-info-conatiner">
            <p className="pointer listing-business-name">
              {toTitleCase({ input: businessName, lowerCaseRest: false })}
            </p>
            <div className="pointer listing-business-info-svg listing-fill-active">
              <DirectionArrow />
            </div>
          </div>
          <p className="listing-spacing listing-description-main">
            {description}
          </p>

          <ButtonLink
            isDateCurrent
            status="active"
            text="VIRTUAL LINK"
            link={virtualLink}
            isClickable={false}
          />

          {promoCode && (
            <div className="listing-spacing listing-center-content">
              <div
                className="redemtion-button-code-button listing-header-container-active"
                onClick={handlePromoClick}
              >
                <p className="redemtion-button-code-text color-white">
                  REDEMPTION CODE
                </p>
              </div>
            </div>
          )}

          <TimeDisplay
            isDateCurrent
            isTimeBar={false}
            startTime={startTime}
            endTime={endTime}
            adStatus="active"
          />
          <Date data={data} status="active" />
        </div>

        <Features
          adTitle={title}
          name={businessName}
          categories={categories}
          features={features}
          adStatus="active"
          isClickable={false}
        />

        <Contact
          ad={data}
          business={user && "location" in user ? user : data}
          status="active"
          isClickable={false}
        />
      </DetailViewLayout>

      <div className="confirm-button-wrapper">
        <div className="confirm-button-container">
          <Button
            className="submit-button-CreateListing"
            text="Edit"
            isPrimary={false}
            handleClick={() => setIsConfirm(false)}
          />
          <Button
            className="submit-button-CreateListing"
            text="Confirm"
            handleClick={handleSave}
          />
        </div>
      </div>
    </>
  );
};
