import React, { useState } from "react";
import moment from "moment";
import { CSSTransition } from "react-transition-group";
import { connect } from "react-redux";
import * as stateActions from "../../../redux/actions/state-actions";
import * as userActions from "../../../redux/actions/user-actions";

// Components
import Checkbox from "../../components/common/checkbox";
import Button from "../common/button";

// Utilities
import useIsBusiness from "../../../utilites/hooks/useIsBusiness";
import { setBusinessCovid } from "../../../api/business";

// Assets
import Covid from "../../../assets/svg/covid";

// Styles
import "./covid-modal.css";

const CovidModal = ({
  user,
  covidModal,
  setCovidModal,
  updateUser,
  setLoader,
  setMessage,
}) => {
  const isBusiness = useIsBusiness();
  const { _id: businessId, covid } = user || {};
  const { covidCompliant: covidCompliantProp } = covid || {};
  const [covidCompliant, setCovidCompliant] = useState(
    covidCompliantProp || false
  );

  const handleSave = async () => {
    setLoader(true);
    const data = {
      id: businessId,
      covid: { covidCompliant, date: moment().format() },
    };
    const { user } = await setBusinessCovid(data);
    if (user) {
      updateUser(user);
      setCovidModal(false);
      setMessage({ message: "Successful" });
    }
    setLoader(false);
  };

  const handleCancel = () => {
    setCovidModal(false);
    localStorage.setItem("covidModal", "true");
  };

  return isBusiness ? (
    <>
      <CSSTransition in={covidModal} timeout={250} classNames="fade">
        <div className="modal-page-click-overlay" style={{ zIndex: 990 }} />
      </CSSTransition>
      <CSSTransition in={covidModal} timeout={250} classNames="top-slide">
        <div className="no-scroll-bars modal-wrapper" style={{ zIndex: 991 }}>
          <div className="covid-wrapper">
            <div className="covid-modal-title">
              <h2>COVID Compliance Form</h2>
              <div className="covid-modal-icon">
                <Covid />
              </div>
            </div>
            <p style={{ marginTop: "5px" }}>
              By accepting this form, you are agreeing to the public health and
              safety guidelines provided by the CDC and your County/State’s
              Department of Public Heath, which include, but are not limited to:
              <br />
              <br />
              ✓ Ensuring adequate ventilation in all spaces.
              <br /> <br />✓ Physical distancing to the maximum extent possible.
              <br /> <br />✓ Use of face coverings by workers (where respiratory
              protection is not required) and customers/clients.
              <br /> <br />✓ Frequent handwashing and regular cleaning and
              disinfection.
              <br /> <br />✓ Training workers on these and other elements of the
              COVID-19 prevention plan.
              <br />
              <br />
              Please see link below for full guideline details.
            </p>
            <a
              className="covid-more-info-link"
              href="https://files.covid19.ca.gov/pdf/guidance-dine-in-restaurants.pdf"
              target="_blank"
            >
              Click here for full guideline details
            </a>
            <div className="covid-agree-container">
              <Checkbox
                state={covidCompliant}
                params={[!covidCompliant]}
                handleClick={(value) => setCovidCompliant(value)}
              />
              <p>
                I agree to follow the above terms provided by the CDC and local
                County/State Public Health protocols.
              </p>
            </div>

            <div className="covid-modal-button-container">
              <Button
                text="Cancel"
                size="small"
                isPrimary={false}
                handleClick={handleCancel}
              />
              <Button text="Save" size="small" handleClick={handleSave} />
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  ) : null;
};

const mapStateToProps = (store) => ({
  user: store.user.user,
  isUser: store.user.isUser,
  covidModal: store.state.covidModal,
});

const mapDispatchToProps = (dispatch) => ({
  setCovidModal: (boolean) => dispatch(stateActions.setCovidModal(boolean)),
  updateUser: (userObj) => dispatch(userActions.updateUser(userObj)),
  setLoader: (loaderState) => dispatch(stateActions.setLoader(loaderState)),
  setMessage: (messageObj) => dispatch(stateActions.setMessage(messageObj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CovidModal);
