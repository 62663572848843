import React from "react";

// Styles
import "./svg.css";

export default (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 30 30"
    >
      <path
        className="svg-clip-rule"
        d="M15,0.6l4.4,9.8l10.4,1.2L22,18.9l2.1,10.5L15,24.1l-9.1,5.3L8,18.9l-7.7-7.3l10.4-1.2L15,0.6z M11,16.2
		l2.3,1.8l4.7-6.2l1.6,1.3l-5.3,7l-0.6,0.8l-0.8-0.6l-3.1-2.4L11,16.2z"
      />
    </svg>
  );
};
