import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import * as stateActions from "../../../redux/actions/state-actions";

// Components
import ViewLayout from "../../components/view-layout";
import BusinessCard from "./busniess-card";
import Button from "../../components/common/button";

// Utilities
import {
  getSignedUpBusinesses,
  dataEntryCreate,
} from "../../../api/data-entry";
import { toTitleCase } from "../../../utilites/format";
import UseGetEmployeeAnalytics from "../../../utilites/hooks/employeeAnalytics";
import { setEmployeeAnalytics } from "../../../api/employee";
import GetFilterIcon from "../../components/filter-icons";
// import { yelpVerifyBusiness } from "../../../api/business";

// Assets
import Arrow from "../../../assets/svg/back";

// Styles
import "./data-entry.css";

export default () => {
  const dispatch = useDispatch();
  const employeeAnalytics = UseGetEmployeeAnalytics();
  const { accountType, fName, lName } = useSelector(
    (store) => store.user.employee
  );
  const isTelemarketing = accountType === "telemarketing";
  const [isPage, setIsPage] = useState(false);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [signUp, setSignUp] = useState([]);
  const [optionalSection, setOptionalSection] = useState(
    isTelemarketing || false
  );
  const [isSearching, setIsSearching] = useState(false);
  const [nameInput, setNameInput] = useState("");
  const [managerNameInput, setManagerNameInput] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [phoneInput, setPhoneInput] = useState("");
  const [addressInput, setAddressInput] = useState("");
  const [cityInput, setCityInput] = useState("");
  const [stateInput, setStateInput] = useState("");
  const [zipInput, setZipInput] = useState("");
  const agentName = `${fName} ${lName}`;

  const handleChange = (evt, type, newValue) => {
    const { value } = evt.target;
    switch (type) {
      case "nameInput":
        return setNameInput(value);
      case "managerNameInput":
        return setManagerNameInput(value);
      case "emailInput":
        return setEmailInput(value);
      case "phoneInput":
        const newPhoneValue = value
          .replace("-", "")
          .replace(".", "")
          .match(/^[0-9]*$/gi);
        if (newPhoneValue === null) return setPhoneInput(phoneInput);
        return setPhoneInput(newPhoneValue[0].slice(0, 10));
      case "addressInput":
        return setAddressInput(value);
      case "cityInput":
        return setCityInput(value);
      case "stateInput":
        let newStateInput = "";
        if (value) newStateInput = value.match(/\w/gi).join("").slice(0, 2);
        return setStateInput(newStateInput);
      case "zipInput":
        let newZipInput = "";
        if (value) newZipInput = value.match(/\d/gi).join("").slice(0, 5);
        return setZipInput(newZipInput);
    }
  };

  useEffect(() => {
    if (!isTelemarketing) searchSignedUp(0);
  }, []);

  const clearForm = () => {
    setNameInput("");
    setManagerNameInput("");
    setPhoneInput("");
    setEmailInput("");
    setAddressInput("");
    setCityInput("");
    setStateInput("");
    setZipInput("");
  };

  const handleSave = async () => {
    if (!nameInput || !managerNameInput || !emailInput || !phoneInput)
      return dispatch(
        stateActions.setMessage({
          messageType: "error",
          message: "Missing Data",
        })
      );
    setIsSearching(true);
    const newDataEntry = {
      name: nameInput,
      managerName: managerNameInput,
      email: emailInput,
      phone: phoneInput,
      address: addressInput,
      city: cityInput,
      state: stateInput,
      zip: zipInput,
      signedUp: {
        isSignedUp: true,
        agent: agentName,
        timeStamp: moment().format("lll"),
      },
    };
    setEmployeeAnalytics({ ...employeeAnalytics, signed_up: true });
    const { success } = await dataEntryCreate(newDataEntry);
    if (success) {
      dispatch(
        stateActions.setMessage({
          message: "Successful",
        })
      );
      clearForm();
    }
    setIsSearching(false);
  };

  const searchSignedUp = async (page) => {
    dispatch(stateActions.setLoader(true));
    const params = {
      skip: page * 50,
    };
    const { businesses: foundSignUp, count: newCount } =
      await getSignedUpBusinesses(params);
    if (newCount) setTotalCount(newCount);
    if (foundSignUp) {
      setSignUp(foundSignUp);
      if (foundSignUp.length === 50) {
        setIsPage(true);
      } else {
        setIsPage(false);
      }
    }
    dispatch(stateActions.setLoader(false));
    if (page) {
      const element = document.querySelector(".view-layout");
      element.scrollTo(0, 0);
    }
  };

  const setUpdateLocation = (location) => {
    const { _id: locationId } = location;
    const newSignUp = signUp.map((business) => {
      const { _id: businessId } = business;
      if (businessId === locationId) return location;
      return business;
    });
    setSignUp(newSignUp);
  };

  const handlePageClick = (newPage) => {
    setPage(newPage);
    searchSignedUp(newPage);
  };

  return (
    <ViewLayout>
      <div className="card-wrapper signup-wrapper-BusinessSignUp">
        <div className="card card-form">
          <div className="card-info-form">
            <div
              className="data-entry-reset"
              onClick={() => (!isTelemarketing ? searchSignedUp(0) : {})}
            >
              {GetFilterIcon("refresh")}
            </div>

            <h2>{`Hi ${toTitleCase({
              input: fName,
            })}, we have a ${totalCount} business ready for you!`}</h2>

            <div className="card-info-form">
              <div
                className="pointer signup-toggle-split-container"
                onClick={() => setOptionalSection(!optionalSection)}
              >
                <label id="fNameInput" className="pointer label-form">
                  Create Manual Entry:
                </label>
                <div
                  className={`signup-toggle-subsection-svg-container ${
                    optionalSection
                      ? "signup-toggle-subsection-svg-container-open"
                      : ""
                  }`}
                >
                  <Arrow />
                </div>
              </div>

              <div
                className={`card-form signup-toggle-subsection ${
                  optionalSection ? "signup-toggle-subsection-open" : ""
                }`}
              >
                <label id="nameInput" className="label-form">
                  Buiness Name:
                </label>
                <input
                  className="input-style input-form text-form"
                  value={nameInput}
                  onChange={(evt) => handleChange(evt, "nameInput")}
                />

                <label id="locationInput" className="label-form">
                  Manager Name:
                </label>
                <input
                  className="input-style input-form text-form"
                  value={managerNameInput}
                  onChange={(evt) => handleChange(evt, "managerNameInput")}
                />

                <label id="phoneInput" className="label-form">
                  Phone:
                </label>
                <input
                  className="input-style input-form text-form"
                  value={phoneInput}
                  onChange={(evt) => handleChange(evt, "phoneInput")}
                />

                <label id="emailInput" className="label-form">
                  Email:
                </label>
                <input
                  className="input-style input-form text-form"
                  value={emailInput}
                  onChange={(evt) => handleChange(evt, "emailInput")}
                />

                <label id="addressInput" className="label-form">
                  Address:
                </label>
                <input
                  className="input-style input-form text-form"
                  value={addressInput}
                  onChange={(evt) => handleChange(evt, "addressInput")}
                />

                <div
                  className="telemarketing-filter-wrapper"
                  style={{ paddingTop: "10px" }}
                >
                  <label id="cityInput" className="employees-label-form">
                    City:
                  </label>
                  <input
                    className="input-style input-form text-form"
                    value={cityInput}
                    onChange={(evt) => handleChange(evt, "cityInput")}
                  />
                  <label id="stateInput" className="employees-label-form">
                    State:
                  </label>
                  <input
                    className="input-style input-form text-form"
                    value={stateInput}
                    onChange={(evt) => handleChange(evt, "stateInput")}
                  />
                  <label id="zipInput" className="employees-label-form">
                    Zip:
                  </label>
                  <input
                    className="input-style input-form text-form"
                    type="number"
                    value={zipInput}
                    onChange={(evt) => handleChange(evt, "zipInput")}
                  />
                </div>

                <div className="submit-wapper-UserSignUp">
                  <div
                    className="submit-button-UserSignUp"
                    style={{ display: "flex", flex: "row" }}
                  >
                    <Button
                      text="Save"
                      isDisabled={isSearching}
                      handleClick={handleSave}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {signUp.map((business, idx) => (
        <BusinessCard
          business={business}
          agentName={agentName}
          setUpdateLocation={setUpdateLocation}
          page={page}
          idx={idx}
          key={business._id}
        />
      ))}

      <div className="card card-form">
        <div className="marketing-card-wrapper marketing-paging-wrapper">
          {!!page && (
            <div
              className="marketing-page-arrow"
              onClick={() => handlePageClick(page - 1)}
            >
              <Arrow />
            </div>
          )}
          {(isPage || page) && <p className="marketing-page-text">{page}</p>}
          {isPage && (
            <div
              className="marketing-page-arrow marketing-page-arrow-right"
              onClick={() => handlePageClick(page + 1)}
            >
              <Arrow />
            </div>
          )}
        </div>
      </div>
    </ViewLayout>
  );
};
