import React from "react";

export default props => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 26 30"
    >
      <path
        d="M7.8,10.7H3.8c-1.4,0-2.6,1.2-2.6,2.6v14.4c0,1.4,1.2,2.6,2.6,2.6h18.3c1.4,0,2.6-1.2,2.6-2.6V13.3
			c0-1.4-1.2-2.6-2.6-2.6h-3.9v2h4.6v15.7H3.2V12.7h4.6V10.7z"
      />
      <path d="M13,2.9L13,2.9c0.5,0,1,0.4,1,1v15c0,0.5-0.4,1-1,1l0,0c-0.5,0-1-0.4-1-1v-15C12,3.3,12.5,2.9,13,2.9z" />
      <path
        d="M13.7,2L13.7,2c0.4,0.4,0.4,1,0,1.4L9.5,7.5c-0.4,0.4-1,0.4-1.4,0l0,0c-0.4-0.4-0.4-1,0-1.4L12.3,2
			C12.7,1.6,13.3,1.6,13.7,2z"
      />
      <path
        d="M17.9,7.5L17.9,7.5c-0.4,0.4-1,0.4-1.4,0l-4.2-4.2c-0.4-0.4-0.4-1,0-1.4l0,0c0.4-0.4,1-0.4,1.4,0l4.2,4.2
			C18.2,6.5,18.2,7.1,17.9,7.5z"
      />
    </svg>
  );
};
